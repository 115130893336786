/**
 * Created by Nico on 05.07.2016.
 */
define('modules/settings/unit/UnitController',['modules/module', 'settings', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('UnitController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		function ($scope, $controller, BASE_TEMPLATES_PATH) {
			angular.extend(
				this,
				$controller('EntityListController', {
					$scope: $scope,
				})
			);

			$scope.state.requireSuperuserForDelete = true;

			$scope.tableOptions.fields = [
				{
					id: 'Name',
					heading: 'FIELDS.NAME',
					attribute: 'name',
				},
				{
					id: 'UNIT',
					heading: 'FIELDS.UNIT',
					attribute: 'unit',
				},
			];
			$scope.formState = 'root.unit';

			$scope.init('Unit');
		},
	]);
});

