/**
 * Created by nmayer on 09.06.16.
 */
define('modules/settings/priceRule/PriceRuleListController',[
	'modules/module',
	'settings',
	'modules/basemodule/controllers/EntityListController',
	'services/PriceRuleService',
	'directives/controls/customInput/CustomInput',
	'filters/DateFilterTranslatable',
], function (module) {
	'use strict';
	module.controller('PriceRuleListController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		'PriceRuleService',
		function ($scope, $controller, BASE_TEMPLATES_PATH, PriceRuleService) {
			angular.extend(
				this,
				$controller('EntityListController', {
					$scope: $scope,
				})
			);

			$scope.tableOptions.fields = [
				{
					id: 'ID',
					heading: 'Id',
					attribute: 'id',
				},
				{
					id: 'SHOP_NAME',
					heading: 'Shop Name',
					template: BASE_TEMPLATES_PATH + '/settings/priceRule/col-shop-name.html',
					attribute: '',
					orderDisabled: true,
					searchDisabled: true,
				},
				{
					id: 'NAME',
					heading: 'FIELDS.NAME',
					attribute: 'name',
				},
				{
					id: 'Status',
					heading: 'FIELDS.STATUS',
					template: BASE_TEMPLATES_PATH + '/settings/priceRule/col-status.html',
					attribute: '',
				},
				{
					id: 'PRIORITY',
					heading: 'FIELDS.PRIORITY',
					template: BASE_TEMPLATES_PATH + '/settings/priceRule/col-order-number.html',
					searchDisabled: true,
					attribute: '',
				},
				{
					id: 'VALIDFROM',
					heading: 'FIELDS.VALID_FROM',
					template: BASE_TEMPLATES_PATH + '/settings/priceRule/col-create-date.html',
					attribute: 'validFrom',
				},
				{
					id: 'VALIDTO',
					heading: 'FIELDS.VALID_TO',
					template: BASE_TEMPLATES_PATH + '/settings/priceRule/col-update-date.html',
					attribute: 'validTo',
				},
			];
			$scope.formState = 'root.pricerule';

			$scope.init('PriceRule');

			$scope.$on('Priority', function (evt, data) {
				PriceRuleService.update(data);
			});

			/*
                    do not add ng-click to column priority
                */
			$scope.preserveForDialogs = function (item, field) {
				return field.id != 'PRIORITY';
			};
		},
	]);
});

