define('modules/cms/controllers/CmsTagListController',['modules/module', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('CmsTagListController', ['$scope', '$controller', CmsTagListController]);
});

function CmsTagListController($scope, $controller) {
	angular.extend(
		this,
		$controller('EntityListController', {
			$scope: $scope,
		})
	);

	$scope.tableOptions.fields = [
		{
			id: 'NAME',
			heading: 'FIELDS.NAME',
			attribute: 'name',
		},
	];

	$scope.formState = 'root.cmstag';

	$scope.init('CmsTag');
}
;
