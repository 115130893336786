define('services/ContentBuilderHelper',['app', 'modules/module', 'services/FileUploadService'], function (app, module) {
	'use strict';
	return module.factory('ContentBuilderHelper', [
		'$rootScope',
		'FileUploadService',
		function ($rootScope, FileUploadService) {
			var ALL_INSTANCES = 'ALL';
			var PART_GROUP = Object.freeze({ LEAF: 'leaf', LAYOUT: 'layout' });
			var LAYOUT_TYPE = Object.freeze({
				SINGLE: 'single',
				DOUBLE: 'double',
				TRIPLE: 'triple',
				DOUPLE: 'douple',
				TRIBLE: 'trible',
			});
			var ELEMENT_TYPE = Object.freeze({
				ROW: 'row',
				CELL: 'cell',
				SNIPPET: 'snippet',
				LAYOUT: 'layout',
				PART: 'part',
				VOID: 'void',
				PAGE: 'page',
			});
			var VIEWPORT_SIZE = Object.freeze({ DESKTOP: 999999, LAPTOP: 1199, TABLET: 768, MOBILE: 375 });
			var TEMPLATE_APPLICATION_MODE = Object.freeze({ FRONT: 'front', BACK: 'back', REPLACE: 'replace' });

			var _COLUMNS = 6;
			var _PARTITIONS = 12;
			var _MAX_CELLS_IN_ROW = 6;
			var _STEPSIZE = _PARTITIONS / _COLUMNS;
			var _draggedPart = undefined;
			var _metaIdentifierCount = 0;
			var _elementIdentifierCount = 0;
			var _layoutInstances = new Map();
			var _cleanupCrewRegistered = false;
			var _dragging = { v: false };
			var _viewportSetup = 'DESKTOP';
			var _styles = [];
			var _styleClipboard = undefined;

			var _registerElement = function (metaIdentifier) {
				return metaIdentifier * 100000 + _elementIdentifierCount++;
			};

			var _boxStyle = function () {
				return {};
			};

			var _labeledTextStyle = function () {
				return {};
			};

			var cellStyle = function (layoutIndex) {
				var style = {};

				return {};
			};

			var _createPartitions = function (parts) {
				var partitions = {};
				partitions['xs'] = parts;
				partitions['sm'] = parts;
				partitions['md'] = parts;
				partitions['lg'] = parts;
				partitions['xl'] = parts;

				return partitions;
			};

			var _applyPartitionClasses = function (partitioning, responsive, metaId, rowId) {
				var classes = [];
				// classes.push('m' + metaId + '-' + rowId);
				classes.push('c' + rowId);
				if (responsive) {
					for (var att in partitioning) {
						if (partitioning[att] > 0 && partitioning[att] <= 12) {
							classes.push('content-' + partitioning[att]);
						} else {
							classes.push('hide-' + att);
						}
					}
				} else {
					classes.push('content-' + partitioning['xs']);
				}
				return classes;
			};

			var _createBlankCell = function (parts, responsive, type, minSize) {
				//var classes = [];
				if (!responsive) {
					//classes.push('static', 'static-cell');
				}

				var partitioning = _createPartitions(parts);
				var classes = _applyPartitionClasses(partitioning, responsive);

				return {
					type: type || 'stack',
					children: [],
					content: '',
					style: {},
					parts: partitioning,
					classes: classes,
					minSize: minSize || _STEPSIZE,
				};
			};

			var _createDummyCell = function (responsive) {
				var style = {
					'user-select': 'none',
				};
				style['padding'] = '0';
				style['outline'] = '0';

				var partitions = _createPartitions(0);
				var classes = _applyPartitionClasses(partitions, responsive);

				return {
					type: 'dummy',
					style: style,
					parts: partitions,
					classes: classes,
					minSize: 0,
				};
			};

			var _buildRow = function (cellAmount, partitions, metaIdentifier) {
				var row = { type: 'layout', children: [], style: {}, container: 'full' };

				for (var i = 0; i < cellAmount; i++) {
					var parts = _PARTITIONS / cellAmount;
					if (partitions !== undefined && partitions.length > i) {
						parts = partitions[i];
					}
					row.children.push(_createBlankCell(parts));
				}
				row.children.push(_createDummyCell());

				row.identifier = _registerElement(metaIdentifier);
				row.classes = [];
				// row.classes.push('r' + metaIdentifier + '-' + row.identifier);
				row.classes.push('r' + row.identifier);

				_applyPartitioning(row, metaIdentifier);
				row.responsive = {};
				for (var att in VIEWPORT_SIZE) {
					row.responsive[att] = 'FILL';
				}
				row.flowDirection = {};
				for (var att in VIEWPORT_SIZE) {
					row.flowDirection[att] = 'row';
				}
				return row;
			};

			var _buildProductsRow = function (responsive) {
				return [_createBlankCell(12, responsive, 'products', 12), _createDummyCell(responsive)];
			};

			var _buildSumRow = function (responsive) {
				return [
					_createBlankCell(4, responsive),
					_createBlankCell(4, responsive),
					_createBlankCell(4, responsive, 'sum', 4),
					_createDummyCell(responsive),
				];
			};

			var _getTextWidth = function (text, font) {
				var canvas = _getTextWidth.canvas || (_getTextWidth.canvas = document.createElement('canvas'));
				var context = canvas.getContext('2d');
				context.font = font;
				var metrics = context.measureText(text);
				return metrics.width;
			};

			var _blankSnippet = function () {
				return {
					type: 'blank',
					style: {
						box: {},
					},
				};
			};

			var _setDragOverTarget = function (instance, hemisphere, index, container) {
				if (instance === undefined || container === undefined || container.length <= index) {
					return;
				}

				if (container.length > index) {
					container[index].dragClass = hemisphere;
				}
				if (hemisphere === 'before' && container.length > index - 1 && index > 0) {
					container[index - 1].dragClass = 'after';
				}
				if (hemisphere === 'after' && container.length > index + 1) {
					container[index + 1].dragClass = 'before';
				}

				instance.dragOverTarget = {
					index: index,
					container: container,
				};
			};

			var _clearDragOverTarget = function (element) {
				if (element !== undefined) {
					element.dragOver = false;
					element.dragClass = '';
				}
			};

			var _clearMoveIndicator = function (instance) {
				if (instance === undefined) {
					return;
				}
				if (instance === ALL_INSTANCES) {
					for (var key in _layoutInstances.keys()) {
						_clearMoveIndicator(_layoutInstances.get(key));
					}
					return;
				}

				if (instance.dragMoveTarget !== undefined) {
					if (instance.dragMoveTarget.container.length > instance.dragMoveTarget.index) {
						_clearMoveTarget(instance.dragMoveTarget.container[instance.dragMoveTarget.index]);
					}
				}
			};

			var _clearMoveTarget = function (element) {
				if (element !== undefined) {
					element.moveTarget = false;
					element.moveClass = '';
				}
				_draggedPart = undefined;
			};

			var _clearDragOverIndicators = function (instance) {
				if (instance === undefined) {
					return;
				}
				if (instance === ALL_INSTANCES) {
					for (var key in _layoutInstances.keys()) {
						_clearDragOverIndicators(_layoutInstances.get(key));
					}
					return;
				}

				if (instance.dragOverTarget !== undefined) {
					if (instance.dragOverTarget.container.length > instance.dragOverTarget.index) {
						_clearDragOverTarget(instance.dragOverTarget.container[instance.dragOverTarget.index]);
					}
					if (
						instance.dragOverTarget.container.length > instance.dragOverTarget.index - 1 &&
						instance.dragOverTarget.index > 0
					) {
						_clearDragOverTarget(instance.dragOverTarget.container[instance.dragOverTarget.index - 1]);
					}
					if (instance.dragOverTarget.container.length > instance.dragOverTarget.index + 1) {
						_clearDragOverTarget(instance.dragOverTarget.container[instance.dragOverTarget.index + 1]);
					}
					if (instance.dragOverTarget.container.length > instance.dragOverTarget.index + 2) {
						_clearDragOverTarget(instance.dragOverTarget.container[instance.dragOverTarget.index + 2]);
					}
					instance.dragOverTarget = undefined;
				}
			};

			var _evaluatePartitioning = function (row, responsive) {
				if (responsive) {
					return;
				}

				var sum = 0;
				var minSum = 0;
				for (var c = 0; c < row.children.length; c++) {
					sum += row.children[c].parts['xs'];
					minSum += row.children[c].minSize;
				}
				if (sum > _PARTITIONS) {
					// row is too big

					var missingParts = sum - _PARTITIONS;
					//var actualElements = row[row.length - 1].type === 'dummy' ? row.length -1 : row.length;
					if (minSum > _PARTITIONS) {
						// minimum row size would still be too big
						// illegal state
						console.log('Row minimum size exceeds partition capacity. This should be prevented.');
						return;
					}

					var dummySize =
						row.children[row.children.length - 1].type === 'dummy'
							? row.children[row.children.length - 1].parts['xs']
							: 0;
					if (dummySize > 0) {
						if (missingParts <= dummySize) {
							// the dummy is big enough to create the space
							row.children[row.children.length - 1].parts['xs'] = dummySize - missingParts;
							return;
						} else {
							missingParts -= dummySize;
							row.children[row.children.length - 1].parts['xs'] = 0;
						}
					}

					// needs more space still
					// cut down right to left until it fits

					for (var i = row.children.length - 1; i >= 0; i--) {
						if (row.children[i].parts['xs'] > row.children[i].minSize) {
							if (missingParts <= row.children[i].parts['xs'] - _STEPSIZE) {
								row.children[i].parts['xs'] -= missingParts;
								missingParts = 0;
								break;
							} else {
								missingParts -= row.children[i].parts['xs'] - _STEPSIZE;
								row.children[i].parts['xs'] = row.children[i].minSize;
							}
						}
					}
				}

				return;
			};

			var _applyPartitioning = function (row, metaIdentifier, responsive) {
				var _sum = 0;
				for (var c = 0; c < row.children.length; c++) {
					if (c === row.children.length - 1) {
						row.children[row.children.length - 1].parts['xs'] = _PARTITIONS - _sum;
						if (row.children[row.children.length - 1].parts['xs'] < 0) {
							row.children[row.children.length - 1].parts = _createPartitions(0);
						}
					} else {
						_sum += row.children[c].parts['xs'];
					}

					/*
                     if (row[c].style) {
                     row[c].style['width'] = (row[c].parts * (100 / _PARTITIONS)).toString() + "%";
                     }
                     */
					row.children[c].classes = _applyPartitionClasses(
						row.children[c].parts,
						responsive,
						metaIdentifier,
						row.identifier
					);
				}
			};

			var _createElement = function (blueprint, targetType, metaIdentifier) {
				//if (targetType === ELEMENT_TYPE.CELL || targetType === ELEMENT_TYPE.SNIPPET) {

				var snippet = _blankSnippet();
				snippet.identifier = _registerElement(metaIdentifier);

				switch (blueprint.type) {
					case 'text':
					case 'accordion':
					case 'list':
					case 'carousel':
					case 'image':
					case 'html':
					case 'product_slider':
					case 'section':
					case 'layout': {
						snippet.type = blueprint.type;
						snippet.style = {};
						snippet.edit = true;
						return snippet;
					}
				}

				//} else {
				switch (blueprint.type) {
					case 'single_row': {
						return _buildRow(1, undefined, metaIdentifier);
					}
					case 'double_row': {
						return _buildRow(2, undefined, metaIdentifier);
					}
					case 'triple_row': {
						return _buildRow(3, undefined, metaIdentifier);
					}
					case 'douple_row': {
						return _buildRow(2, [8, 4], metaIdentifier);
					}
					case 'trible_row': {
						return _buildRow(2, [4, 8], metaIdentifier);
					}
					case 'quad_row': {
						return _buildRow(4, [3, 3, 3, 3], metaIdentifier);
					}
					case 'custom_row': {
						var partsIns = blueprint.args ? blueprint.args : '12';
						var parts = partsIns.split('|');
						var total = 0;
						for (var i = 0; i < parts.length; i++) {
							total += parseInt(parts[i]);
						}
						if (total < 12) {
							parts.push((12 - total).toString());
						} else if (parts > 12) {
							throw new Error('Row cannot have more than 12 parts');
						}

						return _buildRow(parts.length, parts, metaIdentifier);
					}
				}
				// produce a row and cell and stuff
				//}
			};

			var _reevaluateRow = function (container, parentContainer, metaIdentifier, responsive) {
				if (container.children.length > 0 && container.children[0].parts !== undefined) {
					_evaluatePartitioning(container, responsive);
					_applyPartitioning(container, metaIdentifier, responsive);

					if (
						parentContainer !== undefined &&
						parentContainer.children !== undefined &&
						(container.children.length <= 0 ||
							(container.children.length === 1 && container.children[0].type === 'dummy'))
					) {
						parentContainer.children.splice(parentContainer.children.indexOf(container), 1);
					}
				}
			};

			var _deleteElement = function (metaIdentifier, container, index, parentContainer) {
				if (container !== undefined && index !== undefined && index < container.children.length) {
					container.children.splice(index, 1);

					if (parentContainer !== undefined) {
						_reevaluateRow(container, parentContainer, metaIdentifier);
					}
				}
			};

			var _relocateElement = function (
				instance,
				t_hemisphere,
				t_index,
				t_container,
				tp_container,
				responsive,
				targetType
			) {
				if (instance === undefined) {
					return;
				}

				if (t_hemisphere === undefined || t_index === undefined || t_container === undefined) {
					return;
				}

				var migrant = undefined;
				if (instance.dragMoveTarget) {
					if (instance.dragMoveTarget.container.children.length > instance.dragMoveTarget.index) {
						migrant = instance.dragMoveTarget.container.children[instance.dragMoveTarget.index];
					} else {
						return;
					}
					if (instance.dragMoveTarget.container === t_container) {
						if (instance.dragMoveTarget.index !== t_index) {
							// If the relocation is within the same array and the index increases we have to adjust the target index
							if (instance.dragMoveTarget.index < t_index) {
								t_index -= 1;
							}
						} else {
							// If the relocation is within the same array and the index is the same, there is nothing to do
							_clearMoveTarget(migrant);
							return;
						}
					}
					// Remove target element from old position
					var sourceContainer = instance.dragMoveTarget.container;
					sourceContainer.children.splice(instance.dragMoveTarget.index, 1);
					instance.dragMoveTarget = undefined;

					if (t_container.children.length - 1 === t_index && t_container.children[t_index].type === 'dummy') {
						t_hemisphere = 'before';
					}
					_reevaluateRow(sourceContainer, tp_container, instance.identifier, responsive);
				} else if (_draggedPart !== undefined) {
					migrant = _createElement(_draggedPart, targetType, instance.identifier);
				} else {
					return;
				}

				_clearMoveTarget(migrant);

				if (!responsive) {
					if (t_container.children.length > 0 && t_container.children[t_index].type) {
						var minSum = 0;
						for (var c = 0; c < t_container.children.length; c++) {
							minSum += t_container.children[c].minSize;
						}
						//var actualElements = t_container[t_container.length - 1].type === 'dummy' ? t_container.length - 1 : t_container.length;
						if (minSum >= _PARTITIONS) {
							// too many elements in here
							return;
						}
					}
				}

				// Add element at new position
				if (t_hemisphere === 'before') {
					t_container.children.splice(t_index, 0, migrant);
				}
				if (t_hemisphere === 'after') {
					if (t_container.children.length > 0) {
						t_container.children.splice(t_index + 1, 0, migrant);
					} else {
						t_container.children.splice(t_index, 0, migrant);
					}
				}

				_reevaluateRow(t_container, tp_container, instance.identifier, responsive);
			};

			var _clone = function (obj) {
				var clone = {};

				if (typeof obj === 'object') {
					for (var att in obj) {
						if (Array.isArray(obj[att])) {
							clone[att] = [];
							for (var i = 0; i < obj[att].length; i++) {
								clone[att].push(_clone(obj[att][i]));
							}
						} else if (typeof obj[att] === 'object') {
							clone[att] = _clone(obj[att]);
						} else {
							clone[att] = obj[att];
						}
					}
				} else {
					clone = obj;
				}

				return clone;
			};

			var _copyElement = function (metaIdentifier, eType, element) {
				var ini = _layoutInstances.get(metaIdentifier);
				ini.clipboard = _clone(element);
				ini.clipboard.elementType = eType;
			};

			var _duplicateElement = function (metaIdentifier, index, elementType, parent, targetElement) {
				var ini = _layoutInstances.get(metaIdentifier);
				var clone = _clone(targetElement);
				clone.identifier = _registerElement(metaIdentifier);
				parent.children.splice(index, 0, clone);

				if (elementType === ELEMENT_TYPE.CELL) {
					_reevaluateRow(parent, undefined, metaIdentifier, true);
				}
			};

			var _decreaseSize = function (index, cell, row, metaIdentifier, responsive) {
				if (row.children.length >= _COLUMNS + 1 && !responsive) {
					return;
				}

				if (cell.parts['xs'] > (cell.minSize !== undefined ? cell.minSize : _STEPSIZE)) {
					cell.parts = _createPartitions(cell.parts['xs'] - _STEPSIZE);
					if (!responsive) {
						if (
							(row.children[row.children.length - 1].type === 'dummy' ||
								row.children[row.children.length - 1].type === 'sum') &&
							row.children[row.children.length - 1].parts['xs'] < _PARTITIONS - _STEPSIZE
						) {
							row.children[row.children.length - 1].parts = _createPartitions(
								cell.parts['xs'] + _STEPSIZE
							);
						}
					}
				}

				_reevaluateRow(row, undefined, metaIdentifier, responsive);
			};

			var _increaseSize = function (index, cell, row, metaIdentifier, responsive) {
				if (row.children.length >= _COLUMNS + 1 && !responsive) {
					return;
				}

				if (cell.parts['xs'] <= _PARTITIONS - _STEPSIZE) {
					if (!responsive) {
						if (
							row.children[row.children.length - 1].parts['xs'] >= _STEPSIZE &&
							(row.children[row.children.length - 1].type === 'dummy' ||
								(row.children[row.children.length - 1].type === 'sum' &&
									row.children[row.children.length - 1].parts > _STEPSIZE * 2))
						) {
							row.children[row.children.length - 1].parts = _createPartitions(
								cell.parts['xs'] - _STEPSIZE
							);
							cell.parts = _createPartitions(cell.parts['xs'] + _STEPSIZE);
						}
					} else {
						cell.parts = _createPartitions(cell.parts['xs'] + _STEPSIZE);
					}
				}

				_reevaluateRow(row, undefined, metaIdentifier, responsive);
			};

			var _addRow = function (instance) {
				// Deprecated
				instance.layout.root.push([
					{ type: 'blank', content: 'Bitte Layout wählen', style: { width: '100%' }, minSize: _PARTITIONS },
				]);
			};

			var _addCell = function (row, responsive) {
				if (!responsive) {
					var minSum = 0;
					for (var c = 0; c < row.children.length; c++) {
						minSum += row.children[c].minSize;
					}
					if (minSum >= _PARTITIONS) {
						// too many elements in here
						return;
					} else if (
						row.children.length === _MAX_CELLS_IN_ROW &&
						row.children[row.children.length - 1].type !== 'dummy'
					) {
						return;
					} else if (row.children.length > _MAX_CELLS_IN_ROW) {
						return;
					}
				}
				for (var c = row.children.length - 1; c >= 0; c--) {
					if (!responsive && c === row.length - 1) {
						if (row.children[c].parts['xs'] >= _STEPSIZE && row.children[c].type === 'dummy') {
							var par = row.children[c].parts['xs'];
							row.children[c].parts = _createPartitions(0);
							row.children.splice(c, 0, _createBlankCell(par, responsive));
							break;
						}
						if (row.children[c].parts['xs'] >= _STEPSIZE * 3 && row.children[c].type === 'sum') {
							row.children[c].parts = _createPartitions(row.children[c].parts['xs'] - _STEPSIZE);
							row.children.splice(c, 0, _createBlankCell(_STEPSIZE, responsive));
							break;
						}
						continue;
					}

					if (
						row.children[c].parts['xs'] / _STEPSIZE >= 2 &&
						row.children[c].minSize <= row.children[c].parts['xs'] - _STEPSIZE
					) {
						var par = row.children[c].parts['xs'] - _STEPSIZE;
						row.children[c].parts = _createPartitions(par);
						row.children.splice(row.children.length - 1, 0, _createBlankCell(_STEPSIZE, responsive));
						break;
					} else if (responsive) {
						row.children.splice(row.children.length - 1, 0, _createBlankCell(_STEPSIZE, responsive));
						break;
					}
				}

				_applyPartitioning(row.children, metaIdentifier, responsive);
			};

			var _elementSelected = function (metaIdentifier, elementIdentifier, elementType, snippetType, element) {
				_toggleParts(false);
				_toggleOutline(false);
				var ini = _layoutInstances.get(metaIdentifier);
				if (ini) {
					ini.selectedElement = {
						elementIdentifier: elementIdentifier || 'NONE',
						elementType: elementType,
						snippetType: snippetType,
						element: element,
					};
				}
				$rootScope.$emit('elementSelected', { metaIdentifier: metaIdentifier });
			};

			var _cancelDragProcess = function () {
				_layoutInstances.forEach(function (entry, key) {
					var ini = entry;
					_clearMoveIndicator(ini);
					_clearDragOverIndicators(ini);
					_clearDragOverTarget(ini);
				});
			};

			var _toggleParts = function (state, override) {
				if (override === void 0) {
					override = false;
				}
				$rootScope.$emit('ToggleConfigOutline', { state: false });
				$rootScope.$emit('ToggleConfigParts', { state: state, override: override });
			};
			var _toggleOutline = function (state, override) {
				if (override === void 0) {
					override = false;
				}
				$rootScope.$emit('ToggleConfigParts', { state: false });
				$rootScope.$emit('ToggleConfigOutline', { state: state, override: override });
			};

			var _applyTemplate = function (metaIdentifier, template, mode) {
				var ini = _layoutInstances.get(metaIdentifier);

				// Hydrate template
				for (var r = 0; r < template.root.length; r++) {
					var row = template.root[r];
					row.identifier = _registerElement(metaIdentifier);

					for (var c = 0; c < row.children.length; c++) {
						var cell = row.children[c];
						cell.identifier = _registerElement(metaIdentifier);

						if (cell.children) {
							for (var i = 0; i < cell.children.length; i++) {
								var snipp = cell.children[i];
								snipp.identifier = _registerElement(metaIdentifier);
							}
						}
					}
					_reevaluateRow(row, undefined, metaIdentifier);
				}

				if (ini.layout.root.length === 0) {
					// If the current layout is empty all that makes sense is replace
					mode = TEMPLATE_APPLICATION_MODE.REPLACE;
				}

				switch (mode) {
					case TEMPLATE_APPLICATION_MODE.FRONT: {
						ini.layout.root.splice.apply(ini.layout.root, [0, 0].concat(template.root));
						break;
					}
					case TEMPLATE_APPLICATION_MODE.BACK: {
						ini.layout.root.splice.apply(
							ini.layout.root,
							[ini.layout.root.length, 0].concat(template.root)
						);
						break;
					}
					case TEMPLATE_APPLICATION_MODE.REPLACE: {
						ini.layout.root.splice.apply(
							ini.layout.root,
							[0, ini.layout.root.length].concat(template.root)
						);
						break;
					}
				}
			};

			return {
				MAX_COLUMNS: _COLUMNS,
				LAYOUT_TYPE: LAYOUT_TYPE,
				ELEMENT_TYPE: ELEMENT_TYPE,
				VIEWPORT_SIZE: VIEWPORT_SIZE,
				TEMPLATE_APPLICATION_MODE: TEMPLATE_APPLICATION_MODE,
				isDragging: function () {
					return _dragging;
				},
				clearDragIndicators: function () {
					_layoutInstances.forEach(function (ini) {
						_clearDragOverIndicators(_layoutInstances.get(ini.identifier));
					});
				},
				getViewportSetupId: function () {
					return _viewportSetup;
				},
				setViewportSetup: function (setup) {
					_viewportSetup = setup;
					$rootScope.$emit('sizeChanged');
				},
				selectedElement: function (metaIdentifier) {
					_dragging.v = false;
					var ini = _layoutInstances.get(metaIdentifier);
					return ini !== undefined && ini.selectedElement !== undefined ? ini.selectedElement : {};
				},
				insertSingleRow: function (container, rowIndex) {
					container.splice(rowIndex, 1, _buildRow(1));
				},
				insertDoubleRow: function (container, rowIndex) {
					container.splice(rowIndex, 1, _buildRow(2));
				},
				insertTripleRow: function (container, rowIndex) {
					container.splice(rowIndex, 1, _buildRow(3));
				},
				insertDoupleRow: function (container, rowIndex) {
					container.splice(rowIndex, 1, _buildRow(2, [8, 4]));
				},
				insertTribleRow: function (container, rowIndex) {
					container.splice(rowIndex, 1, _buildRow(2, [4, 8]));
				},
				insertOfferSetup: function (container, rowIndex, metaIdentifier) {
					container.splice(rowIndex, 1, _buildProductsRow(), _buildSumRow());
					_reevaluateRow(container[rowIndex], undefined, metaIdentifier);
				},
				getLabeledTextStyle: function () {
					return _labeledTextStyle();
				},
				getImageStyle: function () {
					return _boxStyle();
				},
				getTextWidth: _getTextWidth,
				getCurrentInstanceIdentifier: function () {
					return _metaIdentifierCount - 1;
				},
				registerInstance: function (name, layout) {
					var identifier = _metaIdentifierCount++;

					_layoutInstances.set(identifier, {
						identifier: identifier,
						name: name,
						layout: layout,
						dragOverTarget: undefined,
					});

					return identifier;
				},
				removeInstance: function (metaIdentifier) {
					_layoutInstances.delete(metaIdentifier);
				},
				registerElement: function (metaIdentifier) {
					return _registerElement(metaIdentifier);
				},
				deregisterInstance: function (identifier) {
					if (_layoutInstances.has(identifier)) {
						_layoutInstances.delete(identifier);
					}
				},
				decreaseSize: _decreaseSize,
				increaseSize: _increaseSize,
				getInstance: function (identifier) {
					return _layoutInstances.get(identifier);
				},
				toggleEditMode: function (metaIdentifier, state) {
					$rootScope.$emit('editModeToggled', {
						metaIdentifier: metaIdentifier,
						identifier: metaIdentifier,
						edit: state,
					});
				},
				toggleEditGlobal: function (state, preSave) {
					$rootScope.$emit('editModeToggled', {
						metaIdentifier: 'GLOBAL',
						identifier: 'GLOBAL',
						edit: state,
						preSave: preSave || false,
					});
				},
				subscribeSizeChanged: function (scope, callback) {
					scope.handler$sizeChanged = $rootScope.$on('sizeChanged', callback);
					scope.$on('$destroy', scope.handler$sizeChanged);
				},
				subscribeEditModeToggled: function (scope, callback) {
					scope.handler$editModeToggled = $rootScope.$on('editModeToggled', callback);
					scope.$on('$destroy', scope.handler$editModeToggled);
				},
				subscribeDraggingToggled: function (scope, callback) {
					scope.handle$draggingToggled = $rootScope.$on('draggingToggled', callback);
					scope.$on('$destroy', scope.handle$draggingToggled);
				},
				subscribeSelected: function (scope, callback) {
					scope.handle$elementSelected = $rootScope.$on('elementSelected', callback);
					scope.$on('$destroy', scope.handle$elementSelected);
				},
				callOutDragStart: function (
					metaIdentifier,
					index,
					container,
					elementIdentifier,
					elementType,
					snippetType,
					element
				) {
					_dragging.v = true;
					_toggleParts(undefined, true);
					var ini = _layoutInstances.get(metaIdentifier);
					_clearMoveIndicator(ini);
					_clearDragOverIndicators(ini);

					if (container.length >= index) {
						container[index].moveClass = 'moving';
					}

					ini.dragMoveTarget = {
						elementIdentifier: elementIdentifier,
						elementType: elementType,
						snippetType: snippetType,
						element: element,
						index: index,
						container: container,
					};
				},
				callOutDragOver: function (metaIdentifier, hem, index, container) {
					var ini = _layoutInstances.get(metaIdentifier);
					_clearDragOverIndicators(ini);
					_setDragOverTarget(ini, hem, index, container);
				},
				callOutSelected: _elementSelected,
				callOutDrop: function (metaIdentifier, hem, index, container, parentContainer, responsive, targetType) {
					_dragging.v = false;

					var partIsDragged = _draggedPart !== undefined;
					var ini = _layoutInstances.get(metaIdentifier);
					var dragTarget = Object.assign({}, ini.dragMoveTarget);
					_relocateElement(ini, hem, index, container, parentContainer, responsive, targetType);
					_clearDragOverIndicators(ini);

					_elementSelected(
						metaIdentifier,
						dragTarget.identifier,
						dragTarget.elementType,
						dragTarget.snippetType,
						dragTarget.element
					);

					if (partIsDragged) {
						_toggleParts(true);
						_draggedPart = undefined;
					}
				},
				callOutDragStartPart: function (type, data, args) {
					_dragging.v = true;
					_toggleParts(undefined, true);
					_clearMoveIndicator(ALL_INSTANCES);
					_clearDragOverIndicators(ALL_INSTANCES);

					_draggedPart = {
						type: type,
						group: LAYOUT_TYPE[type] !== undefined ? PART_GROUP.LAYOUT : PART_GROUP.LEAF,
						args: args,
					};
					$rootScope.$emit('ContentBuilderPartDragStart', data);
				},
				callOutDragEndPart: function (type, data) {
					$rootScope.$emit('ContentBuilderPartDragEnd', data);
				},
				getBlankSnippet: _blankSnippet,
				deleteElement: _deleteElement,
				addRow: function (metaIdentifier) {
					var ini = _layoutInstances.get(metaIdentifier);
					_addRow(ini);
				},
				addCell: _addCell,
				copyElement: _copyElement,
				duplicateElement: _duplicateElement,
				canPasteType: function (metaIdentifier, elementType) {
					var ini = _layoutInstances.get(metaIdentifier);
					return ini.clipboard && ini.clipboard.elementType === elementType;
				},
				applyTemplate: _applyTemplate,
				subscribeTriggerSave: function (scope, callback) {
					if ($scope.handler$triggeredSaveContent) {
						$scope.handler$triggeredSaveContent();
					}
					$scope.handler$triggeredSaveContent = $rootScope.$on('triggeredSaveContent', callback);
					scope.$on('$destroy', $scope.handler$triggeredSaveContent);
				},
				triggerSave: function (identifier) {
					$rootScope.$emit('triggeredSaveContent', { identifier: identifier });
				},
				subscribeSave: function (scope, callback) {
					if (scope.handler$saveContent) {
						scope.handler$saveContent();
					}
					scope.handler$saveContent = $rootScope.$on('saveContent', callback);
					scope.$on('$destroy', scope.handler$saveContent);
				},
				saveContent: function (identifier, content) {
					$rootScope.$emit('saveContent', { identifier: identifier, content: content });
				},
				saveGlobal: function () {
					$rootScope.$emit('saveContent', { identifier: 'GLOBAL' });
				},
				registerCleanupCrew: function () {
					if (!_cleanupCrewRegistered) {
						document.addEventListener('mouseup', function () {
							_cancelDragProcess();
						});
						_cleanupCrewRegistered = true;
					}
				},
				toggleParts: _toggleParts,
				toggleOutline: _toggleOutline,
				saveStyle: function (style) {
					_styles.push(_clone(style));
				},
				getStyles: function () {
					return _styles;
				},
				deleteStyle: function (index) {
					if (_styles.length > index) {
						_styles.splice(index, 1);
					}
				},
				applyStyle: function (style, element) {
					element.style = _clone(style);
				},
				copyStyle: function (style) {
					_styleClipboard = _clone(style);
				},
				pasteStyle: function (element) {
					element.style = _clone(_styleClipboard);
				},
			};
		},
	]);
});

