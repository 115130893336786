define('services/SystemMessageUnpagedService',['app', 'modules/module', 'services/EntityCrudUnpagedService'], function (app, module) {
	'use strict';
	return module.factory('SystemMessageUnpagedService', ['$injector', SystemMessageUnpagedService]);
});

function SystemMessageUnpagedService($injector) {
	crudInstance = $injector.instantiate(EntityCrudUnpagedService);
	crudInstance.setType('SystemMessage');

	angular.extend(this, crudInstance);

	return this;
}
;
