define('services/MockupService',['angular', 'modules/module'], function (angular, module) {
	return module.factory('MockupService', [
		'$httpBackend',
		function ($httpBackend) {
			var customers = [
				{
					id: 1,
					username: 'user_1',
					email: 'user_1@moodia.net',
					addresses: {
						defaultInvoiceAddress: {
							sequenceNumber: 2,
							country: null,
							person: {
								foreName: 'foreName DEFAULT',
								sureName: 'lastName',
							},
							street: 'Fifty Fifth Avenue 55',
							city: 'Gotham',
							zipCode: '123456',
							addressType: 'INVOICE',
							countryState: null,
						},
						defaultShippingAddress: null,
						otherAddresses: [
							{
								sequenceNumber: 0,
								country: null,
								person: {
									foreName: 'foreName',
									sureName: 'lastName',
								},
								street: 'Fifth Avenue 11',
								city: 'Gotham',
								zipCode: '123456',
								addressType: 'INVOICE',
								countryState: null,
							},
							{
								sequenceNumber: 1,
								country: null,
								person: {
									foreName: 'foreName',
									sureName: 'lastName',
								},
								street: 'Wayne Manor',
								city: 'Bristol',
								zipCode: '123456',
								addressType: 'SHIPPING',
								countryState: null,
							},
						],
					},
				},

				{
					id: 2,
					username: 'mischa',
					email: 'user_1@moodia.net',
					addresses: {
						defaultInvoiceAddress: {
							sequenceNumber: 2,
							country: null,
							person: {
								foreName: 'Mischa',
								sureName: 'Leister',
							},
							street: 'Friedrich-Breuer-Str. 109',
							city: 'Bonn',
							zipCode: '53225',
							addressType: 'INVOICE',
							countryState: null,
						},
						defaultShippingAddress: {
							sequenceNumber: 1,
							country: null,
							person: {
								foreName: 'Mischa',
								sureName: 'Leister',
							},
							street: 'Musterstraße',
							city: 'Musterstadt',
							zipCode: '123456',
							addressType: 'SHIPPING',
							countryState: null,
						},
						otherAddresses: [
							{
								sequenceNumber: 0,
								country: null,
								person: {
									foreName: 'Mischa',
									sureName: 'Leister',
								},
								street: 'Moselring 7',
								city: 'Koblenz',
								zipCode: '56068',
								addressType: 'INVOICE',
								countryState: null,
							},
							{
								sequenceNumber: 1,
								country: null,
								person: {
									foreName: 'Mischa',
									sureName: 'Leister',
								},
								street: 'Musterstraße',
								city: 'Musterstadt',
								zipCode: '123456',
								addressType: 'SHIPPING',
								countryState: null,
							},
						],
					},
					orderHistory: [
						{
							timestamp: 1432657194151,
							cartEntries: [
								{
									product: {
										id: 1,
										title: 'Product 1',
										subtitle: null,
										weightDescription: null,
										price: null,
										image: {
											characteristics: [
												{
													originalWidth: 200,
													originalHeight: 200,
													aspectRatio: null,
													screenDpi: 96,
													uri: '/image?id=123456',
												},
											],
										},
										version: null,
									},
									singleProductPriceDuringCreation: null,
									salesTaxInPercentDuringCreation: null,
									amount: 2,
								},
								{
									product: {
										id: 2,
										title: 'Product 2',
										subtitle: null,
										weightDescription: null,
										price: null,
										image: {
											characteristics: [
												{
													originalWidth: 200,
													originalHeight: 200,
													aspectRatio: null,
													screenDpi: 96,
													uri: '/image?id=123456',
												},
											],
										},
										version: null,
									},
									singleProductPriceDuringCreation: null,
									salesTaxInPercentDuringCreation: null,
									amount: 5,
								},
							],
							invoiceAddress: {
								person: {
									foreName: 'Any',
									sureName: 'User',
								},
								street: 'Any street 7',
								zipCode: '123456',
								city: 'Gotham',
								addressType: 'INVOICE',
								country: null,
								countryState: null,
							},
							shippingAddress: null,
							paymentType: 'PayPal',
						},
					],
				},
			];

			var products = [
				{
					id: 1,
					title: 'Almlaib',
					shortDescription:
						'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque, id perferendis praesentium voluptate iusto blanditiis eaque debitis mollitia, nulla. Eos perferendis veritatis fuga iure libero, voluptates, consequatur quo sint numquam.',
					categories: [
						{
							name: 'brot',
							displayName: 'Brot',
							version: null,
						},
					],
					tags: [
						{
							name: 'sometag',
							displayName: 'Some Tag',
						},
					],
					images: [
						{
							charakteristics: [
								{
									originalWidth: 100,
									originalHeight: 100,
									aspectRatio: 100,
									uri: 'images/products/thumbnail_brot1.png',
								},
							],
						},
					],
					weightDescription: '300g',
					price: 300,
					manufacturer: 'Herzberger',
				},
				{
					id: 2,
					title: 'Sonnenblumenbrot',
					shortDescription:
						'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio pariatur laudantium quasi nihil velit vitae amet consequatur nesciunt labore eos. Vel quis eligendi, adipisci incidunt magnam voluptate, commodi recusandae eum.',
					categories: [
						{
							name: 'brot',
							displayName: 'Brot',
							version: null,
						},
					],
					tags: [
						{
							name: 'sometag',
							displayName: 'Some Tag',
						},
					],
					images: [
						{
							charakteristics: [
								{
									originalWidth: 100,
									originalHeight: 100,
									aspectRatio: 100,
									uri: 'images/products/thumbnail_brot2.png',
								},
							],
						},
					],
					weightDescription: '750g',
					price: 400,
					manufacturer: 'Herzberger',
				},
				{
					id: 3,
					title: 'Bergsteigerbrot',
					shortDescription:
						'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt mollitia voluptatibus, magni minus pariatur aliquam nemo, fuga itaque sint corporis porro totam eum hic sed soluta dolore. Unde, sapiente quaerat!',
					categories: [
						{
							name: 'brot',
							displayName: 'Brot',
							version: null,
						},
					],
					tags: [
						{
							name: 'sometag',
							displayName: 'Some Tag',
						},
					],
					images: [
						{
							charakteristics: [
								{
									originalWidth: 100,
									originalHeight: 100,
									aspectRatio: 100,
									uri: 'images/products/thumbnail_brot3.png',
								},
							],
						},
					],
					weightDescription: '500g',
					price: 400,
					manufacturer: 'Herzberger',
				},
				{
					id: 4,
					title: 'Bauernbrot - ohne zusatz von Hefe',
					shortDescription:
						'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias quas, a dicta quod nemo aperiam commodi autem! Minus eius, saepe provident, eos ex consectetur, necessitatibus ratione voluptatum aperiam numquam repudiandae.',
					categories: [
						{
							name: 'brot',
							displayName: 'Brot',
							version: null,
						},
					],
					tags: [
						{
							name: 'sometag',
							displayName: 'Some Tag',
						},
					],
					images: [
						{
							charakteristics: [
								{
									originalWidth: 100,
									originalHeight: 100,
									aspectRatio: 100,
									uri: 'images/products/thumbnail_brot4.png',
								},
							],
						},
					],
					weightDescription: '2x 250g',
					price: 620,
					manufacturer: 'Herzberger',
				},
				{
					id: 5,
					title: 'Roggen-Dinkel-Volkornbrot',
					shortDescription:
						'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perspiciatis laudantium repellat, dicta magni sequi nemo soluta vitae inventore quia! Nesciunt sapiente, vitae necessitatibus iusto corporis. Accusamus, neque quia officia corporis!',
					categories: [
						{
							name: 'brot',
							displayName: 'Brot',
							version: null,
						},
					],
					tags: [
						{
							name: 'sometag',
							displayName: 'Some Tag',
						},
					],
					images: [
						{
							charakteristics: [
								{
									originalWidth: 100,
									originalHeight: 100,
									aspectRatio: 100,
									uri: 'images/products/thumbnail_brot5.png',
								},
							],
						},
					],
					weightDescription: '500g',
					price: 395,
					manufacturer: 'Herzberger',
				},
				{
					id: 6,
					title: 'Mischbrot Hell',
					shortDescription:
						'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam veniam quae architecto nemo mollitia iure in ratione vitae dolorum, adipisci, officiis nesciunt delectus voluptatibus, molestias natus quaerat vel consectetur aliquid.',
					categories: [
						{
							name: 'brot',
							displayName: 'Brot',
							version: null,
						},
					],
					tags: [
						{
							name: 'sometag',
							displayName: 'Some Tag',
						},
					],
					images: [
						{
							charakteristics: [
								{
									originalWidth: 100,
									originalHeight: 100,
									aspectRatio: 100,
									uri: 'images/products/thumbnail_brot6.png',
								},
							],
						},
					],
					weightDescription: '330g',
					price: 400,
					manufacturer: 'Herzberger',
				},
			];

			var product = {
				id: 6,
				title: 'Mischbrot Hell',
				subtitle: 'Schmeckt lecker und ist gesund',
				shortDescription: 'Lorem ipsum dolor sit amet',
				longDescription:
					'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur facilis enim voluptas praesentium aperiam eaque illo eum, atque, adipisci vero necessitatibus similique amet et harum, incidunt doloremque. Minus, dolore, error!',
				status: 'enabled',
				visibility: ['catalouge', 'search'],
				prices: {
					generalPrice: 400,
					specialPrices: [
						{
							priceFromDate: '2015-06-01',
							priceToDate: '2015-08-01',
							offPercentage: 10,
						},
					],
					tax: {
						percentage: 19,
						name: '19% MwSt',
					},
				},
				categories: [
					{
						id: 8,
						name: 'brot',
						displayName: 'Brot',
					},
					{
						id: 3,
						name: 'nahrung',
						displayName: 'Nahrung',
					},
				],
				tags: [
					{
						name: 'tag_1',
						displayName: 'Tag 1',
					},
					{
						name: 'tag_2',
						displayName: 'Tag 2',
					},
					{
						name: 'tag_8',
						displayName: 'Tag 8',
					},
				],
				images: [
					{
						id: 1,
						label: 'Vorschaubild Mischbrot Hell',
						uri: '/images/products/thumbnail_brot2.png',
						type: 'thumbnail',
					},
					{
						id: 2,
						label: 'Seitenansicht Mischbrot Hell',
						uri: '/images/products/thumbnail_brot2.png',
						type: 'normal',
					},
					{
						id: 3,
						label: 'Hauptansicht Mischbrot Hell',
						uri: '/images/products/thumbnail_brot2.png',
						type: 'base',
					},
				],
				metaInformation: {
					metaTitle: 'Mischbrot Hell',
					metaDescription:
						'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae sint repellendus consequatur itaque tempora, quos ut aperiam saepe, eum unde obcaecati asperiores ullam quam quo pariatur nihil recusandae corrupti. Consequatur.',
					metaKeywords: 'Lecker, Bort, Mischbrot, Frisch',
				},
				weightDescription: '330g',
				manufacturer: 'Herzberger',
			};

			var tags = [
				{
					name: 'tag_1',
					displayName: 'Tag 1',
					description: 'description tag 1',
				},
				{
					name: 'tag_2',
					displayName: 'Tag 2',
					description: 'description tag 2',
				},
				{
					name: 'tag_3',
					displayName: 'Tag 3',
					description: 'description tag 3',
				},
				{
					name: 'tag_4',
					displayName: 'Tag 4',
					description: 'description tag 4',
				},
				{
					name: 'tag_5',
					displayName: 'Tag 5',
					description: 'description tag 5',
				},
				{
					name: 'tag_6',
					displayName: 'Tag 6',
					description: 'description tag 6',
				},
				{
					name: 'tag_7',
					displayName: 'Tag 7',
					description: 'description tag 7',
				},
				{
					name: 'tag_8',
					displayName: 'Tag 8',
					description: 'description tag 8',
				},
				{
					name: 'tag_9',
					displayName: 'Tag 9',
					description: 'description tag 9',
				},
				{
					name: 'tag_10',
					displayName: 'Tag 10',
					description: 'description tag 10',
				},
			];
			var contents = {
				content: [
					{
						id: 2,
						alias: 'test2',
						title: 'Der zweite Test',
						content: 'Und noch ein Lorem und ein weiteres Ipsum führen zum Erfolg.',
						version: 0,
						categories: [
							{
								id: 1,
							},
							{
								id: 5,
							},
						],
					},
					{
						id: 3,
						alias: 'test3',
						title: 'Der dritte Test',
						content:
							'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae sint ipsam eveniet eaque debitis consequuntur rem esse incidunt modi nostrum suscipit, ex nam delectus aspernatur veritatis. Voluptates, nisi tempora esse.',
						version: 0,
						categories: [
							{
								id: 8,
							},
							{
								id: 10,
							},
						],
					},
				],
				totalPages: 1,
				totalElements: 2,
				actualPage: 0,
				contentCount: 2,
			};

			var attributeGroup = {
				id: '1',
				attributeValue: [
					{ name: 'Brennwert', unit: 'kj', value: 775.0 },
					{ name: 'Brennwert', unit: 'kcal', value: 183.0 },
					{ name: 'Eiweiß', unit: 'g', value: 4.2 },
				],
				name: 'nutritionfacts',
				format: 'JSON',
				version: '0',
				categories: [],
			};

			var attributeGroups = [
				{
					id: '2',
					attributeValue: [
						{ name: 'Brennwert', unit: 'kj', value: 775.0 },
						{ name: 'Brennwert', unit: 'kcal', value: 183.0 },
						{ name: 'Eiweiß', unit: 'g', value: 4.2 },
					],
					name: 'nutritionfacts2',
					format: 'JSON',
					version: '0',
					categories: [],
				},
				{
					id: '3',
					attributeValue: [
						{ name: 'Brennwert', unit: 'kj', value: 775.0 },
						{ name: 'Brennwert', unit: 'kcal', value: 183.0 },
						{ name: 'Eiweiß', unit: 'g', value: 4.2 },
					],
					name: 'nutritionfacts3',
					format: 'JSON',
					version: '0',
					categories: [],
				},
			];

			attributeGroups.push(attributeGroup);

			var shipments = {
				content: [
					{
						id: 1,
						name: 'DHL Standart Versand',
						duration: '2-3 Tage',
						enabled: true,
					},
					{
						id: 2,
						name: 'Overnightexpress',
						duration: '24h',
						enabled: true,
					},
					{
						id: 3,
						name: 'DPD',
						duration: '3 Werktage',
						enabled: false,
					},
				],
				totalPages: 1,
				totalElements: 3,
				contentCount: 3,
				actualPage: 0,
			};

			var availableCountries = [
				{
					id: 1,
					name: 'Deutschland',
				},
				{
					id: 2,
					name: 'Indien',
				},
				{
					id: 3,
					name: 'Schweiz',
				},
				{
					id: 4,
					name: 'Österreich',
				},
				{
					id: 5,
					name: 'USA',
				},
				{
					id: 6,
					name: 'England',
				},
				{
					id: 7,
					name: 'Frankreich',
				},
				{
					id: 8,
					name: 'Italien',
				},
				{
					id: 9,
					name: 'Spanien',
				},
				{
					id: 10,
					name: 'Griechenland',
				},
				{
					id: 11,
					name: 'Portugal',
				},
				{
					id: 12,
					name: 'Polen',
				},
			];

			var shippingGlobal = {
				content: [
					{
						id: 1,
						name: 'Shipping global1',
						products: [],
						shippingRules: [
							{
								id: 1,
								name: 'rule1',
								shippingConditionGroups: [
									{
										id: 1,
										name: 'ConditionGroup1',
										shippingConditions: [
											{
												id: 2,
												name: 'gfgf',
												operator: 'NOT_EQUAL',
												fieldType: 'TOTAL_PRICE',
												value: 777,
												unit: null,
												version: 0,
											},
											{
												id: 1,
												name: 'Condition1',
												operator: 'EQUAL',
												fieldType: 'TOTAL_WEIGHT',
												value: 3,
												unit: 'g',
												version: 0,
											},
										],
										version: 0,
									},
								],
								action: {
									type: 'ADD',
									value: 5,
								},
								version: 0,
								selected: true,
							},
						],
					},
					{
						id: 2,
						name: 'Shipping global2',
						products: [],
						shippingRules: [
							{
								id: 2,
								name: 'rule1',
								shippingConditionGroups: [
									{
										id: 3,
										name: 'ConditionGroup1',
										shippingConditions: [
											{
												id: 2,
												name: 'gfgf',
												operator: 'NOT_EQUAL',
												fieldType: 'TOTAL_PRICE',
												value: 777,
												unit: null,
												version: 0,
											},
										],
										version: 0,
									},
								],
								action: {
									type: 'ADD',
									value: 5,
								},
								version: 0,
								selected: true,
							},
						],
					},
				],
				totalPages: 1,
				totalElements: 2,
				contentCount: 2,
				actualPage: 0,
			};

			return {
				init: function () {
					/*$httpBackend.whenGET(/\/api\/v1\/tag/).respond(function (method, url, data){
					console.log('Mocked tags: '+ angular.toJson(tags));
					return [200, tags];
				});

				//---=== Product-Mock ===---
				$httpBackend.whenGET(/\/api\/v1\/products/).respond(function(method, url, data) {
					console.log('Mocked products: ' + angular.toJson(products));
					return [200, {"content": products}];
				});*/

					/*$httpBackend.whenGET(/\/api\/v1\/product\/.+/).respond(function(method, url, data){
					console.log('Mocked product: ' + angular.toJson(product));
					return [200, product];
				});*/

					/*$httpBackend.whenPOST(/\/api\/v1\/product/).respond(function (method, url, data) {
					product = data;
					return [200, product];
				});*/

					/*$httpBackend.whenGET(/\/api\/v1\/customers/).respond( function (method, url, data) {
					console.log('whenGET customers');
					return [200, customers];
				});

				$httpBackend.whenGET(/\/api\/v1\/customer\/.+/).respond( function (method, url, data) {
					return [200, customers[1]];
				});
				*/
					//---=== ProductAttribute-Mock ===---
					/*$httpBackend.whenGET(/^\/api\/v1\/attribute[^\/]*\/{0,1}$/).respond(function (method, url, data) {
					return [200, {
						content: attributeGroups,
						totalPages: 1,
						actualPage: 0,
						contentCount: 1
					}];
				});
				$httpBackend.whenGET(/^\/api\/v1\/attribute\/[\d]+$/).respond(function (method, url, data) {
					return [200, attributeGroup];
				});
				$httpBackend.whenPOST(/^\/api\/v1\/attribute.*$/).respond(function (method, url, data){
					data = JSON.parse(data);
					isNew = true;
					for (var i = 0; i < attributeGroups.length; i++) {
						if(attributeGroups[i].id == data.id){
							attributeGroups.splice(i, 1, data);
							isNew = false;
							break;
						}
					}
					if(isNew){
						data.id = parseInt(attributeGroups[attributeGroups.length -1].id) + 1;
						attributeGroups.push(data);
					}
					return [200, {
						content: attributeGroups,
						totalPages: 1,
						actualPage: 0,
						contentCount: 1
					}];
				});
				$httpBackend.whenDELETE(/^\/api\/v1\/attribute\/[\d]+$/).respond(function (method, url, data) {
					var id = parseInt(url.split("/").pop());
					for (var i = 0; i < attributeGroups.length; i++){
						if(attributeGroups[i].id == id){
							attributeGroups.splice(i, 1);
							return [200, {
								content: attributeGroups,
								totalPages: 1,
								actualPage: 0,
								contentCount: 1
							}];
						}
					}
					return [400, {'error': 'id ' + id + ' not found'}];
				});*/
					//---===Shippment-Mock===---
					$httpBackend.whenGET(/\/api\/v1\/shipment\?.*/).respond(function (method, url, data) {
						return [200, shipments];
					});

					$httpBackend.whenPOST(/\/api\/v1\/shipment.*/).respond(function (method, url, data) {
						var post = angular.fromJson(data);
						shipments.content = post.shipments;
						return [200, shipments];
					});

					/*$httpBackend.whenGET(/\/api\/econ\/recipients/).respond( function (method, url, data) {
					return [200, recipients];
				});	*/

					/*$httpBackend.whenGET(/\/api\/v1\/shipping\/available-countries/).respond( function () {
					return [200, availableCountries];
				});*/

					//$httpBackend.whenGET(/\/api\/v1\/shipping\/global\/[\d+].*/).respond( function (method, url, data) {
					//	return [200, shippingGlobal.content[0]];
					//});

					//$httpBackend.whenGET(/\/api\/v1\/shipping\/global.*/).respond( function (method, url, data) {
					//	return [200, shippingGlobal];
					//});

					/*
				//---OM-Settings-Mock===---
				$httpBackend.whenGET(/\/api\/v1\/ordermanagement\/settings/).respond(function (method, url, data) {
					return [200, orderManagementSettings];
				});

				$httpBackend.whenPOST(/\api\/v1\/ordermanagement\/settings/).respond(function (method, url, data) {
					orderManagementSettings = angular.fromJson(data);
					return [200, orderManagementSettings];
				});
				*/
					//---===OM-Labels-Mock===---
					/*$httpBackend.whenGET(/\/api\/v1\/ordermanagement\/\w{2}\-\w{12}\-\w{6}\/start/).respond(function (method, url, data) {
					var x = Math.random()
					if(x<.8) {
						console.log('here i am');
						var rand = Math.floor((Math.random() * 10000000000) + 1);
						var rand = Array(Math.max(20 - String(rand).length + 1, 0)).join(0) + rand;
						return [200, {trackingNumber: rand}];
					}else if(x < .95){
						return [400, {error: "some error occured"}];	
					}
					return [400, {
						errors: [
							{
								"type": "validation_error",
								"description": "Street must not be empty"
							},
							{
								"type": "validation_error",
								"description": "House number '18 Rue de la Montagne' must have maximum 8 characters"
							}
						]
					}];
				});

				$httpBackend.whenGET(/\/api\/v1\/ordermanagement\/\w{2}\-\w{12}\-\w{6}\/cancel/).respond(function (method, url, data) {
					console.log('here i am');
					var rand = Math.floor((Math.random() * 10000000000) + 1);
					var rand = Array(Math.max(20 - String(rand).length + 1, 0)).join(0) + rand;
					return [200, {success: true}];
				})*/
					/*
					 * Else: passThrough() to API
					 */
					$httpBackend.whenPOST(/.*/).passThrough();
					$httpBackend.whenGET(/.*/).passThrough();
					$httpBackend.whenDELETE(/.*/).passThrough();
					$httpBackend.whenPUT(/.*/).passThrough();
				},
			};
		},
	]);
});

