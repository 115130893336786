define('modules/wawi/product/controllers/tabs/ProductDimensionsTabController',[], function () {
	'use strict';

	return ($parentScope, $scope, { BASE_TEMPLATES_PATH, AttributeService }) => {
		$parentScope.formTabs.push({
			label: 'FIELDS.PRODUCT_DIMENSIONS',
			template: BASE_TEMPLATES_PATH + '/wawi/product/form-product-dimensions.html',
		});

		$scope.init = () => {
			if ($parentScope.entity.productDimensions == undefined) {
				$parentScope.entity.productDimensions = [];
			}
		};

		AttributeService.getTypes().then(function () {
			$scope.attributeTypes = AttributeService.getAttrTypes();
		});

		$scope.removeProductDimension = function (index) {
			$parentScope.entity.productDimensions.splice(index, 1);
		};

		$scope.addDimension = function () {
			$parentScope.entity.productDimensions.push({
				minValue: '1',
				maxValue: '-1',
				step: 0,
				name: '',
				unit: 0,
			});
			return;

			if (parseInt(price.toAmount) >= parseInt(price.fromAmount)) {
				var fromAmountNew = (parseInt(price.toAmount) + 1).toString();
				jQuery('.last-item input').css('border-color', '');
			} else {
				jQuery('.last-item input').css('border-color', 'red');
			}
			$scope.checkRange();
		};

		$scope.showDimensionAddButton = function () {
			if (
				$parentScope.entity.productDimensions == undefined ||
				$parentScope.entity.productDimensions.length === 0
			) {
				return true;
			}
			return false;
		};
	};
});

