define('services/PaymentPolicyService',['app', 'modules/module'], function (app, module) {
	'use strict';
	return module.factory('PaymentPolicyService', ['$injector', '$q', 'ModelFactory', PaymentPolicyService]);
});

function PaymentPolicyService($injector, $q, ModelFactory) {
	let paymentPolicyTemplates;

	this.fetchPaymentPolicyTemplates = function () {
		let deferred = $q.defer();
		ModelFactory.PaymentPolicy()
			.getTemplates()
			.$promise.then(
				function (response) {
					paymentPolicyTemplates = response;
					deferred.resolve(version);
				},
				() => {}
			);
		return deferred.promise;
	};

	this.getTemplates = function () {
		return paymentPolicyTemplates;
	};

	this.fetchShopPaymentPolicies = function () {
		let deferred = $q.defer();
		ModelFactory.PaymentPolicy()
			.getPolicies()
			.$promise.then(
				function (response) {
					paymentPolicyTemplates = response;
					deferred.resolve(version);
				},
				() => {}
			);
		return deferred.promise;
	};

	return this;
}
;
