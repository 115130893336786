define('modules/settings/econ/EconSettingsController',['modules/module', 'services/EconSettingService'], function (module) {
	'use strict';
	module.controller('EconSettingsController', [
		'$scope',
		'EconSettingService',
		'NotificationService',
		'EconStatisticsService',
		EconSettingsController,
	]);
});

function EconSettingsController($scope, EconSettingService, NotificationService, EconStatisticsService) {
	$scope.encodableListValueKeys = ['dayliReportRecivers', 'singleReportRecivers', 'weeklyReportRecivers'];

	$scope.yesNoRadioData = [
		{ display: 'FIELDS.YES', value: 'true' },
		{ display: 'FIELDS.NO', value: 'false' },
	];

	$scope.reportMailDaysData = {
		MONDAY: false,
		TUESDAY: false,
		WEDNESDAY: false,
		THURSDAY: false,
		FRIDAY: false,
		SATURDAY: false,
		SUNDAY: false,
	};

	EconSettingService.loadSettings().then(function () {
		$scope.settings = EconSettingService.getSettings();

		$scope.settings.forEach(function (item) {
			$scope[item.settingsKey] = $scope.decodeValue(item.settingsKey, item.settingsValue);
		});
	});

	$scope.statisticsCapturingRadioValues = [
		{ value: 'ScriptInFrontend', display: 'FIELDS.YES' },
		{ value: 'NoScriptInFrontend', display: 'FIELDS.NO' },
	];

	$scope.updateAll = function () {
		var settingsToUpdate = [];
		$scope.tags = {};
		$scope.settings.forEach(function (item) {
			var settingToUpdate = {};
			settingToUpdate.settingsKey = item.settingsKey;
			settingToUpdate.settingsValue = $scope.encodeValue(item.settingsKey, $scope[item.settingsKey]);
			settingsToUpdate.push(settingToUpdate);
		});
		if ($scope.validate(settingsToUpdate)) {
			EconSettingService.updateAll(settingsToUpdate).then(function () {
				NotificationService.notifySuccess({ statusText: 'Erfolg', msg: 'Es wurde abgespeichert.' });
			});
		}
	};

	$scope.encodeValue = function (key, value) {
		if (key == 'reportMailDays') {
			var newValue = '';
			for (var day in $scope.reportMailDaysData) {
				if ($scope.reportMailDaysData[day]) {
					newValue += day + ';';
				}
			}
			return newValue;
		}

		if ($scope.encodableListValueKeys.indexOf(key) != -1) {
			var newValue = '';
			for (var i = 0; i < value.length; i++) {
				newValue += value[i].text + ';';
			}
			value = newValue;
		}
		return value;
	};

	$scope.decodeValue = function (key, value) {
		if (key == 'reportMailDays') {
			var splittedValues = value.split(';');
			for (var i = 0; i < splittedValues.length; i++) {
				for (var day in $scope.reportMailDaysData) {
					if (splittedValues[i] == day) {
						$scope.reportMailDaysData[day] = true;
					}
				}
			}
		}

		if ($scope.encodableListValueKeys.indexOf(key) != -1) {
			var array = [];
			var splittedValues = value.split(';');
			for (var i = 0; i < splittedValues.length; i++) {
				var newValue = {};
				newValue.text = splittedValues[i];
				if (newValue.text == '') {
					continue;
				}
				array.push(newValue);
			}
			value = array;
		}
		return value;
	};

	$scope.sendDayliReport = function (arg) {
		EconStatisticsService.sendDayliMail();
	};

	$scope.validate = function (entity) {
		let websiteURL = entity.find((x) => x.settingsKey == 'websiteURL').settingsValue;
		let serverURL = entity.find((x) => x.settingsKey == 'serverURL').settingsValue;
		let errors = [];

		if (!validURL(websiteURL)) {
			errors.push({ attributeName: 'Website URL', errorMsg: 'Website URL is incorrect' });
		}

		if (!validURL(serverURL)) {
			errors.push({ attributeName: 'Server URL', errorMsg: 'Server URL is incorrect' });
		}

		if (validURL(websiteURL) && validURL(serverURL)) {
			return true;
		}

		NotificationService.notifyValidationErrors(errors);

		return false;
	};

	function validURL(str) {
		var pattern = new RegExp(
			'^(https?:\\/\\/)?' + // protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
				'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
				'(\\#[-a-z\\d_]*)?$',
			'i'
		); // fragment locator

		return !!pattern.test(str);
	}
}
;
