/**
 * Created by nmayer on 19.05.16.
 */
define('modules/imEx/controllers/ImExProfilesController',[
	'modules/module',
	'settings',
	'modules/basemodule/controllers/EntityListController',
	'services/NavigationService',
	'services/ImExProfileService',
	'filters/CronFilter',
], function (module) {
	'use strict';
	module.controller('ImExProfilesController', [
		'$scope',
		'$controller',
		'NavigationService',
		'ImExProfileService',
		'BASE_TEMPLATES_PATH',
		function ImExProfilesController(
			$scope,
			$controller,
			NavigationService,
			ImExProfileService,
			BASE_TEMPLATES_PATH
		) {
			angular.extend(
				this,
				$controller('EntityListController', {
					$scope: $scope,
				})
			);

			$scope.tableOptions.fields = [
				{
					id: 'NAME',
					heading: 'FIELDS.DESIGNATION',
					attribute: 'name',
				},
				{
					id: 'TYPE',
					heading: 'CONTROLLERS.DIRECTION',
					attribute: 'imexType',
				},
				{
					id: 'INTERVALL',
					heading: 'FIELDS.INTERVAL',
					template: BASE_TEMPLATES_PATH + '/imEx/partials/cron-expression-col.html',
				},
				{
					id: 'ACTIV',
					heading: 'ACTIONS.ACTIVATION',
					template: BASE_TEMPLATES_PATH + '/imEx/partials/activ-col.html',
					searchDisabled: true,
				},
			];

			/*
                 do not add ng-click to column activ
                 */
			$scope.preserveForDialogs = function (item, field) {
				return field.id != 'ACTIV';
			};

			$scope.formState = 'root.imexprofile';

			$scope.init('ImExProfile').then(function () {});

			$scope.$on('SaveImexProfil', function (evt, data) {
				ImExProfileService.update(data);
			});
		},
	]);
});

