define('services/TicketUnpagedService',['app', 'modules/module', 'services/EntityCrudUnpagedService'], function (app, module) {
	'use strict';
	return module.factory('TicketUnpagedService', ['$injector', '$q', TicketUnpagedService]);
});

function TicketUnpagedService($injector, $q) {
	crudInstance = $injector.instantiate(EntityCrudUnpagedService);
	crudInstance.setType('Ticket');

	angular.extend(this, crudInstance);

	this.saveTicket = function (ticket) {
		if (ticket.id != undefined) {
			var promise = $injector.get('ModelFactory')['Ticket']().update(ticket).$promise;
		} else {
			var promise = $injector.get('ModelFactory')['Ticket']().create(ticket).$promise;
		}
		return promise;
	};

	this.getStatuses = function () {
		var ref = $q.defer();
		setTimeout(function () {
			ref.resolve([
				{ id: 'OPEN', name: 'OPEN' },
				{ id: 'CLOSED', name: 'CLOSED' },
				{ id: 'REOPENED', name: 'REOPENED' },
				{ id: 'NEEDS_RESPONSE', name: 'NEEDS_RESPONSE' },
				{ id: 'IN_PROGRESS', name: 'IN_PROGRESS' },
				{ id: 'ESCALATED', name: 'ESCALATED' },
			]);
		});
		return ref.promise;
	};

	return this;
}
;
