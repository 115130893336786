define('directives/cancellation/cancellation',[
	'app',
	'settings',
	'services/PaymentMethodService',
	'services/ValidationService',
	'services/CancellationService',
	'services/NotificationService',
], function (app) {
	'use strict';
	app.directive('ngCancellation', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					cancellation: '=?',
					shopOrder: '=',
					onFinish: '&',
				},
				templateUrl: BASE_DIRECTIVES_PATH + '/cancellation/views/cancellation.html',
				controller: [
					'$scope',
					'PaymentMethodService',
					'ValidationService',
					'CancellationService',
					'NotificationService',
					function (
						$scope,
						PaymentMethodService,
						ValidationService,
						CancellationService,
						NotificationService
					) {
						PaymentMethodService.findAll(true).then(function () {
							$scope.paymentMethods = PaymentMethodService.getAll();
						});

						$scope.createCancellation = function () {
							var validationObj = {
								reason: {
									method: 'isset',
									fieldName: 'Grund',
								},
								handling: {
									method: 'isset',
									fieldName: 'Bearbeitung',
								},
								refundMethod: {
									fieldName: 'Rückzahlmethode',
									customValidate: function (cancellation) {
										if (!cancellation.refundMethodId && cancellation.handling === 'REFUND') {
											return false;
										}
										return true;
									},
								},
							};
							if (!ValidationService.validate($scope.cancellation, validationObj)) {
								$scope.validationErrors = ValidationService.getErrors();
								return;
							}

							if (!$scope.cancellation.id) {
								//create new cancellation
								CancellationService.create($scope.cancellation).then(function (response) {
									if (response.ok !== false) {
										NotificationService.notifySuccess({
											statusText: 'Die ausgewählten Positionen wurden erfolgreich storniert.',
										});
										$scope.onFinish();
									} else {
										NotificationService.notifyGeneralError({
											statusText:
												'Es ist ein Fehler aufgetreten. Bitte probieren Sie es noch einmal.',
										});
										$scope.onFinish();
									}
								});
							} else {
								console.log('Update of a cancellation is not allowed.');
							}
						};
					},
				],
			};
		},
	]);
});

