define('directives/Dialog/Dialog',['app', 'settings'], function (app) {
	'use strict';
	app.directive('ngDialog', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					title: '@?',
					content: '@',
					onConfirm: '=',
					isOpen: '=',
				},
				templateUrl: BASE_DIRECTIVES_PATH + '/dialog/views/dialog.html',
				controller: [
					'$scope',
					function ($scope) {
						$scope.onDialogClose = () => {
							$scope.isOpen = false;
						};
					},
				],
			};
		},
	]);
});

