define('modules/cms/controllers/ProductFaqController',['modules/module', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('ProductFaqController', ['$scope', '$controller', 'BASE_TEMPLATES_PATH', ProductFaqController]);
});

function ProductFaqController($scope, $controller, BASE_TEMPLATES_PATH) {
	angular.extend(
		this,
		$controller('EntityListController', {
			$scope: $scope,
		})
	);

	$scope.tableOptions.fields = [
		{
			id: 'COMMENT',
			heading: 'FIELDS.COMMENT',
			template: BASE_TEMPLATES_PATH + '/cms/productFaq/comment-col.html',
			attribute: 'comment',
		},
		{
			id: 'STATUS',
			heading: 'FIELDS.STATUS',
			attribute: 'publishState',
		},
		{
			id: 'DATE',
			heading: 'FIELDS.DATE',
			template: BASE_TEMPLATES_PATH + '/cms/productRating/rating-date-col.html',
			attribute: 'createdAt',
			searchDisabled: true,
		},
	];

	$scope.formState = 'root.productFaq';

	$scope.init('ProductFaq');
}
;
