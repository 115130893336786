define('directives/dateRangePicker/DateRangePicker',['app', 'settings', 'filters/DateFilter'], function (app) {
	'use strict';
	app.directive('ngDateRangePicker', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH, moment) {
			return {
				scope: {
					from: '=?',
					to: '=?',
					onChange: '=?',
					parent: '=?',
				},
				templateUrl: BASE_DIRECTIVES_PATH + '/dateRangePicker/views/view.html',
				controller: [
					'$scope',
					'$rootScope',
					'$timeout',
					function ($scope) {
						$scope.isModalOpen = false;
						$scope.minDate = new Date();

						$scope.startDate = new Date();
						$scope.endDate = new Date();

						if ($scope.from) {
							$scope.startDate = new Date($scope.from);
						}

						if ($scope.to) {
							$scope.endDate = new Date($scope.to);
						}

						$scope.openModal = () => {
							$scope.isModalOpen = true;
						};

						$scope.closeModal = () => {
							$scope.isModalOpen = false;
							console.log('clicked');
						};

						$scope.handleSaveDate = (from, to) => {
							$scope.from = from;
							$scope.to = to;
							$scope.onChange(from, to, $scope.parent);
							$scope.closeModal();
						};
					},
				],
			};
		},
	]);
});

