define('modules/navigation/controllers/FooterController',[
	'app',
	'angular',
	'modules/module',
	'modules/models',
	'services/UserService',
	'services/ToolboxService',
	'services/SystemInformationService',
], function (module) {
	'use strict';
	module.controller('FooterController', [
		'$rootScope',
		'$scope',
		'$state',
		'UserService',
		'SystemInformationService',
		'InstallationService',
		function ($rootScope, $scope, $state, UserService, SystemInformationService, InstallationService) {
			InstallationService.fetchVersion().then(function (response) {
				$scope.backendVersion = InstallationService.getVersion();
			});

			SystemInformationService.getFrontEndVersion().then(function (response) {
				$scope.adminVersion = response.version;
			});
		},
	]);
});

