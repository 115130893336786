/**
 * Created by mrafiq on 24.05.18.
 */

define('modules/home/controllers/SeminarDetailReportController',[
	'modules/module',
	'services/ReportService',
	'services/SessionValuesService',
	'services/NavigationService',
], function (app, module) {
	'use strict';
	module.controller('SeminarDetailReportController', [
		'$controller',
		'$scope',
		'$rootScope',
		'ReportService',
		'$window',
		'SessionValuesService',
		'$state',
		'NavigationService',
		SeminarDetailReportController,
	]);
});
function SeminarDetailReportController(
	$controller,
	$scope,
	$rootScope,
	ReportService,
	$window,
	SessionValuesService,
	$state,
	NavigationService
) {
	$scope.currentPage = ReportService.getCurrentPage();
	$scope.pageSize = ReportService.getPageSize();
	$scope.totalItems = ReportService.getTotalItems();
	$scope.totalPages = ReportService.getTotalPages();
	$scope.appointmentId = '';

	$scope.data = {};
	$scope.data.selectedReportColumns = '';
	$scope.entityId = $state.params.id;

	$scope.downloadTypes = [
		{ display: 'PDF', value: 'PDF' },
		{ display: 'Excel', value: 'EXCEL' },
	];

	$scope.filter = {
		filterFirstName: '',
		filterLastName: '',
		filterTitle: '',
		filterEmail: '',
		filterPaymentMethod: '',
		filterPaymentStatus: '',
	};

	$scope.filterChange = function () {
		$scope.getReport($scope.entityId);
	};

	$scope.setPageSizeUpdate = function () {
		$scope.setPageSize();
		$scope.getReport($scope.date);
	};

	$scope.setPageUpdate = function () {
		$scope.setPage();
		$scope.getReport($scope.entityId);
	};
	$scope.appointments = [];

	$scope.listPropertyNames = {};

	ReportService.getSeminarAppointments($scope.entityId).then(function (response) {
		$scope.appointments = response;
	});

	$scope.changeAppointment = function () {
		$scope.getReport($scope.entityId);
	};

	$scope.getReport = function (id) {
		ReportService.getSeminarDetailReport(
			$scope.entityId,
			$scope.pageSize,
			$scope.appointmentId,
			$scope.filter
		).then(function (response) {
			if (response.data) {
				$scope.seminarDetailReport = response.data;
				$scope.setPageVariables();

				if ($scope.seminarDetailReport[0]) {
					$scope.listPropertyNames = Object.keys($scope.seminarDetailReport[0]);

					for (var i = 0; i < $scope.listPropertyNames.length; i++) {
						var result = $scope.listPropertyNames[i].replace(/([A-Z])/g, ' $1');
						$scope.listPropertyNames[i] = result.charAt(0).toUpperCase() + result.slice(1);
					}
				}
			}
		});
	};

	$scope.getReport($scope.date);

	$scope.setPageSize = function () {
		ReportService.setPageSize($scope.pageSize);
	};

	$scope.setPage = function () {
		ReportService.setCurrentPage($scope.getRealCurrentPage());
	};

	$scope.getRealCurrentPage = function () {
		var cur = angular.copy(parseInt($scope.currentPage));
		cur = cur === 0 ? cur : cur - 1;
		return cur;
	};

	$scope.showCurrentlyDisplayed = function () {
		var from = parseInt($scope.pageSize) * parseInt($scope.getRealCurrentPage());
		var to =
			from + parseInt($scope.pageSize) < $scope.totalItems ? from + parseInt($scope.pageSize) : $scope.totalItems;
		return from + 1 + ' - ' + to;
	};

	$scope.setPageVariables = function () {
		$scope.currentPage = ReportService.getCurrentPage();
		$scope.pageSize = ReportService.getPageSize();
		$scope.totalItems = ReportService.getTotalItems();
		$scope.totalPages = ReportService.getTotalPages();
	};

	$scope.downloadReport = function () {
		if ($scope.data.selectedDownloadType === 'EXCEL') {
			$window.open(
				'/api/v1/core/report/get-seminar-detail-excel?id=' +
					$scope.entityId +
					'&shopId=' +
					SessionValuesService.getShop() +
					'&localeId=' +
					SessionValuesService.getLocale() +
					'&appointmentId=' +
					$scope.appointmentId +
					'&' +
					SessionValuesService.getAuthorizationParamKeyAndValue() +
					'&columns=' +
					$scope.data.selectedReportColumns
			);
		} else {
			$window.open(
				'/api/v1/core/report/get-seminar-detail-pdf?id=' +
					$scope.entityId +
					'&shopId=' +
					SessionValuesService.getShop() +
					'&localeId=' +
					SessionValuesService.getLocale() +
					'&appointmentId=' +
					$scope.appointmentId +
					'&' +
					SessionValuesService.getAuthorizationParamKeyAndValue() +
					'&columns=' +
					$scope.data.selectedReportColumns
			);
		}
	};

	$scope.onSuccess = function (result) {};

	$scope.onCancel = function () {};

	$scope.dialogReportController = function ($scope, dataToPass, $mdDialog, $rootScope) {
		$scope.date = {
			start: dataToPass.start,
			end: dataToPass.end,
		};

		$scope.data = dataToPass;
		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		$scope.getReport = function () {
			$rootScope.$emit('getReport', $scope.entityId);
			$rootScope.$emit('closeModal');
		};
	};

	$rootScope.$on('getReport', function (event, data) {
		$scope.getReport(data);
	});
}
;
