define('interceptors',['services/UserService', 'services/NotificationService'], function () {
	'use strict';
	return {
		initInterceptors: function ($httpProvider) {
			$httpProvider.interceptors.push([
				'$rootScope',
				'$location',
				'$injector',
				'NotificationService',
				function ($rootScope, $location, $injector, NotificationService) {
					return {
						request: function (config) {
							$rootScope.$broadcast('showLoadingBar');
							if (config.url.includes('api/v1/cms/content-pages')) {
								$rootScope.$broadcast('disableTinymce', true);
							}
							return config;
						},
						response: function (result) {
							$rootScope.$broadcast('hideLoadingBar');
							if (result.config.url.includes('api/v1/cms/content-pages')) {
								$rootScope.$broadcast('enableTinymce', true);
							}
							return result;
						},
						requestError: function (rejection) {
							$rootScope.$broadcast('hideLoadingBar');
							return rejection;
						},
						responseError: function (response) {
							$rootScope.$broadcast('hideLoadingBar');
							if (response.data && response.data.userError) {
								NotificationService.notifyGeneralError({ msg: response.data.userError });
								return response;
							}

							if (
								response.data &&
								response.data.validationErrors &&
								response.data.validationErrors.length > 0
							) {
								NotificationService.notifyValidationErrors(response.data.validationErrors);
								return response;
							}

							if (
								(response.data && response.data.success === false) ||
								(response.data && response.data.ok === false) ||
								response.status < 200 ||
								response.status >= 400
							) {
								NotificationService.notifyRequestError(response);
							}

							if (response.status == 503 || response.status == 409) {
								var UserService = $injector.get('UserService');
								UserService.removeLogin();
								$injector.get('$state').go('root.login');
							}

							return response;
						},
					};
				},
			]);
		},
	};
});

