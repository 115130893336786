define('services/FilterPageService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('FilterPageService', ['$injector', FilterPageService]);
});

function FilterPageService($injector) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('FilterPage');

	angular.extend(this, crudInstance);

	return this;
}
;
