define('directives/contentConfigurator/contentSnippets/sectionSnippet/directives/breadcrumbs/breadcrumbs',['app', 'settings', 'services/ContentBuilderHelper'], function (app) {
	'use strict';
	app.directive('ngBreadcrumbs', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					inputData: '<data',
					stateEditing: '<',
				},
				templateUrl:
					BASE_DIRECTIVES_PATH +
					'/contentConfigurator/contentSnippets/sectionSnippet/directives/breadcrumbs/views/index.html',
				controller: [
					'$scope',
					'$timeout',
					'$element',
					'$compile',
					'ContentBuilderHelper',
					function ($scope, $timeout, $element, $compile, ContentBuilderHelper) {
						var path =
							BASE_DIRECTIVES_PATH +
							'/contentConfigurator/contentSnippets/sectionSnippet/directives/breadcrumbs/views/';

						$scope.customStyle = path + 'style.css';
						$scope.customStyleFake = path + 'styleFake.css';

						function _init() {
							$scope.data = angular.merge({}, $scope.inputData);
						}
						_init();

						$scope.$watch(
							'inputData',
							function (newValue, oldValue) {
								if (newValue !== oldValue) {
									$scope.data = angular.merge({}, $scope.inputData);
								}
							},
							true
						);
					},
				],
			};
		},
	]);
});

/* sample data
{
  "home": false,
  "items": [
    {
      "title": "Services",
      "url": "/services"
    },
    {
      "title": "Planungshilfe",
      "url": "/services"
    }
  ]
}
*/
;
