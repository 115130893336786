define('modules/cms/controllers/ContentPageController',['modules/module', 'settings', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('ContentPageController', ['$scope', '$controller', 'BASE_TEMPLATES_PATH', ContentPageController]);
});

function ContentPageController($scope, $controller, BASE_TEMPLATES_PATH) {
	angular.extend(
		this,
		$controller('EntityListController', {
			$scope: $scope,
		})
	);

	$scope.tableOptions.fields = [
		{
			heading: 'FIELDS.DESIGNATION',
			attribute: 'name',
		},
		{
			heading: 'URL',
			attribute: 'slug',
		},
	];

	$scope.formState = 'root.contentPage';
	$scope.init('ContentPage');
}
;
