define('directives/contentConfigurator/responsiveCss/ResponsiveCss',['app', 'angular', 'services/ContentBuilderHelper', 'services/ContentCssHelper'], function (app) {
	'use strict';
	app.directive('responsiveCss', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				restrict: 'E',
				scope: {
					metaIdentifier: '<',
					row: '<?',
					isBase: '<?',
				},
				templateUrl: BASE_DIRECTIVES_PATH + '/contentConfigurator/responsiveCss/views/responsiveCss.html',
				controller: [
					'$scope',
					'$controller',
					'ContentBuilderHelper',
					'ContentCssHelper',
					function ($scope, $controller, ContentBuilderHelper, ContentCssHelper) {
						var VIEWPORT_SIZE = ($scope.VIEWPORT_SIZE = ContentBuilderHelper.VIEWPORT_SIZE);
						$scope.wrapSetups = [];

						var _init = function () {
							$scope.edit = true;
							$scope.wrapSetups = ContentCssHelper.wrapSetups;
							$scope.selectedSetupId = ContentBuilderHelper.getViewportSetupId();
							$scope.selectedDimensions = { min: 0, max: 0 };
							$scope.viewportSetups = [];

							if (!$scope.isBase && $scope.row) {
								for (var att in ContentBuilderHelper.VIEWPORT_SIZE) {
									var setup = {};
									var dims = ContentCssHelper.getViewportDimensions(att);
									setup.min = dims.min;
									setup.max = dims.max;
									setup.style = ContentCssHelper.getWrapSetup($scope.row.responsive[att]).style;

									$scope.viewportSetups.push(setup);
								}
							}

							if ($scope.isBase) {
								var dims = ContentCssHelper.getViewportDimensions(
									ContentBuilderHelper.getViewportSetupId()
								);
								$scope.selectedDimensions.min = dims.min;
								$scope.selectedDimensions.max = dims.max;
							}

							if ($scope.row !== undefined) {
								$scope.rowIdentifier = $scope.row.identifier;
								if ($scope.row.responsive === undefined) {
									$scope.row.responsive = {};
									$scope.row.responsive[$scope.selectedSetupId] = 'FILL';
								}
								$scope.selectedSetup = ContentCssHelper.getWrapSetup(
									$scope.row.responsive[$scope.selectedSetupId]
								);
							} else {
								$scope.selectedSetup = ContentCssHelper.getWrapSetup('FILL');
							}

							ContentBuilderHelper.subscribeSizeChanged($scope, function (e, args) {
								if ($scope.isBase) {
									var dims = ContentCssHelper.getViewportDimensions(
										ContentBuilderHelper.getViewportSetupId()
									);
									$scope.selectedDimensions.min = dims.min;
									$scope.selectedDimensions.max = dims.max;
								}

								if (!$scope.isBase) {
									$scope.selectedSetupId = ContentBuilderHelper.getViewportSetupId();

									if ($scope.row.responsive === undefined) {
										$scope.row.responsive = {};
										$scope.row.responsive[$scope.selectedSetupId] =
											ContentCssHelper.WRAP_MODES.FILL;
									}

									$scope.selectedSetup = ContentCssHelper.getWrapSetup(
										$scope.row.responsive[$scope.selectedSetupId]
									);
								}
							});

							ContentBuilderHelper.subscribeEditModeToggled($scope, function (e, args) {
								if (args.identifier === $scope.metaIdentifier || args.identifier === 'GLOBAL') {
									$scope.edit = args.edit;
									$scope.final = args.preSave || false;
								}
							});
						};
						_init();
					},
				],
			};
		},
	]);
});

