define('modules/wawi/product/controllers/ProductFormController',[
	'modules/module',
	'modules/basemodule/controllers/EntityFormController',
	'modules/wawi/product/controllers/ProductBaseFormController',
	'services/ProductService',
	'services/TagService',
	'services/ShippingGroupService',
	'services/NavigationService',
	'services/ValidationService',
	'services/NotificationService',
	'services/ImageService',
	'services/MediaService',
	'services/UnitService',
	'services/TaxService',
	'services/RefinementProductService',
	'services/ManufacturerService',
	'directives/imageUpload/imageUpload',
	'directives/mediaUpload/mediaUpload',
	'directives/controls/customInput/CustomInput',
	'directives/infinityTransform/InfinityTransform',
	'filters/RemoveSpacesAndSpecialCharacters',
	'settings',
], function (module) {
	'use strict';
	module.controller('ProductFormController', ['$scope', '$controller', ProductFormController]);
});

function ProductFormController($scope, $controller) {
	angular.extend(
		this,
		$controller('ProductBaseFormController', {
			$scope: $scope,
		})
	);

	$scope.afterInit = $scope.init('Product').then(function () {});
}
;
