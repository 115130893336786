define('services/EntityCrudUnpagedService',['app', 'modules/module', 'services/LocalStorageService'], function (app, module) {
	'use strict';
	return module.factory('EntityCrudUnpagedService', [
		'$rootScope',
		'$injector',
		'LocalStorageService',
		EntityCrudUnpagedService,
	]);
});

function EntityCrudUnpagedService($rootScope, $injector) {
	var _query = {},
		_entities = [],
		_entity,
		_entityName,
		_additionalParams = [];

	var _clear = function () {
		return _findAll();
	};

	var _findAllSuccess = function (response) {
		_entities = response;
		$rootScope.$broadcast(_entityName + 'Changes', _entities);
		return response;
	};

	var _findAllError = function (response) {
		_entities = [];
		$rootScope.$broadcast(_entityName + 'Error', response);
		return response;
	};

	var _findAll = function () {
		_entity = undefined;
		if (Object.keys(_query).length === 0) {
			var params = { page: 0, size: 10000000 };
		} else {
			var params = {
				q: btoa(angular.toJson(_query)),
			};
		}

		//adding additional parameters to the call
		_additionalParams.forEach(function (param) {
			params[param.name] = param.value;
		});
		_additionalParams = [];

		return getModel().findAll(params).$promise.then(_findAllSuccess, _findAllError);
	};

	const getModel = () => {
		if (_entityName) {
			return new $injector.get('ModelFactory')[_entityName]();
		} else {
			throw "Can't construct model, entityName is not set";
		}
	};

	return {
		clear: _clear,
		getModel: getModel,
		setType: function (type) {
			_entityName = type;
			return this;
		},
		getType: function () {
			return _entityName;
		},
		setAdditionalParams: function (params) {
			_additionalParams = params;
		},
		setQuery: function (query, findAll) {
			_query = query;

			if (findAll !== undefined && findAll === false) {
				return;
			}

			return _findAll();
		},
		getAll: function () {
			return _entities;
		},
		getOne: function () {
			return _entity;
		},
		findAll: function (deactivatePaging) {
			return _findAll(deactivatePaging);
		},
		findOne: function (id) {
			return getModel()
				.findOne({ id: id })
				.$promise.then(function (response) {
					_entity = response;
				});
		},
		create: function (entity) {
			var promise = getModel().create(entity).$promise;
			promise.then(_clear);
			return promise;
		},
		update: function (entity) {
			var promise = getModel().update(entity).$promise;
			promise.then(_clear);
			return promise;
		},
		delete: function (id) {
			var promise = getModel().delete({ id: id }).$promise;
			promise.then(_clear);
			return promise;
		},
	};
}
;
