define('directives/mediaUpload/mediaUpload',['app', 'settings', 'services/MediaService', 'services/SessionValuesService'], function (app) {
	'use strict';
	app.directive('ngMediaUpload', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					contextLabel: '@?',
					mode: '=?',
					files: '=?',
				},
				templateUrl: BASE_DIRECTIVES_PATH + '/mediaUpload/views/mediaUpload.html',
				controller: [
					'$scope',
					'$rootScope',
					'$timeout',
					'MediaService',
					'SessionValuesService',
					'FileUploader',
					function ($scope, $rootScope, $timeout, MediaService, SessionValuesService, FileUploader) {
						$scope.files;

						$scope.uploader = MediaService.initFileUpload(
							new FileUploader({
								url: '/api/v1/media/files?directoryid=-1',
								headers: SessionValuesService.getFullSessionHeader(),
							})
						);

						$scope.uploadViaInput = function () {
							angular.element('#file-input').trigger('click');
						};

						$scope.uploadItems = () => {
							console.log($scope.contextLabel);
							MediaService.uploadItems($scope.uploader.queue, $scope.contextLabel);
						};

						$scope.getView = function () {
							if ($scope.mode === 'customer') {
								return BASE_DIRECTIVES_PATH + '/mediaUpload/views/mediaUpload-customer.html';
							} else {
								return BASE_DIRECTIVES_PATH + '/mediaUpload/views/mediaUpload-standard.html';
							}
						};

						$scope.$on('mediaUploaded', (response) => {
							$scope.uploader.queue = [];
						});
					},
				],
			};
		},
	]);
});

