/**
 * Created by nmayer on 09.06.16.
 */
define('modules/settings/systemMessage/SystemMessageListController',['modules/module', 'settings', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('SystemMessageListController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		function ($scope, $controller, BASE_TEMPLATES_PATH) {
			angular.extend(
				this,
				$controller('EntityListController', {
					$scope: $scope,
				})
			);

			$scope.state.requireSuperuserForDelete = true;

			$scope.tableOptions.fields = [
				{
					id: 'NAME',
					heading: 'FIELDS.NAME',
					attribute: 'name',
				},
				{
					id: 'STATE',
					heading: 'FIELDS.STATE',
					attribute: 'state',
				},
			];
			$scope.formState = 'root.systemMessage';

			$scope.init('SystemMessage');
		},
	]);
});

