define('modules/settings/shop/tabs/LegalTextsTabController',[], function () {
	'use strict';

	return function LegalPagesController($parentScope, $scope, { BASE_TEMPLATES_PATH, LocaleService, $translate }) {
		$parentScope.formTabs.push({
			label: 'FIELDS.LEGAL_TEXTS',
			template: BASE_TEMPLATES_PATH + '/settings/shop/form-legal-pages.html',
		});

		$scope.setSelectedLocale = (id) => {
			if (id === undefined) {
				$scope.selectedLocale = !!$scope.shopLocales.length ? $scope.shopLocales[0] : undefined;
			} else {
				$scope.selectedLocale = $scope.shopLocales.find((l) => l.id === id);
			}
		};

		$scope.getLocaleLabelById = (id) => {
			return $scope.shopLocales.find((l) => l.id === id) || {};
		};

		function createBlankLegalPageIfMissing(pageId, shopLocales) {
			let page = null;

			if (!shopLocales || shopLocales.length <= 0) {
				return;
			}

			// Try to get existing page from shop
			if (!!$parentScope.entity) {
				page = $parentScope.entity.legalPages.find(
					(page) => page.slug === pageId.toLocaleLowerCase().slice(pageId.lastIndexOf('.') + 1)
				);
			}

			const pageMissing = !page;

			// Init page if missing or is new shop entity
			if ($parentScope.newEntity || pageMissing) {
				page = {
					slug: pageId.toLocaleLowerCase().slice(pageId.lastIndexOf('.') + 1),
					locales: [],
				};
			}

			const pageLocales = [];

			// Iterate shop locales
			for (let locale of shopLocales) {
				// If the page has locale not set, create content dummy
				if (!page.locales.find((l) => l.localeId === locale.id)) {
					const pageName = $translate.instant(pageId, undefined, undefined, locale.locale);
					pageLocales.push({
						name: pageName,
						localeId: locale.id,
						longDescription: {
							html: '',
							json: '',
						},
						shortDescription: {
							html: '',
							json: '',
						},
					});
				} else {
					pageLocales.push(page.locales.find((l) => l.localeId === locale.id));
				}
			}

			page.locales = pageLocales;

			if (pageMissing) {
				$parentScope.entity.legalPages.push(page);
			}
		}

		function resolveShopLocales() {
			const shopLocaleIds = $parentScope.entity.localeIds || [];
			const allLocales = LocaleService.getAll();

			const shopLocales = [];
			for (let lId of shopLocaleIds) {
				const loc = allLocales.find((al) => al.id === lId);
				if (!!loc) {
					shopLocales.push(loc);
				}
			}

			return shopLocales;
		}

		$scope.onSelectedShopLocale = (shopLocales) => {
			$scope.shopLocales = shopLocales;
			$scope.setSelectedLocale();
			prepareLegalPages(shopLocales);
		};

		function prepareLegalPages(shopLocales) {
			createBlankLegalPageIfMissing('SETTINGS.LEGAL_PAGES.REVOCATION', shopLocales);
			createBlankLegalPageIfMissing('SETTINGS.LEGAL_PAGES.TERMS_OF_SERVICE', shopLocales);
			createBlankLegalPageIfMissing('SETTINGS.LEGAL_PAGES.DATA_PROTECTION', shopLocales);
			createBlankLegalPageIfMissing('SETTINGS.LEGAL_PAGES.IMPRINT', shopLocales);
			createBlankLegalPageIfMissing('SETTINGS.LEGAL_PAGES.DISCLAIMER', shopLocales);
		}

		$scope.init = () => {
			// Assert legal pages array
			if (!$parentScope.entity.legalPages) {
				$parentScope.entity.legalPages = [];
			}

			LocaleService.findAll(true).then(() => {
				$scope.shopLocales = resolveShopLocales();

				$scope.selectedLocale = $scope.shopLocales.find((l) => l.id === $parentScope.entity.defaultLocaleId);
				prepareLegalPages($scope.shopLocales);
			});
		};
	};
});

