define('directives/contentConfigurator/contentRow/ContentRow',[
	'app',
	'angular',
	'directives/contentConfigurator/toolbar/Toolbar',
	'directives/contentConfigurator/contentRow/ContentRowConfig',
	'directives/contentConfigurator/contentCell/ContentCell',
	'directives/contentConfigurator/responsiveCss/ResponsiveCss',
	// 'directives/contentConfigurator/contentSnippets/imageSnippet/ImageSnippet',
	// 'directives/contentConfigurator/contentSnippets/textSnippet/TextSnippet',
	// 'directives/contentConfigurator/contentSnippets/accordionSnippet/AccordionSnippet',
	// 'directives/contentConfigurator/contentSnippets/tinySnippet/TinySnippet',
	// 'directives/contentConfigurator/contentSnippets/sectionSnippet/SectionSnippet',
	// 'directives/contentConfigurator/contentSnippets/sliderSnippet/SliderSnippet',
	// 'directives/contentConfigurator/contentSnippets/layoutSnippet/LayoutSnippet',
	// 'directives/contentConfigurator/blankRow/BlankRow',
	// 'services/FileUploadService',
	'services/ContentBuilderHelper',
], function (app) {
	'use strict';
	app.directive('contentRow', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				restrict: 'E',
				transclude: true,
				scope: {
					metaIdentifier: '<',
					row: '=',
					// meta: '=?',
					rowIndex: '=',
					// cellIndex: '=',
					root: '=',
					responsive: '<',
					allowLayouts: '<',
				},
				templateUrl: BASE_DIRECTIVES_PATH + '/contentConfigurator/contentRow/views/contentRow.html',
				controller: [
					'$scope',
					'ContentBuilderHelper',
					'FileUploadService',
					function ($scope, ContentBuilderHelper, FileUploadService) {
						var ELEMENT_TYPE = ($scope.ELEMENT_TYPE = ContentBuilderHelper.ELEMENT_TYPE);
						// $scope.elementIdentifier = ContentBuilderHelper.registerElement($scope.metaIdentifier);
						$scope.elementType = ELEMENT_TYPE.ROW;
						// $scope.selected = ContentBuilderHelper.selectedElement($scope.metaIdentifier).elementIdentifier === $scope.elementIdentifier;

						var _init = function () {
							// if ($scope.allowLayouts === undefined) {
							//   $scope.allowLayouts = true;
							// }
							// $scope.contentAnchor = angular.element(document.querySelector('#element' + $scope.metaIdentifier));
							$scope.edit = true;
							// $scope.cell.selected = false;
							// $scope.dummySnippet = ContentBuilderHelper.getBlankSnippet();
							// $scope.elementIdentifier = ContentBuilderHelper.registerElement($scope.metaIdentifier);
							// $scope.cell.identifier = $scope.elementIdentifier;

							ContentBuilderHelper.subscribeEditModeToggled($scope, function (e, args) {
								if (args.metaIdentifier === $scope.metaIdentifier || args.metaIdentifier === 'GLOBAL') {
									$scope.edit = args.edit;
									$scope.final = args.preSave || false;
								}
							});
							ContentBuilderHelper.subscribeSelected($scope, function (e, args) {
								if (args.metaIdentifier === $scope.metaIdentifier || args.metaIdentifier === 'GLOBAL') {
									var selectedElementIdentifier = ContentBuilderHelper.selectedElement(
										args.metaIdentifier
									).elementIdentifier;
									var isCurrentSelected = selectedElementIdentifier === $scope.row.identifier;

									$scope.selected = isCurrentSelected;
								}
							});
						};

						_init();

						// $scope.isDropValid = function (event, data) {
						//   ContentBuilderHelper.clearDragIndicators();
						//   return true;
						// };

						// $scope.selectCell = function (event) {
						//   event.stopPropagation();
						//   ContentBuilderHelper.callOutSelected($scope.metaIdentifier, $scope.elementIdentifier, $scope.elementType, undefined, $scope.cell);
						// };

						// $scope.onDragOverSnippet = function (event, data, hem, index) {
						//   switch (data) {
						//     case ELEMENT_TYPE.LAYOUT: {
						//       if (!$scope.allowLayouts) {
						//         break;
						//       }
						//     }
						//     case ELEMENT_TYPE.PART:
						//     case ELEMENT_TYPE.SNIPPET: {
						//       hem = hem.indexOf('top') >= 0 ? 'top' : 'bottom';
						//       ContentBuilderHelper.callOutDragOver($scope.metaIdentifier, hem, index, $scope.cell.children);
						//       break;
						//     }
						//     case ELEMENT_TYPE.CELL: {
						//       hem = hem.indexOf('left') >= 0 ? 'before' : 'after';
						//       if ($scope.cell.type === 'dummy') {
						//         hem = 'before';
						//       }
						//       ContentBuilderHelper.callOutDragOver($scope.metaIdentifier, hem, $scope.cellIndex, $scope.root.children[$scope.rowIndex]);
						//       break;
						//     }
						//     case ELEMENT_TYPE.ROW: {
						//       hem = hem.indexOf('top') >= 0 ? 'top' : 'bottom';
						//       ContentBuilderHelper.callOutDragOver($scope.metaIdentifier, hem, $scope.rowIndex, $scope.root);
						//       break;
						//     }
						//   }

						// };

						// $scope.onDrop = function (event, data, hem, index) {
						//   switch (data) {
						//     case ELEMENT_TYPE.LAYOUT: {
						//       if (!$scope.allowLayouts) {
						//         break;
						//       }
						//     }
						//     case ELEMENT_TYPE.PART:
						//     case ELEMENT_TYPE.SNIPPET: {
						//       hem = hem.indexOf('top') >= 0 ? 'before' : 'after';
						//       ContentBuilderHelper.callOutDrop($scope.metaIdentifier, hem, index, $scope.cell, undefined, $scope.responsive, ELEMENT_TYPE.CELL);
						//       break;
						//     }
						//     case ELEMENT_TYPE.CELL: {
						//       hem = hem.indexOf('left') >= 0 ? 'before' : 'after';
						//       if ($scope.cell.type === 'dummy') {
						//         hem = 'before';
						//       }
						//       ContentBuilderHelper.callOutDrop($scope.metaIdentifier, hem, $scope.cellIndex, $scope.root[$scope.rowIndex], { identifier: $scope.metaIdentifier, children: $scope.root }, $scope.responsive, ELEMENT_TYPE.CELL);
						//       break;
						//     }
						//     case ELEMENT_TYPE.ROW: {
						//       hem = hem.indexOf('top') >= 0 ? 'before' : 'after';
						//       ContentBuilderHelper.callOutDrop($scope.metaIdentifier, hem, $scope.rowIndex, { identifier: $scope.metaIdentifier, children: $scope.root }, undefined, $scope.responsive, ELEMENT_TYPE.CELL);
						//       break;
						//     }
						//   }
						// };

						// $scope.onDragOverEmpty = function (event, data, hem) {
						//   switch (data) {
						//     case ELEMENT_TYPE.LAYOUT: {
						//       if (!$scope.allowLayouts) {
						//         break;
						//       }
						//     }
						//     case ELEMENT_TYPE.PART:
						//     case ELEMENT_TYPE.SNIPPET: {
						//       hem = 'after';
						//       if ($scope.cell.children.length > 0) {
						//         ContentBuilderHelper.callOutDragOver($scope.metaIdentifier, 'insert', $scope.cell.children.length - 1, $scope.cell.children);
						//       } else {
						//         ContentBuilderHelper.callOutDragOver($scope.metaIdentifier, 'insert', 0, [$scope.dummySnippet]);
						//       }
						//       break;
						//     }
						//     case ELEMENT_TYPE.CELL: {
						//       hem = hem.indexOf('left') >= 0 ? 'before' : 'after';
						//       if ($scope.cell.type === 'dummy') {
						//         hem = 'before';
						//       }
						//       ContentBuilderHelper.callOutDragOver($scope.metaIdentifier, hem, $scope.cellIndex, $scope.root.children[$scope.rowIndex]);
						//       break;
						//     }
						//     case ELEMENT_TYPE.ROW: {
						//       hem = hem.indexOf('top') >= 0 ? 'before' : 'after';
						//       ContentBuilderHelper.callOutDragOver($scope.metaIdentifier, hem, $scope.rowIndex, $scope.root);
						//       break;
						//     }
						//   }
						// };

						// $scope.onDropEmpty = function (event, data, hem) {
						//   if ($scope.cell && $scope.cell.children) {
						//     var dropIndex = $scope.cell.children.length - 1;
						//     if (dropIndex < 0) {
						//       dropIndex = 0;
						//     }
						//   }
						//   switch (data) {
						//     case ELEMENT_TYPE.LAYOUT: {
						//       if (!$scope.allowLayouts) {
						//         break;
						//       }
						//     }
						//     case ELEMENT_TYPE.PART:
						//     case ELEMENT_TYPE.SNIPPET: {
						//       hem = 'after';
						//       ContentBuilderHelper.callOutDrop($scope.metaIdentifier, hem, dropIndex, $scope.cell, undefined, $scope.responsive, ELEMENT_TYPE.CELL);
						//       break;
						//     }
						//     case ELEMENT_TYPE.CELL: {
						//       hem = hem.indexOf('left') >= 0 ? 'before' : 'after';
						//       if ($scope.cell.type === 'dummy') {
						//         hem = 'before';
						//       }
						//       ContentBuilderHelper.callOutDrop($scope.metaIdentifier, hem, $scope.cellIndex, $scope.root.children[$scope.rowIndex], $scope.root, $scope.responsive, ELEMENT_TYPE.CELL);
						//       break;
						//     }
						//     case ELEMENT_TYPE.ROW: {
						//       hem = hem.indexOf('top') >= 0 ? 'before' : 'after';
						//       ContentBuilderHelper.callOutDrop($scope.metaIdentifier, hem, $scope.rowIndex, { identifier: $scope.metaIdentifier, children: $scope.root }, undefined, $scope.responsive, ELEMENT_TYPE.CELL);
						//       break;
						//     }
						//   }
						// };
					},
				],
			};
		},
	]);
});

