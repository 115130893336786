define('services/ShippingGroupService',['app', 'modules/module', 'services/EntityCrudService', 'modules/models'], function (app, module) {
	'use strict';
	return module.factory('ShippingGroupService', [
		'$q',
		'$rootScope',
		'$cookies',
		'$injector',
		'ModelFactory',
		function ($q, $rootScope, $cookies, $injector, ModelFactory) {
			var crudInstance = $injector.instantiate(CrudService);
			angular.extend(this, crudInstance);
			this.setType('ShippingGroup');

			var _availableCountries = [];
			/*var ShippingCountries = $injector.get("ShippingCountries");*/

			this.loadAvailableCountries = function () {
				var countries = new $injector.get('ModelFactory')['ShippingCountries']();
				var ref = $q.defer();
				countries.findAll().$promise.then(function (response) {
					_availableCountries = response.content;
					ref.resolve();
				});
				return ref.promise;
			};

			this.getAvailableCountries = function () {
				return _availableCountries;
			};

			return this;
		},
	]);
});
/*define([
	'app',
	'modules/module',
	'services/EntityCrudService'
], function (app, module) {
	'use strict';
	return module.factory('ShippingGroupService', [
		'$injector',
		'$q',
		ShippingGroupService
	]);
});


function ShippingGroupService($injector, $q, ModelFactory) {

	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('ShippingGroup');

	angular.extend(this, crudInstance);

	var _availableCountries = [];
	var ShippingCountries = $injector.get("ShippingCountries");

	this.loadAvailableCountries = function () {
		var ref = $q.defer();
		ModelFactory.ShippingCountries.findAll().$promise.then(function (response) {
			_availableCountries = response.content;
			console.log(response);
			ref.resolve();
		});
		return ref.promise;
	};

	this.getAvailableCountries = function () {
		return _availableCountries;
	};

	return this;
}


*/
;
