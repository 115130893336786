/**
 * Created by nmayer on 09.06.16.
 */
define('modules/settings/user/UserController',['modules/module', 'settings', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('UserController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		function ($scope, $controller, BASE_TEMPLATES_PATH) {
			angular.extend(
				this,
				$controller('EntityListController', {
					$scope: $scope,
				})
			);

			$scope.state.requireSuperuserForDelete = true;

			$scope.tableOptions.fields = [
				{
					id: 'USERNAME',
					heading: 'FIELDS.USERNAME',
					attribute: 'username',
				},
				{
					id: 'FIRST_NAME',
					heading: 'FIELDS.FIRSTNAME',
					attribute: 'firstName',
				},
				{
					id: 'LAST_NAME',
					heading: 'FIELDS.LASTNAME',
					attribute: 'lastName',
				},
				{
					id: 'EMAIL',
					heading: 'FIELDS.EMAIL',
					attribute: 'email',
				},
				{
					id: 'ROLES',
					heading: 'FIELDS.ROLES',
					template: BASE_TEMPLATES_PATH + '/settings/user/user-roles-col.html',
					searchDisabled: true,
					orderDisabled: true,
				},
			];
			$scope.formState = 'root.user';

			$scope.init('User');
		},
	]);
});

