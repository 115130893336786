define('services/ProductMetadataValueSetService',['app', 'modules/module'], function (app, module) {
	'use strict';
	return module.factory('ProductMetadataValueSetService', [
		'$injector',
		'ModelFactory',
		ProductMetadataValueSetService,
	]);
});

function ProductMetadataValueSetService($injector, ModelFactory) {
	let _vehicles;
	let _pages, _page, _totalItems;
	let _vehicleFilters = ['hsn', 'tsn', 'engineCodes', 'order'];

	this.fetchVehicles = (page = 0, size = 10, filters = {}) => {
		let params = {};
		for (let key of Object.keys(filters)) {
			if (!_vehicleFilters.includes(key)) {
				delete filters[key];
			} else if (key === 'engineCodes') {
				params[key] = filters[key];
			} else {
				params['filter[' + key + ']'] = filters[key];
			}
		}
		params.page = page === page ? page : 0; // Check for NaN
		params.size = size;

		return ModelFactory.ProductMetadataValueSet()
			.getVehicles(params)
			.$promise.then(function (response) {
				// console.log(response);
				_vehicles = response.data.map((item) => {
					delete item.cylinders;
					delete item.engineType;
					delete item.fuelType;
					return {
						brand: item.brand,
						model: item.model,
						engineCode: item.engineCode,
						constructionStart: item.constructionStart,
						engineDisplacement: item.engineDisplacement,
						enginePowerKw: item.enginePowerKw,
						hsn: item.hsn,
						id: item.id,
						tsn: item.tsn,
					};
				});
				_pages = response.pages;
				_page = response.page + 1;
				_totalItems = response.count;
			})
			.catch((e) => {});
	};

	this.getVehicles = () => {
		return _vehicles;
	};

	this.getPages = () => {
		return _pages;
	};

	this.getCurrentPage = () => {
		return _page;
	};

	this.getTotalItems = () => {
		return _totalItems;
	};

	this.getVehicleFilters = () => {
		return _vehicleFilters;
	};

	return this;
}
;
