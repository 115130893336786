/**
 * Created by Nico on 18.07.2016.
 */
define('services/TitleUnpagedService',['app', 'modules/module', 'services/EntityCrudUnpagedService'], function (app, module) {
	'use strict';
	return module.factory('TitleUnpagedService', ['$injector', TitleUnpagedService]);
});

function TitleUnpagedService($injector) {
	crudInstance = $injector.instantiate(EntityCrudUnpagedService);
	crudInstance.setType('Title');

	angular.extend(this, crudInstance);

	return this;
}
;
