define('services/BankAccountService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('BankAccountService', ['$injector', BankAccountService]);
});

function BankAccountService($injector) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('BankAccount');

	angular.extend(this, crudInstance);

	return this;
}
;
