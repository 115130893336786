define('modules/settings/dataFeedInput/DataFeedInputController',['modules/module', 'settings', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('DataFeedInputController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		function ($scope, $controller, BASE_TEMPLATES_PATH) {
			angular.extend(
				this,
				$controller('EntityListController', {
					$scope: $scope,
				})
			);

			$scope.tableOptions.fields = [
				{
					id: 'TYPE',
					heading: 'Type',
					attribute: 'fileType',
					orderDisabled: false,
				},
				{
					id: 'FILE_NAME',
					heading: 'Dateiname',
					attribute: 'fileName',
					orderDisabled: false,
				},
				{
					id: 'SHOP',
					heading: 'Shop',
					attribute: 'shop',
					orderDisabled: false,
				},
				{
					id: 'LOCALE',
					heading: 'FIELDS.LANGUAGE',
					attribute: 'locale',
					orderDisabled: false,
				},
				{
					id: 'CURRENCY',
					heading: 'FIELDS.CURRENCY',
					attribute: 'currency',
					orderDisabled: false,
				},
				{
					id: 'CUSTOMER_GROUP',
					heading: 'FIELDS.CUSTOMER_GROUP',
					attribute: 'customerGroup',
					orderDisabled: false,
				},
				{
					id: 'LAST_GENERATION',
					heading: 'FIELDS.LAST_GENERATION',
					attribute: 'lastGeneration',
					orderDisabled: true,
				},
				{
					id: 'INCLUDE_ALL_PRODUCTS',
					heading: 'INCLUDES_ALL_PRODUCTS',
					template: BASE_TEMPLATES_PATH + '/settings/dataFeedInput/col/include-all-products-col.html',
					attribute: 'includeAllProducts',
					searchDisabled: true,
					orderDisabled: true,
				},
			];
			$scope.formState = 'root.dataFeedInput';

			$scope.init('DataFeedInput');
		},
	]);
});

