define('directives/categorySelector/CategorySelector',['app', 'settings', 'services/ImageService', 'directives/bufferedScroll/bufferedScroll'], function (app) {
	'use strict';
	app.directive('ngCategorySelector', [
		'BASE_DIRECTIVES_PATH',
		'CategoryService',
		function (BASE_DIRECTIVES_PATH, CategoryService) {
			return {
				scope: {
					selectedCategoryIds: '=',
					onCategoriesLoaded: '=?',
				},
				templateUrl: BASE_DIRECTIVES_PATH + '/categorySelector/views/categorySelector.html',
				controller: [
					'$scope',
					function ($scope) {
						CategoryService.getHierarchy().then(function () {
							$scope.categories = CategoryService.getAllHierarchy();
							$scope.setSelectedCategories($scope.categories);
							if ($scope.onCategoriesLoaded) {
								$scope.onCategoriesLoaded();
							}
						});

						$scope.setCategory = function (categoryId, checked) {
							if ($scope.selectedCategoryIds == undefined) {
								$scope.selectedCategoryIds = [];
							}
							if (checked) {
								if ($scope.selectedCategoryIds.indexOf(categoryId) === -1) {
									$scope.selectedCategoryIds.push(categoryId);
								}
							} else {
								var index = $scope.selectedCategoryIds.indexOf(categoryId);
								$scope.selectedCategoryIds.splice(index, 1);
							}
						};

						$scope.toggleAll = function (category, checkIt) {
							if (checkIt === undefined) {
								checkIt = !category.checked;
							}
							category.checked = checkIt;
							$scope.setCategory(category.id, checkIt);
							var children = category.children;
							for (var i = 0; i < children.length; i++) {
								var child = children[i];
								child.checked = checkIt;
								$scope.setCategory(child.id, checkIt);
								if (child.children && child.children.length > 0) {
									$scope.toggleAll(child, checkIt);
								}
							}
						};

						$scope.setSelectedCategories = function (categories) {
							categories.forEach(function (category) {
								var isCategorySelected = false;
								$scope.selectedCategoryIds.forEach(function (entityCategoryId) {
									if (entityCategoryId == category.id) {
										isCategorySelected = true;
									}
								});
								if (isCategorySelected) {
									category.checked = true;
								}
								if (category.children.length > 0) {
									$scope.setSelectedCategories(category.children);
								}
							});
						};
					},
				],
			};
		},
	]);
});

