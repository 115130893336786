define('filters/TimestampFilter',['app'], function (app) {
	'use strict';
	app.filter('timestampFilter', function () {
		return function (isoDate) {
			var a = new Date(isoDate);
			var months = ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
			var year = a.getFullYear();
			var month = months[a.getMonth()];
			var date = a.getDate();
			var hour = a.getHours();
			var min = a.getMinutes();
			var sec = a.getSeconds();

			if (hour < 10) {
				hour = '0' + hour;
			}
			if (min < 10) {
				min = '0' + min;
			}
			if (sec < 10) {
				sec = '0' + sec;
			}

			var time = date + '. ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
			if (isNaN(date)) {
				return '';
			}
			return time;
		};
	});
});

