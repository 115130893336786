define('modules/settings/bankAccount/BankAccountFormController',[
	'modules/module',
	'modules/basemodule/controllers/EntityFormController',
	'services/ValidationService',
	'settings',
], function (module) {
	'use strict';
	module.controller('BankAccountFormController', [
		'$scope',
		'$controller',
		'ValidationService',
		'BASE_TEMPLATES_PATH',
		function ($scope, $controller, ValidationService, BASE_TEMPLATES_PATH) {
			angular.extend(
				this,
				$controller('EntityFormController', {
					$scope: $scope,
				})
			);

			$scope.formTabs = [
				{
					label: 'FIELDS.GENERAL',
					template: BASE_TEMPLATES_PATH + '/settings/bankAccount/form-main.html',
					active: true,
				},
			];
			$scope.init('BankAccount').then(function () {});

			$scope.listState = 'root.bankAccounts';

			$scope.validate = function () {
				return ValidationService.validate($scope.entity, {
					bankAccountHolder: {
						method: 'isset',
						fieldName: 'Kontoinhaber',
					},
					bankBic: {
						method: 'isset',
						fieldName: 'BIC',
					},
					bankName: {
						method: 'isset',
						fieldName: 'Name der Bank',
					},
					bankKnr: {
						method: 'isset',
						fieldName: 'Kontonummer',
					},
					bankBlz: {
						method: 'isset',
						fieldName: 'BLZ',
					},
					bankIban: {
						method: 'isset',
						fieldName: 'IBAN',
					},
				});
			};
		},
	]);
});

