define('directives/contentConfigurator/contentSnippets/imageSnippet/ImageSnippet',[
	'app',
	'angular',
	'services/ContentBuilderHelper',
	'directives/contentConfigurator/contentSnippets/_snippet',
], function (app) {
	'use strict';

	// helper directive to create dynamic tags inside parent directive
	app.directive('imageSnippetContent', [
		'$sce',
		'$compile',
		function ($sce, $compile) {
			return function (scope, element, attrs) {
				function render(config) {
					var conf;
					if (typeof config !== 'undefined') {
						conf = config;
					} else {
						conf = scope.snippet.config;
					}
					var hasLink = !!conf.link;
					var hasClasses = !!conf.classes;

					// a[classes][title] img[alt]

					var children = [
						{
							tag: 'img',
							attrs: 'ng-src="{{snippet.src}}" alt="{{snippet.config.alt}}"',
						},
					];

					if (hasLink) {
						// ... > a[attr][target]
						var target = '';
						if (conf.link_target_blank) {
							target = ' target="_blank" ';
						}
						children = [
							{
								tag: 'a',
								attrs: 'ng-href="{{snippet.config.link}}"' + target + 'title="{{snippet.config.alt}}"',
								children: children,
							},
						];
					}

					if (hasClasses) {
						children[0].attrs += ' ng-class="\'{{snippet.config.classes}}\'"';
					}

					// build tag tree first
					// var tags = [{ tag: conf.type, children: children, attrs: 'ng-class="\'{{snippet.config.classes}}\'"' }]; // p|h1|h2|h3

					function buildTree(tagTree) {
						var treeHtml = '';
						if (Array.isArray(tagTree)) {
							var innerHtml = '';
							tagTree.forEach(function (tagElem) {
								if (Array.isArray(tagElem.children)) {
									innerHtml += buildTree(tagElem.children);
								}
								treeHtml +=
									'<' +
									tagElem.tag +
									(tagElem.attrs ? ' ' + tagElem.attrs : '') +
									'>' +
									innerHtml +
									'</' +
									tagElem.tag +
									'>';
							});
						}
						return treeHtml;
					}

					element.html(buildTree(children));
					$compile(element.contents())(scope);
				}
				scope.$watch('snippet.config', function (value) {
					render(value);
				});
				scope.$watch('edit', function (value) {
					render();
				});
			};
		},
	]);

	app.directive('imageSnippet', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				restrict: 'E',
				scope: {
					metaIdentifier: '<',
					snippet: '=',
					position: '=',
					parent: '<',
				},
				templateUrl:
					BASE_DIRECTIVES_PATH + '/contentConfigurator/contentSnippets/imageSnippet/views/imageSnippet.html',
				controller: [
					'$scope',
					'$controller',
					'$rootScope',
					'ContentBuilderHelper',
					function ($scope, $controller, $rootScope, ContentBuilderHelper) {
						angular.extend(
							this,
							$controller('snippetController', {
								$scope: $scope,
							})
						);
						var snippet = $scope.snippet;

						$scope.modalTemplate =
							BASE_DIRECTIVES_PATH +
							'/contentConfigurator/contentSnippets/imageSnippet/views/imageModal.html';

						var _init = function () {
							$scope.snippetType = 'image';
							$scope.elementType = $scope.ELEMENT_TYPE.SNIPPET;

							if ($scope.snippet.config === undefined) {
								$scope.snippet.config = {
									alt: '',
									classes: '',
									link: undefined,
									link_target_blank: false,
								};
							}
						};

						$scope.dialogImageController = function ($scope, dataToPass, $mdDialog) {
							// $scope.data = dataToPass;

							$scope.cancel = function () {
								$mdDialog.hide();
							};

							$scope.close = function () {
								$mdDialog.hide();
							};
							$scope.selectImageCallback = function (image, imagePath) {
								snippet.src = imagePath;
							};
						};

						$scope.onCancel = function (result) {};

						$scope.onSuccess = function (result) {};
						_init();
					},
				],
			};
		},
	]);
});

