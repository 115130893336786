define('directives/contentConfigurator/contentSnippets/sectionSnippet/SectionSnippet',[
	'app',
	'angular',
	'services/ContentBuilderHelper',
	'directives/contentConfigurator/contentSnippets/_snippet',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/popoverPinpoint/popoverPinpoint',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/popoverPinpointList/popoverPinpointList',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/popupPinpoint/popupPinpoint',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/partners/partners',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/slider/slider',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/infoSlider/infoSlider',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/search/search',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/depiction/depiction',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/timeline/timeline',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/infoCarousel/infoCarousel',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/card/card',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/grid/grid',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/breadcrumbs/breadcrumbs',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/jobs/jobs',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/lightGallery/lightGallery',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/documentation/documentation',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/news/news',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/form/form',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/compareTable/compareTable',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/onePageNav/onePageNav',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/tabsAnchorNav/tabsAnchorNav',
	'directives/contentConfigurator/contentSnippets/sectionSnippet/directives/customPageStyles/customPageStyles',
], function (app) {
	'use strict';
	app.directive('sectionSnippet', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				restrict: 'EA',
				scope: {
					metaIdentifier: '<',
					snippet: '=',
					position: '=',
					parent: '<',
				},
				templateUrl:
					BASE_DIRECTIVES_PATH +
					'/contentConfigurator/contentSnippets/sectionSnippet/views/sectionSnippet.html',
				controller: [
					'$scope',
					'$controller',
					'$rootScope',
					'BASE_DIRECTIVES_PATH',
					'$sce',
					'ContentBuilderHelper',
					function ($scope, $controller, $rootScope, BASE_DIRECTIVES_PATH, $sce, ContentBuilderHelper) {
						angular.extend(
							this,
							$controller('snippetController', {
								$scope: $scope,
							})
						);

						var _init = function () {
							$scope.snippetType = 'section';
							$scope.elementType = $scope.ELEMENT_TYPE.SNIPPET;
						};
						_init();
					},
				],
			};
		},
	]);
});

