define('services/CategoryService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('CategoryService', ['$injector', CategoryService]);
});

function CategoryService($injector) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('Category');

	angular.extend(this, crudInstance);

	let hierarchyResult;
	let _findAllSuccess = function (response) {
		hierarchyResult = response;
	};

	let _findAllError = function (response) {};

	this.getHierarchy = function (type) {
		let model = $injector.get('ModelFactory')['Category']();
		return model.hierarchy({ type: type }).$promise.then(_findAllSuccess, _findAllError);
	};

	this.getAllHierarchy = function () {
		return hierarchyResult;
	};

	this.getCategories = function (type, publishState) {
		let model = $injector.get('ModelFactory')['Category']();
		return model.findAll({ type: type, publishState: publishState }).$promise;
	};

	this.updateTree = function (categories) {
		return $injector.get('ModelFactory')['Category']().updateTree(categories).$promise;
	};

	return this;
}
;
