define('services/PayPalService',['app', 'modules/module'], function (app, module) {
	'use strict';
	return module.factory('PayPalService', ['$injector', '$q', PayPalService]);
});

function PayPalService($injector, $q) {

	this.fetchTransactionInfo = function (orderId) {
		var ref = $q.defer();

		$injector
			.get('ModelFactory')
			.PayPal()
			.fetchTransactionInfo({ orderId })
			.$promise.then((res) => {
				ref.resolve(res);
			});

		return ref.promise;
	};

	this.refundCustomAmount = function(orderId, amount) {
		var ref = $q.defer();

		$injector
			.get('ModelFactory')
			.PayPal()
			.refund({ orderId, amount })
			.$promise.then((res) => {
				if (!!ref.id) {
					ref.resolve(res);
				}
		});

		return ref.promise;
	};

	return this;
}
;
