define('modules/cms/controllers/CmsTagFormController',[
	'modules/module',
	'modules/basemodule/controllers/EntityFormController',
	'services/ValidationService',
	'directives/languageForm/languageForm',
	'directives/contentLocalesForm/contentLocalesForm',
	'settings',
], function (module) {
	'use strict';
	module.controller('CmsTagFormController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		'ValidationService',
		CmsTagFormController,
	]);
});

function CmsTagFormController($scope, $controller, BASE_TEMPLATES_PATH, ValidationService) {
	$scope.formTabs = [
		{
			label: 'FIELDS.GENERAL',
			template: BASE_TEMPLATES_PATH + '/wawi/tag/form-main.html',
			active: true,
		},
	];

	$scope.listState = 'root.cmstags';

	angular.extend(
		this,
		$controller('EntityFormController', {
			$scope: $scope,
		})
	);

	$scope.init('CmsTag').then(function () {});

	$scope.validate = function () {
		return ValidationService.validate($scope.entity, {
			name: {
				method: 'isset',
				fieldName: 'Name',
				errorMsg: 'Das Feld Name darf in der Standardsprache des Shops nicht leer sein',
				isLocaleValue: true,
			},
		});
	};
}
;
