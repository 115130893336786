define('directives/contentConfigurator/contentSnippets/sectionSnippet/directives/tabsAnchorNav/tabsAnchorNav',['app', 'settings'], function (app) {
	'use strict';
	app.directive('ngTabsAnchorNav', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					inputData: '<data',
					stateEditing: '<',
					tab: '&tab',
				},
				templateUrl:
					BASE_DIRECTIVES_PATH +
					'/contentConfigurator/contentSnippets/sectionSnippet/directives/tabsAnchorNav/views/index.html',
				controller: [
					'$scope',
					'$timeout',
					'$element',
					function ($scope, $timeout, $element) {
						var path =
							BASE_DIRECTIVES_PATH +
							'/contentConfigurator/contentSnippets/sectionSnippet/directives/tabsAnchorNav/views/';

						$scope.customStyle = path + 'style.css';
						$scope.customStyleFake = path + 'styleFake.css';
						$scope.customJs = path + 'js.html';
						$scope.customJs2 = path + 'js2.html';
						$scope.includeVisibility = true;

						$scope.tab = 1;

						function _init() {
							$scope.data = angular.merge({}, $scope.inputData);

							$scope.setTab = function (tabId) {
								//$scope.tab = tabId;
							};
							$scope.isSet = function (tabId) {
								//return $scope.tab === tabId;
							};
						}
						_init();

						$scope.$watchCollection('inputData', function (newValue, oldValue) {
							if (newValue !== oldValue) {
								_init();
							}
						});
						$scope.$watch('stateEditing', function (newValue, oldValue) {
							if (newValue !== oldValue) {
								$scope.data = angular.merge({}, $scope.inputData);
								_init();
							}
						});
					},
				],
			};
		},
	]);
});

