define('directives/contentConfigurator/contentSnippets/tinySnippet/TinySnippetConfig',[
	'app',
	'angular',
	'services/ContentBuilderHelper',
	'directives/contentConfigurator/contentSnippets/_snippet',
], function (app) {
	'use strict';
	app.directive('tinySnippetConfig', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				restrict: 'E',
				scope: {
					metaIdentifier: '<',
					elementIdentifier: '<',
					snippet: '=',
					element: '=',
				},
				templateUrl:
					BASE_DIRECTIVES_PATH +
					'/contentConfigurator/contentSnippets/tinySnippet/views/tinySnippetConfig.html',
				controller: [
					'$scope',
					'$controller',
					'ContentBuilderHelper',
					function ($scope, $controller, ContentBuilderHelper) {
						/*angular.extend(this, $controller('snippetController', {
                            $scope: $scope
                        }));*/

						var tab = 0;

						$scope.tab = function (id) {
							return tab === id;
						};

						$scope.setTab = function (id) {
							tab = id;
						};

						var _init = function () {
							tab = 0;

							ContentBuilderHelper.subscribeEditModeToggled($scope, function (e, args) {
								if (args.identifier === $scope.metaIdentifier || args.identifier === 'GLOBAL') {
									$scope.edit = args.edit;
									$scope.final = args.preSave || false;
								}
							});
						};
						_init();
					},
				],
			};
		},
	]);
});

