define('directives/customerInfo/CustomerInfo',['app', 'settings', 'services/CustomerInfoService'], function (app) {
	'use strict';
	app.directive('ngCustomerInfo', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					customer: '=',
				},
				templateUrl: BASE_DIRECTIVES_PATH + '/customerInfo/views/customer-info.html',
				controller: [
					'$scope',
					'$rootScope',
					'CustomerInfoService',
					'$sce',
					function ($scope, $rootScope, CustomerInfoService, $sce) {
						$scope.details = {
							currentlyDisplayed: {},
							isOpened: false,
						};

						$scope.setTableData = function () {
							$scope.history = CustomerInfoService.getAll();
							$scope.pageSize = CustomerInfoService.getPageSize();
							$scope.totalItems = CustomerInfoService.getTotalItems();
							$scope.totalPages = CustomerInfoService.getTotalPages();

							if ($scope.totalPages === 0) {
								//If there are no items the total pages size should be displayed as 1
								$scope.totalPages = 1;
							}

							$scope.currentPage = CustomerInfoService.getCurrentPage();

							//trust this html
							$scope.history.forEach(function (item) {
								item.body = $sce.trustAsHtml(item.body);
							});
						};

						$scope.setPageSize = function () {
							CustomerInfoService.setAdditionalParams([{ name: 'id', value: $scope.customer.id }]);
							CustomerInfoService.setPageSize($scope.pageSize).then(function () {
								$scope.setTableData();
							});
						};

						$scope.setPage = function () {
							CustomerInfoService.setAdditionalParams([{ name: 'id', value: $scope.customer.id }]);
							CustomerInfoService.setCurrentPage($scope.getRealCurrentPage()).then(function () {
								$scope.setTableData();
							});
						};

						$scope.getRealCurrentPage = function () {
							var cur = angular.copy(parseInt($scope.currentPage));
							cur = cur === 0 ? cur : cur - 1;
							return cur;
						};

						$scope.showCurrentlyDisplayed = function () {
							var from = parseInt($scope.pageSize) * parseInt($scope.getRealCurrentPage());
							from = from + 1;
							if ($scope.items === undefined || $scope.items.length === 0) {
								from = 0;
							}

							var to =
								from + parseInt($scope.pageSize) < $scope.totalItems
									? from + parseInt($scope.pageSize)
									: $scope.totalItems;
							return from + ' - ' + to;
						};

						$scope.showDetails = function (index) {
							$scope.details.isOpened = true;
							$scope.details.currentlyDisplayed = $scope.history[index];
						};

						CustomerInfoService.setAdditionalParams([{ name: 'id', value: $scope.customer.id }]);
						//					CustomerInfoService.findAll().then(function(){
						//						$scope.setTableData();
						//					});
						$scope.pageSize = 5;
						$scope.setPageSize();
					},
				],
			};
		},
	]);
});

