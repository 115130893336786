define('services/NotificationService',['app', 'modules/module'], function (app, module) {
	'use strict';
	return module.factory('NotificationService', [
		'$rootScope',
		'$q',
		function ($rootScope, $q) {
			var errorNotificationOnRequest = function (response) {
				var data = response.data;
				if ((data.error !== undefined || data.errorCode !== undefined) && data.ok == false) {
					exceptionError(data, data.error, data.errorCode, response.config);
				} else if (response.statusText && !data.message) {
					validationError(data.errors, response.statusText);
				} else if (data.message) {
					errorNotifyMessage(data.message);
				} else {
					errorNotifyMessage(response.status);
				}
			};

			var validationError = function (errors, statusText) {
				errors.forEach(function (error) {
					if (error.errorMessage == null) {
						var msg = 'Context ' + angular.toJson(error.errorContext);
					}
					errorNotification({
						statusText: statusText,
						msg: msg,
					});
				});
			};

			var exceptionError = function (error, statusText, errorCode, config) {
				if (config.url == '/api/v1/econnect/recipients' && config.method == 'POST') {
					errorNotification({ statusText: 'Ein Empfänger mit dieser Email-Adresse ist bereits vorhanden' });
				} else {
					errorNotification({
						statusText: 'Es ist ein Fehler aufgetreten',
						msg: statusText,
						errorCode: errorCode,
					});
				}
			};

			var errorNotification = function (error) {
				notify('danger', error);
			};

			var errorNotifyMessage = function (message, errorCode) {
				$rootScope.$emit('notification', {
					type: 'danger',
					statusText: 'Es ist eine Fehler aufgetreten',
					msg: message,
					code: errorCode,
				});
			};

			var successNotification = function (notification) {
				notify('success', notification);
			};

			var warningNotification = function (notification) {
				notify('warning', notification);
			};

			var notify = function (type, notification) {
				$rootScope.$emit('notification', {
					type: type,
					statusText: notification.statusText,
					msg: notification.msg,
					code: notification.errorCode,
				});
			};

			var confirm = function (notification) {
				var ref = $q.defer();
				$rootScope.$emit('notification', {
					type: 'confirm',
					statusText: notification.statusText,
					msg: notification.msg,
					ref: ref,
				});
				return ref.promise;
			};

			var errorValidationNotifications = function (errors) {
				$rootScope.$emit('notification', {
					type: 'validation',
					errors: errors,
				});
			};

			return {
				notifyRequestError: function (response) {
					errorNotificationOnRequest(response);
				},
				notifyGeneralError: function (notification) {
					errorNotification(notification);
				},
				notifySuccess: function (notification, callback) {
					successNotification(notification);
				},
				notifyValidationErrors: function (errors) {
					errorValidationNotifications(errors);
				},
				confirm: function (confirmMsg) {
					return confirm(confirmMsg);
				},
				notifyWarning: function (notification, callback) {
					warningNotification(notification);
				},
			};
		},
	]);
});

