define('modules/wawi/product/controllers/ProductBundleFormController',[
	'modules/module',
	'settings',
	'modules/wawi/product/controllers/ProductFormController',
	'modules/basemodule/controllers/EntityListController',
	'services/NotificationService',
	'services/ValidationService',
	'services/ProductService',
	'services/AttributeService',
	'services/TagVariantService',
	'services/ImageService',
	'services/MediaService',
	'directives/entityTable/ngEntityTable',
	'directives/listSelectAmount/ListSelectAmount',
	'filters/RemoveSpacesAndSpecialCharacters',
], function (module) {
	'use strict';
	module.controller('ProductBundleFormController', [
		'$scope',
		'$controller',
		'NotificationService',
		'ValidationService',
		'ProductService',
		'AttributeService',
		'ImageService',
		'MediaService',
		'BASE_TEMPLATES_PATH',
		ProductBundleFormController,
	]);
});

function ProductBundleFormController(
	$scope,
	$controller,
	NotificationService,
	ValidationService,
	ProductService,
	AttributeService,
	ImageService,
	MediaService,
	BASE_TEMPLATES_PATH
) {
	angular.extend(
		this,
		$controller('ProductFormController', {
			$scope: $scope,
		})
	);

	$scope.ProductService = ProductService;

	$scope.formTabs.push({
		label: 'Bundleprodukte',
		template: BASE_TEMPLATES_PATH + '/wawi/product/form-bundlearticle.html',
	});

	$scope.afterInit.then(function () {
		if (!$scope.entity.products) {
			$scope.entity.products = [];
		}

		if ($scope.entity.areCustomAmountsAllowed === null || $scope.entity.areCustomAmountsAllowed === undefined) {
			$scope.entity.areCustomAmountsAllowed = true;
		}
	});

	$scope.addProduct = function () {
		if ($scope.entity.products == undefined) {
			$scope.entity.products = [];
		}
		$scope.entity.products.push({});
	};

	$scope.removeProduct = function (index) {
		$scope.entity.products.splice(index, 1);
	};

	// If custom amounts are not allowed, check if amounts are correct (> 0)
	$scope.validationConfig.bundleProductAmounts = {
		fieldName: 'Mengen der Bundelprodukte',
		errorMsg: 'Bitte fügen Sie positive Mengen hinzu, um Produkte zu bündeln.',
		customValidate: (entity) => {
			if (entity.areCustomAmountsAllowed) {
				return true;
			}

			for (const product of $scope.entity.products) {
				if (!product.amount || product.amount < 1) {
					return false;
				}
			}

			return true;
		},
	};

	// If custom amounts are allowed, require at least one bundle product
	$scope.validationConfig.bundleProductsOrSellingPrice = {
		fieldName: 'Bundelprodukt',
		errorMsg: 'Mindestens ein Bündelprodukt ist erforderlich.',
		customValidate: (entity) => {
			if (!entity.areCustomAmountsAllowed) {
				return true;
			}

			return entity.products.length > 0;
		},
	};

	// If custom amounts are allowed, don't require a price, it will be calculated from bundle product products
	$scope.validationConfig.sellingPrice.customValidate = (entity) => {
		if (entity.areCustomAmountsAllowed) {
			return true;
		}

		return $scope.priceScope.isAtLeastOneSellingPricePresent(entity);
	};
}
;
