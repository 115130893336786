define('directives/infinityTransform/InfinityTransform',['app'], function (app) {
	'use strict';
	app.directive('infinityTransform', function () {
		return {
			require: 'ngModel',
			link: function (scope, element, attrs, ngModelController) {
				ngModelController.$parsers.push(function (data) {
					//convert data from view format to model format
					if (data === Infinity) {
						data = -1;
					}
					return data; //converted
				});

				ngModelController.$formatters.push(function (data) {
					//convert data from model format to view format
					if (data === -1) {
						data = Infinity;
					}
					return data; //converted
				});
			},
		};
	});
});

