define('services/EntityFilterService',['app', 'modules/module'], function (app, module) {
	'use strict';
	return module.factory('EntityFilterService', [EntityFilterService]);
});

function EntityFilterService() {
	var _filterSets = new Map();
	var _sorting = new Map();
	var _count = 0;

	var _registerTable = function (id, filters, field, direction, persist) {
		if (!persist && id !== undefined) {
			id = id + _count.toString();
			_count++;
		}
		if (id === undefined) {
			id = _count.toString();
			_count++;
		}

		if (_filterSets.get(id) === undefined && filters !== undefined) {
			_filterSets.set(id, filters);
		}
		if (_sorting.get(id) === undefined && field !== undefined && direction !== undefined) {
			_sorting.set(id, field + ' ' + direction);
		}
		return id;
	};

	return {
		getFilterSet: function (id) {
			if (id === undefined) {
				return;
			}
			return _filterSets.get(id);
		},
		getSorting: function (id) {
			if (id === undefined) {
				return;
			}
			return _sorting.get(id);
		},
		registerTable: function (id, filters, field, direction, persist) {
			return _registerTable(id, filters, field, direction, persist);
		},
		updateFilterSet: function (id, filters) {
			if (id === undefined) {
				return;
			}
			_filterSets.set(id, filters);
		},
		updateSorting: function (id, sorting) {
			if (id === undefined) {
				return;
			}
			_sorting.set(id, sorting);
		},
		resetTable: function (id) {
			_filterSets.set(id, {});
			_sorting.set(id, undefined);
		},
	};
}
;
