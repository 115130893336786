define('services/CustomerGroupService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('CustomerGroupService', ['$injector', 'ModelFactory', CustomerGroupService]);
});

function CustomerGroupService($injector, ModelFactory) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('CustomerGroup');

	angular.extend(this, crudInstance);

	this.findByShopId = function (shopId) {
		return ModelFactory.CustomerGroup().findAll({ shopId: shopId }).$promise;
	};

	this.getPriceHandlingOptions = () => {
		return [
			{
				display: 'FIELDS.DISCOUNT',
				value: true,
			},
			{
				display: 'CONTENT.SURCHARGE',
				value: false,
			},
		];
	};

	return this;
}
;
