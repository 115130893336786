/**
 * Created by Nico on 11.07.2016.
 */
define('modules/cms/nodes/NodeController',['modules/module', 'settings', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('NodeController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		function ($scope, $controller, BASE_TEMPLATES_PATH) {
			angular.extend(
				this,
				$controller('EntityListController', {
					$scope: $scope,
				})
			);

			$scope.tableOptions.fields = [
				{
					id: 'STATE',
					heading: 'FIELDS.STATUS',
					//attribute:  ''
				},
				{
					id: 'DESCRIPTION',
					heading: 'FIELDS.DESIGNATION',
				},
				{
					id: 'POSITION',
					heading: 'FIELDS.POSITION',
				},
				{
					id: 'CREATED',
					heading: 'FIELDS.CREATED_AT',
				},
				{
					id: 'MODIFIED',
					heading: 'FIELDS.MODIFIED_AT',
				},
			];
			$scope.formState = 'root.node';

			$scope.init('Node');
		},
	]);
});

