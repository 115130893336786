define('directives/contentLocalesForm/contentLocalesForm',['app', 'uiBootstrap', 'filters/RemoveSpacesAndSpecialCharacters', 'settings'], function (app) {
	'use strict';
	app.directive('ngContentLocalesForm', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					result: '=',
					locales: '=',
					templateUrl: '@',
				},
				templateUrl: BASE_DIRECTIVES_PATH + '/contentLocalesForm/views/view.html',
				controller: [
					'$scope',
					function ($scope) {
						$scope.$watch(
							'result',
							function (newValue, oldValue) {
								if (newValue === undefined || newValue == null) {
									return;
								}

								if ($scope.result.locales === undefined) {
									$scope.result.locales = [];
								}
								_updateContent();
							},
							true
						);

						$scope.$watch('locales', function (newValue, oldValue) {
							if ($scope.locales != undefined) {
								$scope.locales.sort(function (a, b) {
									if (a.id < b.id) return -1;
									if (a.id > b.id) return 1;
									return 0;
								});
							}

							if (newValue === undefined || newValue == null) {
								return;
							}
							for (var i = 0; i < $scope.locales.length; i++) {
								if ($scope.locales[i].isDefault) {
									$scope.selectedLocale = angular.copy($scope.locales[i]);
								}
							}
						});

						$scope.r = function () {
							if ($scope.selectedLocale === undefined) {
								return null;
							}

							var result = null;
							var resultTmp = $scope.result;
							if (resultTmp == undefined) {
								return;
							}
							for (var i in resultTmp.locales) {
								if ($scope.result.locales[i].localeId == $scope.selectedLocale.id) {
									result = $scope.result.locales[i];
								}
							}

							if (result == null) {
								result = {};
								result.localeId = $scope.selectedLocale.id;
								$scope.result.locales.push(result);
							}

							return result;
						};

						var _updateContent = function () {
							$scope.localeContent = $scope.r();
							if ($scope.localeContent === null || $scope.localeContent === undefined) {
								$scope.localeContent = {};
							}

							if ($scope.type === 'product') {
								$scope.localeContent.shortDescription = $scope.localeContent.shortDescription || '';
								$scope.localeContent.longDescription = $scope.localeContent.longDescription || '';
							} else {
								$scope.localeContent.shortDescription = $scope.localeContent.shortDescription || {
									html: '',
									json: {},
								};
								$scope.localeContent.longDescription = $scope.localeContent.longDescription || {
									html: '',
									json: {},
								};
							}
						};

						$scope.$watch(
							'selectedLocale',
							function (newValue, oldValue) {
								_updateContent();
							},
							true
						);

						$scope.rOption = function () {
							if ($scope.selectedLocale === undefined) {
								return null;
							}
							var result = null;
							for (var i in $scope.result.locales) {
								if ($scope.result.locales[i].localeId == $scope.selectedLocale.id) {
									var options = $scope.options;
									if ($scope.result.locales[i].options == undefined) {
										$scope.result.locales[i].options = [];
									}
									result = $scope.result.locales[i].options;
								}
							}
							if (result == null) {
								result = {};
								result.localeId = $scope.selectedLocale.id;
								result.localeId.options = [];
								$scope.result.locales.push(result);
							}
							return result;
						};

						$scope.rMetadataValue = function () {
							if ($scope.selectedLocale === undefined) {
								return null;
							}
							var result = null;
							for (var i in $scope.result.locales) {
								if ($scope.result.locales[i].localeId == $scope.selectedLocale.id) {
									var options = $scope.options;
									if ($scope.result.locales[i].metadataValues == undefined) {
										$scope.result.locales[i].metadataValues = [];
									}
									result = $scope.result.locales[i].metadataValues;
								}
							}
							if (result == null) {
								result = {};
								result.localeId = $scope.selectedLocale.id;
								result.metadataValues = [];
								$scope.result.locales.push(result);
							}
							return result;
						};

						$scope.rKeyword = function () {
							if ($scope.selectedLocale === undefined) {
								return null;
							}
							var result = null;
							for (var i in $scope.result.locales) {
								if ($scope.result.locales[i].localeId == $scope.selectedLocale.id) {
									if ($scope.result.locales[i].seoInformation == undefined) {
										$scope.result.locales[i].seoInformation = {};
									}
									if ($scope.result.locales[i].seoInformation.keywords == undefined) {
										$scope.result.locales[i].seoInformation.keywords = [];
									}
									result = $scope.result.locales[i].seoInformation.keywords;
								}
							}
							if (result == null) {
								result = {};
								result.localeId = $scope.selectedLocale.id;
								result.localeId.seoInformation.keywords = [];
								$scope.result.locales.push(result);
							}
							return result;
						};

						$scope.getLanguageFormTemplateUrl = function () {
							return $scope.templateUrl;
						};

						$scope.changeTab = function (locale) {
							if ($scope.selectedLocale !== undefined) {
								$scope.selectedLocale = locale;
							}
						};
					},
				],
			};
		},
	]);
});

