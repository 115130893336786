define('directives/contentConfigurator/contentSnippets/sectionSnippet/directives/popupPinpoint/popupPinpoint',['app', 'settings'], function (app) {
	'use strict';
	app.directive('ngPopupPinpoint', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					inputData: '<data',
					stateEditing: '<',
				},
				templateUrl:
					BASE_DIRECTIVES_PATH +
					'/contentConfigurator/contentSnippets/sectionSnippet/directives/popupPinpoint/views/index.html',
				controller: [
					'$scope',
					'$timeout',
					'$element',
					function ($scope, $timeout, $element) {
						var path =
							BASE_DIRECTIVES_PATH +
							'/contentConfigurator/contentSnippets/sectionSnippet/directives/popupPinpoint/views/';

						$scope.customStyle = path + 'style.css';
						$scope.customStyleFake = path + 'styleFake.css';
						$scope.customJs = path + 'js.html';
						$scope.includeVisibility = true;

						// $scope.currentPin = 0;
						// $scope.pinsCount = 0;
						// $scope.isNextDisabled = true;
						// $scope.isPrevDisabled = true;
						// $scope.isContentOverlayVisible = true;

						function _init() {
							$scope.data = angular.merge({}, $scope.inputData);

							// $scope.data.pins.forEach(function(pin, pinIndex) {
							//     var textChunks = _truncate(pin.text);
							//     pin.textIntro = textChunks[0];
							//     pin.textMore = textChunks[1];
							//     // pin.moreFlag = false;
							//     // pin.index = pinIndex;
							//     // pin.active = false;
							// });
							$scope.pinsCount = $scope.data.pins.length;
							// link: function (scope, element) {

							// },
							// _refresh(0);
						}
						_init();
						function _truncate(str, maxWords) {
							if (typeof maxWords === 'undefined') {
								maxWords = 10;
							}
							var array = str.trim().split(' ');

							return [array.slice(0, maxWords).join(' '), array.slice(maxWords).join(' ')];
						}
						// function _refresh(index) {
						//     if (index >= 0 && index < $scope.pinsCount) {
						//     $scope.data.pins[$scope.currentPin].active = false;
						//     $scope.currentPin = index;
						//     $scope.data.pins[$scope.currentPin].active = true;

						//     $scope.isContentOverlayVisible = true;

						//     $scope.isPrevDisabled = $scope.currentPin === 0;
						//     $scope.isNextDisabled = $scope.currentPin === $scope.pinsCount - 1;
						//     }
						// };
						$scope.$watchCollection('inputData', function (newValue, oldValue) {
							if (newValue !== oldValue) {
								_init();
								try {
									$($element).find('[data-content-block="popup-pinpoint"]').popupPinpoint('destroy');
								} catch (e) {}
								$scope.includeVisibility = false;
								$timeout(function () {
									$scope.includeVisibility = true;
								}, 100);
							}
						});
					},
				],
			};
		},
	]);
});

/* sample data
{
    "image": "/asset/images/content/pinpoint/big_placeholder.jpg",
    "imageAlt": "Interactive Product Map",
    "pins": [
        {
            "title": "Lorem Ipsum ",
            "top": "27.8%",
            "left": "46.8%",
            "text": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel nesciunt praesentium quas! Error, repellat. Autem cum reprehenderit vel non rerum, magni nesciunt natus cumque quae quia ratione assumenda aliquam distinctio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel nesciunt praesentium quas! Error, repellat. Autem cum reprehenderit vel non rerum, magni nesciunt natus cumque quae quia ratione assumenda aliquam distinctio.",
            "url": "test1.com",
            "image": "/asset/images/content/product/e-cart/thumbnail.png",
            "imageAlt": "E-Cart"
        },
        {
            "title": "Dolor Sit Amet",
            "top": "87%",
            "left": "64.7%",
            "text": "2 Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel nesciunt praesentium quas! Error, repellat. Autem cum reprehenderit vel non rerum, magni nesciunt natus cumque quae quia ratione assumenda aliquam distinctio.",
            "url": "test2.com",
            "image": "/asset/images/content/product/e-cart/thumbnail.png",
            "imageAlt": "E-Cart"
        }
    ]
}
 */
;
