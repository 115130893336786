define('directives/contentConfigurator/_partsStore/PartsStore',['app', 'angular', 'services/ContentBuilderHelper'], function (app) {
	'use strict';
	app.directive('partsStore', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				restrict: 'EA',
				scope: {},
				templateUrl: BASE_DIRECTIVES_PATH + '/contentConfigurator/_partsStore/views/partsStore.html',
				controller: [
					'$scope',
					'$rootScope',
					'$timeout',
					'ContentBuilderHelper',
					function ($scope, $rootScope, $timeout, ContentBuilderHelper) {
						$scope.ELEMENT_TYPE = ContentBuilderHelper.ELEMENT_TYPE;
						var tab = 0;

						$scope.tab = function (id) {
							return tab === id;
						};

						$scope.setTab = function (id) {
							tab = id;
						};

						$scope.showStore = false;
						$scope.overrideShow = false;

						var _init = function () {
							if ($scope.handle$ShowConfigParts !== undefined) {
								$scope.handle$ShowConfigParts();
							}
							$scope.handle$ShowConfigParts = $rootScope.$on('ToggleConfigParts', function (event, args) {
								if (args.override) {
									$scope.overrideShow = args.override;
								} else {
									$scope.overrideShow = false;
								}
								if (args.state === undefined) {
									$scope.showStore = !$scope.showStore;
								} else {
									$scope.showStore = args.state;
								}
							});
							$scope.$on('$destroy', $scope.handle$ShowConfigParts);
						};
						_init();

						$scope.customParts = '';

						$scope.onDragStartPart = function (event, data, type, args) {
							// enable allowed drop areas
							ContentBuilderHelper.callOutDragStartPart(type, data, args);
						};
						$scope.onDragEndPart = function (event, data, type, args) {
							// disable allowed drop areas
							ContentBuilderHelper.callOutDragEndPart(type, data, args);
						};
					},
				],
			};
		},
	]);
});

