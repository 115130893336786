/**
 * Created by Nico on 05.07.2016.
 */
define('services/PublishStateService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('PublishStateService', ['$injector', PublishStateService]);
});

function PublishStateService($injector) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('PublishState');

	angular.extend(this, crudInstance);

	return this;
}
;
