define('directives/contentConfigurator/contentSnippets/sectionSnippet/directives/compareTable/compareTable',['app', 'settings', 'services/ContentBuilderHelper'], function (app) {
	'use strict';
	app.directive('ngCompareTable', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					inputData: '<data',
					stateEditing: '<',
				},
				templateUrl:
					BASE_DIRECTIVES_PATH +
					'/contentConfigurator/contentSnippets/sectionSnippet/directives/compareTable/views/index.html',
				controller: [
					'$scope',
					'$timeout',
					'$element',
					'$compile',
					'ContentBuilderHelper',
					function ($scope, $timeout, $element, $compile, ContentBuilderHelper) {
						var path =
							BASE_DIRECTIVES_PATH +
							'/contentConfigurator/contentSnippets/sectionSnippet/directives/compareTable/views/';

						$scope.customStyle = path + 'style.css';
						$scope.customStyleFake = path + 'styleFake.css';
						$scope.customJs = path + 'js.html';
						//$scope.customJs2 = path + "js2.html";
						$scope.includeVisibility = true;

						var wrapper = $($element).find('[data-content-block="compare-table"]');
						var template =
							'<div class="compare-table-container"><h1>{{data.title}}</h1></div><table><tbody><tr ng-repeat="row in data.rows"><td ng-repeat="cell in row.cells">{{row.content}}</td></tr></tbody></table>';
						//  data-url="{{item.url}}" <a ng-href="{{item.url}}">{{data.items[0].title}}</a>

						function _init() {
							$scope.data = angular.merge({}, $scope.inputData);
						}

						_init();

						function _refresh() {
							try {
								wrapper.compareTable('destroy');
							} catch (e) {}
							wrapper.find('.compare-table-container').remove();
							wrapper.append($compile(template)($scope));
							$scope.includeVisibility = false;
							$timeout(function () {
								$scope.$apply();
								$scope.includeVisibility = true;
							}, 100);
						}

						/*$scope.$watchCollection('inputData', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                $scope.data = angular.merge({}, $scope.inputData);
                                _refresh();
                            }
                        });
                        $scope.$watch('stateEditing', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                $scope.data = angular.merge({}, $scope.inputData);
                                _refresh();
                            }
                        });*/
					},
				],
			};
		},
	]);
});

