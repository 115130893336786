define('modules/settings/shop//tabs/SmtpTabController',[], function () {
	'use strict';

	return function SmtpController($parentScope, $scope, { BASE_TEMPLATES_PATH }) {
		$parentScope.adminFormTabs.push({
			label: 'SMTP',
			template: BASE_TEMPLATES_PATH + '/settings/shop/form-smtp-settings.html',
		});
	};
});

