define('services/ProductService',[
	'app',
	'modules/module',
	'services/EntityCrudService',
	'services/LocalStorageService',
	'services/NotificationService',
], function (app, module) {
	'use strict';
	return module.factory('ProductService', [
		'$injector',
		'LocalStorageService',
		'NotificationService',
		'$q',
		ProductService,
	]);
});

function ProductService($injector, LocalStorageService, NotificationService, $q) {
	var _productsListForBulkUpdate;

	var productFields = [
		{ label: 'Art. Nr.', value: 'sku' },
		{ label: 'FIELDS.NAME', value: 'title' },
		{ label: 'FIELDS.CATEGORIES', value: 'category' },
		{ label: 'FIELDS.LONG_DESCRIPTION', value: 'longDescription' },
		{ label: 'FIELDS.MANUFACTURER', value: 'manufacturer' },
		{ label: 'FIELDS.PRICE', value: 'price' },
		{ label: 'FIELDS.WEIGHT', value: 'weight' },
	];

	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('Product');

	angular.extend(this, crudInstance);

	this.setFilters = function (filters) {
		this.filters = filters;
	};

	this.getFilters = function () {
		if (this.filters === undefined) {
			return [];
		}
		return this.filters;
	};

	this.getProductFields = function () {
		return productFields;
	};

	this.filterForBulkUpdate = function (filters, params) {
		if (LocalStorageService.read('ProductCurrentPage') === null) {
			LocalStorageService.write('ProductCurrentPage', 0);
		}
		var page = LocalStorageService.read('ProductCurrentPage') || 0;
		var size = LocalStorageService.read('ProductPageSize') || 10;
		var queryParams = {
			page: page,
			size: size,
			filter: filters,
		};

		prepareFilterParamsInRequestBody(queryParams, params);

		return new $injector.get('ModelFactory')
			['Product']()
			.filterForBulkUpdate(queryParams)
			.$promise.then(function (response) {
				_productsListForBulkUpdate = response;
			});
	};

	this.getBulkUpdateProducts = function () {
		return _productsListForBulkUpdate;
	};

	this.bulkUpdate = function (obj, params) {
		if (obj.filterProduct) {
			prepareFilterParamsInRequestBody(obj.filterProduct, params);
		}

		return new $injector.get('ModelFactory')
			['Product']()
			.bulkUpdate(obj)
			.$promise.then(function (response) {
				if (response.error == undefined && response.errorCode == undefined && response.ok) {
					NotificationService.notifySuccess({ statusText: 'Bearbeitung erfolgreich' });
				} else {
					NotificationService.notifyGeneralError({ statusText: 'Bearbeitung fehlgeschlagen' });
				}
			});
	};

	this.updateSortOrder = function (products) {
		return new $injector.get('ModelFactory')
			['Product']()
			.updateSortOrder(products)
			.$promise.then(function (response) {
				if (response.error == undefined && response.errorCode == undefined && response.ok) {
					NotificationService.notifySuccess({ statusText: 'Bearbeitung erfolgreich' });
				} else {
					NotificationService.notifyGeneralError({ statusText: 'Bearbeitung fehlgeschlagen' });
				}
			});
	};

	this.updateCategorySortOrder = function (products, categoryId) {
		products['categoryId'] = categoryId;

		return new $injector.get('ModelFactory')
			['Product']()
			.updateCategorySortOrder(products)
			.$promise.then(function (response) {
				if (response.error == undefined && response.errorCode == undefined && response.ok) {
					NotificationService.notifySuccess({ statusText: 'Bearbeitung erfolgreich' });
				} else {
					NotificationService.notifyGeneralError({ statusText: 'Bearbeitung fehlgeschlagen' });
				}
			});
	};

	this.findByIdsIn = function (ids) {
		var ref = $q.defer();
		$injector
			.get('ModelFactory')
			.Product()
			.findByIdsIn({ ids: ids })
			.$promise.then(function (response) {
				ref.resolve(response);
			}, NotificationService.notifyRequestError);
		return ref.promise;
	};

	this.findByDefaultLocale = function (id) {
		var ref = $q.defer();
		$injector
			.get('ModelFactory')
			.Product()
			.findWithDefaultLocale({ id: id })
			.$promise.then(function (response) {
				ref.resolve(response);
			}, NotificationService.notifyRequestError);
		return ref.promise;
	};

	this.getFlagOptions = function () {
		var values = [
			{
				display: 'Pure Addon',
				value: 'PURE_ADDON',
			},
			{
				display: 'Addon',
				value: 'ADDON',
			},
		];
		return values;
	};

	const prepareFilterParamsInRequestBody = (requestBody, params) => {
		for (const param of params) {
			switch (param.name) {
				case 'filter[name]':
					requestBody['filterName'] = param.value;
					break;
				case 'filter[sku]':
					requestBody['filterSku'] = param.value;
					break;
				case 'filter[slug]':
					requestBody['filterSlug'] = param.value;
					break;
				case 'filter[sortOrder]':
					requestBody['filterSortOrder'] = param.value;
					break;
				case 'filter[categorySortOrder]':
					requestBody['filterCategorySortOrder'] = param.value;
					break;
				case 'filter[category]':
					requestBody['filterCategory'] = param.value;
					break;
				case 'filter[shopId]':
					requestBody['filterShopId'] = param.value;
					break;
				case 'filter[order]':
					requestBody['order'] = param.value;
					break;
				default:
					requestBody[param.name] = param.value;
					break;
			}
		}
	};

	return this;
}
;
