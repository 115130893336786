define('modules/wawi/product/controllers/tabs/ImagesTabController',[], function () {
	'use strict';

	return ($parentScope, $scope, { BASE_TEMPLATES_PATH }) => {
		$parentScope.formTabs.push({
			label: 'FIELDS.IMAGES',
			template: BASE_TEMPLATES_PATH + '/wawi/product/form-images.html',
		});

		$scope.setCatalogImage = function (productImage) {
			for (var i = 0; i < $parentScope.entity.productImages.length; i++) {
				if ($parentScope.entity.productImages[i].image.id == productImage.productImage.image.id) {
					$parentScope.entity.productImages[i].isCatalogImage = true;
				} else {
					$parentScope.entity.productImages[i].isCatalogImage = false;
				}
			}
		};

		$scope.setSearchImage = function (productImage) {
			for (var i = 0; i < $parentScope.entity.productImages.length; i++) {
				if ($parentScope.entity.productImages[i].image.id == productImage.productImage.image.id) {
					$parentScope.entity.productImages[i].isSearchImage = true;
				} else {
					$parentScope.entity.productImages[i].isSearchImage = false;
				}
			}
		};

		$scope.setDetailImage = function (productImage) {
			for (var i = 0; i < $parentScope.entity.productImages.length; i++) {
				if ($parentScope.entity.productImages[i].image.id == productImage.productImage.image.id) {
					$parentScope.entity.productImages[i].isDetailImage = true;
				} else {
					$parentScope.entity.productImages[i].isDetailImage = false;
				}
			}
		};

		$scope.removeImage = function (productImage) {
			var index = $parentScope.entity.productImages.indexOf(productImage);
			$parentScope.entity.productImages.splice(index, 1);
		};

		$scope.onDrop = function ($event, $data, array) {
			$parentScope.entity.productImages.push({
				image: $data,
				isCatalogImage: false,
				isDetailImage: false,
				isSearchImage: false,
				isInActive: false,
			});
		};

		$scope.beforeBaseSave = () => {
			for (var i = 0; i < $parentScope.entity.productImages.length; i++) {
				var productImage = $parentScope.entity.productImages[i];
				productImage.sortOrder = i + 1;
			}
		};
	};
});

