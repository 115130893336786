define('directives/contentConfigurator/_contentConfiguratorFullscreen/ContentConfiguratorFullscreen',[
	'app',
	'angular',
	'directives/contentConfigurator/_contentConfigurator/ContentConfigurator',
	'services/ContentBuilderHelper',
], function (app) {
	'use strict';
	app.directive('contentConfigFullscreen', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				restrict: 'E',
				scope: {
					content: '=',
					layoutObj: '=',
					responsive: '<',
				},
				templateUrl:
					BASE_DIRECTIVES_PATH +
					'/contentConfigurator/_contentConfiguratorFullscreen/views/contentConfiguratorFullscreen.html',
				controller: [
					'$scope',
					'$element',
					'BASE_DIRECTIVES_PATH',
					'BASE_TEMPLATES_PATH',
					'$sce',
					'$rootScope',
					'$controller',
					'$timeout',
					'ContentBuilderHelper',
					function (
						$scope,
						$element,
						BASE_DIRECTIVES_PATH,
						BASE_TEMPLATES_PATH,
						$sce,
						$rootScope,
						$controller,
						$timeout,
						ContentBuilderHelper
					) {
						$scope.modalTemplate =
							BASE_DIRECTIVES_PATH +
							'/contentConfigurator/_contentConfiguratorFullscreen/views/fullscreenModal.html';

						$scope.edit = true;

						ContentBuilderHelper.subscribeEditModeToggled($scope, function (e, args) {
							if (args.identifier === $scope.metaIdentifier || args.identifier === 'GLOBAL') {
								$scope.edit = args.edit;
								$scope.final = args.preSave || false;
							}
						});

						$scope.handle$showTemplateSelectionModal = $rootScope.$on(
							'showTemplateSelectionModal',
							function (event, args) {
								$scope.showTemplateSelectionModal(args.metaIdentifier);
							}
						);

						$scope.handle$showTemplateSaveModal = $rootScope.$on('showTemplateSaveModal', function (
							event,
							args
						) {
							$scope.showTemplateSaveModal(args.metaIdentifier);
						});

						$scope.$on('$destroy', function () {
							$scope.handle$showTemplateSelectionModal();
							$scope.handle$showTemplateSaveModal();
						});

						/* *************** Configurator Modal Controller ************** */
						$scope.configFullscreenController = function ($scope, dataToPass, $mdDialog) {
							var VIEWPORT_SIZE = ($scope.VIEWPORT_SIZE = ContentBuilderHelper.VIEWPORT_SIZE);
							$scope.viewportWidth = ContentBuilderHelper.getViewportSetupId();

							$scope.edit = true;

							ContentBuilderHelper.subscribeEditModeToggled($scope, function (e, args) {
								if (args.identifier === 'GLOBAL') {
									$scope.edit = args.edit;
								}
							});

							$scope.onChangeViewportWidth = function () {
								ContentBuilderHelper.setViewportSetup($scope.viewportWidth);
							};

							for (var att in dataToPass) {
								$scope[att] = dataToPass[att];
							}

							// on modal close, prepare html and close after delay
							$scope.generateRawHtml = function (callback) {
								var $fullscreenModal = $(document).find('.md-dialog-container');
								var $fullscreenButton = $(document).find('.config-fullscreen button');
								$rootScope.$emit('EntityFormActionButtonsToggle', false);
								$fullscreenModal.hide();
								$fullscreenButton.attr('disabled', true);
								ContentBuilderHelper.toggleEditGlobal(false, true);
								$timeout(function () {
									ContentBuilderHelper.saveGlobal();
									$timeout(function () {
										ContentBuilderHelper.toggleEditGlobal(true);
										callback();
										$timeout(function () {
											$rootScope.$emit('EntityFormActionButtonsToggle', true);
											$fullscreenModal.show();
											$fullscreenButton.attr('disabled', false);
										}, 1000);
									}, 100);
								}, 1000);
							};

							$scope.cancel = function () {
								$scope.generateRawHtml(function () {
									$mdDialog.hide();
								});
							};

							$scope.close = function () {
								$scope.generateRawHtml(function () {
									ContentBuilderHelper.toggleParts(false);
									$mdDialog.hide();
								});
							};

							$scope.toggleParts = function () {
								ContentBuilderHelper.toggleParts();
							};
							$scope.toggleOutline = function () {
								ContentBuilderHelper.toggleOutline();
							};
							$scope.togglePreview = function () {
								ContentBuilderHelper.toggleEditGlobal(!$scope.edit);
							};

							$scope.showTemplateSelectionModal = function () {
								$scope.$broadcast('showTemplateSelectionModalDown');
							};

							$scope.showTemplateSaveModal = function () {
								$scope.$broadcast('showTemplateSaveModalDown');
							};

							$scope.$on('showTemplateSelectionModalUp', function (event, args) {
								$scope.$emit('showTemplateSelectionModal', args);
							});

							$scope.$on('showTemplateSaveModalUp', function (event, args) {
								$scope.$emit('showTemplateSaveModal', args);
							});
						};

						/* ****************************** SELECT TEMPLATE DIALOG CONTROLLER *********************************** */
						$scope.templateSelectionModalController = [
							'$scope',
							'dataToPass',
							'$mdDialog',
							'ContentBuilderHelper',
							function ($controllerScope, dataToPass, $mdDialog, ContentBuilderHelper) {
								angular.extend(
									this,
									$controller('TemplatePageController', {
										$scope: $controllerScope,
									})
								);

								$controllerScope.selectedItem = undefined;

								$controllerScope.update = function (item) {
									$controllerScope.selectedItem = item;
								};

								$controllerScope.metaIdentifier = dataToPass;

								$controllerScope.cancel = function () {
									$mdDialog.cancel();
								};

								$controllerScope.backToList = function () {
									$controllerScope.selectedItem = undefined;
								};

								$controllerScope.returnReplace = function (item) {
									//$controllerScope.data.json = JSON.parse(item.layout.json);
									var temp = JSON.parse(item.layout.json);

									ContentBuilderHelper.applyTemplate(
										$controllerScope.metaIdentifier,
										temp,
										ContentBuilderHelper.TEMPLATE_APPLICATION_MODE.REPLACE
									);

									//$controllerScope.data.stamp = -13;
									$mdDialog.hide();
								};

								$controllerScope.returnFront = function (item) {
									var temp = JSON.parse(item.layout.json);

									ContentBuilderHelper.applyTemplate(
										$controllerScope.metaIdentifier,
										temp,
										ContentBuilderHelper.TEMPLATE_APPLICATION_MODE.FRONT
									);

									//$controllerScope.data.stamp = -13;
									$mdDialog.hide();
								};

								$controllerScope.returnBack = function (item) {
									var temp = JSON.parse(item.layout.json);

									ContentBuilderHelper.applyTemplate(
										$controllerScope.metaIdentifier,
										temp,
										ContentBuilderHelper.TEMPLATE_APPLICATION_MODE.BACK
									);

									//$controllerScope.data.stamp = -13;
									$mdDialog.hide();
								};
							},
						];

						/* ****************************SAVE TEMPLATE DIALOG CONTROLLER *********************************** */
						$scope.templateSaveModalController = [
							'$scope',
							'dataToPass',
							'$mdDialog',
							function ($controllerScope, dataToPass, $mdDialog) {
								$controllerScope.preventInit = true;

								angular.extend(
									this,
									$controller('EntityFormController', {
										$scope: $controllerScope,
									})
								);

								$controllerScope.metaIdentifier = dataToPass;

								$controllerScope.cancel = function () {
									$mdDialog.cancel();
								};

								$controllerScope.$on('beforeCreate', function () {
									$controllerScope.beforeUpdateCreate();
								});

								$controllerScope.$on('beforeCreate', function () {
									$controllerScope.beforeUpdateCreate();
								});

								$controllerScope.$on('afterCreate', function () {
									$controllerScope.afterUpdateCreate();
								});

								$controllerScope.beforeUpdateCreate = function () {};

								$controllerScope.afterUpdateCreate = function () {
									$controllerScope.entity.layout.json = $controllerScope.swap;

									$mdDialog.hide();
								};

								$controllerScope.formTabs = [];

								$controllerScope.validationConfig = {
									name: {
										method: 'isset',
										fieldName: 'Name',
									},
								};

								$controllerScope.init('TemplatePage').then(function () {
									if ($controllerScope.newEntity) {
										$controllerScope.entity.layout = {};
										$controllerScope.entity.layout.json = angular.toJson(
											ContentBuilderHelper.getInstance($controllerScope.metaIdentifier).layout
										);
										$controllerScope.entity.layout.html = '';
										$controllerScope.entity.name = undefined;
									}
								});
							},
						];

						$scope.onCancel = function () {};

						$scope.onSuccess = function () {};

						$scope.$on('ContentFullscreenModal', function (evt, data) {
							$scope.showConfigFullscreen({
								content: $scope.content,
								layoutObj: $scope.layoutObj,
								responsive: $scope.responsive,
							});
						});
					},
				],
			};
		},
	]);
});

