define('modules/cms/controllers/FilterPageController',['modules/module', 'settings', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('FilterPageController', ['$scope', '$controller', 'BASE_TEMPLATES_PATH', FilterPageController]);
});

function FilterPageController($scope, $controller, BASE_TEMPLATES_PATH) {
	angular.extend(
		this,
		$controller('EntityListController', {
			$scope: $scope,
		})
	);

	$scope.tableOptions.fields = [
		{
			id: 'NAME',
			heading: 'FIELDS.DESIGNATION',
			attribute: 'name',
		},
		{
			id: 'URL',
			heading: 'URL',
			attribute: 'slug',
		},
	];

	$scope.tableOptions.optionalFields = [
		{
			id: 'SHORTDESCRIPTION',
			heading: 'FIELDS.SHORT_DESCRIPTION',
			template: BASE_TEMPLATES_PATH + '/cms/partials/filter-page-shortdescription-col.html',
			attribute: 'shortDescription',
			searchDisabled: true,
			orderDisabled: true,
		},
		{
			id: 'DESCRIPTION',
			heading: 'FIELDS.LONG_DESCRIPTION',
			template: BASE_TEMPLATES_PATH + '/cms/partials/filter-page-description-col.html',
			attribute: 'htmlContent',
			searchDisabled: true,
			orderDisabled: true,
		},
	];

	$scope.formState = 'root.filterPage';
	$scope.init('FilterPage');
}
;
