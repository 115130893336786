define('services/UnitService',['app', 'modules/module', 'modules/models'], function (app, module) {
	'use strict';
	return module.factory('UnitService', [
		'$q',
		'$rootScope',
		'$cookies',
		'$injector',
		'ModelFactory',
		function ($q, $rootScope, $cookies, $injector, ModelFactory) {
			var crudInstance = $injector.instantiate(CrudService);
			angular.extend(this, crudInstance);
			this.setType('Unit');

			var _units = [],
				_query = {},
				_totalPages,
				_totalItems,
				_unit;

			if ($cookies.get('_unitsPageSize') === undefined) {
				$cookies.put('_unitsPageSize', 10);
			}

			if ($cookies.get('_unitsCurrentPage') === undefined) {
				$cookies.put('_unitsCurrentPage', 0);
			}

			this.loadUnits = function () {
				var ref = $q.defer();
				ModelFactory.Unit()
					.findAll({
						page: $cookies.get('_unitsCurrentPage'),
						size: $cookies.get('_unitsPageSize'),
						q: angular.toJson(_query),
					})
					.$promise.then(function (response) {
						_units = response.content;
						$cookies.put('_unitsCurrentPage', response.actualPage);
						_totalPages = response.totalPages;
						_totalItems = response.totalElements;
						ref.resolve();
						$rootScope.$broadcast('unitsChanges', _units);
					});
				return ref.promise;
			};

			var loadUnitsWithoutPagination = function () {
				var ref = $q.defer();
				ModelFactory.Unit()
					.findAll()
					.$promise.then(function (response) {
						_units = response.content;
						ref.resolve();
					});
				return ref.promise;
			};

			this.getUnitsByType = function (type) {
				var units = [];
				this.getAll().forEach(function (unit) {
					if (unit.type == type) {
						units.push(unit);
					}
				});
				return units;
			};

			this.getUnitById = function (id) {
				var respUnit;
				this.getAll().forEach(function (unit) {
					if (unit.id == id) {
						respUnit = unit;
					}
				});
				return respUnit;
			};

			return this;

			/*    return {
             getCurrentPage: function () {
             return (parseInt($cookies.get('_unitsCurrentPage')) + 1);
             },
             getTotalPages: function () {
             return _totalPages;
             },
             getTotalItems: function () {
             return _totalItems;
             },
             getPageSize: function () {
             return $cookies.get('_unitsPageSize');
             },
             setPageSize: function (pageSize) {
             $cookies.put('_unitsPageSize', pageSize);
             loadUnits();
             },
             setCurrentPage: function (currentPage) {
             $cookies.put('_unitsCurrentPage', currentPage);
             loadUnits();
             },
             setQuery: function (query) {
             _query = query;
             loadUnits();
             },
             getUnits: function(){
             return _units;
             },
             getUnit: function(){
             return _units;
             },
             loadUnits: function(withPagination){
             if(withPagination !== false){
             return loadUnits();
             } else {
             return loadUnitsWithoutPagination();
             }
             },
             loadUnit: function(id) {
             console.log('loading unit ' + id);
             var unit = $q.defer();
             Unit.findOne({name: id}).$promise.then(function(response) {
             _units = response;
             unit.resolve();
             });
             return unit.promise;
             },
             saveUnit: function(unit) {
             console.log('saving unit: ' + angular.toJson(unit));
             var ref = $q.defer();
             Unit.save(unit).$promise.then(function(response){
             ref.resolve();
             });
             return ref.promise;
             },
             deleteUnit: function(id) {
             console.log('deleting unit: ' + angular.toJson(id));
             var ref = $q.defer();
             Unit.delete({name: id}).$promise.then(function(response) {
             ref.resolve();
             });
             return ref.promise;
             },
             getUnitsByType: function(type) {
             var units = [];
             _units.forEach(function (unit) {
             if(unit.unitType == type) {
             units.push(unit);
             }
             });
             return units;
             }
             }*/
		},
	]);
});

