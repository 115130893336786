define('modules/econ/controllers/EconNewsletterController',['modules/module', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('EconNewsletterController', ['$scope', '$controller', EconNewsletterController]);
});

function EconNewsletterController($scope, $controller) {
	angular.extend(
		this,
		$controller('EntityListController', {
			$scope: $scope,
		})
	);

	$scope.formState = 'root.econNewsletter';

	$scope.tableOptions.fields = [
		{
			id: 'NAME',
			heading: 'FIELDS.NAME',
			attribute: 'name',
		},
		{
			id: 'SENDER',
			heading: 'FIELDS.SENDER',
			attribute: 'sender',
		},
		{
			id: 'SUBJECT',
			heading: 'FIELDS.SUBJECT',
			attribute: 'subject',
		},
	];

	$scope.init('Newsletter');
}
;
