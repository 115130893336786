define('directives/contentConfigurator/contentSnippets/_snippetConfig',['app', 'angular', 'services/ContentBuilderHelper'], function (module) {
	'use strict';
	module.controller('snippetConfigController', [
		'$scope',
		'ContentBuilderHelper',
		'BASE_DIRECTIVES_PATH',
		function SnippetConfigController($scope, ContentBuilderHelper, BASE_DIRECTIVES_PATH) {
			$scope.options = {
				title: 'Untitled Snippet',
				tabs: [
					{
						name: 'Content', //,
						// template: function() {
						//   return BASE_DIRECTIVES_PATH + '/contentConfigurator/contentSnippets/' + $scope.snippet.name + '/views/_content.html';
						// }
					},
					{
						name: 'Styles', //,
						// template: function () {
						//   return BASE_DIRECTIVES_PATH + '/contentConfigurator/contentSnippets/' + $scope.snippet.name + '/views/_styles.html';
						// }
					},
				],
			};

			var tab = 0;

			$scope.tab = function (id) {
				return tab === id;
			};

			$scope.setTab = function (id) {
				tab = id;
			};

			var _init = function () {
				tab = 0;

				ContentBuilderHelper.subscribeEditModeToggled($scope, function (e, args) {
					if (args.identifier === $scope.metaIdentifier || args.identifier === 'GLOBAL') {
						$scope.edit = args.edit;
						$scope.final = args.preSave || false;
					}
				});
			};
			_init();
		},
	]);
});

