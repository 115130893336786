define('modules/econ/controllers/EconCampaignController',['modules/module', 'settings', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('EconCampaignController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		EconCampaignController,
	]);
});

function EconCampaignController($scope, $controller, BASE_TEMPLATES_PATH) {
	angular.extend(
		this,
		$controller('EntityListController', {
			$scope: $scope,
		})
	);

	$scope.tableOptions.fields = [
		{
			id: 'NAME',
			heading: 'FIELDS.DESIGNATION',
			attribute: 'name',
		},
		{
			id: 'ACTIONS',
			heading: 'FIELDS.ACTIONS',
			template: BASE_TEMPLATES_PATH + '/econ/partials/_campaign-actions-template.html',
			searchDisabled: true,
		},
		{
			id: 'RECIPIENT_LIST',
			heading: 'FIELDS.RECIPIENT_LIST',
			template: BASE_TEMPLATES_PATH + '/econ/partials/_campaign-recipient-list.html',
			searchDisabled: true,
		},
	];

	$scope.formState = 'root.econCampaign';

	$scope.init('Campaign');
}
;
