define('directives/contentConfigurator/contentSnippets/_snippet',['app', 'angular', 'services/ContentBuilderHelper'], function (module) {
	'use strict';
	module.controller('snippetController', [
		'$scope',
		'$rootScope',
		'ContentBuilderHelper',
		function SnippetController($scope, $rootScope, ContentBuilderHelper) {
			$scope.ELEMENT_TYPE = ContentBuilderHelper.ELEMENT_TYPE;
			$scope.edit = true;
			$scope.elementType = $scope.ELEMENT_TYPE.SNIPPET;
			$scope.snippetType = undefined;

			$scope.position = $scope.position !== 'undefined' ? $scope.position : 0;

			if ($scope.snippet) {
				//$scope.selected = ContentBuilderHelper.selectedElement($scope.metaIdentifier).elementIdentifier === $scope.snippet.elementIdentifier;
			}

			$scope.selectSnippet = function (event) {
				if (event.target.getAttribute('ng-content-clickable') === null) {
					event.stopPropagation();
					// event.preventDefault(); commented due contenteditable typing issue (no spaces and new lines)
					ContentBuilderHelper.callOutSelected(
						$scope.metaIdentifier,
						$scope.snippet.identifier,
						$scope.elementType,
						$scope.snippetType,
						$scope.snippet
					);
				}
			};

			ContentBuilderHelper.subscribeEditModeToggled($scope, function (e, args) {
				if (args.identifier === $scope.metaIdentifier || args.identifier === 'GLOBAL') {
					$scope.edit = args.edit;
					$scope.final = args.preSave || false;
				}
			});

			ContentBuilderHelper.subscribeSelected($scope, function (e, args) {
				if (args.metaIdentifier === $scope.metaIdentifier || args.metaIdentifier === 'GLOBAL') {
					if (
						ContentBuilderHelper.selectedElement(args.metaIdentifier).elementIdentifier ===
						$scope.snippet.identifier
					) {
						$scope.selected = true;
					} else {
						$scope.selected = false;
					}
				}
			});
		},
	]);
});

