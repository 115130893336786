define('directives/components/common/modalDialog/ModalDialog',['app', 'settings'], function (app) {
	'use strict';
	app.directive('ngModalDialog', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					templateUrl: '@',
					controller: '=',
					onSuccess: '=',
					onFailed: '=',
					show: '=',
					slide: '=',
					multiple: '=',
					backdrop: '=?',
					id: '<?',
				},
				controller: [
					'$scope',
					'$rootScope',
					'$mdDialog',
					function ($scope, $rootScope, $mdDialog) {
						$scope.show = function (data) {
							$mdDialog
								.show({
									locals: { dataToPass: data },
									controller: $scope.controller,
									templateUrl: $scope.templateUrl,
									multiple: $scope.multiple,
									hasBackdrop: $scope.backdrop,
									/*parent: angular.element(document.body),*/
									/*targetEvent: ev,*/
									clickOutsideToClose: true,
									onRemoving: function (event, removePromise) {
										$rootScope.$emit('removeModal');
									},
									/*fullscreen: true*/
								})
								.then(
									function (answer) {
										if ($scope.onSuccess !== undefined) {
											$scope.onSuccess(answer);
										}
									},
									function () {
										if ($scope.onFailed !== undefined) {
											$scope.onFailed();
										}
									}
								);
						};

						$rootScope.$on('closeModal', function (e, args) {
							$mdDialog.hide();
						});
					},
				],
			};
		},
	]);
});

