define('directives/LanguageSwitcher/LanguageSwitcher',['app', 'settings', 'services/LocaleService', 'services/SessionValuesService'], function (app) {
	'use strict';
	app.directive('ngLanguageSwitcher', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {},
				templateUrl: BASE_DIRECTIVES_PATH + '/LanguageSwitcher/partials/view.html',
				controller: [
					'$scope',
					'$translate',
					'LocaleService',
					function ($scope, $translate, LocaleService) {
						[$scope.locales, $scope.currentLocale] = LocaleService.init();

						$scope.setLanguage = (locale) => {
							$scope.currentLocale = locale;
							$translate.use($scope.currentLocale.locale);
							sessionStorage.setItem('locale', locale.id);
						};
					},
				],
			};
		},
	]);
});

