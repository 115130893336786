define('directives/inputSelect/inputSelect',['app', 'uiBootstrap', 'settings'], function (app) {
	'use strict';
	app.directive('ngInputSelect', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				/*link: function (scope, element, attr) {

	                },*/
				scope: {
					valueId: '=valueId',
					value: '=value',
					selection: '=selection',
					idPath: '@idPath',
					valuePath: '@valuePath',
					label: '@label',
				},

				transclude: false,
				templateUrl: BASE_DIRECTIVES_PATH + '/inputSelect/partials/view.html',
				controller: [
					'$scope',
					'$q',
					function ($scope, $q) {
						$scope.identity = angular.identity;

						$scope.getValue = function (select, valuePath) {
							var dp = valuePath.split('.');

							var value = select;
							for (var i = 0; i < dp.length; i++) {
								value = value[dp[i]];
							}

							return value;
						};

						$scope.loadedPromises = [];

						$scope.selection_promise = $q.defer();
						$scope.id_path_promise = $q.defer();
						$scope.data_path_promise = $q.defer();

						$scope.$watch('selection', function (newValue) {
							if (newValue !== undefined) {
								$scope.selection_promise.resolve('resolve');
							}
						});

						$scope.$watch('idPath', function (newValue) {
							if (newValue !== undefined) {
								$scope.id_path_promise.resolve('resolve');
							}
						});

						$scope.$watch('valuePath', function (newValue) {
							if (newValue !== undefined) {
								$scope.data_path_promise.resolve('resolve');
							}
						});

						$q.all($scope.selection_promise, $scope.id_path_promise, $scope.data_path_promise).then(
							function () {
								$scope.calculateSelection();
							}
						);

						$scope.calculateSelection = function () {
							if ($scope.selection !== undefined) {
								$scope.selection_format = [];

								for (var i = 0; i < $scope.selection.length; i++) {
									var value = $scope.getValue($scope.selection[i], $scope.valuePath);
									var id = $scope.getValue($scope.selection[i], $scope.idPath);

									$scope.selection_format.push({ id: id, value: value });
									//console.log($scope.getValue($scope.selection[i], $scope.valuePath));
								}

								//console.log($scope.selection);
							}
						};

						$scope.open_select = function () {
							$scope.select_open = true;
						};

						$scope.toggle_select = function () {
							$scope.select_open = !$scope.select_open;
						};

						$scope.toggled = function (open) {
							//console.log($scope.Regions);
						};

						$scope.select_item = function (id, value) {
							$scope.value = value;
							$scope.valueId = id;
						};

						$scope.blur = function ($event) {
							var rel_parent = $event.relatedTarget.parentNode.parentNode.parentNode;
							var parent = $event.delegateTarget.parentNode;

							if (parent !== rel_parent) {
								$scope.select_open = false;
							}
						};
					},
				],
			};
		},
	]);
});

