define('services/CustomerService',['app', 'modules/module', 'services/EntityCrudService', 'services/NotificationService'], function (app, module) {
	'use strict';
	return module.factory('CustomerService', [
		'$injector',
		'$q',
		'NotificationService',
		'ModelFactory',
		CustomerService,
	]);
});

function CustomerService($injector, $q, NotificationService, ModelFactory) {
	var crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('Customer');

	angular.extend(this, crudInstance);

	var _customerOrders = [];
	var _transferedDocument = {};
	var _transferedImage = {};
	var _customerDocuments = [];
	var _customerImages = [];

	var _findOrdersSuccess = function (response) {
		_customerOrders = response.data;
	};

	var _findError = function (response) {
		console.error(response);
	};

	this.findOrders = function (customerId) {
		return this.getModel().findOrders({ id: customerId }).$promise.then(_findOrdersSuccess, _findError);
	};

	this.getOrders = function (customerId) {
		return _customerOrders;
	};

	this.transferImage = function (customerId, file) {
		return this.getModel()
			.transferImage({ id: customerId, file: file })
			.$promise.then(function (response) {
				if (response.error == undefined) {
					_transferedImage = response;
				} else {
					NotificationService.notifyValidationErrors([
						{
							attributeName: 'Dateityp',
							errorMsg: 'Der hochgeladene Dateityp ist ungültig',
						},
					]);
				}
			});
	};

	this.transferDocument = function (customerId, file, type, visibility) {
		return this.getModel()
			.transferDocument({ id: customerId, file: file, type: type, visibility: visibility })
			.$promise.then(function (response) {
				if (response.error == undefined) {
					_transferedDocument = response;
				} else {
					NotificationService.notifyValidationErrors([
						{
							attributeName: 'Dateityp',
							errorMsg: 'Der hochgeladene Dateityp ist ungültig',
						},
					]);
				}
			});
	};
	this.transferDocuments = function (files) {
		var ref = $q.defer();

		var transferPromises = [];
		for (var i = 0; i < files.length; i++) {
			transferPromises.push(
				ModelFactory.Document().transferDocument({
					file: files[i],
				}).$promise
			);
		}

		$q.all(transferPromises).then(function (responses) {
			var documents = [];

			for (var i = 0; i < responses.length; i++) {
				if (responses[i] && responses[i].error === undefined && responses[i].id !== undefined) {
					documents.push(responses[i]);
				}
			}

			ref.resolve(documents);
		}, ref.reject);

		return ref.promise;
	};

	this.loadDocuments = function (customerId) {
		return this.getModel()
			.getDocuments({ id: customerId })
			.$promise.then(function (response) {
				_customerDocuments = response.data;
			}, _findError);
	};

	this.loadImages = function (customerId) {
		return this.getModel()
			.getImages({ id: customerId })
			.$promise.then(function (response) {
				_customerImages = response.data;
			}, _findError);
	};

	this.getDocuments = function () {
		return _customerDocuments;
	};

	this.saveDocuments = function (customerId, documents) {
		return this.getModel()
			.updateDocuments({ id: customerId, documents: documents })
			.$promise.then(function (response) {
				_customerDocuments = response.data;
			}, _findError);
	};

	this.getImages = function () {
		return _customerImages;
	};

	this.deleteImage = function (customerId, imageId) {
		return this.getModel()
			.deleteImage({ id: customerId, imageId: imageId })
			.$promise.then(function (response) {}, _findError);
	};

	this.deleteDocument = function (customerId, documentId) {
		return this.getModel()
			.deleteDocument({ id: customerId, documentId: documentId })
			.$promise.then(function (response) {}, _findError);
	};

	this.deleteDataFeed = function (customerId, dataFeedId) {
		return this.getModel().deleteDataFeed({ id: customerId, dataFeedId: dataFeedId }).$promise;
	};

	this.initPasswordReset = function (email) {
		var ref = $q.defer();

		this.getModel()
			.initPasswordReset({ email: email })
			.$promise.then(
				function (response) {
					ref.resolve(response);
				},
				function (response) {
					_findError(response);

					ref.reject(response);
				}
			);

		return ref.promise;
	};

	this.initEmailReset = function (email) {
		var ref = $q.defer();

		this.getModel()
			.initEmailReset({ email: email })
			.$promise.then(
				function (response) {
					ref.resolve(response);
				},
				function (response) {
					_findError(response);
					ref.reject(response);
				}
			)
			.catch(function () {
				_findError(response);
				ref.reject(response);
			});

		return ref.promise;
	};

	this.search = function (query) {
		return this.getModel().search({ query: query }).$promise;
	};

	return this;
}
;
