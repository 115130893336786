define('services/FilterPageUnpagedService',['app', 'modules/module', 'services/EntityCrudUnpagedService'], function (app, module) {
	'use strict';
	return module.factory('FilterPageUnpagedService', ['$injector', FilterPageUnpagedService]);
});

function FilterPageUnpagedService($injector) {
	crudInstance = $injector.instantiate(EntityCrudUnpagedService);
	crudInstance.setType('FilterPage');

	angular.extend(this, crudInstance);

	return this;
}
;
