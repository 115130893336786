define('modules/orderManagement/controllers/OrderManagementFormController',[
	'modules/module',
	'modules/orderManagement/controllers/tabs/AdditionalInfoTabController',
	'settings',
	'modules/basemodule/controllers/EntityFormController',
	'services/NavigationService',
	'services/ProductService',
	'services/CountryService',
	'services/PaymentMethodService',
	'services/DeliveryServiceService',
	'services/CustomerService',
	'services/CustomerUnpagedService',
	'services/DropShippingOrderService',
	'services/ShopOrderProtocolService',
	'services/ShippingCarrierService',
	'services/TicketUnpagedService',
	'filters/CurrencyFilter',
	'directives/controls/customDatepicker/CustomDatepicker',
	'directives/components/common/modalDialog/ModalDialog',
	'modules/orderManagement/controllers/OrderManagementSelectCustomerController',
	'modules/orderManagement/controllers/OrderManagementSelectProductController',
	'services/OrderStateUnpagedService',
	'services/OrderService',
	'services/ShoppingCartService',
	'services/LocaleService',
	'services/ShopService',
	'services/CheckoutService',
	'services/UnitService',
	'services/CancellationService',
	'services/PayPalService',
	'directives/controls/customDatepicker/CustomDatepicker',
	'directives/errorsToHtml/errorsToHtml',
	'directives/cancellation/cancellation',
], function (module, additionalInfoTab) {
	'use strict';
	module.controller('OrderManagementFormController', [
		'$sce',
		'$scope',
		'$rootScope',
		'$controller',
		'$translate',
		'$timeout',
		'ShippingLabelService',
		'NavigationService',
		'ProductService',
		'CountryService',
		'LocaleService',
		'PaymentMethodService',
		'DeliveryServiceService',
		'CustomerService',
		'CustomerUnpagedService',
		'DropShippingOrderService',
		'ShopOrderProtocolService',
		'ShippingCarrierService',
		'TicketUnpagedService',
		'OrderStateUnpagedService',
		'ShopService',
		'ShoppingCartService',
		'BASE_TEMPLATES_PATH',
		'CheckoutService',
		'UnitService',
		'ValidationService',
		'NotificationService',
		'$state',
		'CurrencyService',
		'UserService',
		'PayPalService',
		'SessionValuesService',
		'OrderService',
		'CancellationService',
		OrderManagementFormController(module, additionalInfoTab),
	]);
});

function OrderManagementFormController(module, additionalInfoTab) {
	return function (
		$sce,
		$scope,
		$rootScope,
		$controller,
		$translate,
		$timeout,
		ShippingLabelService,
		NavigationService,
		ProductService,
		CountryService,
		LocaleService,
		PaymentMethodService,
		DeliveryServiceService,
		CustomerService,
		CustomerUnpagedService,
		DropShippingOrderService,
		ShopOrderProtocolService,
		ShippingCarrierService,
		TicketUnpagedService,
		OrderStateUnpagedService,
		ShopService,
		ShoppingCartService,
		BASE_TEMPLATES_PATH,
		CheckoutService,
		UnitService,
		ValidationService,
		NotificationService,
		$state,
		CurrencyService,
		UserService,
		PayPalService,
		SessionValuesService,
		OrderService,
		CancellationService
	) {
		angular.extend(
			this,
			$controller('EntityFormController', {
				$scope: $scope,
			})
		);

		$scope.state = Object.assign($scope.state, {
			discount: 0,
			discountArray: [],
			createStart: !$state.params.shoppingCartId, // if shopping cart id doesn't exist it's a new order
		});

		$scope.formTabs = [
			{
				label: 'FIELDS.OVERVIEW',
				template: BASE_TEMPLATES_PATH + '/orderManagement/form-main.html',
				active: true,
			},
		];

		$scope.listState = 'root.ordermanagements';

		$scope.newTicket = undefined;

		$scope.datas = {};
		$scope.productService = ProductService;
		$scope.productService.setCurrentPage(0).then(function () {
			$scope.datas.dropShippingProducts = $scope.productService.getAll();
		});

		$scope.orderTableOptions = {};
		$scope.orderTableOptions.name = 'Status';
		$scope.orderTableOptions.field = {
			id: 'NAME',
			heading: 'FIELDS.NAME',
			attribute: 'displayName',
		};

		$scope.selected = {};

		// Array of custom order products. Used to restore changed custom order product if "undo" is pressed.
		$scope.orderProductsBackup = [];

		$scope.getCancellationColor = function (cancellationId, index) {
			var colors = [
				'LimeGreen',
				'DarkOliveGreen',
				'MediumSpringGreen',
				'DarkSeaGreen',
				'MediumSeaGreen',
				'ForestGreen',
				'DarkGreen',
			];
			if (!$scope.choosedCancellationColors) {
				$scope.choosedCancellationColors = {};
			}
			if ($scope.choosedCancellationColors['color' + cancellationId]) {
				return $scope.choosedCancellationColors['color' + cancellationId];
			}
			if (index !== undefined) {
				if (!colors[index]) {
					//should never happen
					console.error('Not enough colors');
				}
				$scope.choosedCancellationColors['color' + cancellationId] = colors[index];
				return $scope.choosedCancellationColors['color' + cancellationId];
			}
		};

		$scope.updateCancellation = function (cancellation) {
			$scope.cancellation = cancellation;
			return true;
		};

		$scope.createCancellation = function () {
			//init a new cancellation
			$scope.cancellation = { orderProducts: [], refundShipping: false };

			var atLeasOneSelction = false;
			for (var i = 0; i < $scope.entity.orderProducts.length; i++) {
				var orderProduct = $scope.entity.orderProducts[i];
				if (orderProduct.selected) {
					atLeasOneSelction = true;
					$scope.cancellation.orderProducts.push(orderProduct);
				}
				if (orderProduct.selected && orderProduct.cancellationId > 0) {
					NotificationService.notifyGeneralError({
						statusText: 'Ein oder mehere Positionen gehören bereits zu einer Stornierung.',
					});
					return false;
				}
			}

			if (!atLeasOneSelction) {
				NotificationService.notifyGeneralError({
					statusText: 'Es muss mindestens eine Position angewählt werden.',
				});
				return false;
			}

			return true;
		};

		$scope.deleteCancellation = function (cancellation) {
			NotificationService.confirm({
				statusText: 'Wollen Sie wirklich die Stornierung löschen?',
				msg: '',
			}).then(function () {
				CancellationService.delete(cancellation.id).then(function (response) {
					if (response.ok !== false) {
						NotificationService.notifySuccess({
							statusText: 'Die Stornierung wurde erfolgreich augfgehoben.',
						});
						if ($scope.cancellationDialog) {
							$scope.cancellationDialog.cancel();
						}
						$state.reload();
					} else {
						NotificationService.notifyGeneralError({
							statusText: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es noch einmal.',
						});
					}
				});
			});
		};

		$scope.dialogCancellationController = [
			'$scope',
			'$mdDialog',
			function ($dialogScope, $mdDialog) {
				$scope.cancellationDialog = $mdDialog;
				$dialogScope.shopOrder = $scope.entity;
				$dialogScope.cancellation = $scope.cancellation;
				$dialogScope.dialog = $mdDialog;
				$dialogScope.onFinish = function () {
					$mdDialog.cancel();
					$state.reload();
				};
				$dialogScope.cancel = function () {
					$mdDialog.cancel();
				};
			},
		];

		ShopService.findOne(UserService.getShop()).then(function () {
			$scope.shopComplete = ShopService.getOne();
			// console.log($scope.shopComplete);
			CurrencyService.findAll(true).then(function (response) {
				$scope.currencies = CurrencyService.getAll();

				for (var i = 0; i < $scope.currencies.length; i++) {
					if ($scope.currencies[i].id === $scope.shopComplete.defaultCurrencyId) {
						$scope.selected.choosedCurrencyId = $scope.currencies[i].id;
						break;
					}
				}
			});
		});

		$scope.currencyChanged = function () {
			for (var i = 0; i < $scope.currencies.length; i++) {
				var currency = $scope.currencies[i];
				if (currency.id === $scope.selected.choosedCurrencyId) {
					CheckoutService.setCurrencyIso(currency.currencyIso);
					ShoppingCartService.setCurrencyIso(currency.currencyIso);
					break;
				}
			}

			if ($scope.selected.choosedCurrencyId) {
				$scope.saveShoppingCart();
			}
		};

		$scope.validateForCreate = function () {
			var validationObject = {
				addresses: {
					method: 'isset',
					fieldName: 'Rechnungs-/Versandadresse',
					errorMsg: 'Bitte füllen Sie die Felder der Rechnungs-/Versandadresse aus.',
					customValidate: function (entity) {
						var validateAddress = function (address) {
							if (!address) {
								return false;
							}
							if (!address.firstName) {
								return false;
							}
							if (!address.lastName) {
								return false;
							}
							if (!address.street) {
								return false;
							}
							if (!address.zipCode) {
								return false;
							}
							if (!address.city) {
								return false;
							}
							if (!address.country || !address.country.id) {
								return false;
							}
							return true;
						};

						if (!validateAddress(entity.shippingAddress)) {
							return false;
						}

						return validateAddress(entity.invoiceAddress);
					},
				},
				products: {
					method: 'isset',
					fieldName: 'Produkte',
					errorMsg: 'Bitte fügen Sie der Bestellung mindestens ein Produkt hinzu.',
					customValidate: function (entity) {
						return entity.id && entity.orderProducts && entity.orderProducts.length > 0;
					},
				},
				customerId: {
					method: 'isset',
					fieldName: 'Kunde',
				},
				email: {
					method: 'isEmail',
					fieldName: 'Email',
				},
			};

			if (!ValidationService.validate($scope.entity, validationObject)) {
				NotificationService.notifyValidationErrors(ValidationService.getErrors());
				return false;
			}
			return true;
		};

		$scope.create = function () {
			if (!$scope.validateForCreate()) {
				return;
			}

			CheckoutService.createOrder($scope.entity).then(
				function (response) {
					NavigationService.changeState('root.ordermanagement', response.id);
				},
				function () {
					//error
					console.error(response);
				}
			);
		};

		$scope.customShippingChange = function () {
			$scope.customShippingActive = true;

			if ($scope.customShippingDelay) {
				$timeout.cancel($scope.customShippingDelay);
			}

			$scope.customShippingDelay = $timeout(function () {
				CheckoutService.proceed($scope.entity).then(function (response) {
					$scope.refreshOrderEntity(response);
				});
			}, 600);
		};

		$scope.proceed = function () {
			if (!$scope.validateForCreate()) {
				return;
			}

			CheckoutService.proceed($scope.entity).then(function (response) {
				$scope.refreshOrderEntity(response);
			});
		};

		$scope.saveShoppingCart = function () {
			// console.log($scope.entity.id);
			if ($scope.entity.id == $scope.getCurrentShopId()) {
				ShoppingCartService.saveShoppingCart($scope.entity).then(function () {
					$scope.refreshAfterShoppingCartSave();
				});
			}
		};

		$scope.getCurrentShopId = () => {
			const shop = UserService.getShop();
			if (!shop) {
				return null;
			}

			return shop.id;
		};

		$scope.refreshOrderEntity = function (shoppingCartResponse) {
			var oldDeliveryMethodId = $scope.entity.deliveryMethodId;
			var oldPaymentMethodId = $scope.entity.paymentMethodId;
			var oldCustomer = $scope.entity.customer;
			var oldCustomerId = $scope.entity.customerId;
			var oldShippingAddress = $scope.entity.shippingAddress;
			var oldInvoiceAddress = $scope.entity.invoiceAddress;
			var oldEmail = $scope.entity.email;
			var oldTrackingNumber = $scope.entity.trackingNumber;

			if (shoppingCartResponse && shoppingCartResponse.customShipping != undefined) {
				$scope.customShippingActive = true;
			}

			let savedPriceState = ($scope.entity.orderProducts || []).map((x) => x.price);
			$scope.entity = shoppingCartResponse;
			$scope.entity.editable = true;

			for (let i in $scope.entity.orderProducts) {
				if (savedPriceState[i]) {
					$scope.entity.orderProducts[i].price = savedPriceState[i];
				}
			}

			if (shoppingCartResponse.deliveryService && shoppingCartResponse.deliveryService.deliveryMethod) {
				$scope.entity.deliveryMethodId = shoppingCartResponse.deliveryService.deliveryMethod.id;
			} else {
				$scope.entity.deliveryMethodId = oldDeliveryMethodId;
			}

			if (!shoppingCartResponse.paymentMethodId) {
				$scope.entity.paymentMethodId = oldPaymentMethodId;
			}

			if (!shoppingCartResponse.customer) {
				$scope.entity.customer = oldCustomer;
			}

			if (!shoppingCartResponse.customerId) {
				$scope.entity.customerId = oldCustomerId;
			}

			if (!shoppingCartResponse.invoiceAddress) {
				$scope.entity.invoiceAddress = oldInvoiceAddress;
			}

			if (!shoppingCartResponse.shippingAddress) {
				$scope.entity.shippingAddress = oldShippingAddress;
			}

			if (!shoppingCartResponse.email) {
				$scope.entity.email = oldEmail;
			}

			if (!shoppingCartResponse.trackingNumber) {
				$scope.entity.trackingNumber = oldTrackingNumber;
			}

			if ($scope.entity.invoiceAddress) {
				$scope.entity.invoiceAddress.editable = true;
			}
			if ($scope.entity.shippingAddress) {
				$scope.entity.shippingAddress.editable = true;
			}

			ShoppingCartService.setShoppingCart(shoppingCartResponse);
		};

		$scope.refreshAfterShoppingCartSave = function () {
			$scope.refreshOrderEntity(ShoppingCartService.getCart());
		};

		$scope.init('Order').then(function () {
			if ($scope.entity.id) {
				DropShippingOrderService.findByOrderId($scope.entity.id).then(function (dropShippingOrders) {
					$scope.dropShippingOrders = dropShippingOrders;
				});

				ShopOrderProtocolService.get($scope.entity.id).then(function (shopOrderProtocols) {
					$scope.shopOrderProtocols = shopOrderProtocols;
				});

				CancellationService.findByShopOrderId($scope.entity.id).then(function (response) {
					$scope.cancellations = response;
				});
			}

			if (!$scope.newEntity) {
				$scope.formTabs.push({
					label: 'FIELDS.DELIVERY',
					template: BASE_TEMPLATES_PATH + '/orderManagement/form-shipping.html',
				});
				$scope.formTabs.push({
					label: 'FIELDS.STATUS',
					template: BASE_TEMPLATES_PATH + '/orderManagement/form-status.html',
				});
				$scope.formTabs.push({
					label: 'FIELDS.DROP_SHIPMENT',
					template: BASE_TEMPLATES_PATH + '/orderManagement/form-drop-shipping.html',
				});
				$scope.formTabs.push({
					label: 'FIELDS.COMMUNICATION_HISTORY',
					template: BASE_TEMPLATES_PATH + '/orderManagement/form-communication.html',
				});
				$scope.formTabs.push({
					label: 'FIELDS.PROTOCOL',
					template: BASE_TEMPLATES_PATH + '/orderManagement/form-shop-order-protocol.html',
				});

				additionalInfoTab($scope, ($scope.additionalInfoTabScope = {}), {
					$rootScope,
					$translate,
					BASE_TEMPLATES_PATH,
					LocaleService,
				});
			}

			$scope.listState = 'root.ordermanagements';

			$scope.delivery = {
				currentStep: 0,
			};

			if (typeof $scope.entity !== undefined) {
				$scope.entityShippingStates = ShippingCarrierService.renameShippingStates(
					$scope.entity.shippingMethodName,
					$scope.entity.orderStates
				);

				if ($scope.entity.customerId !== undefined) {
					CustomerService.findOne($scope.entity.customerId).then(function () {
						var customer = CustomerService.getOne();
						if (customer.error) {
							$scope.customer = 'Gastkauf';
						} else {
							if (customer.firstName == undefined && customer.lastName === undefined) {
								$scope.customer = customer.companyName;
							} else {
								$scope.customer = customer.firstName + ' ' + customer.lastName;
							}
							$scope.customerNumber = customer.customerNumber;
						}
					});
				} else {
					$scope.entity.orderProducts = [];
					$scope.paymentMethods = [];
					$scope.paymentMethod = '';

					PaymentMethodService.findAll(true).then(function () {
						$scope.paymentMethods = PaymentMethodService.getAll();
						for (var i = $scope.paymentMethods.length - 1; i >= 0; i--) {
							if ($scope.paymentMethods[i].paymentMethodType === 'PAYPAL_PLUS_PAYMENT') {
								$scope.paymentMethods.splice(i, 1);
							}
						}

						$scope.formTabs.push({
							label: 'FIELDS.COMMUNICATION_HISTORY',
							template: BASE_TEMPLATES_PATH + '/orderManagement/form-communication.html',
						});

						if (
							$scope.paymentMethods !== undefined &&
							$scope.paymentMethods.length !== undefined &&
							$scope.paymentMethods.length > 0
						) {
							$scope.entity.paymentMethodId = $scope.paymentMethods[0].id;
						}
					});

					$scope.shippingMethods = [];
					$scope.shippingMethod = '';
					DeliveryServiceService.findAll(true).then(function (response) {
						$scope.deliveryServices = response;
						if (
							$scope.deliveryServices !== undefined &&
							$scope.deliveryServices.length !== undefined &&
							$scope.deliveryServices.length > 0
						) {
							$scope.entity.deliveryMethodId = $scope.deliveryServices[0].deliveryMethod.id;
						}
					});

					CountryService.findAll(true).then(function () {
						$scope.countries = CountryService.getAll();
					});

					$scope.entity = { editable: true };

					if ($state.params.shoppingCartId) {
						ShoppingCartService.loadById($state.params.shoppingCartId).then(
							function (response) {
								$scope.refreshOrderEntity(response);
							},
							function (response) {
								console.error(response);
							}
						);
					}
				}

				if ($scope.entity.seminarParticipants && $scope.entity.seminarParticipants.length > 0) {
					var participantTypeLabelMap = {
						CUSTOMER: 'Kunde',
						CHILD: 'Kind',
						PARTNER: 'Partner',
						NOT_SET: null, //hides field
					};

					$scope.entity.seminarParticipants.forEach(function (participant, i) {
						if (participant.seminarParticipantType in participantTypeLabelMap) {
							$scope.entity.seminarParticipants[i].seminarParticipantType =
								participantTypeLabelMap[participant.seminarParticipantType];
						}
					});

					$scope.formTabs.push({
						label: 'FIELDS.PARTICIPANTS',
						template: BASE_TEMPLATES_PATH + '/orderManagement/form-participants.html',
					});
				}

				OrderStateUnpagedService.findAllOrderStates('ORDER').then(function () {
					$scope.orderStates = OrderStateUnpagedService.getAllOrderStates().data;
					$scope.prepareDataForFrontend();
				});
			}

			if ($scope.entity.orderDate != undefined) {
				$scope.entity.orderDate = new Date($scope.entity.orderDate);
			}

			if ($scope.entity.tickets == undefined) {
				$scope.entity.ticket = [];
			}
		});

		$scope.copyToShipping = function () {
			if (!$scope.entity.invoiceAddress) {
				return;
			}
			var addressTmp = angular.copy($scope.entity.invoiceAddress);
			delete addressTmp.id;
			$scope.entity.shippingAddress = addressTmp;
		};

		$scope.openCustomerSelector = function () {};

		$scope.onOrderStatusClicked = function (item) {
			$scope.orderStateForComment = item;
			$scope.orderStateForComment.new =
				$scope.entity.orderStates.filter(function (f) {
					return f.id === item.id;
				}).length === 0;
		};

		$scope.sendEmail = function () {
			OrderService.sendEmail(
				$scope.entity,
				$scope.orderStateForComment,
				$scope.orderStateForComment.comment
			).then(function () {
				ShopOrderProtocolService.get($scope.entity.id).then(function (shopOrderProtocols) {
					$scope.shopOrderProtocols = shopOrderProtocols;
					NotificationService.notifySuccess({ statusText: 'Die E-Mail wurde erfolgreich versandt.' });
				});
			});
		};

		$scope.$on('beforeUpdate', function () {
			$scope.beforeUpdateCreate();
		});

		$scope.$on('beforeCreate', function () {
			$scope.beforeUpdateCreate();
		});

		$scope.$on('afterCreate', function () {
			$scope.afterSave();
		});

		$scope.$on('afterUpdate', function () {
			$scope.afterSave();
		});

		$scope.beforeUpdateCreate = function () {
			$scope.entity.orderStates = [];
			for (var i = 0; $scope.entity.orderStateIds && i < $scope.entity.orderStateIds.length; i++) {
				var orderStateId = $scope.entity.orderStateIds[i];
				var comment = null;

				for (var j = 0; j < $scope.orderStates.length; j++) {
					if (orderStateId == $scope.orderStates[j].id && $scope.orderStates[j].comment) {
						comment = $scope.orderStates[j].comment;
						break;
					}
				}
				$scope.entity.orderStates.push({ id: orderStateId, comment: comment });
			}
		};

		$scope.afterSave = function () {
			$scope.prepareDataForFrontend();
		};

		$scope.prepareDataForFrontend = function () {
			for (var j = 0; j < $scope.orderStates.length; j++) {
				$scope.orderStates[j].comment = null;
			}
			$scope.entity.orderStateIds = [];
			for (var i = 0; $scope.entity.orderStates && i < $scope.entity.orderStates.length; i++) {
				var orderState = $scope.entity.orderStates[i];
				$scope.entity.orderStateIds.push(orderState.id);
				for (var j = 0; orderState.comment && j < $scope.orderStates.length; j++) {
					if ($scope.orderStates[j].id == orderState.id) {
						$scope.orderStates[j].comment = orderState.comment;
					}
				}
			}
		};

		UnitService.findAll().then(function () {
			$scope.units = UnitService.getAll(true);
			$scope.weightUnits = UnitService.getUnitsByType('WEIGHT');
			$scope.volumeUnits = UnitService.getUnitsByType('VOLUME');
		});

		$scope.getUnitById = function (id) {
			var returnUnit = null;
			if (!$scope.units) {
				return null;
			}
			$scope.units.forEach(function (unit) {
				if (unit.id == id) {
					returnUnit = unit.unit;
				}
			});
			return returnUnit;
		};

		$scope.isOrderManagementActive = function () {
			//TODO: test if the user has bought ordermanagement
			return true;
		};

		$scope.getOrderStateById = function (id) {
			for (var i = 0; i < $scope.orderStates.length; i++) {
				if ($scope.orderStates[i].id == id) {
					return $scope.orderStates[i];
				}
			}
		};

		$scope.itemChange = function (itemIndex) {
			const item = $scope.entity.orderProducts[itemIndex];

			if (!item.custom) {
				return;
			}

			// save custom order product's initial state, so it can be restored if needed
			if (item.id && !$scope.orderProductsBackup[itemIndex]) {
				$scope.orderProductsBackup[itemIndex] = angular.copy(item);
			}

			item.hasChanges = true;
		};

		$scope.addCustomShipping = function () {
			if (!$scope.validateForCreate()) {
				return;
			}
			$scope.customShippingActive = true;
		};

		$scope.removeCustomShipping = function () {
			$scope.customShippingActive = false;
			$scope.entity.customShipping = undefined;
			CheckoutService.proceed($scope.entity).then(function (response) {
				$scope.refreshOrderEntity(response);
			});
		};

		/*
		 Ticket modal Dialog functions
		 */
		$scope.onSuccess = function (result) {
			console.log('Das war ein voller Erfolg!');
		};

		$scope.onCancel = function () {};

		$scope.dialogImageController = function ($scope, dataToPass, $mdDialog) {
			$scope.data = dataToPass;
			$scope.cancel = function () {
				$mdDialog.cancel();
			};
		};

		$scope.setTicketStatus = function (status, ticket) {
			ticket.status = status;
			TicketUnpagedService.update(ticket);
		};

		$scope.addCommunication = function (ticket) {
			if (ticket.communications == undefined) {
				ticket.communications = [];
			}
			ticket.communications.unshift({
				author: $scope.shop.name + ' Team',
				content: '',
				createdAt: new Date(),
				new: true,
			});
		};

		$scope.saveCommunication = function (ticket) {
			for (var i = 0; i < ticket.communications.length; i++) {
				delete ticket.communications[i].new;
			}
			var ticket1;
			TicketUnpagedService.saveTicket(ticket).then(function (response) {
				ticket1 = response;
				for (var i = 0; i < $scope.entity.tickets.length; i++) {
					if ($scope.entity.tickets[i].id == ticket1.id) {
						$scope.entity.tickets[i] = ticket1;
						break;
					}
				}
			});
		};

		$scope.removeCommunication = function (ticket) {
			ticket.communications.shift();
		};

		$scope.createTicket = function () {
			$scope.newTicket = {
				author: $scope.shop.name + ' Team',
				createdAt: new Date(),
				name: '',
				customerId: $scope.entity.customerId,
				orderId: $scope.entity.id,
				status: 'OPEN',
				communications: [
					{
						author: $scope.shop.name + ' Team',
						content: '',
						createdAt: new Date(),
					},
				],
			};
		};

		$scope.saveNewTicket = function () {
			var ticket;
			TicketUnpagedService.saveTicket($scope.newTicket).then(function (response) {
				ticket = response;
				$scope.entity.tickets.unshift(ticket);
				$scope.newTicket = undefined;
			});
		};

		$scope.curentPriceCount = (index) => {
			// let discount = $scope.entity.orderProducts[index].productPercentageDiscount;
			// $scope.state.discountArray[index] = $scope.state.discountArray[index] || {};
			// if(discount != 100) {
			//     let priceVal = parseFloat($scope.entity.orderProducts[index].price);
			//     if(!discount) {
			//         discount = 0;
			//     }
			//     $scope.state.discountArray[index].price = Number(priceVal / ((100 - discount) / 100)).toFixed(2);
			// } else {
			//     $scope.state.discountArray[index].price = $scope.entity.orderProducts[index].price;
			// }
			// $scope.state.discountArray[index].discount = $scope.entity.orderProducts[index].productPercentageDiscount;
			// $scope.state.discountArray[index].amount = $scope.entity.orderProducts[index].amount;
			// $scope.state.discountArray[index].total = $scope.entity.orderProducts[index].totalPrice;
			// for(let i in $scope.state.discountArray) {
			//     $scope.entity.orderProducts[i].price = $scope.state.discountArray[i].price;
			// }
		};

		$scope.removeProduct = function (index) {
			if (!$scope.entity.orderProducts[index]) {
				return;
			}

			if ($scope.entity.orderProducts[index].custom) {
				$scope.entity.orderProducts.splice(index, 1);
				return;
			}

			ShoppingCartService.removeProduct($scope.entity.orderProducts[index].product.id, false).then(function () {
				$scope.state.discountArray.splice(index, 1);
				$scope.refreshAfterShoppingCartSave();
				// $scope.curentPriceCount(index);
			});
		};

		$scope.changeAmount = function (index, amount) {
			//TODO: Law of Demeter
			if ($scope.entity.orderProducts[index].custom) {
				$scope.entity.orderProducts[index].amount = amount;

				$scope.itemChange(index);

				return;
			}

			if ($scope.amountDelay !== undefined) {
				$timeout.cancel($scope.amountDelay);
			}

			$scope.amountDelay = $timeout(function () {
				$scope.amountDelay = undefined;
				ShoppingCartService.changeAmount($scope.entity.orderProducts[index].product.id, amount).then(
					function () {
						$scope.refreshAfterShoppingCartSave();
					}
				);
			}, 600);
		};

		$scope.changeDiscount = function (index) {
			if ($scope.entity.orderProducts[index].custom) {
				$scope.itemChange(index);

				return;
			}

			if ($scope.discountDelay !== undefined) {
				$timeout.cancel($scope.discountDelay);
			}

			$scope.discountDelay = $timeout(function () {
				$scope.discountDelay = undefined;
				ShoppingCartService.saveShoppingCart($scope.entity).then(function () {
					$scope.refreshAfterShoppingCartSave();
					// $scope.curentPriceCount(index);
				});
			}, 600);
		};

		$scope.$on('CustomerSelectModal', function (evt, data) {
			$scope.customerSelectModal(data);
		});

		$scope.$on('ProductSelectModal', function (evt, data) {
			$scope.clearShoppingCartForNewOrderInJosuaShop();
			$scope.productSelectModal(data);
		});

		$scope.$on('CustomerSelected', function (evt, customer) {});

		$scope.addCustomOrderProduct = function () {
			$scope.clearShoppingCartForNewOrderInJosuaShop();

			if (!$scope.entity.orderProducts) {
				$scope.entity.orderProducts = [];
			}
			$scope.entity.orderProducts.push({
				amount: 1,
				custom: true,
				displayedAmount: 1,
				hasChanges: true,
				price: 0,
				productPercentageDiscount: 0,
				taxValue: 0,
			});
			$scope.state.discountArray.push({
				amount: 0,
				price: 0,
				discount: 0,
				total: 0,
			});
		};

		$scope.saveCustomOrderProduct = function (item, index) {
			ShoppingCartService.saveShoppingCart($scope.entity).then(() => {
				delete $scope.orderProductsBackup[index];
				$scope.refreshAfterShoppingCartSave();
				// $scope.curentPriceCount(index);
			});
		};

		$scope.undoCustomOrderProductChanges = (orderProductIndex) => {
			$scope.entity.orderProducts[orderProductIndex] = $scope.orderProductsBackup[orderProductIndex];
			delete $scope.orderProductsBackup[orderProductIndex];
		};

		//TODO: add property to shop configuration instead of hard-coding shop's name
		$scope.clearShoppingCartForNewOrderInJosuaShop = () => {
			let name = ShopService.getOne().name;
			if ($scope.state.createStart && name === 'Josua') {
				ShoppingCartService.clear();
				$scope.state.createStart = false;
			}
		};

		$scope.tinymceOptions = {
			onChange: function (e) {},
			inline: false,
			plugins: 'advlist autolink link image lists charmap print preview code',
			skin: 'lightgray',
			theme: 'modern',
		};

		// ShoppingCartService.clear();

		/* *********************************** DIALOG CONTROLLER *********************************** */
		$scope.dialogCustomerSelectController = [
			'$scope',
			'dataToPass',
			'$mdDialog',
			function ($controllerScope, dataToPass, $mdDialog) {
				angular.extend(
					this,
					$controller('OrderManagementSelectCustomerController', {
						$scope: $controllerScope,
					})
				);

				$controllerScope.currentInstanceOf = 'PERSON';

				$controllerScope.data = dataToPass;
				$controllerScope.cancel = function () {
					$mdDialog.cancel();
				};

				$controllerScope.returnCustomerSelection = function () {
					var invoiceAddress;
					var shippingAddress;
					for (var i = 0; i < $controllerScope.customer.addresses.length; i++) {
						if ($controllerScope.invoiceAddressId === $controllerScope.customer.addresses[i].id) {
							invoiceAddress = $controllerScope.customer.addresses[i];
						}
						if ($controllerScope.shippingAddressId === $controllerScope.customer.addresses[i].id) {
							shippingAddress = $controllerScope.customer.addresses[i];
						}
					}

					$scope.entity.customerId = $controllerScope.customer.id;
					$scope.entity.firstName = $controllerScope.customer.firstName;
					$scope.entity.lastName = $controllerScope.customer.lastName;

					if (!invoiceAddress) {
						invoiceAddress = {};
					}
					invoiceAddress.editable = true;
					$scope.entity.invoiceAddress = angular.copy(invoiceAddress);

					if (!shippingAddress) {
						shippingAddress = {};
					}
					shippingAddress.editable = true;
					$scope.entity.shippingAddress = angular.copy(shippingAddress);

					$mdDialog.hide();
				};

				$controllerScope.returnAsGuest = function () {
					var editableAddress = {
						editable: true,
					};

					$scope.customer = 'Gastkauf';
					$scope.entity.customerId = -1;
					$scope.entity.invoiceAddress = angular.copy(editableAddress);
					$scope.entity.shippingAddress = angular.copy(editableAddress);

					$mdDialog.hide();
				};
			},
		];

		$scope.dialogProductSelectController = [
			'$scope',
			'dataToPass',
			'$mdDialog',
			function ($controllerScope, dataToPass, $mdDialog) {
				angular.extend(
					this,
					$controller('OrderManagementSelectProductController', {
						$scope: $controllerScope,
					})
				);

				$controllerScope.cancel = function () {
					$mdDialog.cancel();
				};

				$controllerScope.addProductsAndBack = function () {
					$controllerScope.addSelectedProducts();
					$mdDialog.hide();
				};

				$controllerScope.addProduct = function (product) {
					ShoppingCartService.addProducts([$controllerScope.wrapIntoOrder(product)]).then(function () {
						$scope.refreshAfterShoppingCartSave();
					});
				};

				$controllerScope.addSelectedProducts = function () {
					var addList = [];
					for (var p = 0; p < $controllerScope.selected.items.length; p++) {
						addList.push($controllerScope.wrapIntoOrder($controllerScope.selected.items[p]));
					}
					ShoppingCartService.addProducts(addList).then(function () {
						$scope.refreshAfterShoppingCartSave();
					});
				};
			},
		];

		/* *********************************** DIALOG CONTROLLER *********************************** */

		// TODO: do this better
		// Plugins
		$scope.requestPlugins(function () {
			if ($scope.isPluginInstalled('3082817298595101757L')) {
				ShopService.findOne(UserService.getShop()).then(function () {
					const shop = ShopService.getOne();
					$scope.qrPdfURL = `/api/v1/plugin/massfeller/qr-reprint/
				${$scope.entity.id}
				?localeId=${shop.defaultLocaleId}
				&shopId=${shop.id}
				&Authorization=${SessionValuesService.getBearerToken()}
				`;
				});
			}
		});

		// should be a plugin
		$scope.$watch('entity', function (newValue, oldValue) {
			if (newValue !== oldValue) {
				if ($scope.entity.paymentMethod.paymentMethodType === 'PAYPAL_PLUS_PAYMENT') {
					$scope.pp = {};

					const fetchTransactionInfo = function () {
						PayPalService.fetchTransactionInfo($scope.entity.id).then((res) => {
							$scope.paypalTransactionInfo = res;
						});
					};
					fetchTransactionInfo();

					$scope.pp_manualRefund = function () {
						PayPalService.refundCustomAmount($scope.entity.id, $scope.pp.manual_amount).then((res) => {
							fetchTransactionInfo();
							NotificationService.notifySuccess({ statusText: 'Rückzahlung erfolgreich.' });
						});
					};
				}
			}
		});
	};
}
;
