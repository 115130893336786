define('modules/settings/dataFeedInput/DataFeedInputFormController',[
	'modules/module',
	'modules/basemodule/controllers/EntityFormController',
	'services/DataFeedInputService',
	'services/ValidationService',
	'directives/productSelect/productSelect',
	'settings',
], function (module) {
	'use strict';
	module.controller('DataFeedInputFormController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		'ValidationService',
		'ShopService',
		'LocaleService',
		'CurrencyService',
		'CustomerGroupService',
		'ProductService',
		'NotificationService',
		'DataFeedInputService',
		function (
			$scope,
			$controller,
			BASE_TEMPLATES_PATH,
			ValidationService,
			ShopService,
			LocaleService,
			CurrencyService,
			CustomerGroupService,
			ProductService,
			NotificationService,
			DataFeedInputService
		) {
			angular.extend(
				this,
				$controller('EntityFormController', {
					$scope: $scope,
				})
			);

			$scope.formTabs = [
				{
					label: 'FIELDS.GENERAL',
					template: BASE_TEMPLATES_PATH + '/settings/dataFeedInput/form-main.html',
					active: true,
				},
			];

			$scope.selectedItems = [];
			$scope.init('DataFeedInput').then(function () {
				if ($scope.entity.includeAllProducts === undefined) {
					$scope.entity.includeAllProducts = true;
				}
				if ($scope.entity.productIds !== undefined) {
					for (var i = 0; i < $scope.entity.productIds.length; i++) {
						$scope.selectedItems.push({ id: $scope.entity.productIds[i] });
					}
				}

				if (!$scope.entity.fileType) {
					$scope.entity.fileType = 'XML';
				}

				$scope.loadCustomerGroups();
			});

			$scope.listState = 'root.dataFeedInputs';

			$scope.validate = function () {
				var rules = {
					fileType: {
						method: 'isset',
						fieldName: 'Dateityp',
					},
					shopId: {
						method: 'isset',
						fieldName: 'Shop',
					},
					localeId: {
						method: 'isset',
						fieldName: 'FIELDS.LANGUAGE',
					},
					currencyId: {
						method: 'isset',
						fieldName: 'FIELDS.CURRENCY',
					},
					customerGroupId: {
						method: 'isset',
						fieldName: 'FIELDS.CUSTOMER_GROUP',
					},
				};
				if (!$scope.entity.includeAllProducts) {
					rules['productIds'] = {
						method: 'isset',
						fieldName: 'FIELDS.PRODUCTS',
					};
				}
				return ValidationService.validate($scope.entity, rules);
			};

			$scope.dropDown = {
				fileTypes: [
					{
						display: 'XML',
						value: 'XML',
					},
					{
						display: 'PDF',
						value: 'PDF',
					},
				],
			};

			ShopService.findAll(true).then((response) => {
				$scope.dropDown.shops = response.data;
			});

			LocaleService.findAll(true).then(function (response) {
				$scope.dropDown.locales = LocaleService.getAll();
			});

			CurrencyService.findAll(true).then(function () {
				$scope.dropDown.currencies = CurrencyService.getAll(true);
			});

			$scope.$watch(
				'selectedItems',
				function (newVal, oldVal) {
					if ($scope.entity !== undefined) {
						$scope.entity.productIds = [];
						for (var i = 0; i < newVal.length; i++) {
							$scope.entity.productIds.push(newVal[i].id);
						}
					}
				},
				true
			);

			$scope.generateDataFeed = function () {
				if (!$scope.entity.id) {
					return;
				}

				NotificationService.confirm({
					statusText: 'Dies kann sehr lange dauern und hat das System verlangsamt. Bist du sicher?',
				}).then(
					function () {
						DataFeedInputService.generateDataFeed($scope.entity.id).then(function (response) {
							if (response.ok) {
								NotificationService.notifySuccess({
									statusText: 'Die Generierung des Datenfeeds wurde gestartet.',
								});
							}
						});
					},
					function () {}
				);
			};

			$scope.loadCustomerGroups = () => {
				if ($scope.entity.shopId) {
					CustomerGroupService.findByShopId($scope.entity.shopId).then((response) => {
						$scope.dropDown.customerGroups = response.data;
					});
				} else {
					$scope.dropDown.customerGroups = [];
				}
			};
		},
	]);
});

