define('services/ServerProfileService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('ServerProfileService', ['$injector', ServerProfileService]);
});

function ServerProfileService($injector) {
	var crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('ServerProfile');

	angular.extend(this, crudInstance);

	return this;
}
;
