/**
 * Created by Nico on 05.07.2016.
 */
define('services/LocaleService',['app', 'modules/module', 'services/EntityCrudService', 'services/SessionValuesService'], function (
	app,
	module
) {
	'use strict';
	return module.factory('LocaleService', [
		'$injector',
		'$q',
		'$translate',
		'SessionValuesService',
		'ModelFactory',
		'LOCALES',
		LocaleService,
	]);
});

function LocaleService($injector, $q, $translate, SessionValuesService, ModelFactory, LOCALES) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('Locale');
	angular.extend(this, crudInstance);
	let _currentLocale = {};

	this.init = () => {
		const browserLang = navigator.language || navigator.userLanguage;
		let locales = this.loadLocalesFromSettings();

		if (!sessionStorage.getItem('locale')) {
			if (locales) {
				let langId = 1;
				for (let lang of locales) {
					if (browserLang.toLowerCase().match(lang.locale)) {
						langId = lang.id;
					}
				}
				sessionStorage.setItem('locale', langId);
				_currentLocale = locales.find((x) => x.id === langId);
				$translate.use(_currentLocale.locale);
			} else {
				sessionStorage.setItem('locale', 1);
				_currentLocale = locales.find((x) => x.id === 1);
				$translate.use(_currentLocale.locale);
			}
		} else {
			_currentLocale = locales.find((x) => x.id === parseInt(sessionStorage.getItem('locale')));
			$translate.use(_currentLocale.locale);
		}

		return [locales, _currentLocale];
	};

	this.loadLocales = () => {
		const id = SessionValuesService.getShop();
		var ref = $q.defer();
		return ModelFactory.Shop().shopLocales({ id }, (res) => {
			return ref.resolve(res);
		});
	};

	this.loadLocalesFromSettings = () => {
		return LOCALES;
	};

	this.getCurrent = () => {
		return _currentLocale;
	};

	this.getTinyLang = () => {
		let tinyLang = '';

		if (!_currentLocale.locale) {
			this.init();
		}

		switch (_currentLocale.locale) {
			case 'fr':
				return (tinyLang = 'fr_FR');
			default:
				return (tinyLang = _currentLocale.locale);
		}
	};

	return this;
}
;
