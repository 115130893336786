define('services/EconMailtemplateService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('EconMailtemplateService', ['$injector', MailtemplateService]);
});

function MailtemplateService($injector) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('Mailtemplate');

	angular.extend(this, crudInstance);

	this.saveMailtemplate = function (template) {
		return this.getModel().save(template).$promise.then(this.clear);
	};

	this.update = this.saveMailtemplate;
	this.create = this.saveMailtemplate;

	this.send = function (mailTemplateId, recipientId) {
		var mailTemplate = $injector.get('ModelFactory').Mailtemplate();
		return mailTemplate.send({ id: mailTemplateId, recipientId: recipientId }).$promise;
	};

	this.duplicate = function (mailTemplateId) {
		var mailTemplate = $injector.get('ModelFactory').Mailtemplate();
		return mailTemplate.duplicate({ id: mailTemplateId }).$promise;
	};

	this.sendPreview = function (id, emails) {
		var mailTemplate = $injector.get('ModelFactory').Mailtemplate();
		return mailTemplate.sendPreview({ id: id }, emails).$promise;
	};

	var emptyTemplate = {
		page: {
			title: 'Empty Bee Template',
			description: 'Template for BEE - Empty',
			template: {
				name: 'template-base',
				type: 'basic',
				version: '0.0.1',
			},
			body: {
				type: 'mailup-bee-page-proprerties',
				container: {
					style: {
						'background-color': '#FFFFFF',
					},
				},
				content: {
					style: {
						'font-family': "Arial, 'Helvetica Neue', Helvetica, sans-serif",
						color: '#000000',
					},
					computedStyle: {
						linkColor: '#0068A5',
						messageBackgroundColor: 'transparent',
						messageWidth: '500px',
					},
				},
			},
			rows: [
				{
					type: 'one-column-empty',
					container: {
						style: {
							'background-color': 'transparent',
						},
					},
					content: {
						style: {
							'background-color': 'transparent',
							color: '#000000',
							width: '500px',
						},
					},
					columns: [
						{
							'grid-columns': 12,
							modules: [
								{
									type: 'mailup-bee-newsletter-modules-empty',
									descriptor: {},
								},
							],
							style: {
								'background-color': 'transparent',
								'padding-top': '5px',
								'padding-right': '0px',
								'padding-bottom': '5px',
								'padding-left': '0px',
								'border-top': '0px dotted transparent',
								'border-right': '0px dotted transparent',
								'border-bottom': '0px dotted transparent',
								'border-left': '0px dotted transparent',
							},
						},
					],
				},
			],
		},
	};

	this.getEmptyTemplate = function () {
		return emptyTemplate;
	};

	return this;
}
;
