/**
 * Created by Nico on 26.07.2016.
 */
define('modules/wawi/refinementProduct/RefinementProductFormController',[
	'modules/module',
	'modules/basemodule/controllers/EntityFormController',
	'services/NotificationService',
	'services/ValidationService',
	'services/TaxService',
	'services/OrganisationService',
	'services/CategoryService',
	'settings',
], function (module) {
	'use strict';
	module.controller('RefinementProductFormController', [
		'$scope',
		'$controller',
		'NotificationService',
		'ValidationService',
		'TaxService',
		'BASE_TEMPLATES_PATH',
		'OrganisationService',
		'CategoryService',
		function (
			$scope,
			$controller,
			NotificationService,
			ValidationService,
			TaxService,
			BASE_TEMPLATES_PATH,
			OrganisationService,
			CategoryService
		) {
			angular.extend(
				this,
				$controller('EntityFormController', {
					$scope: $scope,
				})
			);

			$scope.formTabs = [
				{
					label: 'FIELDS.GENERAL',
					template: BASE_TEMPLATES_PATH + '/wawi/refinementProduct/form-main.html',
					active: true,
				},
				{
					label: 'FIELDS.PRICE',
					template: BASE_TEMPLATES_PATH + '/wawi/refinementProduct/form-prices.html',
				},
				{
					label: 'FIELDS.CATEGORIES',
					template: BASE_TEMPLATES_PATH + '/wawi/refinementProduct/form-categories.html',
				},
			];

			$scope.priceTypes = [
				{ value: 'ONETIME', display: 'Einmalig' },
				{ value: 'PERPCS', display: 'Pro Stück' },
			];
			$scope.init('RefinementProduct').then(function () {
				$scope.initRefinementProduct();

				CategoryService.getHierarchy('REFINEMENT').then(function () {
					$scope.categories = CategoryService.getAllHierarchy();
					if (!$scope.newEntity) {
						$scope.setSelectedCategories($scope.categories);
					}
				});
			});

			$scope.setSelectedCategories = function (categories) {
				categories.forEach(function (category) {
					var isCategorySelected = false;
					$scope.entity.categoryIds.forEach(function (entityCategoryId) {
						if (entityCategoryId == category.id) {
							isCategorySelected = true;
						}
					});
					if (isCategorySelected) {
						category.checked = true;
					}
					if (category.children.length > 0) {
						$scope.setSelectedCategories(category.children);
					}
				});
			};

			$scope.initRefinementProduct = function () {
				if (!$scope.entity.prices || $scope.entity.prices.length == 0) {
					$scope.entity.prices = [];
					$scope.entity.prices.push({
						fromQty: 1,
						toQty: -1,
						price: 0,
					});
				}

				for (var i = 0; i < $scope.entity.prices.length; i++) {
					var price = $scope.entity.prices[i];
					if (!price.refinementProductPrices || price.refinementProductPrices.length == 0) {
						price.refinementProductPrices = [];
						price.refinementProductPrices.push({});
					}
				}
			};

			$scope.setCategory = function (categoryId, checked) {
				if ($scope.entity.categoryIds == undefined) {
					$scope.entity.categoryIds = [];
				}
				if (checked) {
					$scope.entity.categoryIds.push(categoryId);
				} else {
					var index = $scope.entity.categoryIds.indexOf(categoryId);
					$scope.entity.categoryIds.splice(index, 1);
				}
			};

			$scope.addAdditionalPrice = function (price) {
				price.refinementProductPrices.push({});
			};

			$scope.removeAdditionalPrice = function (index, price) {
				price.refinementProductPrices.splice(index, 1);
			};

			$scope.listState = 'root.refinementProducts';

			$scope.$on('beforeUpdate', function () {
				$scope.beforeCreateOrUpdate();
			});

			$scope.$on('beforeCreate', function () {
				console.log('Locales: ' + $scope.entity.locales.length);
				$scope.beforeCreateOrUpdate();
			});

			$scope.$on('afterUpdate', function () {
				$scope.initRefinementProduct();
			});

			$scope.$on('afterCreate', function () {
				$scope.initRefinementProduct();
			});

			$scope.beforeCreateOrUpdate = function () {
				for (var i = 0; i < $scope.entity.prices.length; i++) {
					var price = $scope.entity.prices[i];
					for (var j = price.refinementProductPrices.length - 1; j >= 0; j--) {
						if (!price.refinementProductPrices[j]) {
							continue;
						}
						var refinementPrice = price.refinementProductPrices[j];
						if (!refinementPrice.name && !refinementPrice.price) {
							price.refinementProductPrices.splice(j, 1);
						}
					}
				}
			};

			TaxService.findAll().then(function () {
				$scope.taxes = TaxService.getAll(true);
			});

			OrganisationService.findAll().then(function () {
				$scope.organisations = OrganisationService.getAll(true);
			});

			$scope.validate = function () {
				return ValidationService.validate($scope.entity, {
					name: {
						method: 'isset',
						fieldName: 'Name',
						errorMsg: 'Das Feld Name darf in der Standardsprache des Shops nicht leer sein',
						isLocaleValue: true,
					},
					tax: {
						method: 'isset',
						fieldName: 'Steuersatz',
						errorMsg: 'Das Feld Steursatz darf nicht leer sein',
					},
				});
			};

			$scope.addPrice = function (price) {
				if (parseInt(price.toQty) >= parseInt(price.fromQty)) {
					var fromAmountNew = (parseInt(price.toQty) + 1).toString();
					$scope.entity.prices.push({
						fromQty: fromAmountNew,
						toQty: '-1',
						price: price.price,
						refinementProductPrices: angular.copy(price.refinementProductPrices),
					});
					jQuery('.last-item input').css('border-color', '');
				} else {
					jQuery('.last-item input').css('border-color', 'red');
				}
			};

			$scope.removePrice = function (index) {
				$scope.entity.prices.splice(index, 1);
				$scope.entity.prices[index - 1].toQty = '-1';
			};
		},
	]);
});

