define('modules/module',['angular', 'ngResource', 'angular-file-upload', 'ngMockE2E', 'angular-ui-tree', 'ang-drag-drop'], function (
	ng
) {
	'use strict';
	return ng
		.module('app.modules', [
			'ngResource',
			'angularFileUpload',
			'ngMockE2E',
			'ui.tree',
			'ang-drag-drop',
			'ngMaterial',
			'ngSanitize',
		])
		.config(function ($mdThemingProvider) {
			var customPrimary = {
				50: '#cae976',
				100: '#c1e660',
				200: '#b9e24a',
				300: '#b0de35',
				400: '#a6d723',
				500: '#95c11f',
				600: '#84ab1b',
				700: '#739518',
				800: '#627f14',
				900: '#516911',
				A100: '#d2ed8c',
				A200: '#dbf0a2',
				A400: '#e4f4b8',
				A700: '#40530d',
			};
			$mdThemingProvider.definePalette('customPrimary', customPrimary);

			var customAccent = {
				50: '#2f3d0a',
				100: '#40530d',
				200: '#516911',
				300: '#627f14',
				400: '#739518',
				500: '#84ab1b',
				600: '#a6d723',
				700: '#b0de35',
				800: '#b9e24a',
				900: '#c1e660',
				A100: '#a6d723',
				A200: '#95c11f',
				A400: '#84ab1b',
				A700: '#cae976',
			};
			$mdThemingProvider.definePalette('customAccent', customAccent);

			var customWarn = {
				50: '#ff7b82',
				100: '#ff626a',
				200: '#ff4852',
				300: '#ff2f3a',
				400: '#ff1522',
				500: '#fb000d',
				600: '#e1000c',
				700: '#c8000a',
				800: '#ae0009',
				900: '#950008',
				A100: '#ff959a',
				A200: '#ffaeb3',
				A400: '#ffc8cb',
				A700: '#7b0006',
			};
			$mdThemingProvider.definePalette('customWarn', customWarn);

			var customBackground = {
				50: '#ffffff',
				100: '#ffffff',
				200: '#ffffff',
				300: '#ffffff',
				400: '#ffffff',
				500: '#ffffff',
				600: '#f2f2f2',
				700: '#e6e6e6',
				800: '#d9d9d9',
				900: '#cccccc',
				A100: '#ffffff',
				A200: '#ffffff',
				A400: '#ffffff',
				A700: '#bfbfbf',
			};
			$mdThemingProvider.definePalette('customBackground', customBackground);

			$mdThemingProvider
				.theme('default')
				.primaryPalette('customPrimary')
				.accentPalette('customAccent')
				.warnPalette('customWarn')
				.backgroundPalette('customBackground');
		});
});

