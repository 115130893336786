/**
 * Created by Nico on 05.07.2016.
 */
define('services/CurrencyService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('CurrencyService', ['$injector', CurrencyService]);
});

function CurrencyService($injector) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('Currency');

	angular.extend(this, crudInstance);

	return this;
}
;
