define('filters/DateFilterTranslatable',['app'], function (app) {
	'use strict';
	app.filter('dateFilterTranslatable', [
		function () {
			return function (isoDate) {
				var a = new Date(isoDate);

				var months = [
					'MONTHS.JANUARY',
					'MONTHS.FEBRUARY',
					'MONTHS.MARCH',
					'MONTHS.APRIL',
					'MONTHS.MAY',
					'MONTHS.JUNE',
					'MONTHS.JULY',
					'MONTHS.AUGUST',
					'MONTHS.SEPTEMBER',
					'MONTHS.OCTOBER',
					'MONTHS.NOVEMBER',
					'MONTHS.DECEMBER',
				];

				var year = a.getFullYear();
				var month = months[a.getMonth()];
				var date = a.getDate();

				if (isNaN(date)) {
					return '';
				}
				return [date, month, year];
			};
		},
	]);
});

