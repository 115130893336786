/**
 * Created by nmayer on 20.05.16.
 */
define('modules/imEx/controllers/ServerProfilesController',['modules/module', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('ServerProfilesController', [
		'$scope',
		'$controller',
		function ServerProfilesController($scope, $controller) {
			angular.extend(
				this,
				$controller('EntityListController', {
					$scope: $scope,
				})
			);

			$scope.tableOptions.fields = [
				{
					id: 'NAME',
					heading: 'FIELDS.NAME',
					attribute: 'name',
				},
				{
					id: 'HOST',
					heading: 'FIELDS.HOST',
					attribute: 'host',
				},
				{
					id: 'PORT',
					heading: 'FIELDS.PORT',
					attribute: 'port',
				},
				{
					id: 'USERNAME',
					heading: 'FIELDS.USERNAME',
					attribute: 'username',
				},
				{
					id: 'PASSWORD',
					heading: 'FIELDS.PASSWORD',
					attribute: 'password',
				},
			];

			$scope.init('ServerProfile').then(function () {});

			$scope.formState = 'root.serverprofile';
		},
	]);
});

