define('services/EconRecipientService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('EconRecipientService', ['$injector', RecipientService]);
});

function RecipientService($injector) {
	var crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('Recipient');

	angular.extend(this, crudInstance);

	this.sendOptIns = function () {
		this.getModel().sendOptIns();
	};

	this.sendBulkOptIns = function (ids) {
		this.getModel().sendBulkOptIns({ ids: ids });
	};

	this.findByRecipientListId = function (recipientListId, paging, filters) {
		var params = {
			page: paging.page,
			size: paging.size,
		};
		for (var key in filters) {
			params['filter[' + key + ']'] = filters[key];
		}
		return this.getModel().findByRecipientListId(params, { recipientListId: recipientListId }).$promise;
	};

	return this;
}
;
