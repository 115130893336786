define('modules/wawi/product/controllers/tabs/DescriptionTabController',[], function () {
	'use strict';

	return ($parentScope, $scope, { BASE_TEMPLATES_PATH }) => {
		$parentScope.formTabs.push({
			label: 'FIELDS.DESCRIPTION',
			template: BASE_TEMPLATES_PATH + '/wawi/product/form-description.html',
		});
	};
});

