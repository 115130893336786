define('modules/settings/shop/tabs/SlugSettingsTabController',[], function () {
	'use strict';

	return function SlugSettingsController($parentScope, $scope, { BASE_TEMPLATES_PATH, MetadataUnpagedService }) {
		$parentScope.adminFormTabs.push({
			label: 'FIELDS.SLUG_SETTINGS',
			template: BASE_TEMPLATES_PATH + '/settings/shop/form-slug-settings.html',
		});

		$scope.checkSlugExist = function check(arr, name) {
			var id = arr.length + 1;
			var found = arr.some(function (el) {
				return el.value === name;
			});
			return found;
		};

		$scope.init = () => {
			$scope.source = [];
			$scope.model = [];
			var usedMetas = {};

			if ($parentScope.entity.shopSlugSettings === undefined) {
				$parentScope.entity.shopSlugSettings = {};
			}
			if ($parentScope.entity.shopSlugSettings.slugSettingsFieldList === undefined) {
				$parentScope.entity.shopSlugSettings.slugSettingsFieldList = [];
			}

			for (var i = 0; i < $parentScope.entity.shopSlugSettings.slugSettingsFieldList.length; i++) {
				if ($parentScope.entity.shopSlugSettings.slugSettingsFieldList[i].fieldValue == 'Name') {
					$parentScope.entity.shopSlugSettings.slugSettingsFieldList[i].metaDataNameId = null;
				}

				if ($parentScope.entity.shopSlugSettings.slugSettingsFieldList[i].fieldValue == 'Untertitel') {
					$parentScope.entity.shopSlugSettings.slugSettingsFieldList[i].metaDataNameId = null;
				}

				if ($parentScope.entity.shopSlugSettings.slugSettingsFieldList[i].fieldValue == 'Sku') {
					$parentScope.entity.shopSlugSettings.slugSettingsFieldList[i].metaDataNameId = null;
				}
				if ($parentScope.entity.shopSlugSettings.slugSettingsFieldList[i].fieldValue == 'Hersteller') {
					$parentScope.entity.shopSlugSettings.slugSettingsFieldList[i].metaDataNameId = null;
				}

				$scope.model.push({
					id: $parentScope.entity.shopSlugSettings.slugSettingsFieldList[i].metaDataNameId,
					value: $parentScope.entity.shopSlugSettings.slugSettingsFieldList[i].fieldValue,
				});
				usedMetas[$parentScope.entity.shopSlugSettings.slugSettingsFieldList[i].metaDataNameId] =
					$parentScope.entity.shopSlugSettings.slugSettingsFieldList[i].fieldValue;
			}

			if (!$scope.checkSlugExist($scope.model, 'Untertitel')) {
				$scope.source.push({ id: null, value: 'Untertitel' });
			}

			if (!$scope.checkSlugExist($scope.model, 'Hersteller')) {
				$scope.source.push({ id: null, value: 'Hersteller' });
			}

			if (!$scope.checkSlugExist($scope.model, 'Name')) {
				$scope.source.push({ id: null, value: 'Name' });
			}

			if (!$scope.checkSlugExist($scope.model, 'Sku')) {
				$scope.source.push({ id: null, value: 'Sku' });
			}

			MetadataUnpagedService.findAll().then(function () {
				$parentScope.metadatas = MetadataUnpagedService.getAll(true).data;
				for (var j = 0; j < $parentScope.metadatas.length; j++) {
					if (!($parentScope.metadatas[j].id in usedMetas))
						$scope.source.push({
							id: $parentScope.metadatas[j].id,
							value: $parentScope.metadatas[j].identifier,
						});
				}
			});
		};

		$scope.setSelectedSlugs = function () {
			if ($parentScope.entity.shopSlugSettings === undefined) {
				$parentScope.entity.shopSlugSettings = {};
			}
			$parentScope.entity.shopSlugSettings.slugSettingsFieldList = [];

			var count = 1;
			$scope.model.forEach(function (model) {
				$parentScope.entity.shopSlugSettings.slugSettingsFieldList.push({
					fieldValue: model.value,
					metaDataNameId: model.id,
					sortOrder: count,
				});
				count++;
			});
		};
	};
});

