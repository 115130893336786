/**
 * Created by nmayer on 09.06.16.
 */
define('modules/settings/user/UserFormController',[
	'modules/module',
	'modules/basemodule/controllers/EntityFormController',
	'services/RoleService',
	'services/ShopService',
	'services/UserService',
	'services/ValidationService',
	'settings',
	'underscore',
], function (module) {
	'use strict';
	module.controller('UserFormController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		'RoleService',
		'ShopService',
		'UserService',
		'ValidationService',
		function ($scope, $controller, BASE_TEMPLATES_PATH, RoleService, ShopService, UserService, ValidationService) {
			angular.extend(
				this,
				$controller('EntityFormController', {
					$scope: $scope,
				})
			);

			$scope.formTabs = [
				{
					label: 'FIELDS.GENERAL',
					template: BASE_TEMPLATES_PATH + '/settings/user/form-main.html',
					active: true,
				},
				{
					label: 'FIELDS.ROLES',
					template: BASE_TEMPLATES_PATH + '/settings/user/form-roles.html',
				},
			];

			$scope.listState = 'root.users';
			$scope.data = {
				loggedInUser: {
					isSuperuser: UserService.isSuperuser(),
					isEconnectUser: UserService.isEconnectUser(),
				},
				disableEditingOfCredentials: false,
				isSuperuser: false, // Contains whether user which is being edit in form is superuser
				roles: [],
				selectedRoleId: null,
				selectedRoles: [], // Example: [{shopId: -1, shopName: 'Shop', disabled: false, rolesMap: {role1Id: true, role2Id: false}}]
				selectedShopId: null,
				shops: [],
				superuserRoleId: null,
			};

			$scope.init('User').then(() => {
				ShopService.findAll(true).then((response) => {
					$scope.data.shops = response.data;
					$scope.data.selectedShopId = $scope.data.shops[0].id;

					initSelectedRoles();
				});

				RoleService.findAll(true).then((response) => {
					// Delete ROLE_SUPERUSER from roles, it will be handled separately
					$scope.data.roles = response.data.filter((role) => role.name !== UserService.ROLE_SUPERUSER);
					$scope.data.superuserRoleId = response.data.find(
						(role) => role.name === UserService.ROLE_SUPERUSER
					).id;

					$scope.data.selectedRoleId = $scope.data.roles[0].id;
				});
				setDisableEditingOfCredentials();
			});

			$scope.addSelectedRole = (roleId, shopId) => {
				if (!roleId || !shopId) {
					return;
				}

				// Check if shop was already added to selectedRoles
				for (const selectedRole of $scope.data.selectedRoles) {
					if (selectedRole.shopId === shopId) {
						selectedRole.rolesMap[roleId] = true;
						return;
					}
				}

				// If roles for this shop were not added yet
				const newSelectedRole = { shopId: shopId, rolesMap: {} };
				newSelectedRole.rolesMap[roleId] = true;

				if (UserService.hasRoleAdmin(shopId)) {
					newSelectedRole.shopName = getShopNameById(shopId);
				} else {
					// if user doesn't have access, row is disabled and placeholder is shown instead of a shop name
					newSelectedRole.disabled = true;
				}

				$scope.data.selectedRoles.push(newSelectedRole);
			};

			$scope.addSelectedRoleFromForm = () => {
				$scope.addSelectedRole($scope.data.selectedRoleId, $scope.data.selectedShopId);
			};

			$scope.deleteSelectedRole = (selectedRole) => {
				const index = $scope.data.selectedRoles.indexOf(selectedRole);
				$scope.data.selectedRoles.splice(index, 1);
			};

			$scope.validate = () => {
				const rules = {
					username: {
						method: 'isset',
						fieldName: 'Benutzername',
					},
					firstName: {
						method: 'isset',
						fieldName: 'Vorname',
					},
					lastName: {
						method: 'isset',
						fieldName: 'Nachname',
					},
					email: {
						method: 'isEmail',
						fieldName: 'E-Mail',
					},
				};

				// If user is new, require password
				if (!$scope.entity.id) {
					rules.password = {
						method: 'isset',
						fieldName: 'Passwort',
					};
				}
				if ($scope.entity.id && $scope.entity.password) {
					rules.repeatPassword = {
						fieldName: 'Repeat Passwort',
						errorMsg: 'Die Passwörter stimmen nicht überein',
						customValidate: function () {
							return $scope.entity.password === $scope.entity.repeatPassword;
						},
					};
				}

				return ValidationService.validate($scope.entity, rules);
			};

			const afterSave = () => {
				setDisableEditingOfCredentials();
				initSelectedRoles();
			};

			const beforeSave = () => {
				$scope.entity.roles = [];
				$scope.data.selectedRoles.forEach((selectedRole) => {
					for (const roleId in selectedRole.rolesMap) {
						// If checkbox is not checked
						if (!selectedRole.rolesMap[roleId]) {
							continue;
						}

						$scope.entity.roles.push({
							id: roleId,
							shopId: selectedRole.shopId,
						});
					}
				});

				// If superuser checkbox is checked, add superuser role to the first shop
				if ($scope.data.isSuperuser) {
					$scope.entity.roles.push({
						id: $scope.data.superuserRoleId,
					});
				}
			};

			const deregisterAfterCreate = $scope.$on('afterCreate', afterSave);
			const deregisterAfterUpdate = $scope.$on('afterUpdate', afterSave);
			const deregisterBeforeCreate = $scope.$on('beforeCreate', beforeSave);
			const deregisterBeforeUpdate = $scope.$on('beforeUpdate', beforeSave);

			const getShopNameById = (id) => {
				const shop = $scope.data.shops.find((shop) => shop.id === id);

				return shop ? shop.name : null;
			};

			const initSelectedRoles = () => {
				$scope.data.selectedRoles = [];

				if (!$scope.entity.id) {
					return;
				}

				$scope.entity.roles.forEach((role) => {
					if (role.name === UserService.ROLE_SUPERUSER) {
						$scope.data.isSuperuser = true;
					} else {
						$scope.addSelectedRole(role.id, role.shopId);
					}
				});
			};

			const setDisableEditingOfCredentials = () => {
				// Non-superuser is able to update only their own credentials
				$scope.data.disableEditingOfCredentials =
					$scope.entity.id &&
					!$scope.data.loggedInUser.isSuperuser &&
					$scope.entity.id !== UserService.getUserId();
			};

			$scope.$on('$destroy', () => {
				deregisterAfterCreate();
				deregisterAfterUpdate();
				deregisterBeforeCreate();
				deregisterBeforeUpdate();
			});
		},
	]);
});

