define('modules/orderManagement/controllers/tabs/AdditionalInfoTabController',[], function () {
	'use strict';

	return function ($parentScope, $scope, { $rootScope, $translate, BASE_TEMPLATES_PATH, LocaleService }) {
		if (!!$parentScope.entity.additionalInfo) {
			$parentScope.formTabs.push({
				label: 'FIELDS.ADDITIONAL_INFO',
				template: BASE_TEMPLATES_PATH + '/orderManagement/form-additional-info.html',
			});

			$scope.infoObj = JSON.parse($parentScope.entity.additionalInfo);

			$scope.locale = $translate.use();
			$rootScope.$on('$translateChangeSuccess', function (e, { language }) {
				$scope.locale = language;
			});

			$scope.isObject = function (subject) {
				return typeof subject === 'object';
			};
		}
	};
});

