/**
 * Created by nmayer on 23.05.16.
 */
define('services/PriceClassService',['app', 'modules/module', 'services/EntityCrudService', 'services/NotificationService'], function (app, module) {
	'use strict';
	return module.factory('PriceClassService', [
		'$injector',
		'$q',
		'NotificationService',
		'ModelFactory',
		PriceClassService,
	]);
});

function PriceClassService($injector, $q, NotificationService, ModelFactory) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('PriceClass');

	angular.extend(this, crudInstance);
	let _priceClasses = [];

	this.findAll = function ({ customerGroupId }) {
		return $injector
			.get('ModelFactory')
			.PriceClass()
			.findAll({ customerGroupId })
			.$promise.then((response) => {
				_priceClasses = response;
			});
	};

	this.getAll = () => {
		return _priceClasses;
	};

	return this;
}
;
