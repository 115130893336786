define('modules/settings/shop/tabs/SeoSettingsTabController',[], function () {
	'use strict';

	return function SeoController(
		$parentScope,
		$scope,
		{ BASE_TEMPLATES_PATH, ShopService, NotificationService, $window, SessionValuesService }
	) {
		$parentScope.adminFormTabs.push({
			label: 'FIELDS.SEO',
			template: BASE_TEMPLATES_PATH + '/settings/shop/form-seo-settings.html',
		});

		$scope.init = () => {
			if ($parentScope.entity.shopSeoSettings === undefined) {
				$parentScope.entity.shopSeoSettings = {};
			}

			$parentScope.entity.shopScriptBlocks = $parentScope.entity.shopScriptBlocks || [];
			$scope.cookieArray = [...$parentScope.entity.shopScriptBlocks];

			if ($scope.cookieArray.length > 0) {
				//TODO: consider case when some scripts are missing from backend
				$scope.cookieArray.map((x) => (x.active = false));
				$scope.cookieArray[0].active = true;
			} else {
				$scope.cookieArray = [
					{
						name: 'COMFORT',
						script: '',
						active: true,
					},
					{
						name: 'PARTNERSHIPS',
						script: '',
					},
					{
						name: 'PROMOTION',
						script: '',
					},
				];
			}
		};

		$scope.gtmShow = true;

		$scope.sitemapGeneraionInterval = [
			{
				name: 'Daily',
				value: 'DAILY',
			},
			{
				name: 'Weekly',
				value: 'WEEKLY',
			},
			{
				name: 'Monthly',
				value: 'MONTHLY',
			},
		];

		$scope.seoChangeState = function (seoState) {
			$scope.gtmActive = false;
			$scope.sitemapActive = false;
			$scope.defseoActive = false;
			$scope.cookiesActive = false;
			$scope.gtmShow = false;
			$scope.sitemapShow = false;
			$scope.defseoShow = false;
			$scope.cookiesShow = false;

			switch (seoState) {
				case 'gtmTag':
					$scope.gtmShow = true;
					$scope.gtmActive = 'active';
					break;
				case 'sitemap':
					$scope.sitemapShow = true;
					$scope.sitemapActive = 'active';
					break;
				case 'defseo':
					$scope.defseoShow = true;
					$scope.defseoActive = 'active';
					break;
				case 'cookies':
					$scope.cookiesShow = true;
					$scope.cookiesActive = 'active';
					break;
			}
		};

		$scope.generateSiteMap = function () {
			ShopService.generateSitemap().then(function () {
				NotificationService.notifySuccess('site map generated');
			});
		};

		$scope.downloadSiteMap = function () {
			$window.open('/api/v1/core/seo-settings/download-sitemap/?shopId=' + SessionValuesService.getShop());
		};

		$scope.cookieChangeState = (id) => {
			for (let item of $scope.cookieArray) {
				item.active = false;
			}

			$scope.cookieArray[id].active = true;
		};

		$scope.setScriptToEntity = () => {
			$scope.cookieArray.map((x) => {
				$parentScope.entity.shopScriptBlocks.find((y) => y.id == x.id).script = x.script;
			});
		};
	};
});

