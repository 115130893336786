/**
 * Created by nmayer on 09.06.16.
 */
define('modules/settings/tax/TaxFormController',[
	'modules/module',
	'modules/basemodule/controllers/EntityFormController',
	'services/ValidationService',
	'services/CountryService',
	'settings',
], function (module) {
	'use strict';
	module.controller('TaxFormController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		'ValidationService',
		'CountryService',
		'EU_COUNTRIES',
		function ($scope, $controller, BASE_TEMPLATES_PATH, ValidationService, CountryService, EU_COUNTRIES) {
			angular.extend(
				this,
				$controller('EntityFormController', {
					$scope: $scope,
				})
			);

			$scope.state.requireSuperuserForUpdate = true;

			$scope.formTabs = [
				{
					label: 'FIELDS.GENERAL',
					template: BASE_TEMPLATES_PATH + '/settings/tax/form-main.html',
					active: true,
				},
			];
			$scope.init('Tax').then(function () {
				if (!$scope.entity.taxRules) {
					$scope.entity.taxRules = [];
				}
			});

			$scope.addTaxRule = function () {
				$scope.entity.taxRules.push({
					shippingCountriesCondition: [],
					invoiceCountriesCondition: [],
					customerTypeCondition: null,
				});
			};

			$scope.deleteTaxRule = function (index) {
				$scope.entity.taxRules.splice(index, 1);
			};

			$scope.listState = 'root.taxes';

			$scope.customerTypeOptions = [
				{ display: 'FIELDS.ANY', value: 'ANY' },
				{ display: 'SETTINGS.SHOP.COMPANY_NAME', value: 'COMPANY' },
				{ display: 'FIELDS.PRIVATE_PERSON', value: 'PRIVATE_PERSON' },
			];

			$scope.countryService = CountryService;
			$scope.countryService.deactivateRequestId();

			$scope.selectEuOrThirdPartyCountries = function (countriesArray, selectEuCountries) {
				countriesArray.splice(0, countriesArray.length);
				CountryService.findAll(true).then(function (response) {
					var allCountries = response.data;
					for (var i = 0; i < allCountries.length; i++) {
						var country = allCountries[i];
						if (selectEuCountries && EU_COUNTRIES.indexOf(country.countryIso3) !== -1) {
							countriesArray.push(country);
						} else if (!selectEuCountries && EU_COUNTRIES.indexOf(country.countryIso3) === -1) {
							countriesArray.push(country);
						}
					}
				});
			};

			$scope.selectAllCountries = function (countriesArray) {
				countriesArray.splice(0, countriesArray.length);
				CountryService.findAll(true).then(function (response) {
					var allCountries = response.data;
					for (var i = 0; i < allCountries.length; i++) {
						var country = allCountries[i];
						countriesArray.push(country);
					}
				});
			};

			$scope.deleteSelection = function (countriesArray) {
				countriesArray.splice(0, countriesArray.length);
			};

			$scope.validate = function () {
				return ValidationService.validate($scope.entity, {
					name: {
						method: 'isset',
						fieldName: 'Name',
					},
					value: {
						method: 'isset',
						fieldName: 'Steuersatz',
					},
				});
			};
		},
	]);
});

