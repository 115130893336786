/**
 * Created by gdoi on 21.01.19.
 */
define('modules/settings/log/LogController',['modules/module', 'settings', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('LogController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		function ($scope, $controller, BASE_TEMPLATES_PATH) {
			angular.extend(
				this,
				$controller('EntityListController', {
					$scope: $scope,
				})
			);

			$scope.hideAddDeleteButtons = true;

			$scope.tableOptions.fields = [
				{
					id: 'CREATED_AT',
					heading: 'FIELDS.CREATED_AT',
					template: BASE_TEMPLATES_PATH + '/settings/log/col-create-date.html',
					attribute: 'createdAt',
					searchDisabled: true,
				},
				{
					id: 'LOG_ACTION',
					heading: 'FIELDS.LOG_ACTION',
					attribute: 'logAction',
				},
				{
					id: 'MESSAGE',
					heading: 'FIELDS.MESSAGE',
					template: BASE_TEMPLATES_PATH + '/settings/log/col-message.html',
				},
				{
					id: 'USER_SUMMARY',
					heading: 'FIELDS.USER_SUMMARY',
					template: BASE_TEMPLATES_PATH + '/settings/log/col-user-summary.html',
				},
			];

			$scope.tableOptions.optionalFields = [
				{
					id: 'EMAIL',
					heading: 'FIELDS.EMAIL',
					attribute: 'email',
				},
				{
					id: 'FIRST_NAME',
					heading: 'FIELDS.FIRSTNAME',
					attribute: 'firstName',
				},
				{
					id: 'LAST_NAME',
					heading: 'FIELDS.LASTNAME',
					attribute: 'lastName',
				},
				{
					id: 'IP_ADDRESS',
					heading: 'FIELDS.IP_ADDRESS',
					attribute: 'ipAddress',
				},
			];

			//$scope.formState = 'root.logs';

			$scope.init('Log');
		},
	]);
});

