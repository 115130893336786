define('directives/contentConfigurator/contentSnippets/tinySnippet/TinySnippet',[
	'app',
	'angular',
	'services/ContentBuilderHelper',
	'directives/contentConfigurator/contentSnippets/_snippet',
	'directives/contentConfigurator/contentSnippets/tinySnippet/compile',
], function (app) {
	'use strict';
	app.directive('tinySnippet', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				restrict: 'E',
				scope: {
					metaIdentifier: '<',
					snippet: '=',
					position: '=',
					parent: '<',
				},
				templateUrl:
					BASE_DIRECTIVES_PATH + '/contentConfigurator/contentSnippets/tinySnippet/views/tinySnippet.html',
				controller: [
					'$scope',
					'$controller',
					'BASE_DIRECTIVES_PATH',
					'$sce',
					'ContentBuilderHelper',
					function ($scope, $controller, BASE_DIRECTIVES_PATH, $sce, ContentBuilderHelper) {
						angular.extend(
							this,
							$controller('snippetController', {
								$scope: $scope,
							})
						);

						$scope.modalTemplate =
							BASE_DIRECTIVES_PATH +
							'/contentConfigurator/contentSnippets/tinySnippet/views/tinyModal.html';
						$scope.snippet.htmlContent = $scope.snippet.htmlContent || '';

						var snippet = $scope.snippet;

						var sanitizeTinyHtml = function (content) {
							if (content !== undefined && content.htmlContent !== undefined) {
								$scope.trustedHtml = $sce.trustAsHtml($scope.snippet.htmlContent);
							}
						};

						$scope.snippetType = 'html';

						$scope.$watch(
							'snippet',
							function (newValue, oldValue) {
								sanitizeTinyHtml(newValue);
							},
							true
						);

						$scope.dialogTinyController = function ($scope, dataToPass, $mdDialog) {
							$scope.data = dataToPass;

							$scope.showPreview = true;

							$scope.togglePreview = function () {
								$scope.showPreview = !$scope.showPreview;
							};

							$scope.tinymceOptions = {
								theme: 'modern',
								convert_urls: false,
								relative_urls: false,
								remove_script_host: false,
								height: '65vh',
								valid_elements: '*[*]',
								valid_children: '+body[style]',
								plugins: [
									'link advlist lists image charmap print preview hr anchor pagebreak',
									'searchreplace visualblocks visualchars code',
									'insertdatetime media nonbreaking save table contextmenu directionality',
									'template paste textcolor colorpicker textpattern imagetools',
									'moodiaimage shortcodes',
								],
								menubar: 'insert | tools | format ',
								toolbar1:
									'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright | bullist numlist | link moodiaimage',
								toolbar2:
									'media | forecolor backcolor | fontsizeselect | fontselect | code | shortcodes',
								image_advtab: true,
							};

							$scope.cc = { htmlContent: snippet.htmlContent };

							$scope.cancel = function () {
								$mdDialog.hide(tinymce.activeEditor.getContent());
							};

							$scope.close = function () {
								$mdDialog.hide(tinymce.activeEditor.getContent());
							};

							$scope.$watch(
								'cc',
								function (newValue, oldValue) {
									if (newValue !== undefined && newValue.htmlContent !== undefined) {
										$scope.trustedHtml = $sce.trustAsHtml($scope.cc.htmlContent);
									}
								},
								true
							);
						};

						$scope.onCancel = function (result) {
							$scope.snippet.htmlContent = result || $scope.snippet.htmlContent;
							sanitizeTinyHtml($scope.snippet);
						};

						$scope.onSuccess = function (result) {
							$scope.snippet.htmlContent = result;
							sanitizeTinyHtml($scope.snippet);
						};

						$scope.showTinyModal = function () {
							$scope.$emit('TinyModal', snippet);
						};

						$scope.editSnippet = function () {
							$scope.element.edit = true;
						};

						$scope.$on('TinyModal', function (evt, data) {
							$scope.showTinyModal(evt);
						});
					},
				],
			};
		},
	]);
});

