define('modules/shippingRules/controllers/ShippingRuleController',['modules/module', 'settings', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('ShippingRuleController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		ShippingRuleController,
	]);
});

function ShippingRuleController($scope, $controller, BASE_TEMPLATES_PATH) {
	angular.extend(
		this,
		$controller('EntityListController', {
			$scope: $scope,
		})
	);

	$scope.tableOptions.fields = [
		{
			id: 'NAME',
			heading: 'FIELDS.NAME',
			attribute: 'name',
		},
		{
			id: 'NAME',
			heading: 'FIELDS,CONDITIONS',
			template: BASE_TEMPLATES_PATH + '/shippingRules/partials/shipping-rule-condition-col.html',
		},
		{
			id: 'NAME',
			heading: 'FIELDS.ACTION',
			template: BASE_TEMPLATES_PATH + '/shippingRules/partials/shipping-rule-action-col.html',
		},
	];

	$scope.formState = 'root.shippingrule';

	$scope.init('ShippingRule');

	$scope.getConditionGroups = function (rule) {
		return 'lsdkfjlsdf';
		var conditionGroups = [];
		rule.shippingConditionGroups.forEach(function (conditionGroup) {
			conditionGroups.push(conditionGroup.name);
		});
		return conditionGroups.join(',');
	};
}
;
