define('modules/wawi/product/controllers/tabs/SeoTabController',[], function () {
	'use strict';

	return ($parentScope, $scope, { BASE_TEMPLATES_PATH }) => {
		$parentScope.formTabs.push({
			label: 'FIELDS.SEO',
			template: BASE_TEMPLATES_PATH + '/wawi/product/form-seo.html',
		});

		$scope.afterInit = () => {
			var countLocalesSeo = 0;
			$scope.options = [];

			for (var i = 0; i < $parentScope.entity.locales.length; i++) {
				if (
					$parentScope.entity.locales[i].seoInformation != null &&
					$parentScope.entity.locales[i].seoInformation != undefined
				) {
					if ($parentScope.entity.locales[i].seoInformation.keywords != undefined) {
						if ($parentScope.entity.locales[i].seoInformation.keywords.length > countLocalesSeo) {
							countLocalesSeo = $parentScope.entity.locales[i].seoInformation.keywords.length;
						}
					}
				}
			}

			for (var i = 0; i < countLocalesSeo; i++) {
				$scope.options.push({});
			}
		};

		$scope.addKeyword = function () {
			$scope.options.push({});
		};

		$scope.removeKeyword = function (index) {
			$scope.options.splice(index, 1);
		};
	};
});

