define('directives/contentConfigurator/contentSnippets/sectionSnippet/directives/onePageNav/onePageNav',['app', 'settings'], function (app) {
	'use strict';
	app.directive('ngOnePageNav', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					inputData: '<data',
					stateEditing: '<',
				},
				templateUrl:
					BASE_DIRECTIVES_PATH +
					'/contentConfigurator/contentSnippets/sectionSnippet/directives/onePageNav/views/index.html',
				controller: [
					'$scope',
					'$timeout',
					'$element',
					function ($scope, $timeout, $element) {
						var path =
							BASE_DIRECTIVES_PATH +
							'/contentConfigurator/contentSnippets/sectionSnippet/directives/onePageNav/views/';

						$scope.customStyle = path + 'style.css';
						$scope.customStyleFake = path + 'styleFake.css';
						$scope.customJs = path + 'js.html';
						$scope.customJs2 = path + 'js2.html';
						$scope.includeVisibility = true;

						function _init() {
							$scope.data = angular.merge({}, $scope.inputData);
						}
						_init();

						$scope.$watchCollection('inputData', function (newValue, oldValue) {
							if (newValue !== oldValue) {
								_init();
							}
						});
						$scope.$watch('stateEditing', function (newValue, oldValue) {
							if (newValue !== oldValue) {
								_init();
							}
						});
					},
				],
			};
		},
	]);
});

