define('modules/cms/controllers/LandingPageController',['modules/module', 'settings', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('LandingPageController', ['$scope', '$controller', 'BASE_TEMPLATES_PATH', LandingPageController]);
});

function LandingPageController($scope, $controller, BASE_TEMPLATES_PATH) {
	angular.extend(
		this,
		$controller('EntityListController', {
			$scope: $scope,
		})
	);

	$scope.tableOptions.fields = [
		{
			heading: 'FIELDS.DESIGNATION',
			attribute: 'name',
		},
		{
			heading: 'URL',
			attribute: 'slug',
		},
	];

	$scope.formState = 'root.landingPage';
	$scope.init('LandingPage');
}
;
