define('modules/econ/controllers/EconRecipientListController',[
	'modules/module',
	'settings',
	'modules/basemodule/controllers/EntityListController',
	'services/EconRecipientService',
], function (module) {
	'use strict';
	module.controller('EconRecipientListController', [
		'$scope',
		'$controller',
		'EconRecipientService',
		'NotificationService',
		'BASE_TEMPLATES_PATH',
		RecipientListController,
	]);
});

function RecipientListController($scope, $controller, EconRecipientService, NotificationService, BASE_TEMPLATES_PATH) {
	angular.extend(
		this,
		$controller('EntityListController', {
			$scope: $scope,
		})
	);
	$scope.tableOptions.fields = [
		{
			id: 'NAME',
			heading: 'FIELDS.LIST_NAME',
			attribute: 'name',
		},
		{
			id: 'RECIPIENTS_COUNT',
			heading: 'FIELDS.NUMBER_OF_RECIPIENTS',
			template: BASE_TEMPLATES_PATH + '/econ/partials/_recipient-list-recipients-count.html',
			searchDisabled: true,
		},
		{
			id: 'SHORTCUT',
			heading: 'FIELDS.ABBREVIATION',
			template: BASE_TEMPLATES_PATH + '/econ/partials/_recipient-list-color.html',
			attribute: 'shortcut',
		},
		{
			id: 'SHOW_IN_FRONTEND',
			heading: 'FIELDS.SHOW_IN_FRONTEND',
			template: BASE_TEMPLATES_PATH + '/econ/partials/_recipient-list-color.html',
			searchDisabled: true,
			template: BASE_TEMPLATES_PATH + '/econ/partials/_recipient-list-show-in-frontend.html',
		},
	];

	$scope.formState = 'root.econRecipientList';

	$scope.init('RecipientList').then(function () {});

	$scope.sendOptInToAll = function () {
		NotificationService.confirm({
			statusText: 'Wollen Sie wirklich an alle Empfänger mit dem Status 0 eine Opt In Nachricht versenden?',
			msg: '',
		}).then(
			function () {
				EconRecipientService.sendOptIns();
			},
			function () {}
		);
	};
}
;
