/**
 * Created by nmayer on 09.06.16.
 */
define('modules/settings/metadata/MetadataFormController',[
	'modules/module',
	'modules/basemodule/controllers/EntityFormController',
	'services/ValidationService',
	'services/ImageService',
	'settings',
], function (module) {
	'use strict';
	module.controller('MetadataFormController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		'ValidationService',
		'ImageService',
		function ($scope, $controller, BASE_TEMPLATES_PATH, ValidationService, ImageService) {
			angular.extend(
				this,
				$controller('EntityFormController', {
					$scope: $scope,
				})
			);

			$scope.state.requireSuperuserForUpdate = true;

			$scope.image = {};
			$scope.metadataValues = [];
			$scope.treeOptions = {
				accept: function (sourceNodeScope, destNodesScope, destIndex) {
					return true;
				},
				dropped: function () {
					for (let i in $scope.metadataValues) {
						$scope.metadataValues[i].position = +i + 1;
					}
				},
			};

			$scope.formTabs = [
				{
					label: 'FIELDS.GENERAL',
					template: BASE_TEMPLATES_PATH + '/settings/metadata/form-main.html',
					active: true,
				},
				{
					label: 'FIELDS.IMAGE',
					template: BASE_TEMPLATES_PATH + '/settings/metadata/form-image.html',
				},
				{
					label: 'FIELDS.VALUES',
					template: BASE_TEMPLATES_PATH + '/settings/metadata/form-values.html',
					visible: false,
				},
			];

			$scope.init('MetaData').then(function () {
				if (!$scope.newEntity) {
					$scope.initialInputType = $scope.entity.inputType;
					$scope.changeInputType();
				}

				if ($scope.entity.imageId != undefined && $scope.entity.imageId != null) {
					ImageService.loadImage($scope.entity.imageId).then(function () {
						$scope.image = ImageService.getImage();
					});
				}

				$scope.metadataValues = [];
				var countLocales = 0;
				if ($scope.entity.locales != undefined) {
					$scope.entity.locales.sort(function (a, b) {
						if (a.localeId < b.localeId) return -1;
						if (a.localeId > b.localeId) return 1;
						return 0;
					});
					for (var i = 0; i < $scope.entity.locales.length; i++) {
						if ($scope.entity.locales[i].metadataValues != undefined) {
							if ($scope.entity.locales[i].metadataValues.length > countLocales) {
								countLocales = $scope.entity.locales[i].metadataValues.length;
							}
						}
					}
					for (var i = 0; i < countLocales; i++) {
						$scope.metadataValues.push({});
					}
				}
				if ($scope.entity.isFilterable == undefined) {
					$scope.entity.isFilterable = false;
				}
				if ($scope.entity.isSearchable == undefined) {
					$scope.entity.isSearchable = false;
				}
				if ($scope.entity.showInProductDetails == undefined) {
					$scope.entity.showInProductDetails = false;
				}
				if ($scope.entity.showInCatalog == undefined) {
					$scope.entity.showInCatalog = false;
				}
			});

			$scope.listState = 'root.metadatas';

			$scope.inputTypesForDateType = [
				{
					name: 'TEXTFIELD',
				},
				{
					name: 'DROPDOWN',
				},
				{
					name: 'MULTISELECT',
				},
				{
					name: 'YES_NO',
				},
				{
					name: 'DATE',
				},
				{
					name: 'TEXTAREA',
				},
				{
					name: 'DOCUMENT',
				},
			];

			$scope.inputTypesForNonDateType = [
				{
					name: 'TEXTFIELD',
				},
				{
					name: 'DROPDOWN',
				},
				{
					name: 'MULTISELECT',
				},
				{
					name: 'YES_NO',
				},
				{
					name: 'TEXTAREA',
				},
				{
					name: 'DOCUMENT',
				},
			];

			$scope.inputTypes = $scope.inputTypesForDateType;

			$scope.changeInputType = function () {
				if ($scope.entity != undefined) {
					if ($scope.entity.inputType == 'DROPDOWN' || $scope.entity.inputType == 'MULTISELECT') {
						$scope.formTabs[2].visible = true;
					} else {
						$scope.formTabs[2].visible = false;
					}

					if ($scope.initialInputType == undefined || $scope.initialInputType == 'DATE') {
						$scope.inputTypes = $scope.inputTypesForDateType;
					} else {
						$scope.inputTypes = $scope.inputTypesForNonDateType;
					}
				}
			};

			$scope.validate = function () {
				for (let i = 0; i < $scope.entity.locales.length; i++) {
					if (
						$scope.entity.locales[i].metadataValues !== undefined &&
						$scope.entity.locales[i].metadataValues.length > 0
					) {
						for (let k = 0; k < $scope.entity.locales[i].metadataValues.length; k++) {
							if ($scope.entity.locales[i].metadataValues[k].identifier === undefined) {
								return ValidationService.validate($scope.entity.locales[i].metadataValues[k], {
									identifier: {
										method: 'isset',
										fieldName: 'Interner Bezeichner',
										errorMsg: "Das Feld 'Interner Bezeichner' für Wert(e) darf nicht leer sein",
									},
								});
							}
						}
					}
				}

				return ValidationService.validate($scope.entity, {
					name: {
						method: 'isset',
						fieldName: 'Name',
						errorMsg: 'Das Feld Name darf in der Standardsprache des Shops nicht leer sein',
						isLocaleValue: true,
					},
					inputType: {
						method: 'isset',
						fieldName: 'Eingabetyp',
					},
					identifier: {
						method: 'isset',
						fieldName: 'Interner Bezeichner',
					},
				});
			};

			$scope.onDrop = function ($event, $data, image) {
				$scope.image = $data;
				$scope.entity.imageId = $scope.image.id;
			};

			$scope.onDropMetadata = function ($event, $data, $index, selectedLocale) {
				if ($scope.entity.locales[selectedLocale.id - 1].metadataValues[$index] == undefined) {
					$scope.entity.locales[selectedLocale.id - 1].metadataValues[$index] = {};
				}
				$scope.entity.locales[selectedLocale.id - 1].metadataValues[$index].imageId = $data.id;
			};

			$scope.removeImageMetadata = function (index, selectedLocale) {
				if ($scope.entity.locales[selectedLocale.id - 1].metadataValues[index] != undefined) {
					delete $scope.entity.locales[selectedLocale.id - 1].metadataValues[index].imageId;
				}
			};

			$scope.removeImage = function () {
				$scope.image = undefined;
				$scope.entity.imageId = null;
			};

			$scope.addValue = function () {
				$scope.metadataValues.push({});
			};

			$scope.removeValue = function (index) {
				$scope.metadataValues.splice(index, 1);
				for (var i = 0; i < $scope.entity.locales.length; i++) {
					$scope.entity.locales[i].metadataValues.splice(index, 1);
				}
			};

			$scope.$on('beforeUpdate', function () {
				$scope.beforeSave();
			});

			$scope.$on('beforeCreate', function () {
				$scope.beforeSave();
			});

			$scope.beforeSave = function () {
				if (
					$scope.entity.inputType != 'DROPDOWN' &&
					$scope.entity.inputType != 'MULTISELECT' &&
					$scope.entity.inputType != 'YES_NO'
				) {
					for (var i = 0; i < $scope.entity.locales.length; i++) {
						$scope.entity.locales[i].metadataValues = [];
					}
				}
				for (var i = 0; i < $scope.locales.length; i++) {
					if ($scope.entity.locales[i] == undefined) {
						$scope.entity.locales[i] = {
							localeId: $scope.locales[i].id,
						};
					}
					if ($scope.entity.locales[i].name == undefined) {
						$scope.entity.locales[i].name = $scope.entity.locales[0].name;
					}
					if ($scope.entity.locales[i].metadataValues == undefined) {
						$scope.entity.locales[i].metadataValues = [];
					}
				}
				var countValues = 0;
				$scope.entity.countValues = $scope.entity.locales[0].metadataValues.length;
				for (var i = 0; i < $scope.entity.locales.length; i++) {
					if ($scope.entity.locales[i].metadataValues.length > countValues) {
						$scope.entity.countValues = $scope.entity.locales[i].metadataValues.length;
					}
				}

				for (var i = 0; i < $scope.entity.locales.length; i++) {
					if ($scope.entity.locales[i].metadataValues.length > 0) {
						$scope.entity.locales[i].identifier = $scope.entity.locales[i].metadataValues[0].identifier;
					}
				}

				$scope.entity.identifier = $scope.entity.identifier.replace(/\u00dc/g, 'Ue');
				$scope.entity.identifier = $scope.entity.identifier.replace(/\u00fc/g, 'ue');
				$scope.entity.identifier = $scope.entity.identifier.replace(/\u00c4/g, 'Ae');
				$scope.entity.identifier = $scope.entity.identifier.replace(/\u00e4/g, 'ae');
				$scope.entity.identifier = $scope.entity.identifier.replace(/\u00d6/g, 'Oe');
				$scope.entity.identifier = $scope.entity.identifier.replace(/\u00f6/g, 'oe');
				$scope.entity.identifier = $scope.entity.identifier.replace(/\u00df/g, 'ss');
				$scope.entity.identifier = $scope.entity.identifier.replace('(', '');
				$scope.entity.identifier = $scope.entity.identifier.replace(')', '');

				if ($scope.entity.inputType == 'YES_NO') {
					for (var i = 0; i < $scope.entity.locales.length; i++) {
						if ($scope.entity.locales[i].metadataValues.length < 1) {
							$scope.entity.locales[i].metadataValues = [
								{
									value: 'FIELDS.YES',
									identifier: true,
								},
								{
									value: 'FIELDS.NO',
									identifier: false,
								},
							];
						}
					}
				}
			};
		},
	]);
});

