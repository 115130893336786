/**
 * Created by nmayer on 24.05.16.
 */
define('services/AttributeService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('AttributeService', ['$injector', '$q', AttributeService]);
});

function AttributeService($injector, $q) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('Attribute');

	angular.extend(this, crudInstance);

	const model = new $injector.get('ModelFactory').Attribute();

	var _findError = function (response) {
		console.error(response);
	};

	this.deleteOption = function (id) {
		var model = new $injector.get('ModelFactory').Attribute();

		return model.deleteOption({ id: id }).$promise;
	};

	this.getTypes = function () {
		var model = new $injector.get('ModelFactory').Attribute();
		return model.getTypes().$promise.then(function (response) {
			attrTypes = response.data;
		});
		return ref.promise;
	};

	this.getAttrTypes = function () {
		return attrTypes;
	};

	/**
	 * Check if attribute option is assigned to a product
	 * @param attributeOptionId
	 * @returns promise
	 */
	this.isAttributeOptionAssigned = (attributeOptionId) => {
		const ref = $q.defer();

		model.isAttributeOptionAssigned({ id: attributeOptionId }).$promise.then((response) => {
			let isAssigned = false;

			if (typeof response.isAssigned === 'boolean') {
				isAssigned = response.isAssigned;
			}

			ref.resolve(isAssigned);
		}, ref.reject);

		return ref.promise;
	};

	return this;
}
;
