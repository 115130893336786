define('modules/settings/shop/tabs/ImagesTabController',[], function () {
	'use strict';

	return function ImagesController($parentScope, $scope, { BASE_TEMPLATES_PATH, ImageService }) {
		$parentScope.formTabs.push({
			label: 'FIELDS.IMAGE',
			template: BASE_TEMPLATES_PATH + '/settings/shop/form-image.html',
		});

		$scope.image = {};
		$scope.pdfHeaderImage = {};
		$scope.defaultProductImage = {};

		$scope.init = () => {
			if (
				$parentScope.entity.shopConfiguration.imageId != undefined &&
				$parentScope.entity.shopConfiguration.imageId != null
			) {
				ImageService.loadImage($parentScope.entity.shopConfiguration.imageId).then(function () {
					$scope.image = ImageService.getImage();
				});
			}

			if (
				$parentScope.entity.shopConfiguration.pdfHeaderImageId != undefined &&
				$parentScope.entity.shopConfiguration.pdfHeaderImageId != null
			) {
				ImageService.loadImage($parentScope.entity.shopConfiguration.pdfHeaderImageId).then(function () {
					$scope.pdfHeaderImage = ImageService.getImage();
				});
			}

			if (
				$parentScope.entity.shopConfiguration.defaultProductImageId != undefined &&
				$parentScope.entity.shopConfiguration.defaultProductImageId != null
			) {
				ImageService.loadImage($parentScope.entity.shopConfiguration.defaultProductImageId).then(function () {
					$scope.defaultProductImage = ImageService.getImage();
				});
			}
		};

		$scope.onDrop = function ($event, $data, image) {
			$scope.image = $data;
			$parentScope.entity.shopConfiguration.imageId = $scope.image.id;
		};

		$scope.removeImage = function () {
			$scope.image = undefined;
			$parentScope.entity.shopConfiguration.imageId = null;
		};

		$scope.onDropPdfHeader = function ($event, $data, image) {
			$scope.pdfHeaderImage = $data;
			$parentScope.entity.shopConfiguration.pdfHeaderImageId = $scope.pdfHeaderImage.id;
		};

		$scope.removeImagePdfHeader = function () {
			$scope.pdfHeaderImage = undefined;
			$parentScope.entity.shopConfiguration.pdfHeaderImageId = null;
		};

		$scope.onDropDefaultProduct = function ($event, $data, image) {
			$scope.defaultProductImage = $data;
			$parentScope.entity.shopConfiguration.defaultProductImageId = $scope.defaultProductImage.id;
		};

		$scope.removeImageDefaultProduct = function () {
			$scope.defaultProductImage = undefined;
			$parentScope.entity.shopConfiguration.defaultProductImageId = null;
		};
	};
});

