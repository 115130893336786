/**
 * Created by Nico on 04.08.2016.
 */
define('services/ImExEntityTypeService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('ImExEntityTypeService', ['$injector', ImExEntityTypeService]);
});

function ImExEntityTypeService($injector) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('ImExEntityType');

	angular.extend(this, crudInstance);

	return this;
}
;
