define('services/EconRecipientListService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('EconRecipientListService', ['$injector', '$q', RecipientListService]);
});

function RecipientListService($injector, $q) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('RecipientList');

	angular.extend(this, crudInstance);

	this.removeRecipientsFromRecipientList = function (request) {
		const ref = $q.defer();
		this.getModel().removeRecipient(request, (data, headers, status) => {
			if (status === 200) {
				ref.resolve(data);
			} else {
				ref.reject(status);
			}
		});
		return ref.promise;
	};

	return this;
}
;
