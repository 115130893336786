/**
 * Created by Nico on 07.07.2016.
 */
define('services/ShippingMethodService',['app', 'modules/module', 'services/EntityCrudService', 'services/NotificationService'], function (app, module) {
	'use strict';
	return module.factory('ShippingMethodService', ['$injector', 'NotificationService', ShippingMethodService]);
});

function ShippingMethodService($injector, NotificationService) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('ShippingMethod');

	angular.extend(this, crudInstance);

	this.create = function (obj) {
		return new $injector.get('ModelFactory')
			['ShippingMethod']()
			.create(obj)
			.$promise.then(function (response) {
				if (response.error == undefined && response.errorCode == undefined && response.id) {
					NotificationService.notifySuccess({ statusText: 'Bearbeitung erfolgreich' });
				} else {
					NotificationService.notifyGeneralError({ statusText: 'Bearbeitung fehlgeschlagen' });
				}
			});
	};

	return this;
}
;
