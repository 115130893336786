define('directives/controls/customDatepicker/CustomDatepicker',[
	'app',
	'settings',
	'directives/components/common/transformInput/TransformInput',
	'directives/components/common/restrict/Restrict',
	'directives/infinity/Infinity',
], function (app) {
	'use strict';
	app.directive('ngCustomDatepicker', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					label: '@',
					inputId: '@',
					outerLabelClass: '@',
					outerLabel: '=',
					type: '@',
					datatype: '@',
					placeholder: '=',
					styleClass: '@',
					containerClass: '@',
					change: '=',
					maxlength: '=',
					result: '=',
					restrict: '@',
					disable: '=',
					focus: '=',
				},
				templateUrl: BASE_DIRECTIVES_PATH + '/controls/customInput/views/CustomInput.html',
				link: function (scope, element) {
					scope.$watch('focus', function (newValue) {
						if (newValue == true) {
							scope.focus = false;
							$timeout(function () {
								jQuery(element).find('input').focus();
							});
						}
					});
				},
				controller: [
					'$scope',
					function ($scope) {
						//console.log('OPTIONS');
						//console.log($scope.options);
					},
				],
			};
		},
	]);
});

