define('modules/settings/shop/tabs/B2BTabController',[], function () {
	'use strict';

	return function JLSettingsController($parentScope, $scope, { BASE_TEMPLATES_PATH }) {
		$parentScope.adminFormTabs.push({
			label: 'FIELDS.B2B',
			template: BASE_TEMPLATES_PATH + '/settings/shop/form-b2b.html',
		});
	};
});

