/**
 * Created by nmayer on 19.05.16.
 */
define('modules/imEx/controllers/ImExProfilesFormController',[
	'modules/module',
	'modules/basemodule/controllers/EntityFormController',
	'services/ServerProfileService',
	'services/ImExTypeService',
	'services/ImExEntityTypeService',
	'services/ImExMappingPropertyService',
	'services/ValidationService',
	'settings',
], function (module) {
	'use strict';
	module.controller('ImExProfilesFormController', [
		'$scope',
		'$controller',
		'$injector',
		'BASE_TEMPLATES_PATH',
		'ServerProfileService',
		'ImExTypeService',
		'ImExEntityTypeService',
		'ImExMappingPropertyService',
		'ValidationService',
		function (
			$scope,
			$controller,
			$injector,
			BASE_TEMPLATES_PATH,
			ServerProfileService,
			ImExTypeService,
			ImExEntityTypeService,
			ImExMappingPropertyService,
			ValidationService
		) {
			angular.extend(
				this,
				$controller('EntityFormController', {
					$scope: $scope,
				})
			);

			$scope.formTabs = [
				{
					label: 'FIELDS.GENERAL',
					template: BASE_TEMPLATES_PATH + '/imEx/partials/form-imexprofiles.html',
					active: true,
				},
			];

			$scope.listState = 'root.imexprofiles';

			$scope.delimiterOptions = [
				{
					display: 'CSV/Semikolon getrennt',
					value: ';',
				},
				{
					display: 'CSV/Tab getrennt',
					value: '\t',
				},
				{
					display: 'CSV/Komma getrennt',
					value: ',',
				},
			];

			$scope.cronOptions = [
				{
					display: 'jede Minute',
					value: '0 0/1 * 1/1 * ? *',
				},
				{
					display: 'alle 5 min.',
					value: '0 0/5 * 1/1 * ? *',
				},
				{
					display: 'alle 30 min.',
					value: '0 0/30 * 1/1 * ? *',
				},
				{
					display: 'jede Stunde',
					value: '0 0/60 * 1/1 * ? *',
				},
				{
					display: 'alle 5 Std.',
					value: '0 0/300 * 1/1 * ? *',
				},
				{
					display: 'täglich',
					value: '0 0 12 1/1 * ? *',
				},
				{
					display: 'wöchentlich',
					value: '0 0 12 ? * MON *',
				},
				{
					display: 'monatlich',
					value: '0 0 12 1 1/1 ? *',
				},
			];

			$scope.init('ImExProfile').then(function () {
				ServerProfileService.findAll().then(function () {
					$scope.serverProfiles = ServerProfileService.getAll(true);
				});

				ImExEntityTypeService.findAll().then(function () {
					$scope.imExEntityTypes = ImExEntityTypeService.getAll(true);
				});

				ImExTypeService.findAll().then(function () {
					$scope.imExTypes = ImExTypeService.getAll(true);
				});

				ImExMappingPropertyService.findAll().then(function () {
					$scope.imExMappingProperties = ImExMappingPropertyService.getAll(true);
				});

				if ($scope.entity.imexType == undefined) {
					$scope.entity.imexType = 'IMPORT';
				}
			});

			$scope.$on('beforeUpdate', function () {});

			$scope.$on('beforeCreate', function () {});

			$scope.addMapping = function () {
				if ($scope.entity.imexMappings == undefined) {
					$scope.entity.imexMappings = [];
				}
				$scope.entity.imexMappings.push({});
			};

			$scope.removeMapping = function (index) {
				$scope.entity.imexMappings.splice(index, 1);
			};

			$scope.transfer = function (index) {
				$scope.entity.imexMappings[index].imexProperty = $scope.entity.imexMappings[index].mappingPropertyKey;
			};

			$scope.addPattern = function () {
				if ($scope.entity.imexSkuPatterns == undefined) {
					$scope.entity.imexSkuPatterns = [];
				}
				$scope.entity.imexSkuPatterns.push({});
			};

			$scope.removePattern = function (index) {
				$scope.entity.imexSkuPatterns.splice(index, 1);
			};

			$scope.addPrice = function () {
				if ($scope.entity.imexPrices == undefined) {
					$scope.entity.imexPrices = [];
				}
				$scope.entity.imexPrices.push({});
			};

			$scope.removePrice = function (index) {
				$scope.entity.imexPrices.splice(index, 1);
			};

			$scope.validate = function () {
				return ValidationService.validate($scope.entity, {
					name: {
						method: 'isset',
						fieldName: 'Name',
					},
					imexType: {
						method: 'isset',
						fieldName: 'Vorgang',
					},
					imexEntityType: {
						method: 'isset',
						fieldName: 'Ziel',
					},
					localeId: {
						method: 'isset',
						fieldName: 'Sprache',
					},
					cronExpression: {
						method: 'isset',
						fieldName: 'Intervall',
					},
					serverProfile: {
						method: 'isset',
						fieldName: 'Serverprofil',
					},
					csvPath: {
						method: 'isset',
						fieldName: 'CSV Dateipfad',
					},
					delimiter: {
						method: 'isset',
						fieldName: 'Art',
					},
					surroundingValue: {
						method: 'isset',
						fieldName: 'Umschließende Werte',
					},
					imexSkuPatterns: {
						method: 'isset',
						fieldName: 'SKU Patterns',
					},
					imexMappings: {
						method: 'isset',
						fieldName: 'Mappings',
					},
					imexPrices: {
						method: 'isset',
						fieldName: 'Preisstaffeln',
					},
				});
			};
		},
	]);
});

