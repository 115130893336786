define('modules/settings/shop/ShopController',['modules/module', 'settings', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('ShopController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		function ($scope, $controller, BASE_TEMPLATES_PATH) {
			angular.extend(
				this,
				$controller('EntityListController', {
					$scope: $scope,
				})
			);

			$scope.state.requireSuperuserForCreateAndDelete = true;

			$scope.tableOptions.fields = [
				{
					id: 'ID',
					heading: 'Id',
					attribute: 'id',
					searchDisabled: true,
				},
				{
					id: 'NAME',
					heading: 'FIELDS.NAME',
					attribute: 'name',
				},
				{
					id: 'DESCRIPTION',
					heading: 'FIELDS.DESCRIPTION',
					attribute: 'description',
				},
				{
					id: 'WEBSITE',
					heading: 'FIELDS.WEBPAGE',
					template: BASE_TEMPLATES_PATH + '/settings/shop/shop-website-col.html',
					attribute: 'website',
				},
			];
			$scope.formState = 'root.shop';

			$scope.init('Shop');
		},
	]);
});

