define('modules/settings/shop/tabs/ShippingLabelTabController',[], function () {
	'use strict';

	return function ShippingLabelController($parentScope, $scope, { BASE_TEMPLATES_PATH, OrderManagementService }) {
		$parentScope.formTabs.push({
			label: 'FIELDS.SHIPPING_LABELS',
			template: BASE_TEMPLATES_PATH + '/settings/shop/form-shipping-labels.html',
		});

		$scope.shippingLabelProvider = [
			{ display: 'DPD', constant: 'DPD' },
			{ display: 'DHL', constant: 'DHL' },
		];

		$scope.init = () => {
			$scope.OrderManagement = OrderManagementService;
			OrderManagementService.loadSettings($parentScope.entity.id);
		};
	};
});

