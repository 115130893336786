/**
 * Created by nmayer on 09.06.16.
 */
define('modules/settings/metadata/MetadataSetFormController',[
	'modules/module',
	'modules/basemodule/controllers/EntityFormController',
	'services/ValidationService',
	'services/MetadataUnpagedService',
	'settings',
], function (module) {
	'use strict';
	module.controller('MetadataSetFormController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		'ValidationService',
		'MetadataUnpagedService',
		function ($scope, $controller, BASE_TEMPLATES_PATH, ValidationService, MetadataUnpagedService) {
			angular.extend(
				this,
				$controller('EntityFormController', {
					$scope: $scope,
				})
			);

			$scope.state.requireSuperuserForUpdate = true;

			$scope.formTabs = [
				{
					label: 'FIELDS.GENERAL',
					template: BASE_TEMPLATES_PATH + '/settings/metadataset/form-main.html',
					active: true,
				},
				{
					label: 'FIELDS.PRODUCT_FEATURES',
					template: BASE_TEMPLATES_PATH + '/settings/metadataset/form-metadata.html',
				},
			];
			$scope.init('MetaDataSet').then(function () {
				if ($scope.entity.metadataNameIds == undefined) {
					$scope.entity.metadataNameIds = [];
				}
				if ($scope.entity.metadataNames != undefined) {
					if ($scope.entity.metadataNames.length > 0) {
						for (var i = 0; i < $scope.entity.metadataNames.length; i++) {
							$scope.entity.metadataNameIds.push($scope.entity.metadataNames[i].id);
						}
					}
				}
			});

			MetadataUnpagedService.findAll().then(function () {
				$scope.metadatas = MetadataUnpagedService.getAll(true).data;
			});
			$scope.metadataTableOptions = {};
			$scope.metadataTableOptions.name = 'Produktmerkmale';
			$scope.metadataTableOptions.field = {
				id: 'NAME',
				heading: 'FIELDS.NAME',
				attribute: 'name',
			};

			$scope.listState = 'root.metadatasets';

			$scope.validate = function () {
				return ValidationService.validate($scope.entity, {
					name: {
						method: 'isset',
						fieldName: 'Name',
					},
				});
			};
		},
	]);
});

