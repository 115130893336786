define('services/ImportExportService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('ImportExportService', ['$injector', ImportExportService]);
});

function ImportExportService($injector) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('ImportExport');

	angular.extend(this, crudInstance);

	this.importCSV = function (productsCSV, refinementProductsCSV, imagesZip, importType, shopId, localeId) {
		return this.getModel().importCSV({
			productsCSV: productsCSV,
			refinementProductsCSV: refinementProductsCSV,
			imagesZip: imagesZip,
			importType: importType,
			shopId: shopId,
			localeId: localeId,
		}).$promise;
	};

	this.importRecipientsCSV = function (recipientsCSV) {
		return this.getModel().importRecipientsCSV({
			recipientsCSV: recipientsCSV,
		}).$promise;
	};

	this.validateCSV = function (productsCSV, refinementProductsCSV, imagesZip, importType, shopId, localeId) {
		return this.getModel().validateCSV({
			productsCSV: productsCSV,
			refinementProductsCSV: refinementProductsCSV,
			imagesZip: imagesZip,
			importType: importType,
			shopId: shopId,
			localeId: localeId,
		}).$promise;
	};

	return this;
}
;
