define('directives/mediaList/mediaList',['app', 'settings', 'services/MediaService', 'directives/bufferedScroll/bufferedScroll'], function (app) {
	'use strict';
	app.directive('ngMediaList', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					deletable: '=?',
					onDragStart: '=',
					onDragEnd: '=',
					selectMediaCallback: '=?',
				},
				templateUrl: BASE_DIRECTIVES_PATH + '/mediaList/views/mediaList.html',
				controller: [
					'$scope',
					'$rootScope',
					'MediaService',
					function ($scope, $rootScope, MediaService) {
						$scope.directoryId = -1;
						$scope.query;
						$scope.selectedPage = 1;
						$scope.pageSize = 10;
						$scope.totalFileElements = 0;
						$scope.getMediaSrc = function (file) {
							return '/api/v1/media/files/' + file.id;
						};

						if (typeof $scope.deletable === 'undefined') {
							$scope.deletable = true;
						}
						$scope.selectMedia = function (file) {
							if (typeof $scope.selectMediaCallback !== 'undefined') {
								$scope.selectMediaCallback(file, $scope.getMediaSrc(file));
							}
						};

						// if ($scope.deletable) {
						//     $scope.deleteImage = ImageService.deleteImage;
						// }

						// $scope.rootDirectory = function () {
						// 	$scope.selectedPage = 1;
						// 	$scope.directoryId = -1;
						// 	$scope.loadMedia($scope.selectedPage, $scope.pageSize);
						// 	delete $scope.directories;
						// };

						$scope.loadMedia = function (page, pageSize, directoryId, query) {
							MediaService.loadDirectories(page - 1, pageSize, directoryId, query).then(function (
								response
							) {
								$scope.directories = response.children;
								$scope.directories.unshift({ id: response.id, name: response.name });
								$scope.files = response.files;
								$scope.totalFileElements = response.totalFileElements;
							});
						};

						$rootScope.$on('ANGULAR_DRAG_START', $scope.onDragStart);
						$rootScope.$on('ANGULAR_DRAG_END', $scope.onDragEnd);

						$scope.$on('MedieDeleted', function (event, data) {
							$scope.loadMedia(1, $scope.pageSize, -1, $scope.query);
						});

						$scope.$on('mediaUploaded', function (event, data) {
							$scope.loadMedia(1, $scope.pageSize, -1, $scope.query);
						});

						// $scope.directoryChange = function (directoryId) {
						// 	$scope.directoryId = directoryId;
						// 	if (directoryId != undefined) {
						// 		$scope.loadMedia($scope.selectedPage, $scope.pageSize, directoryId, $scope.query);
						// 	}
						// };

						$scope.deleteFile = function (id) {
							MediaService.deleteItem(id).then(function () {
								$scope.loadMedia(
									$scope.selectedPage,
									$scope.pageSize,
									$scope.directoryId,
									$scope.query
								);
							});
						};

						$scope.pageChanged = function () {
							$scope.loadMedia($scope.selectedPage, $scope.pageSize, $scope.directoryId, $scope.query);
						};

						$scope.queryChanged = function () {
							if (!$scope.queryTimer) {
								clearTimeout($scope.queryTimer);
							}

							$scope.queryTimer = setTimeout(function () {
								$scope.loadMedia(
									$scope.selectedPage,
									$scope.pageSize,
									$scope.directoryId,
									$scope.query
								);
							}, 800);
						};

						$scope.loadMedia($scope.selectedPage, $scope.pageSize, $scope.directoryId);

						$scope.stickyFolderHolder = () => {
							let holderOffset = jQuery('#sticky-folder-holder').offset().top;
							let isSticky = false;
							let fixedToBottom = false;
							jQuery(window).on('scroll', () => {
								let holder = jQuery('#sticky-folder-holder');
								let windowPosition = jQuery(window).scrollTop();

								if (windowPosition + 100 <= holderOffset && isSticky) {
									isSticky = false;
									holder.css({ position: 'static' });
								}

								if (
									windowPosition + 100 > holderOffset &&
									windowPosition + holder.height() + 100 < jQuery('#footer').offset().top &&
									(!isSticky || fixedToBottom)
								) {
									isSticky = true;
									fixedToBottom = false;
									holder.css({ position: 'fixed', top: 100 + 'px' });
								}

								if (
									!fixedToBottom &&
									isSticky &&
									windowPosition + holder.height() + 100 >= jQuery('#footer').offset().top
								) {
									fixedToBottom = true;
									holder.css({
										position: 'fixed',
										top: `calc(100% - ${holder.height()}px - 160px)`,
									});
								}
							});
						};

						$scope.stickyFolderHolder();
					},
				],
			};
		},
	]);
});

