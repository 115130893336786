define('services/CancellationService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('CancellationService', [
		'$injector',
		function ($injector) {
			var crudInstance = $injector.instantiate(CrudService);
			crudInstance.setType('Cancellation');
			angular.extend(this, crudInstance);

			this.create = function (cancellation) {
				return new $injector.get('ModelFactory').Cancellation().create(cancellation).$promise;
			};

			this.delete = function (cancellationId) {
				return new $injector.get('ModelFactory').Cancellation().delete({ id: cancellationId }).$promise;
			};

			this.findByShopOrderId = function (shopOrderId) {
				return new $injector.get('ModelFactory').Cancellation().findByShopOrderId({ id: shopOrderId }).$promise;
			};

			return this;
		},
	]);
});

