define('modules/shippingGroup/controllers/ShippingGroupFormController',[
	'modules/module',
	'modules/basemodule/controllers/EntityFormController',
	'services/ShippingRuleService',
	'services/ShippingGroupService',
	'services/NavigationService',
	'settings',
], function (module) {
	'use strict';
	module.controller('ShippingGroupFormController', [
		'$scope',
		'$controller',
		'$q',
		'ShippingRuleService',
		'ShippingGroupService',
		'NavigationService',
		'BASE_TEMPLATES_PATH',
		ShippingGroupFormController,
	]);
});

function ShippingGroupFormController(
	$scope,
	$controller,
	$q,
	ShippingRuleService,
	ShippingGroupService,
	NavigationService,
	BASE_TEMPLATES_PATH
) {
	$scope.formTabs = [
		{
			label: 'Versandgruppen',
			template: BASE_TEMPLATES_PATH + '/shippingGroup/partials/form-main.html',
			active: true,
		},
	];

	$scope.listState = 'root.shippinggroups';

	angular.extend(
		this,
		$controller('EntityFormController', {
			$scope: $scope,
		})
	);

	$scope.init('ShippingGroup').then(function () {
		if ($scope.entity.id === undefined) {
			$scope.isNew = true;
			$scope.entity.shippingCountries = [];
			$scope.availableShippingRules = [];
			$scope.entity.shippingRules = [];
		}

		$scope.from = {
			newCountry: '',
		};
		$scope.visibilities = {
			droparea: false,
		};
		$scope.tab = 'general';

		$q.all([ShippingRuleService.loadShippingRules(), ShippingGroupService.loadAvailableCountries()]).then(
			function () {
				$scope.availableShippingRules = ShippingRuleService.findAll();
				$scope.availableCountries = ShippingGroupService.getAvailableCountries();

				$scope.entity.shippingCountries.forEach(function (shippingCountry) {
					$scope.removeAvailableCountry(shippingCountry.country.id);
				});
				$scope.setActiveCountry($scope.entity.shippingCountries[0]);
			}
		);

		$scope.create = function () {
			$scope.setRulesOfActive();
			$scope.entityService.create($scope.entity).then(function () {
				NavigationService.changeState('root.shippinggroups');
			});
		};

		$scope.update = function () {
			$scope.setRulesOfActive();
			$scope.entityService.update($scope.entity).then(function () {
				NavigationService.changeState('root.shippinggroups');
			});
		};
	});

	$scope.setSelectedShippingRules = function () {
		$scope.entity.selectedShippingRules = [];
		$scope.activeCountry.shippingRules.forEach(function (shippingRule) {
			$scope.entity.selectedShippingRules.push(shippingRule.id);
		});
		$scope.availableShippingRules.forEach(function (availableShippingRule) {
			availableShippingRule.selected = false;
			availableShippingRule.selected =
				$scope.entity.selectedShippingRules.indexOf(availableShippingRule.id) !== -1;
		});
	};

	$scope.initActiveCountry = function () {
		$scope.activeCountry = $scope.entity.shippingCountries[$scope.entity.shippingCountries.length - 1];
	};

	$scope.setActiveCountry = function (country) {
		if (country !== undefined) {
			$scope.setRulesOfActive();
			$scope.activeCountry = country;
			$scope.setSelectedShippingRules();
			return;
		}
		//setting default country on the beginning
		$scope.availableCountries.forEach(function (country) {
			if (country.countryCode === 'DE') {
				$scope.from.newCountry = country.name;
				$scope.addShippingCountry();
			}
		});
	};

	$scope.addShippingCountry = function () {
		var country = $scope.getCountryByName($scope.from.newCountry);
		if (country === undefined) {
			$('.open').removeClass('open');
			return false;
		}
		$scope.removeAvailableCountry(country.id);
		var newCountry = {
			country: angular.copy(country),
			shippingQuantityScales: [{}],
			shippingRules: [],
		};
		$scope.entity.shippingCountries.push(newCountry);
		$scope.setActiveCountry(newCountry);
		$('.open').removeClass('open');
		$scope.newShippingCountry = undefined;
	};

	$scope.removeAvailableCountry = function (id) {
		$scope.availableCountries.forEach(function (availableCountry) {
			if (availableCountry.id === id) {
				$scope.availableCountries.splice($scope.availableCountries.indexOf(availableCountry), 1);
			}
		});
	};

	$scope.addAvailableCountry = function (country) {
		$scope.availableCountries.push(angular.copy(country));
	};

	$scope.getCountryByName = function (countryName) {
		var ret;
		$scope.availableCountries.forEach(function (country) {
			if (country.name === countryName) {
				ret = country;
			}
		});
		return ret;
	};

	$scope.removeShippingCountry = function (id) {
		$scope.shippingGroup.shippingCountries.forEach(function (shippingCountry) {
			if (id === shippingCountry.country.id) {
				$scope.addAvailableCountry(shippingCountry.country);
				$scope.shippingGroup.shippingCountries.splice(
					$scope.shippingGroup.shippingCountries.indexOf(shippingCountry),
					1
				);
			}
		});
		$scope.initActiveCountry();
	};

	$scope.addQuantityScale = function ($index) {
		$scope.activeCountry.shippingQuantityScales.splice($index + 1, 0, {});
	};

	$scope.removeQuantityScale = function ($index) {
		$scope.activeCountry.shippingQuantityScales.splice($index, 1);
	};

	$scope.setRulesOfActive = function () {
		if ($scope.activeCountry === undefined) return;
		$scope.activeCountry.shippingRules = [];
		$scope.availableShippingRules.forEach(function (availableShippingRule) {
			if (availableShippingRule.selected) {
				$scope.activeCountry.shippingRules.push(availableShippingRule);
			}
		});
	};

	$scope.getConditionGroups = function (rule) {
		var conditionGroups = [];
		rule.shippingConditionGroups.forEach(function (conditionGroup) {
			conditionGroups.push(conditionGroup.name);
		});
		return conditionGroups.join(',');
	};

	$scope.getAction = function (rule) {
		return rule.action.type + ': ' + rule.action.value;
	};

	$scope.toggleSelected = function (shippingRule) {
		shippingRule.selected = !shippingRule.selected;
	};

	$scope.setTab = function (tab) {
		$scope.attributeSetOpened = false;
		$scope.tab = tab;
		setActiveTab(tab);
	};

	var setActiveTab = function (tab) {
		$('li[role="presentation"]').removeClass('active');
		$('#' + tab + '-tab').addClass('active');
	};

	$scope.getIncludeSrc = function () {
		var src = 'angular/modules/shippingGroup/views/partials/_form' + $scope.tab + '.html';
		return src;
	};

	$scope.getVisibility = function () {
		if ($scope.visibilities.droparea) {
			return 'visible';
		}
		return 'invisible';
	};

	$scope.showDropArea = function () {
		$scope.visibilities.droparea = true;
		$scope.$apply();
	};

	$scope.hideDropArea = function () {
		$scope.visibilities.droparea = false;
		$scope.$apply();
	};

	$scope.onDrop = function ($event, $data, array) {
		if ($scope.shippingGroup.products === undefined) {
			$scope.shippingGroup.products = [];
		}
		$scope.shippingGroup.products.push($data);
	};

	//	$scope.delete = function () {
	//		ShippingGroupService.deleteShippingGroup($scope.shippingGroup.id).then(function () {
	//			$modalInstance.dismiss('deleted');
	//		});
	//	};

	$scope.priceInputTransformer = function (val) {
		return (val * 1000) / 10;
	};

	$scope.priceOutputTransformer = function (val) {
		return val / 100;
	};

	$scope.removeShippingCountry = function (id) {
		$scope.entity.shippingCountries.forEach(function (shippingCountry) {
			if (id === shippingCountry.country.id) {
				$scope.addAvailableCountry(shippingCountry.country);
				$scope.entity.shippingCountries.splice($scope.entity.shippingCountries.indexOf(shippingCountry), 1);
			}
		});
		$scope.initActiveCountry();
	};

	$scope.addAvailableCountry = function (country) {
		$scope.availableCountries.push(angular.copy(country));
	};
}
;
