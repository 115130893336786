define('modules/content/controllers/ContentController',['modules/module', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('ContentController', ['$scope', '$controller', ContentListController]);
});

function ContentListController($scope, $controller) {
	angular.extend(
		this,
		$controller('EntityListController', {
			$scope: $scope,
		})
	);

	$scope.tableOptions.fields = [
		{
			id: 'ID',
			heading: 'ID',
			attribute: 'id',
		},
		{
			id: 'ALIAS',
			heading: 'FIELDS.ALIAS',
			attribute: 'alias',
		},
		{
			id: 'TITLE',
			heading: 'FIELDS.TITLE',
			attribute: 'title',
		},
	];

	$scope.formState = 'root.content';

	$scope.init('Content');
}
;
