define('modules/settings/cache/CacheController',['modules/module', 'services/CacheService'], function (module) {
	'use strict';
	module.controller('CacheController', [
		'$scope',
		'CacheService',
		function ($scope, CacheService) {
			$scope.caches = [];

			const loadCaches = function () {
				CacheService.findAll().then(function (response) {
					$scope.caches = response;
				});
			};

			loadCaches();

			$scope.clearAll = function () {
				CacheService.clearAll().then(function () {
					loadCaches();
				});
			};

			$scope.clear = function (name) {
				CacheService.clear(name).then(function () {
					loadCaches();
				});
			};
		},
	]);
});

