define('directives/contentConfigurator/_contentConfigurator/ContentConfigurator',[
	'app',
	'angular',
	'services/ContentBuilderHelper',
	'directives/contentConfigurator/responsiveCss/ResponsiveCss',
	'directives/contentConfigurator/_partsStore/PartsStore',
	'directives/contentConfigurator/_outline/Outline',
	'directives/contentConfigurator/_elementConfig/ElementConfig',
	'directives/contentConfigurator/_contentConfigurator/ContentPageConfig',
	'directives/contentConfigurator/contentRow/ContentRow',
	'directives/contentConfigurator/contentCell/ContentCell',
	'directives/contentConfigurator/contentSnippets/tinySnippet/TinySnippet',
], function (app) {
	'use strict';
	app.controller('ContentConfiguratorController', [
		'$scope',
		'$rootScope',
		'$timeout',
		'ContentBuilderHelper',
		BaseContentConfigController,
	]);
	app.directive('contentConfig', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				restrict: 'E',
				scope: {
					content: '=',
					layoutObj: '=',
					responsive: '<',
				},
				templateUrl:
					BASE_DIRECTIVES_PATH + '/contentConfigurator/_contentConfigurator/views/contentConfigurator.html',
				controller: ['$scope', '$rootScope', '$timeout', 'ContentBuilderHelper', BaseContentConfigController],
			};
		},
	]);
});

function BaseContentConfigController($scope, $rootScope, $timeout, ContentBuilderHelper) {
	$scope.$on('$destroy', function () {
		ContentBuilderHelper.removeInstance($scope.metaIdentifier);
	});

	$scope.maxCols = ContentBuilderHelper.MAX_COLUMNS + 1;

	var VIEWPORT_SIZE = ($scope.VIEWPORT_SIZE = ContentBuilderHelper.VIEWPORT_SIZE);

	$scope.config = {
		width: ContentBuilderHelper.getViewportSetupId(),
		updateInterval: 600,
	};
	$scope.count = 0;
	$scope.buName = 'Unnamed_content';
	$scope.firstLoading = true;
	$scope.dragging = false;
	$scope.selected = false;
	$scope.isDragging = ContentBuilderHelper.isDragging;

	var ELEMENT_TYPE = ContentBuilderHelper.ELEMENT_TYPE;

	var init = function () {
		$scope.dragOverDummy = { dragClass: '' };
		ContentBuilderHelper.registerCleanupCrew();

		$scope.edit = true;
		$scope.responsive = $scope.responsive | false;

		if ($scope.layoutObj !== undefined) {
			$scope.content.layout = $scope.layoutObj;
		}

		if (!$scope.content.layout) {
			$scope.content.layout = { json: { pageStyle: {}, root: [] } };
		} else {
			if (!$scope.content.layout.json) {
				$scope.content.layout.json = { pageStyle: {}, root: [] };
			} else {
				if (typeof $scope.content.layout.json === 'string') {
					$scope.content.layout.json = JSON.parse($scope.content.layout.json);
				}
				if (!$scope.content.layout.json.root) {
					$scope.content.layout.json.root = [];
				}
				if (!$scope.content.layout.json.pageStyle) {
					$scope.content.layout.json.pageStyle = {};
				}
			}
		}

		$scope.contentRoot = $scope.content.layout.json.root;
		$scope.pageStyle = $scope.content.layout.json.pageStyle;
		$scope.metaIdentifier = ContentBuilderHelper.registerInstance($scope.content.name, $scope.content.layout.json);
		$scope.elementIdentifier = ContentBuilderHelper.registerElement($scope.metaIdentifier);

		_ensureBackwardCompatibility();

		ContentBuilderHelper.subscribeEditModeToggled($scope, function (e, args) {
			if (args.metaIdentifier === $scope.metaIdentifier || args.metaIdentifier === 'GLOBAL') {
				$scope.loading = args.preSave;
				$scope.edit = args.edit;
				$scope.dragOverTarget = undefined;
			}
		});
		ContentBuilderHelper.subscribeSelected($scope, function (e, args) {
			if (args.metaIdentifier === $scope.metaIdentifier || args.metaIdentifier === 'GLOBAL') {
				if (
					ContentBuilderHelper.selectedElement(args.metaIdentifier).elementIdentifier ===
					$scope.elementIdentifier
				) {
					$scope.selected = true;

					for (var i = 0; i < $scope.contentRoot.length; i++) {
						$scope.contentRoot[i].selected = false;
					}
				} else {
					$scope.selected = false;

					var selectedId = ContentBuilderHelper.selectedElement(args.metaIdentifier).elementIdentifier;
					// Check rows also
					for (var i = 0; i < $scope.contentRoot.length; i++) {
						if ($scope.contentRoot[i].identifier === selectedId) {
							$scope.contentRoot[i].selected = true;
						} else {
							$scope.contentRoot[i].selected = false;
						}
					}
				}
			}
		});
		ContentBuilderHelper.subscribeSave($scope, function (e, args) {
			if (args.identifier === $scope.metaIdentifier || args.identifier === 'GLOBAL') {
				$scope.save();
			}
		});
		ContentBuilderHelper.subscribeSizeChanged($scope, function (e, args) {
			$scope.config.width = ContentBuilderHelper.getViewportSetupId();
		});
	};

	$scope.$watch(
		'layoutObj',
		function (newValue, oldValue) {
			if ($scope.buffering) {
				return;
			}

			if (newValue !== undefined) {
				if (newValue.stamp !== $scope.count) {
					if ($scope.count === Number.MAX_VALUE) {
						$scope.count = 0;
					}
					newValue.stamp = ++$scope.count;
					if ($scope.content) {
						$scope.buName = $scope.content.name;
					}

					if (!$scope.firstLoading) {
						$scope.buffering = true;
						$scope.layoutObj = oldValue;
						$scope.bufferObj = angular.toJson(newValue);
						ContentBuilderHelper.toggleEditGlobal(false, true);
						$timeout(function () {
							ContentBuilderHelper.saveGlobal();
							$timeout(function () {
								$scope.layoutObj = JSON.parse($scope.bufferObj);
								$scope.buffering = false;
								init();
								ContentBuilderHelper.toggleEditGlobal(true);
							}, 100);
						}, 1000);
					} else {
						$scope.firstLoading = false;
						if (!$scope.content) {
							$scope.content = {};
						}
						$scope.content.layout = newValue;
					}
				}
			}
		},
		true
	);

	$scope.$watch('content', function (newValue, oldValue) {
		if (newValue != undefined) {
			$scope.buName = $scope.content.name;
			init();
		}
	});

	var _ensureBackwardCompatibility = function () {
		var root = $scope.content.layout.json.root;

		// Fix up row properties
		for (var i = 0; i < root.length; i++) {
			if (root[i].style === undefined) {
				root[i].style = {};
			}
			if (root[i].identifier === undefined) {
				root[i].identifier = ContentBuilderHelper.registerElement($scope.metaIdentifier);

				if (root[i].responsive === undefined) {
					root[i].responsive = {};
				}
				for (var att in ContentBuilderHelper.VIEWPORT_SIZE) {
					if (root[i].responsive[att] === undefined) {
						root[i].responsive[att] = 'FILL';
					}
				}
			}
		}
	};

	$scope.$on('showTemplateSelectionModalDown', function () {
		$scope.$emit('showTemplateSelectionModalUp', { metaIdentifier: $scope.metaIdentifier });
	});

	$scope.$on('showTemplateSaveModalDown', function () {
		$scope.$emit('showTemplateSaveModalUp', { metaIdentifier: $scope.metaIdentifier });
	});

	$scope.selectPage = function () {
		ContentBuilderHelper.callOutSelected(
			$scope.metaIdentifier,
			$scope.elementIdentifier,
			ELEMENT_TYPE.PAGE,
			undefined,
			{ style: $scope.pageStyle }
		);
	};

	$scope.selectRow = function (event, index) {
		event.stopPropagation();
		ContentBuilderHelper.callOutSelected(
			$scope.metaIdentifier,
			$scope.contentRoot[index].identifier,
			ELEMENT_TYPE.ROW,
			undefined,
			$scope.contentRoot[index]
		);
	};

	$scope.deleteRow = function (index, conserveLayout) {
		ContentBuilderHelper.deleteElement(
			$scope.metaIdentifier,
			{ identifier: $scope.metaIdentifier, children: $scope.contentRoot },
			index,
			conserveLayout,
			undefined,
			$scope.responsive
		);
	};

	$scope.deleteCell = function (row, index, conserveLayout) {
		ContentBuilderHelper.deleteElement(
			$scope.metaIdentifier,
			row,
			index,
			conserveLayout,
			{ identifier: $scope.metaIdentifier, children: $scope.contentRoot },
			undefined,
			$scope.responsive
		);
	};

	$scope.locked = function () {
		if (
			!$scope.contentRoot ||
			($scope.contentRoot.length > 0 &&
				$scope.contentRoot.children[$scope.contentRoot.length - 1].children.length > 0 &&
				$scope.contentRoot[$scope.contentRoot.length - 1].children[0].type === 'blank')
		) {
			return true;
		}
		return false;
	};

	$scope.addRow = function () {
		if (!$scope.locked()) {
			ContentBuilderHelper.addRow($scope.metaIdentifier);
		}
	};

	$scope.addCell = function (row) {
		ContentBuilderHelper.addCell(row, $scope.metaIdentifier, $scope.responsive);
	};

	$scope.decreaseSize = function (index, cell, row) {
		ContentBuilderHelper.decreaseSize(index, cell, row, $scope.metaIdentifier, $scope.responsive);
	};

	$scope.increaseSize = function (index, cell, row) {
		ContentBuilderHelper.increaseSize(index, cell, row, $scope.metaIdentifier, $scope.responsive);
	};

	// $scope.switchMode = function () {
	//     ContentBuilderHelper.toggleEditMode($scope.metaIdentifier, !$scope.edit);
	// };

	$scope.onBlurNameInput = function () {
		if ($scope.content.name === undefined || $scope.content.name === null || $scope.content.name === '') {
			$scope.content.name = $scope.buName;
		} else {
			$scope.buName = $scope.content.name;
		}
	};

	$scope.cleanup = function () {
		ContentBuilderHelper.deregisterInstance($scope.identifier);
		delete $scope.contentRoot;
		delete $scope.content;
	};

	$scope.finish = function () {
		$scope.contentAnchor = angular.element(document.querySelector('#layout-content-' + $scope.metaIdentifier));
		if ($scope.contentAnchor[0] === undefined) {
			return;
		}

		var html = $scope.contentAnchor[0].innerHTML;
		html = html.replace(/\/\/\-\-bypass-admin/g, '');
		// ':' is added to skip incorrect replace in css paddi'ng-'bottom
		html = html.replace(/(<!--(.*?)-->|ng-([^=\s:]*?)="(.*?)")/g, '');
		html = html.replace(/<ng-((.|\n)*?)\/ng(.*)/g, '');
		html = html.replace(
			/(ng-scope|ng-isolate-scope|ng-binding|ng-invalid|ng-valid|ng-pristine|ng-dirty|ng-touched|ng-untouched)/g,
			''
		);
		html = html.replace(/class=" *"/g, '');
		html = html.replace(/\n\s*\n/g, '\n');
		html = html.replace(/(  +)/g, ' ');
		//html = html.replace(/(class="([A-z]|-)+)/g, "");
		//html = html.replace(/(class=")/g, "");
		html = html.replace(
			/(<\/?)(content-cell|content-row|text-snippet-content|image-snippet-content|([a-z]|-)+-snippet)/g,
			'$1div'
		);
		// fix double html encode
		html = html.replace(/&amp;amp;/g, '&amp;');
		// remove [role="button"] attribute to fix cursor pointer in front-end
		html = html.replace(/\srole="button"\s/g, '');

		return html;
	};

	$scope.finalizeLayout = function () {
		$scope.content.layout.html = $scope.finish();
		$scope.content.layout.json = { root: $scope.contentRoot, pageStyle: $scope.pageStyle };
	};

	$scope.save = function () {
		$scope.finalizeLayout();
	};

	$scope.back = function () {};

	$scope.saveAndBack = function () {};

	$scope.isDropValid = function (event, data) {
		ContentBuilderHelper.clearDragIndicators();
		return data === ELEMENT_TYPE.CELL || data === ELEMENT_TYPE.ROW || data === ELEMENT_TYPE.LAYOUT;
	};

	// new drop system, based on dragged element type show allowed drop areas
	$scope.visibleDropAreas = [];
	$rootScope.$on('ContentBuilderPartDragStart', function (event, elementType) {
		if (elementType === ELEMENT_TYPE.LAYOUT || elementType === ELEMENT_TYPE.ROW) {
			$scope.visibleDropAreas.push('drop-area--page');
		}
		if (
			elementType === ELEMENT_TYPE.PART ||
			elementType === ELEMENT_TYPE.SNIPPET ||
			elementType === ELEMENT_TYPE.LAYOUT
		) {
			$scope.visibleDropAreas.push('drop-area--cell');
		}
	});
	// new drop system, hide all drop areasareas
	$rootScope.$on('ContentBuilderPartDragEnd', function (event, elementType) {
		$scope.visibleDropAreas = [];
	});
	/*$scope.onDragOverCell = function (event, data, hem, cellIndex, rowIndex) {
        switch (data) {
            case ELEMENT_TYPE.CELL: {
                hem = hem.indexOf('left') >= 0 ? 'before' : 'after';
                if ($scope.contentRoot[rowIndex][cellIndex].type === 'dummy') {
                    hem = 'before';
                }
                ContentBuilderHelper.callOutDragOver($scope.metaIdentifier, hem, cellIndex, $scope.contentRoot[rowIndex].children);
                break;
            }
            case ELEMENT_TYPE.ROW: {
                hem = hem.indexOf('top') >= 0 ? 'before' : 'after';
                ContentBuilderHelper.callOutDragOver($scope.metaIdentifier, hem, rowIndex, $scope.contentRoot);
                break;
            }
        }
    };*/

	/*    $scope.onDropCell = function (event, data, hem, cellIndex, rowIndex) {
        console.log("dunno");
        switch (data) {
            case ELEMENT_TYPE.CELL: {
                hem = hem.indexOf('left') >= 0 ? 'before' : 'after';
                if ($scope.contentRoot[rowIndex][cellIndex].type === 'dummy') {
                    hem = 'before';
                }
                ContentBuilderHelper.callOutDrop($scope.metaIdentifier, hem, cellIndex, $scope.contentRoot[rowIndex], $scope.root, $scope.responsive, ELEMENT_TYPE.ROW);
                break;
            }
            case ELEMENT_TYPE.ROW: {
                hem = hem.indexOf('top') >= 0 ? 'before' : 'after';
                ContentBuilderHelper.callOutDrop($scope.metaIdentifier, hem, rowIndex,{ identifier: $scope.metaIdentifier, children: $scope.contentRoot }, undefined, $scope.responsive);
                break;
            }
        }
    };*/

	// $scope.onDragOverPage = function (event, data) {
	//     console.log("onDragOverPage");
	//     switch (data) {
	//         case ELEMENT_TYPE.ROW: {
	//             ContentBuilderHelper.callOutDragOver($scope.metaIdentifier, data + ' ' + 'insert', 0, [$scope.dragOverDummy]);
	//             break;
	//         }
	//         case ELEMENT_TYPE.CELL: {
	//             //ContentBuilderHelper.callOutDragOver($scope.metaIdentifier, data + ' ' + 'insert', 0, [$scope.dragOverDummy]);
	//             break;
	//         }
	//     }
	// };

	$scope.onDropPage = function (event, data, index) {
		switch (data) {
			case ELEMENT_TYPE.LAYOUT:
			case ELEMENT_TYPE.ROW: {
				ContentBuilderHelper.callOutDrop(
					$scope.metaIdentifier,
					'after',
					index - 1,
					{ identifier: $scope.metaIdentifier, children: $scope.contentRoot },
					undefined,
					$scope.responsive
				);
				// ContentBuilderHelper.callOutDrop($scope.metaIdentifier, 'after', $scope.contentRoot.length -1, { identifier: $scope.metaIdentifier, children: $scope.contentRoot }, undefined, $scope.responsive);
				break;
			}
			case ELEMENT_TYPE.CELL: {
				break;
			}
		}
	};

	// $scope.onDropOutOfBounds = function () {

	// };

	// $scope.onDragStartRow = function (event, data, rowIndex) {
	//     ContentBuilderHelper.callOutDragStart($scope.metaIdentifier, rowIndex, $scope.contentRoot);
	// };

	$scope.preloadTiny = function () {
		if (tinymce) {
			$scope.dummyTiny = { c: '' };
			$scope.dummyTiny.tinymceOptions = {
				theme: 'modern',
				convert_urls: false,
				relative_urls: false,
				remove_script_host: false,
				plugins: [
					'link advlist lists image charmap print preview hr anchor pagebreak',
					'searchreplace visualblocks visualchars code',
					'insertdatetime media nonbreaking save table contextmenu directionality',
					'template paste textcolor colorpicker textpattern imagetools',
					'fullpage moodiaimage shortcodes',
				],
				menubar: 'insert | tools | format ',
				toolbar1:
					'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright | bullist numlist | link moodiaimage',
				toolbar2: 'media | forecolor backcolor | fontsizeselect | fontselect | code | shortcodes',
				image_advtab: true,
			};
		}
	};
	$scope.preloadTiny();

	var FAKE_MEDIA_QUERIES = Object.keys(VIEWPORT_SIZE)
		.sort(function (a, b) {
			return VIEWPORT_SIZE[a] - VIEWPORT_SIZE[b];
		})
		.map(function (m) {
			return m.toLowerCase();
		});

	$scope.fakeMediaQuery = function () {
		var viewportSize = $scope.config.width.toLowerCase();
		var query = [];
		for (var i = 0; i < FAKE_MEDIA_QUERIES.length && FAKE_MEDIA_QUERIES[i] !== viewportSize; ++i) {
			query.push('mq-' + FAKE_MEDIA_QUERIES[i]);
		}
		query.push('mq-' + viewportSize);
		return query.join(' ');
	};
	$scope.getRowClasses = function (row) {
		var rowClasses = row.classes.slice();
		if (row.container === 'fixed') {
			rowClasses.push('holder');
		}
		if (row.customClasses) {
			rowClasses.push(row.customClasses);
		}

		return rowClasses;
	};
}
;
