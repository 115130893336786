define('directives/contentConfigurator/contentSnippets/sectionSnippet/directives/grid/grid',['app', 'settings'], function (app) {
	'use strict';
	app.directive('ngGrid', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					inputData: '<data',
					stateEditing: '<',
				},
				templateUrl:
					BASE_DIRECTIVES_PATH +
					'/contentConfigurator/contentSnippets/sectionSnippet/directives/grid/views/index.html',
				controller: [
					'$scope',
					'$timeout',
					'$element',
					function ($scope, $timeout, $element) {
						var path =
							BASE_DIRECTIVES_PATH +
							'/contentConfigurator/contentSnippets/sectionSnippet/directives/grid/views/';

						$scope.customStyle = path + 'style.css';
						$scope.customStyleFake = path + 'styleFake.css';
						$scope.customJs = path + 'js.html';
						$scope.includeVisibility = true;
						$scope.itemsLimit = 9;

						var wrapper = $($element).find('[data-content-block="grid"]');

						function _init() {
							_apply();
						}

						_init();

						function _apply() {
							$scope.data = angular.merge({}, $scope.inputData);
							_refresh();
						}
						function _refresh() {
							try {
								wrapper.grid('destroy');
							} catch (e) {}

							$scope.includeVisibility = false;
							$timeout(function () {
								$scope.$apply();
								$scope.includeVisibility = true;
							}, 100);
						}

						$scope.$watch(
							'inputData',
							function (newValue, oldValue) {
								if (newValue !== oldValue) {
									_apply();
								}
							},
							true
						);
						$scope.$watch(
							'stateEditing',
							function (newValue, oldValue) {
								if (newValue !== oldValue) {
									_apply();
								}
							},
							true
						);
					},
				],
			};
		},
	]);
});

