define('services/I18nService',['app', 'settings', 'modules/module'], function (module) {
	'use strict';
	return module.factory('I18nService', [
		'$rootScope',
		'$http',
		'$window',
		function ($rootScope, $http, $window) {
			var i18n = {
				language: $window.navigator.userLanguage || $window.navigator.language,

				dictionary: {},

				resourceFileLoaded: false,

				successCallback: function (data) {
					i18n.dictionary = data;
					i18n.resourceFileLoaded = true;
					$rootScope.$broadcast('i18nResourcesUpdated');
				},

				initI18nResources: function () {
					$http({
						method: 'GET',
						cache: false,
						url: '/api/v1//i18n',
						params: {
							languageCode: i18n.language,
						},
					})
						.success(i18n.successCallback)
						.error(function () {});
				},

				getTranslatedString: function (value) {
					var result = '';

					if (!i18n.resourceFileLoaded) {
						i18n.initI18nResources();
						i18n.resourceFileLoaded = true;
						return result;
					}

					if (i18n.dictionary) {
						result = i18n.dictionary[value];
					}
					return result;
				},
			};

			return i18n;
		},
	]);
});

