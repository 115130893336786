define('services/EconAutomationService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('EconAutomationService', ['$injector', EconAutomationService]);
});

function EconAutomationService($injector) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('Automation');

	angular.extend(this, crudInstance);

	return this;
}
;
