define('services/StateFaderService',['modules/module'], function (module) {
	return module.factory('StateFader', [
		'$state',
		function ($state) {
			return {
				go: function (nextState, id) {
					if ($state.current.name == nextState && $state.params.id == id) return;

					$state.go(nextState, { id: id });
				},
				goWithObject: function (nextState, obj) {
					if ($state.current.name == nextState && $state.params.data == obj) return;
					$state.go(nextState, obj);
				},
			};
		},
	]);
});

