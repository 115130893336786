define('modules/wawi/product/controllers/tabs/TagsTabController',[], function () {
	'use strict';

	return ($parentScope, $scope, { BASE_TEMPLATES_PATH, TagUnpagedService }) => {
		$parentScope.formTabs.push({
			label: 'FIELDS.TAGS',
			template: BASE_TEMPLATES_PATH + '/wawi/product/form-tags.html',
		});

		$scope.afterInit = () => {
			if ($parentScope.entity.tagIds == undefined) {
				$parentScope.entity.tagIds = [];
			}
		};

		$scope.init = () => {
			TagUnpagedService.findAll().then(function () {
				$scope.tags = TagUnpagedService.getAll(true).data;
			});
			$scope.tagsTableOptions = {};
			$scope.tagsTableOptions.name = 'FIELDS.ADD_ON_PRODUCT';
			$scope.tagsTableOptions.field = {
				id: 'NAME',
				heading: 'FIELDS.NAME',
				attribute: 'name',
			};
		};
	};
});

