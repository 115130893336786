define('modules/settings/shop/tabs/CopyProductsTabController',[], function () {
	'use strict';

	return function CopyProductsTabController(
		$parentScope,
		$rootScope,
		$scope,
		{
			BASE_TEMPLATES_PATH,
			CopyProductsService,
			ProductService,
			LocalStorageService,
			ValidationService,
			NotificationService,
			MetadataUnpagedService,
			AttributeUnpagedService,
			CategoryService,
			PRODUCT_FILTER_OPTIONS,
		}
	) {
		$parentScope.selected.copyProductsCurrentPage = 0;
		$parentScope.selected.copyProductsPageSize = 10;
		$parentScope.selected.copyProductsTotalItems = 0;

		$parentScope.adminFormTabs.push({
			label: 'FIELDS.COPY_PRODUCTS',
			template: BASE_TEMPLATES_PATH + '/settings/shop/form-copy-products.html',
		});

		$parentScope.$on('filtersChanges', function (e, filters, sorting, id) {
			if (id.substr(0, 7) === 'Product') {
				var params = [];
				for (var obj in filters) {
					params.push({
						name: 'filter[' + obj + ']',
						value: filters[obj],
					});
				}
				params.push({
					name: 'filter[order]',
					value: sorting,
				});
				$scope.copyProductsEntityService.setAdditionalParams(params);

				$scope.copyProductsFilterProducts(false);
			}
		});

		$scope.copyProductsEntityName = 'FIELDS.PRODUCT';
		$scope.copyProductsSingle = 'FIELDS.PRODUCT';
		$scope.copyModes = [
			{ name: 'Kopieren', value: 'COPIED' },
			{ name: 'Synchronisieren', value: 'SYNCHRONIZED' },
		];
		$scope.copyProductsTableOptions = {};

		$scope.copyProductsTableOptions.fields = [
			{
				id: 'SORT_ORDER',
				heading: 'FIELDS.SORTING',
				attribute: 'sortOrder',
				template: BASE_TEMPLATES_PATH + '/wawi/product/col/sort-order-col.html',
				orderDisabled: true,
			},
			{
				id: 'THUMB_IMG',
				heading: 'FIELDS.IMAGE',
				template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-thumb-col.html',
				searchDisabled: true,
				orderDisabled: true,
			},
			{
				id: 'STATUS',
				heading: 'FIELDS.STATUS',
				attribute: 'publishState',
				searchDisabled: true,
			},
			{
				id: 'PRODUCT_TYPE',
				heading: 'FIELDS.PRODUCT_TYPE',
				attribute: 'productType',
				searchDisabled: true,
				orderDisabled: true,
			},
			{
				id: 'NAME',
				heading: 'FIELDS.NAME',
				template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-name-col.html',
				attribute: 'name',
			},
			{
				id: 'SKU',
				heading: 'FIELDS.SKU',
				attribute: 'sku',
			},
			{
				id: 'SLUG',
				heading: 'FIELDS.SLUG',
				attribute: 'slug',
			},
			{
				id: 'CATEGORY',
				heading: 'FIELDS.CATEGORIES',
				template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-category-col.html',
				attribute: 'category',
			},
			{
				id: 'ORIGIN',
				heading: 'FIELDS.IS_ORIGIN',
				attribute: 'origin',
			},
			{
				id: 'COPY_MODE',
				heading: 'FIELDS.COPY_MODE',
				attribute: 'copyMode',
			},
			{
				id: 'SYNC_FIELDS',
				heading: 'FIELDS.SYNC_FIELDS',
				template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-sync-field-col.html',
				attribute: 'syncFields',
				searchDisabled: true,
				orderDisabled: true,
			},
		];

		$scope.copyProductsTableOptions.optionalFields = [
			{
				id: 'DESCRIPTION',
				heading: 'FIELDS.LONG_DESCRIPTION',
				attribute: 'description',
				searchDisabled: true,
				orderDisabled: true,
			},
		];

		$scope.copyProductsSelected = {
			items: [],
			allProducts: true,
		};

		$scope.copyProductsEntityService = ProductService;

		$scope.copyProductsSetPageSize = function () {
			$scope.copyProductsEntityService.setPageSize($parentScope.selected.copyProductsPageSize, false);
			$scope.copyProductsFilterProducts(true);
		};

		$scope.copyProductsSetPage = function (currentPage) {
			if (currentPage) {
				$parentScope.selected.copyProductsCurrentPage = currentPage;
			}
			$scope.copyProductsEntityService.setCurrentPage($scope.copyProductsGetRealCurrentPage(), false);
			$scope.copyProductsFilterProducts(false);
		};

		$scope.copyProductsAddFilter = function () {
			if ($scope.copyProductsFilters === undefined) {
				$scope.copyProductsFilters = [];
			}
			$scope.copyProductsFilters.push({});
		};

		$scope.copyProductsShowCurrentlyDisplayed = function () {
			var from =
				parseInt($parentScope.selected.copyProductsPageSize) *
				parseInt($scope.copyProductsGetRealCurrentPage());
			from = from + 1;

			if ($scope.copyProductsItems === undefined || $scope.copyProductsItems.length === 0) {
				from = 0;
			}

			var to =
				from + parseInt($parentScope.selected.copyProductsPageSize) <
				$parentScope.selected.copyProductsTotalItems
					? from + parseInt($parentScope.selected.copyProductsPageSize) - 1
					: $parentScope.selected.copyProductsTotalItems;

			return from + ' - ' + to;
		};

		$scope.copyProductsGetRealCurrentPage = function () {
			var cur = angular.copy(parseInt($parentScope.selected.copyProductsCurrentPage));
			cur = cur === 0 ? cur : cur - 1;
			return cur;
		};

		$scope.copyProductsRemoveFilter = function (index) {
			$scope.copyProductsFilters.splice(index, 1);
		};

		$scope.copyProductsFilterProducts = function (reset) {
			if (reset) {
				LocalStorageService.write('ProductCurrentPage', 0);
			}
			var filters = [];
			for (var i = 0; i < $scope.copyProductsFilters.length; i++) {
				if (
					$scope.copyProductsFilters[i].filterType != undefined &&
					$scope.copyProductsFilters[i].filterType != '' &&
					$scope.copyProductsFilters[i].filterCond != undefined &&
					$scope.copyProductsFilters[i].filterCond != '' &&
					$scope.copyProductsFilters[i].filterValue != undefined &&
					$scope.copyProductsFilters[i].filterValue != ''
				) {
					filters.push($scope.copyProductsFilters[i]);
				}
			}

			if (!$scope.copyProductsData.filterIsActive) {
				filters = [];
			}

			const additionalParams = $scope.copyProductsEntityService.getAdditionalParams();
			additionalParams.push({ name: 'filter[shopId]', value: $scope.copyProducts.sourceShopId });

			$scope.copyProductsEntityService.filterForBulkUpdate(filters, additionalParams).then(function () {
				$scope.copyProductsItems = $scope.copyProductsEntityService.getBulkUpdateProducts().data;

				$parentScope.selected.copyProductsTotalItems = $scope.copyProductsEntityService.getBulkUpdateProducts().count;
				$parentScope.selected.copyProductsTotalPages = $scope.copyProductsEntityService.getBulkUpdateProducts().pages;

				if ($parentScope.selected.copyProductsTotalPages === 0) {
					//If there are no items the total pages size should be displayed as 1
					$parentScope.selected.copyProductsTotalPages = 1;
				}

				$parentScope.selected.copyProductsCurrentPage =
					$scope.copyProductsEntityService.getBulkUpdateProducts().page + 1;
			});
		};

		$scope.copyProductsEntityService.setQuery({}, false);

		if ($scope.copyProductsEntityService.getPageSize() == null) {
			$parentScope.selected.copyProductsPageSize = 10;
			$scope.copyProductsSetPageSize();
		}

		$scope.init = () => {
			$scope.copyProducts = {
				mode: 'COPIED',
				copyFields: {
					copyPrices: true,
					copyLocales: true,
					copyImages: true,
					copyStock: true,
					copyCategories: true,
				},
				customerGroupId: null,
				sourceShopId: null,
				targetShopId: null,
			};

			// noinspection EqualityComparisonWithCoercionJS
			$scope.shopsExceptCurrent = $parentScope.shops.filter((s) => s.id != $parentScope.entityId);

			if ($scope.shopsExceptCurrent.length > 0) {
				$scope.copyProducts.sourceShopId = $scope.shopsExceptCurrent[0].id;
			}

			if ($parentScope.entity && $parentScope.entity.id) {
				$scope.copyProducts.targetShopId = $parentScope.entity.id;
				$scope.copyProducts.customerGroupId = $parentScope.entity.defaultCustomerGroupId;
			} else if ($parentScope.entityId) {
				// If entity wasn't loaded yet. TODO: wait for both entity and shops to load
				$scope.copyProducts.targetShopId = $parentScope.entityId;
			}

			$scope.copyProductsFilterProducts(true);
		};

		$scope.copyProductsSubmit = function () {
			var copyProductsDTO = { allProducts: $scope.copyProductsSelected.allProducts };

			copyProductsDTO.productIds = [];
			for (var i = 0; i < $scope.copyProductsSelected.items.length; i++) {
				copyProductsDTO.productIds.push($scope.copyProductsSelected.items[i].id);
			}

			copyProductsDTO.sourceShopId = $scope.copyProducts.sourceShopId;
			copyProductsDTO.targetShopId = $scope.copyProducts.targetShopId;

			copyProductsDTO.customerGroupId = $scope.copyProducts.customerGroupId;
			if ($scope.copyProducts.copyCategories === true) {
				copyProductsDTO.copyCategories = true;
			}
			copyProductsDTO.copyMode = $scope.copyProducts.mode;
			if ($scope.copyProducts.mode === 'SYNCHRONIZED') {
				copyProductsDTO.syncFields = [];
				if ($scope.copyProducts.syncPrices === true) {
					copyProductsDTO.syncFields.push('PRICES');
				}
				if ($scope.copyProducts.syncLocales === true) {
					copyProductsDTO.syncFields.push('LOCALES');
				}
				if ($scope.copyProducts.syncImages === true) {
					copyProductsDTO.syncFields.push('IMAGES');
				}
				if ($scope.copyProducts.syncStock === true) {
					copyProductsDTO.syncFields.push('STOCK');
				}
				if ($scope.copyProducts.syncCategories === true) {
					copyProductsDTO.syncFields.push('CATEGORIES');
				}
			}

			if ($scope.copyProducts.mode === 'SYNCHRONIZED' || $scope.copyProducts.mode === 'COPIED') {
				copyProductsDTO.copyFields = [];
				if ($scope.copyProducts.copyFields.copyPrices === true) {
					copyProductsDTO.copyFields.push('PRICES');
				}
				if ($scope.copyProducts.copyFields.copyLocales === true) {
					copyProductsDTO.copyFields.push('LOCALES');
				}
				if ($scope.copyProducts.copyFields.copyImages === true) {
					copyProductsDTO.copyFields.push('IMAGES');
				}
				if ($scope.copyProducts.copyFields.copyStock === true) {
					copyProductsDTO.copyFields.push('STOCK');
				}
				if ($scope.copyProducts.copyFields.copyCategories === true) {
					copyProductsDTO.copyFields.push('CATEGORIES');
				}
			}
			var validateConfig = {
				copyMode: {
					method: 'isset',
					fieldName: 'Modus',
				},
				productIds: {
					method: 'isset',
					fieldName: 'Produkte',
					errorMsg: 'Wählen Sie mindestens ein Produkt.',
					customValidate: function () {
						return (
							($scope.copyProductsSelected.items !== undefined &&
								$scope.copyProductsSelected.items.length > 0) ||
							$scope.copyProductsSelected.allProducts
						);
					},
				},
			};

			if (!ValidationService.validate(copyProductsDTO, validateConfig)) {
				NotificationService.notifyValidationErrors(ValidationService.getErrors());
				return;
			}

			CopyProductsService.copyProducts(copyProductsDTO).then(function (response) {
				if (response.error === undefined && response.errorCode === undefined && response.ok) {
					NotificationService.notifySuccess({ statusText: 'Produkte wurden zum Kopieren eingereicht' });
					initCopyProducts();
					for (var i = 0; i < $scope.copyProductsSelected.items.length; i++) {
						$scope.copyProductsSelected.items[i].selected = false;
					}
					$scope.copyProductsSelected.items = [];
					$scope.copyProductsFilterProducts(false);
				}
			});
		};

		$scope.copyProductsCancelSync = function () {
			var copyProductsDTO = {};

			copyProductsDTO.productIds = [];
			for (var i = 0; i < $scope.copyProductsSelected.items.length; i++) {
				copyProductsDTO.productIds.push($scope.copyProductsSelected.items[i].id);
			}

			CopyProductsService.copyProductsCancelSync(copyProductsDTO).then(function (response) {
				if (response.error === undefined && response.errorCode === undefined && response.ok) {
					NotificationService.notifySuccess({ statusText: 'Die Synchronisation wurde abgebrochen.' });
					$scope.initCopyProducts();
					for (var i = 0; i < $scope.copyProductsSelected.items.length; i++) {
						$scope.copyProductsSelected.items[i].selected = false;
					}
					$scope.copyProductsSelected.items = [];
					$scope.copyProductsFilterProducts(false);
				}
			});
		};

		$scope.copyProductsData = { filter: false, filterIsActive: true };

		$scope.copyProductsSearchTerm = '';

		$scope.copyProductsFilters = $scope.copyProductsEntityService.getFilters();

		$scope.copyProductsAvailableFilterOptions = PRODUCT_FILTER_OPTIONS;

		MetadataUnpagedService.findAll().then(function () {
			$scope.copyProductsMetadatas = MetadataUnpagedService.getAll(true).data;
		});

		AttributeUnpagedService.findAll().then(function () {
			$scope.copyProductsAttributes = AttributeUnpagedService.getAll(true).data;
		});

		CategoryService.findAll().then(function () {
			$scope.copyProductsCategories = CategoryService.getAll().data;
			$scope.copyProductsSuggestCategories = CategoryService.getAll().data;
			$rootScope.$broadcast('$suggestSearchList', $scope.copyProductsSuggestCategories, 'category');
		});

		$scope.copyProductsGetSelectOptions = function (type) {
			for (var i = 0; i < $scope.copyProductsAvailableFilterOptions.length; i++) {
				if ($scope.copyProductsAvailableFilterOptions[i].id === type) {
					return $scope.copyProductsAvailableFilterOptions[i].types;
				}
			}
		};

		$scope.copyProductsGetMetadataById = function (metadataNameId) {
			if (metadataNameId != undefined) {
				for (var i = 0; i < $scope.copyProductsMetadatas.length; i++) {
					if (metadataNameId == $scope.copyProductsMetadatas[i].id) {
						return $scope.copyProductsMetadatas[i];
					}
				}
			}
		};

		$scope.copyProductsGetAttributeById = function (attributeNameId) {
			if (attributeNameId != undefined) {
				for (var i = 0; i < $scope.copyProductsAttributes.length; i++) {
					if (attributeNameId == $scope.copyProductsAttributes[i].id) {
						return $scope.copyProductsAttributes[i];
					}
				}
			}
		};

		$scope.copyProductsActivateFilters = function () {
			if ($scope.copyProductsData.filterIsActive) {
				if ($scope.copyProductsFilters.length > 0) {
					$scope.copyProductsFilterProducts(false, true);
				}
			}
		};

		$scope.copyProductsSetFilter = function () {
			$scope.copyProductsData.filter = !$scope.copyProductsData.filter;
		};

		$scope.copyProductsOnSearchChange = function ($event) {
			$event.stopPropagation();
		};

		$scope.onCopyProductsSourceShopIdChange = () => {
			$scope.copyProductsFilterProducts(true);
		};

		const deregisterCopyProductsFilters = $parentScope.$watch(
			'copyProductsFilters',
			function (newVal, oldVal) {
				if (newVal === undefined) {
					return;
				}

				for (var i = 0; i < $scope.copyProductsFilters.length; i++) {
					if (oldVal[i] != undefined) {
						if (newVal[i].filterValue !== oldVal[i].filterValue) {
							$scope.copyProductsFilters[i].filterOptions = [];
						}
					}
				}

				$scope.copyProductsEntityService.setFilters($scope.copyProductsFilters);
			},
			true
		);

		$parentScope.$on('destroy', () => {
			deregisterCopyProductsFilters();
		});
	};
});

