define('modules/econ/controllers/EconMailtemplateController',['modules/module', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('EconMailtemplateController', ['$scope', '$controller', MailtemplateController]);
});

function MailtemplateController($scope, $controller) {
	angular.extend(
		this,
		$controller('EntityListController', {
			$scope: $scope,
		})
	);

	$scope.formState = 'root.econMailtemplate';

	$scope.tableOptions.fields = [
		{
			id: 'NAME',
			heading: 'FIELDS.NAME',
			attribute: 'name',
		},
		{
			id: 'SENDER',
			heading: 'FIELDS.SENDER',
			attribute: 'sender',
		},
		{
			id: 'SUBJECT',
			heading: 'FIELDS.SUBJECT',
			attribute: 'subject',
		},
	];

	$scope.init('Mailtemplate');
}
;
