/**
 * Created by nmayer on 13.06.16.
 */
define('modules/settings/currency/CurrencyController',['modules/module', 'settings', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('CurrencyController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		function ($scope, $controller, BASE_TEMPLATES_PATH) {
			angular.extend(
				this,
				$controller('EntityListController', {
					$scope: $scope,
				})
			);

			$scope.state.requireSuperuserForDelete = true;

			$scope.tableOptions.fields = [
				{
					id: 'NAME',
					heading: 'FIELDS.NAME',
					attribute: 'name',
				},
				{
					id: 'ISO_CODE',
					heading: 'FIELDS.ISO_CODE',
					attribute: 'currencyIso',
				},
				{
					id: 'FAKTOR',
					heading: 'FIELDS.FACTOR',
					attribute: 'factor',
				},
				{
					id: 'UNICODE_SYMBOL',
					heading: 'FIELDS.UNICODE_SYMBOL',
					attribute: 'unicodeSymbol',
				},
			];
			$scope.formState = 'root.currency';

			$scope.init('Currency');
		},
	]);
});

