define('directives/customOnChange/customOnChange',['app'], function (app) {
	'use strict';
	app.directive('customOnChange', function () {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				var onChangeFunc = scope.$eval(attrs.customOnChange);
				element.bind('change', onChangeFunc);
			},
		};
	});
});

