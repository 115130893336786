define('services/LocalStorageService',['app', 'modules/module', 'angular-local-storage'], function (app, module) {
	'use strict';
	return module.factory('LocalStorageService', [
		'localStorageService',
		function (localStorageService) {
			//TODO: configurate local storage?
			//			var now = new Date();
			//			var expireDate = new Date(now.getTime() + 300 * 60000);

			return {
				write: function (key, value) {
					localStorageService.set(key, value);
				},
				read: function (key) {
					return localStorageService.get(key);
				},
				remove: function (key) {
					localStorageService.remove(key);
				},
			};
		},
	]);
});

