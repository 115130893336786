define('filters/TrustedHtml',['app'], function (app) {
	'use strict';
	app.filter('trustedHtml', [
		'$sce',
		function ($sce) {
			return function (htmlCode) {
				return $sce.trustAsHtml(htmlCode);
			};
		},
	]);
});

