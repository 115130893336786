define('directives/contentConfigurator/contentSnippets/sliderSnippet/SliderSnippetConfig',[
	'app',
	'angular',
	'services/ContentBuilderHelper',
	'directives/contentConfigurator/contentSnippets/_snippetConfig',
], function (app) {
	'use strict';
	app.directive('sliderSnippetConfig', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				restrict: 'E',
				scope: {
					metaIdentifier: '<',
					elementIdentifier: '<',
					element: '=',
				},
				templateUrl:
					BASE_DIRECTIVES_PATH +
					'/contentConfigurator/contentSnippets/sliderSnippet/views/sliderSnippetConfig.html',
				controller: [
					'$scope',
					'$controller',
					'$rootScope',
					'CategoryService',
					function ($scope, $controller, $rootScope, CategoryService) {
						$scope.snippet = {
							name: 'sliderSnippet',
						};

						// debugger;
						angular.extend(
							this,
							$controller('snippetConfigController', {
								$scope: $scope,
							})
						);

						$scope.options.name = 'Slider';

						var _init = function () {
							CategoryService.getCategories('PRODUCT', 'ACTIVE').then(function (response) {
								$scope.categories = response.data;
							});
						};
						_init();

						$scope.$watch('categorySlug', function (newValue, oldValue) {
							if (newValue === undefined) {
								return;
							}

							$rootScope.$broadcast('sliderSnippetConfigChange', {
								id: $scope.elementIdentifier,
								categorySlug: newValue,
							});
						});
					},
				],
			};
		},
	]);
});

