/**
 * Created by nmayer on 09.06.16.
 */
define('modules/settings/branch/BranchListController',['modules/module', 'settings', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('BranchListController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		function ($scope, $controller, BASE_TEMPLATES_PATH) {
			angular.extend(
				this,
				$controller('EntityListController', {
					$scope: $scope,
				})
			);

			$scope.state.requireSuperuserForDelete = true;

			$scope.tableOptions.fields = [
				{
					id: 'NAME',
					heading: 'FIELDS.NAME',
					attribute: 'name',
				},
			];
			$scope.formState = 'root.branch';

			$scope.init('Industry');
		},
	]);
});

