define('filters/GenderFilter',['app'], function (app) {
	'use strict';
	app.filter('genderFilter', function () {
		return function (gender) {
			if (gender == 'MALE') {
				gender = 'Herr';
			} else if (gender == 'FEMALE') {
				gender = 'Frau';
			} else if (gender == 'COMPANY') {
				gender = 'Firma';
			} else if (gender == 'FAMILY') {
				gender = 'Familie';
			}

			return gender;
		};
	});
});

