define('services/DebounceService',['app', 'modules/module'], function (app, module) {
	'use strict';
	return module.factory('DebounceService', [
		'$timeout',
		'$q',
		function ($timeout, $q) {
			return {
				debounce: function (func, wait, immediate) {
					if (wait == null) {
						wait = 500;
					}

					var timeout;
					var deferred = $q.defer();

					return function () {
						var context = this,
							args = arguments;
						var later = function () {
							timeout = null;
							if (!immediate) {
								deferred.resolve(func.apply(context, args));
								deferred = $q.defer();
							}
						};
						var callNow = immediate && !timeout;
						if (timeout) {
							$timeout.cancel(timeout);
						}
						timeout = $timeout(later, wait);
						if (callNow) {
							deferred.resolve(func.apply(context, args));
							deferred = $q.defer();
						}
						return deferred.promise;
					};
				},
			};
		},
	]);
});

