define('modules/wawi/product/controllers/tabs/PrintAreasTabController',[], function () {
	'use strict';

	return (
		$parentScope,
		$scope,
		{ BASE_TEMPLATES_PATH, ImageService, SessionValuesService, RefinementProductService, FileUploader }
	) => {
		$parentScope.formTabs.push({
			label: 'FIELDS.PRINTAREAS',
			template: BASE_TEMPLATES_PATH + '/wawi/product/form-refinement-products.html',
		});

		$scope.beforeSave = () => {
			if ($parentScope.entity.printAreas != undefined) {
				for (var i = 0; i < $parentScope.entity.printAreas.length; i++) {
					var printArea = $parentScope.entity.printAreas[i];
					delete printArea.uploader;
					for (
						var j = 0;
						printArea.selectedRefinementProductIds && j < printArea.selectedRefinementProductIds.length;
						j++
					) {
						if (!printArea.refinementProducts) {
							printArea.refinementProducts = [];
						}
						printArea.refinementProducts.push({ id: printArea.selectedRefinementProductIds[j] });
					}
				}
			}
		};

		$scope.initSelectedRefinementProducts = function () {
			if ($parentScope.entity.printAreas != undefined) {
				for (var i = 0; i < $parentScope.entity.printAreas.length; i++) {
					var printArea = $parentScope.entity.printAreas[i];
					if (!printArea.uploader) {
						printArea.uploader = ImageService.initFileUpload(
							new FileUploader({
								url: '/api/v1/media/images',
								headers: SessionValuesService.getFullSessionHeader(),
							})
						);
						printArea.uploader.removeAfterUpload = true;

						printArea.uploadViaInput = function (index) {
							angular.element('#file-input-' + index).trigger('click');
						};

						printArea.uploadImage = function (image, printArea) {
							$scope.printAreaImageUploadId = printArea.id;
							image.upload();
						};

						printArea.uploader.onSuccessItem = function (fileItem, response, status, headers) {
							for (var i = 0; i < $parentScope.entity.printAreas.length; i++) {
								var printArea = $parentScope.entity.printAreas[i];
								if ($scope.printAreaImageUploadId == printArea.id) {
									printArea.image = {};
									printArea.image.id = response.id;
									break;
								}
							}
						};
					}
					if (!printArea.selectedRefinementProductIds) {
						printArea.selectedRefinementProductIds = [];
						if (printArea.refinementProductLocales === undefined) {
							printArea.refinementProductLocales = [];
						}
						for (
							var j = 0;
							printArea.refinementProductLocales && j < printArea.refinementProductLocales.length;
							j++
						) {
							var productLocale = printArea.refinementProductLocales[j];
							printArea.selectedRefinementProductIds.push(productLocale.id);
						}
					}
				}
			}
		};

		$scope.refinementProductTableOptions = {};
		$scope.refinementProductTableOptions.fields = [
			{
				id: 'name',
				heading: 'FIELDS.NAME',
				attribute: 'name',
			},
			{
				id: 'id',
				heading: 'Id',
				attribute: 'id',
			},
		];
		$scope.refinementProductIds = [];
		$scope.refinementProductService = RefinementProductService;

		$scope.refinementProductService.setCurrentPage(0).then(function () {
			$parentScope.availableData.refinemenProducts = $scope.refinementProductService.getAll();
		});

		$scope.addPrintArea = function () {
			if ($parentScope.entity.printAreas == undefined) {
				$parentScope.entity.printAreas = [];
			}
			$parentScope.entity.printAreas.push({});
			$scope.initSelectedRefinementProducts();
		};

		$scope.removePrintArea = function (printArea) {
			for (var i = 0; i < $parentScope.entity.printAreas.length; i++) {
				var printAreaTmp = $parentScope.entity.printAreas[i];
				if (printAreaTmp.id == printArea.id) {
					$parentScope.entity.printAreas.splice(i, 1);
					break;
				}
			}
		};
	};
});

