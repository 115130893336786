define('directives/contentConfigurator/contentSnippets/imageSnippet/ImageSnippetConfig',[
	'app',
	'angular',
	'services/ContentBuilderHelper',
	'directives/contentConfigurator/contentSnippets/_snippetConfig',
], function (app) {
	'use strict';
	app.directive('imageSnippetConfig', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				restrict: 'E',
				scope: {
					metaIdentifier: '<',
					elementIdentifier: '<',
					// snippet: "=",
					element: '=',
				},
				templateUrl:
					BASE_DIRECTIVES_PATH +
					'/contentConfigurator/contentSnippets/imageSnippet/views/imageSnippetConfig.html',
				controller: [
					'$scope',
					'$controller',
					'$rootScope',
					'ContentBuilderHelper',
					function ($scope, $controller, $rootScope, ContentBuilderHelper) {
						$scope.snippet = {
							name: 'imageSnippet',
						};

						angular.extend(
							this,
							$controller('snippetConfigController', {
								$scope: $scope,
							})
						);

						$scope.options.title = 'Image';
						$scope.options.description =
							'Image snippet with alt attribute and ability to wrap into a link, classes can be set to parent element';

						$scope.config = {
							alt: '',
							classes: '',
							link: undefined,
							link_target_blank: false,
						};

						$scope.previousElementIdentifier = undefined;
						var _init = function () {
							if (typeof $scope.element.config !== 'undefined') {
								$scope.config = $scope.element.config;
							}
						};

						// Track elementIdentifier change for cases when you switch between different sections, directive stays the same only data is updated
						$scope.$watch('elementIdentifier', function (newValue, oldValue) {
							if (newValue !== oldValue) {
								if (typeof $scope.element.config !== 'undefined') {
									$scope.config = $scope.element.config;
								}
							}
						});
						$scope.$watchCollection('config', function (newValue, oldValue) {
							// Do not propagate config change on first load or when switching to different snippet of same type (section)
							if ($scope.previousElementIdentifier !== $scope.elementIdentifier) {
								$scope.previousElementIdentifier = $scope.elementIdentifier;
							} else {
								if (newValue === undefined && newValue === oldValue) {
									return;
								}
								$scope.element.config = {
									// this will trigger section directive watchers
									alt: $scope.config.alt,
									classes: $scope.config.classes,
									link: $scope.config.link,
									link_target_blank: $scope.config.link_target_blank,
								};
							}
						});
						_init();
					},
				],
			};
		},
	]);
});

