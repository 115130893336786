define('modules/econ/controllers/EconCampaignFormController',[
	'modules/module',
	'settings',
	'services/EconCampaignService',
	'services/EconMailtemplateService',
	'services/EconRecipientListService',
	'services/NavigationService',
	'directives/formatDate/formatDate',
	'directives/controls/customInputTime/CustomInputTime',
], function (module) {
	'use strict';
	module.controller('EconCampaignFormController', [
		'$scope',
		'$controller',
		'EconCampaignService',
		'EconMailtemplateService',
		'EconRecipientListService',
		'NavigationService',
		'BASE_TEMPLATES_PATH',
		EconCampaignFormController,
	]);
});

function EconCampaignFormController(
	$scope,
	$controller,
	EconCampaignService,
	EconMailtemplateService,
	EconRecipientListService,
	NavigationService,
	BASE_TEMPLATES_PATH
) {
	$scope.formTabs = [
		{
			label: 'FIELDS.GENERAL',
			template: BASE_TEMPLATES_PATH + '/econ/partials/_form-campaign-main.html',
			active: true,
		},
		{
			label: 'Empfänger',
			template: BASE_TEMPLATES_PATH + '/econ/partials/_form-campaign-recipients.html',
		},
	];

	$scope.validationConfig = {
		name: {
			method: 'isset',
			fieldName: 'FIELDS.DESCRIPTION',
		},
	};

	$scope.campaignStates = [
		{ value: true, display: 'Aktiviert' },
		{ value: false, display: 'Deaktiviert' },
	];

	$scope.recipientListHeadlineText =
		'Bitte wählen Sie die Empfängerlisten aus, die einen Newsletter erhalten sollen.';

	$scope.listState = 'root.econCampaigns';

	angular.extend(
		this,
		$controller('EntityFormController', {
			$scope: $scope,
		})
	);

	$scope.init('Campaign', EconCampaignService).then(function () {
		$scope.deletedActions = [];
		if ($scope.entity.recipientLists == undefined) {
			$scope.entity.recipientLists = [];
		}

		EconMailtemplateService.findAll(true).then(function () {
			$scope.mailtemplates = EconMailtemplateService.getAll();
		});

		initRecipientLists();

		if (!$scope.newEntity) {
			$scope.entity.actions.forEach(function (action) {
				var date = new Date(action.date);
				action.tmpDate = date;
				action.tmpTime = date.getHours() * 60 + date.getMinutes();
			});
		}

		if ($scope.entity.actions === undefined) {
			$scope.entity.actions = [];
		}

		/*if ($scope.entity.actions.length === 0) {
            $scope.addAction();
        }*/

		$scope.$on('beforeUpdate', function () {
			$scope.prepareActions();
			$scope.beforeUpdateCreate();
		});

		$scope.$on('beforeCreate', function () {
			$scope.prepareActions();
			$scope.beforeUpdateCreate();
		});

		$scope.$on('afterCreate', function () {
			$scope.afterUpdate();
		});

		$scope.$on('afterUpdate', function () {
			$scope.afterUpdate();
		});
	});

	$scope.removeAction = function (index) {
		$scope.entity.actions.splice(index, 1);
	};

	$scope.prepareActions = function () {
		$scope.entity.actions.forEach(function (action) {
			delete action.mailTemplate.content;
			action.date = new Date(action.tmpDate);
			action.date.setHours(parseInt(action.tmpTime / 60));
			action.date.setMinutes(action.tmpTime - parseInt(action.tmpTime / 60) * 60);

			delete action.tmpDate;
			delete action.tmpTime;
		});
	};

	$scope.beforeUpdateCreate = function () {
		$scope.entity.recipientLists = [];
		$scope.recipientLists.forEach(function (recipientList) {
			if (recipientList.selected) {
				delete recipientList.selected;
				delete recipientList.color;
				delete recipientList.createdAt;
				delete recipientList.name;
				delete recipientList.recipients;
				$scope.entity.recipientLists.push(recipientList);
			}
		});
	};

	$scope.afterUpdate = function () {
		$scope.entity.actions.forEach(function (action) {
			var date = new Date(action.date);
			action.tmpDate = date;
			action.tmpTime = date.getHours() * 60 + date.getMinutes();
		});

		initRecipientLists();
	};

	$scope.addAction = function () {
		$scope.entity.actions.push({ deleted: false, tmpDate: new Date() });
	};

	function initRecipientLists() {
		EconRecipientListService.findAll(true).then(function () {
			$scope.recipientLists = EconRecipientListService.getAll();
			$scope.recipientLists.forEach(function (recipientList) {
				recipientList.selected = false;
			});
			if (!$scope.newEntity) {
				$scope.entity.recipientLists.forEach(function (selectedRecipientList) {
					$scope.recipientLists.forEach(function (recipientList) {
						if (selectedRecipientList.id === recipientList.id) {
							recipientList.selected = true;
						}
					});
				});
			}
		});
	}
}
;
