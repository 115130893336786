define('modules/crm/customer/controllers/CustomerBaseController',[
	'modules/module',
	'modules/basemodule/controllers/EntityListController',
	'directives/components/common/modalDialog/ModalDialog',
	'filters/CustomerSourceFilter',
	'settings',
], function (module) {
	'use strict';
	module.controller('CustomerBaseController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		CustomerBaseController,
	]);
});

function CustomerBaseController($scope, $controller, BASE_TEMPLATES_PATH) {
	angular.extend(
		this,
		$controller('EntityListController', {
			$scope: $scope,
		})
	);

	$scope.tableOptions.fields = [
		{
			id: 'ID',
			heading: 'FIELDS.CUSTOMER_NUMBER_SHORT',
			attribute: 'id',
		},
		{
			id: 'COMPANY',
			heading: 'FIELDS.COMPANY',
			attribute: 'companyName',
		},
		{
			id: 'FIRSTNAME',
			heading: 'FIELDS.FIRSTNAME',
			attribute: 'firstName',
		},
		{
			id: 'LASTNAME',
			heading: 'FIELDS.LASTNAME',
			attribute: 'lastName',
		},
		{
			id: 'EMAIL',
			heading: 'FIELDS.EMAIL',
			attribute: 'email',
		},
		{
			id: 'CUSTOMERSOURCE',
			heading: 'FIELDS.CUSTOMER_SOURCE',
			template: BASE_TEMPLATES_PATH + '/crm/customer/customer-source-col.html',
			searchDisabled: true,
			orderDisabled: true,
			attribute: '',
		},
		{
			id: 'CONTACT',
			heading: 'FIELDS.CONTACT',
			template: BASE_TEMPLATES_PATH + '/crm/customer/customer-contact-col.html',
			searchDisabled: true,
			orderDisabled: true,
			attribute: '',
		},
	];

	$scope.tableOptions.optionalFields = [
		{
			id: 'USERNAME',
			heading: 'FIELDS.USERNAME',
			attribute: 'username',
		},
		{
			id: 'ZIPCODE',
			heading: 'FIELDS.POSTCODE_SHORT',
			template: BASE_TEMPLATES_PATH + '/crm/customer/customer-zipcode-col.html',
			attribute: 'zipCode',
		},
		{
			id: 'CITY',
			heading: 'FIELDS.CITY',
			template: BASE_TEMPLATES_PATH + '/crm/customer/customer-city-col.html',
			attribute: 'city',
		},
	];

	/*
        do not add ng-click to column contact
     */
	$scope.preserveForDialogs = function (item, field) {
		return field.id != 'CONTACT';
	};

	/*
     Modal Dialog functions
     */
	$scope.onSuccess = function (result) {};

	$scope.onCancel = function () {};

	$scope.dialogCustomerController = function ($scope, dataToPass, $mdDialog) {
		$scope.data = dataToPass;
		$scope.cancel = function () {
			$mdDialog.cancel();
		};
	};

	$scope.$on('PhoneModal', function (evt, data) {
		$scope.showPhoneModal(data);
	});

	$scope.$on('MailModal', function (evt, data) {
		$scope.showMailModal(data);
	});

	$scope.$on('ContactModal', function (evt, data) {
		$scope.showContactModal(data);
	});
}
;
