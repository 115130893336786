define('services/MetadataService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('MetadataService', ['$injector', '$q', 'ModelFactory', MetadataService]);
});

function MetadataService($injector, $q, ModelFactory) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('MetaData');

	angular.extend(this, crudInstance);

	this.findValues = (metadataId) => {
		const ref = $q.defer();

		ModelFactory.MetaData()
			.findValues({ id: metadataId })
			.$promise.then((metadata) => {
				if (metadata && metadata.metadataValues) {
					ref.resolve(metadata.metadataValues);
				} else {
					ref.reject();
				}
			});

		return ref.promise;
	};

	this.updateAll = function (items) {
		var promise = $injector.get('ModelFactory')['MetaData']().updateAll(items).$promise;
		return promise;
	};

	this.updatePosition = function (item) {
		var promise = $injector.get('ModelFactory')['MetaData']().updatePosition(item).$promise;
		return promise;
	};

	return this;
}
;
