define('services/UnitUnpagedService',['app', 'modules/module', 'services/EntityCrudUnpagedService'], function (app, module) {
	'use strict';
	return module.factory('UnitUnpagedService', ['$injector', UnitUnpagedService]);
});

function UnitUnpagedService($injector) {
	crudInstance = $injector.instantiate(EntityCrudUnpagedService);
	crudInstance.setType('Unit');

	angular.extend(this, crudInstance);

	var _volumeUnits = [],
		_weightUnits = [];

	this.findVolumeUnits = function () {
		return new $injector.get('ModelFactory')
			['Unit']()
			.findAllVolume()
			.$promise.then(function (response) {
				_volumeUnits = response;
			});
	};

	this.findWeightUnits = function () {
		return new $injector.get('ModelFactory')
			['Unit']()
			.findAllWeight()
			.$promise.then(function (response) {
				_weightUnits = response;
			});
	};

	this.getVolumeUnits = function () {
		return _volumeUnits;
	};

	this.getWeightUnits = function () {
		return _weightUnits;
	};

	return this;
}
;
