/**
 * Created by Nico on 26.07.2016.
 */
define('modules/wawi/seminar/SeminarListController',['modules/module', 'modules/basemodule/controllers/EntityListController', 'settings'], function (module) {
	'use strict';
	module.controller('SeminarListController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		function ($scope, $controller, BASE_TEMPLATES_PATH) {
			angular.extend(
				this,
				$controller('EntityListController', {
					$scope: $scope,
				})
			);

			$scope.tableOptions.fields = [
				{
					id: 'STATUS',
					heading: 'FIELDS.STATUS',
					attribute: 'publishState',
				},
				{
					id: 'NAME',
					heading: 'FIELDS.SEMINAR_TITLE',
					attribute: 'name',
				},
				{
					id: 'SKU',
					heading: 'Sku',
					attribute: 'sku',
				},
				{
					id: 'DATE',
					heading: 'FIELDS.DATE',
					template: BASE_TEMPLATES_PATH + '/wawi/seminar/column/date-col.html',
					attribute: 'startDate',
				},
				{
					id: 'LOCATION',
					heading: 'FIELDS.LOCATION',
					template: BASE_TEMPLATES_PATH + '/wawi/seminar/column/location-col.html',
					attribute: 'location',
				},
				{
					id: 'MANUFACTURER',
					heading: 'FIELDS.PROVIDER',
					template: BASE_TEMPLATES_PATH + '/wawi/seminar/column/manufacturer-col.html',
					attribute: 'manufacturer',
				},
				{
					id: 'TOTAL',
					heading: 'FIELDS.TOTAL_SEATS',
					template: BASE_TEMPLATES_PATH + '/wawi/seminar/column/total-col.html',
					attribute: 'maxStockLevel',
					searchDisabled: true,
					orderDisabled: true,
				} /*,
                    {
                        id: 'FREE',
                        heading: 'Freie Plätze',
                        template: BASE_TEMPLATES_PATH + 'wawi/seminar/column/free-col.html',
                        attribute: 'availableStockLevel',
                        searchDisabled: true,
                        orderDisabled: true
                    },
                    {
                        id: 'BOOKED',
                        heading: 'Gebucht',
                        template: BASE_TEMPLATES_PATH + 'wawi/seminar/column/booked-col.html',
                        attribute: 'booked',
                        searchDisabled: true,
                        orderDisabled: true
                    }*/,
			];

			$scope.formState = 'root.seminar';

			$scope.init('Seminar');
		},
	]);
});

