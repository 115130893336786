define('directives/unitsInput/unitsInput',['app', 'settings', 'uiBootstrap', 'services/UnitService', 'filters/UnitTypeFilter'], function (app) {
	'use strict';
	app.directive('ngUnitsInput', [
		'BASE_DIRECTIVES_PATH',
		'UnitService',
		function (BASE_DIRECTIVES_PATH, UnitService) {
			return {
				scope: {
					ngModel: '=',
					selectedUnit: '=',
					unitsType: '=',
					unitGroups: '=',
					filterUnitTypes: '=',
					readonly: '=',
				},
				templateUrl: BASE_DIRECTIVES_PATH + '/unitsInput/views/units-input.html',
				controller: [
					'$scope',
					'$http',
					'$state',
					function ($scope, $http, $state) {
						$scope.active = true;
						$scope.filterTypes = [];

						$scope.isChildOfOtherUnits = function (unit, units) {
							if (units === undefined) {
								units = $scope.units;
							}
							for (var i = 0; i < units.length; i++) {
								if (units[i].id === unit.id) {
									return true;
								} else {
									return $scope.isChildOfOtherUnits(unit, units[i].children);
								}
							}
							return false;
						};

						$scope.setDefaultUnit = function () {
							if ($scope.selectedUnit === undefined || $scope.selectedUnit === null) {
								$scope.selectedUnit = $scope.units[0];
							} else {
								$scope.units.forEach(function (unit) {
									if (unit.id === $scope.selectedUnit.id) {
										$scope.selectedUnit = unit;
									}
								});
							}
						};

						$scope.setRootUnit = function () {
							$scope.units.forEach(function (unit) {
								if (unit.children !== undefined && unit.children.length === 0) {
									if (!$scope.isChildOfOtherUnits(unit)) {
										$scope.rootUnit = unit;
									}
								}
							});
						};

						$scope.init = function () {
							$scope.setRootUnit();
							$scope.setDefaultUnit();
							$scope.SetDisplayValue();
							$scope.inputChanged();
						};

						$scope.SetDisplayValue = function () {
							if ($scope.selectedUnit) {
								$scope.value_disp = $scope.ngModel * $scope.selectedUnit.factor;
								$scope.value_disp = $scope.roundResult($scope.value_disp);
								$scope.value_disp = $scope.outputTransformer($scope.value_disp);
							}
						};

						$scope.setModelValue = function () {
							if ($scope.selectedUnit) {
								$scope.ngModel = parseFloat($scope.inputTransformer($scope.value_disp));
								$scope.ngModel = $scope.ngModel / $scope.selectedUnit.factor;
								$scope.ngModel = $scope.roundResult($scope.ngModel);
							}
						};

						$scope.roundResult = function (result) {
							if ($scope.selectedUnit.accuracy !== undefined) {
								var range = Math.pow(10, $scope.selectedUnit.accuracy);
								return Math.round(range * result) / range;
							}
						};

						$scope.inputChanged = function () {
							$scope.active = false;
							$scope.setModelValue();
						};

						$scope.selectionChanged = function (oldType) {
							//	if(!$scope.selecetedUnit || oldType !== $scope.selectedUnit.unitType) {
							//@todo reset rootUnit and recalc Model
							//	        					return;
							//	}
							//							$scop.selectedUnit = oldType;
							$scope.SetDisplayValue();
						};

						$scope.inputTransformer = function (val) {
							/*if($translate.use() == 'de'){
							 return parseFloat(val.toString().replace(/,/, "."));
							 }*/
							return val;
						};

						$scope.outputTransformer = function (val) {
							/*if($translate.use() == 'de') {
							 return val.toString().replace(/[\.]/, ",");
							 }*/
							return val;
						};

						UnitService.findAll().then(function () {
							if ($scope.unitGroups !== undefined) {
								$scope.units = [];
								$scope.unitGroups.forEach(function (unitGroup) {
									unitGroup.unitTypes.forEach(function (unitType) {
										UnitService.getUnitsByType(unitType).forEach(function (unit) {
											unit.groupName = unitGroup.title;
											$scope.units.push(unit);
										});
									});
								});
							} else if ($scope.unitsType !== undefined) {
								$scope.units = [];
								$scope.unitsType.split(',').forEach(function (unitType) {
									UnitService.getUnitsByType(unitType).forEach(function (unit) {
										$scope.units.push(unit);
									});
								});
								//$scope.units = UnitService.getUnitsByType($scope.unitsType);
							} else {
								$scope.units = UnitService.getUnits();
							}
							$scope.init();
						});

						if ($scope.filterUnitTypes !== undefined) {
							$scope.filterUnitTypes.then(
								function () {},
								function () {},
								function (typesToFilter) {
									$scope.filterTypes = typesToFilter;
								}
							);
						}

						$scope.$watch('ngModel', function (newVal, oldVal) {
							$scope.SetDisplayValue();
						});

						$scope.$watch('unitsType', function (newVal, oldVal) {
							if (newVal !== undefined) {
								$scope.units = [];
								$scope.unitsType = newVal;
								$scope.unitsType.split(',').forEach(function (unitType) {
									UnitService.getUnitsByType(unitType).forEach(function (unit) {
										$scope.units.push(unit);
									});
								});
							}
						});
					},
				],
			};
		},
	]);
});

