define('modules/shippingGlobal/controllers/ShippingGlobalController',[
	'modules/module',
	'modules/models',
	'modules/basemodule/controllers/EntityListController',
	'services/ShippingGlobalService',
	'services/ShippingRuleService',
	'directives/rules/rules',
	'directives/shippingConditionGroups/shippingConditionGroups',
	'settings',
], function (module) {
	'use strict';
	module.controller('ShippingGlobalController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		function ($scope, $controller, BASE_TEMPLATES_PATH) {
			angular.extend(
				this,
				$controller('EntityListController', {
					$scope: $scope,
				})
			);

			$scope.tableOptions.fields = [
				{
					id: 'NAME',
					heading: 'FIELDS.NAME',
					attribute: 'name',
				},
				//templates are not from shipping global?
				{
					id: 'NAME',
					heading: 'FIELDS.CONDITIONS',
					template: BASE_TEMPLATES_PATH + '/shippingRules/partials/shipping-rule-condition-col.html',
				},
				{
					id: 'NAME',
					heading: 'FIELDS.ACTION',
					template: BASE_TEMPLATES_PATH + '/shippingRules/partials/shipping-rule-action-col.html',
				},
			];

			$scope.formState = 'root.shippingglobal';

			$scope.init('ShippingGlobal');
		},
	]);
});

