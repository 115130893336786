/**
 * Created by nmayer on 09.06.16.
 */
define('modules/settings/metadata/MetadataListController',[
	'modules/module',
	'settings',
	'services/MetadataService',
	'services/NotificationService',
	'modules/basemodule/controllers/EntityListController',
], function (module) {
	'use strict';
	module.controller('MetadataListController', [
		'$scope',
		'$controller',
		'MetadataService',
		'NotificationService',
		'$rootScope',
		'BASE_TEMPLATES_PATH',
		function ($scope, $controller, MetadataService, NotificationService, $rootScope, BASE_TEMPLATES_PATH) {
			angular.extend(
				this,
				$controller('EntityListController', {
					$scope: $scope,
				})
			);

			$scope.state.requireSuperuserForDelete = true;
			$scope.state.requireSuperuserForUpdate = true;

			$scope.tableOptions.fields = [
				{
					id: 'POSITION',
					heading: 'FIELDS.SORTING',
					template: BASE_TEMPLATES_PATH + '/settings/metadata/partials/position-col.html',
					attribute: 'position',
					searchDisabled: true,
				},
				{
					id: 'NAME',
					heading: 'FIELDS.NAME',
					attribute: 'name',
				},
				{
					id: 'IDENTIFIER',
					heading: 'FIELDS.INTERNAL_IDENTIFIER',
					attribute: 'identifier',
				},
			];
			$scope.formState = 'root.metadata';

			$scope.init('MetaData').then(function () {});

			$scope.save = function (index) {
				NotificationService.confirm({
					statusText: 'Die aktuelle Sortierung wird permanent gespeichert. Fortfahren?',
				}).then(
					function () {
						MetadataService.updateAll($scope.items).then(function (response) {
							if (response.error == undefined && response.errorCode == undefined) {
								NotificationService.notifySuccess({ statusText: 'Erfolgreich gespeichert' });
							} else {
								NotificationService.notifyGeneralError({ statusText: 'Sperchern fehlgeschlagen' });
							}
						});
					},
					function () {}
				);
			};

			$scope.updatePosition = function (item) {
				MetadataService.updatePosition(item).then(function (response) {
					if (response.error == undefined && response.errorCode == undefined) {
						NotificationService.notifySuccess({ statusText: 'Erfolgreich gespeichert' });
					} else {
						NotificationService.notifyGeneralError({ statusText: 'Sperchern fehlgeschlagen' });
					}
				});
			};

			$rootScope.$on('metadataUpdate', function (event, item) {
				$scope.updatePosition(item);
			});
		},
	]);
});

