/**
 * Created by Nico on 11.07.2016.
 */
define('modules/wawi/attribute/controllers/AttributeController',['modules/module', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('AttributeController', [
		'$controller',
		'$scope',
		function ($controller, $scope) {
			angular.extend(
				this,
				$controller('EntityListController', {
					$scope: $scope,
				})
			);

			$scope.tableOptions.fields = [
				{
					id: 'IDENTIFIER',
					heading: 'FIELDS.IDENTIFIER',
					attribute: 'identifier',
				},
				{
					id: 'NAME',
					heading: 'FIELDS.NAME',
					attribute: 'name',
				},
			];

			$scope.formState = 'root.attribute';

			$scope.init('Attribute');
		},
	]);
});

