define('modules/settings/shop/tabs/SearchSettingsTabController',[], function () {
	'use strict';

	return function SearchSettingsController($parentScope, $scope, { BASE_TEMPLATES_PATH }) {
		$parentScope.adminFormTabs.push({
			label: 'FIELDS.SEARCH_SETTINGS',
			template: BASE_TEMPLATES_PATH + '/settings/shop/form-search-settings.html',
		});

		$scope.sourceSearch = [];
		$scope.modelSearch = [];

		$scope.init = () => {
			if ($parentScope.entity.shopSearchSettings === undefined) {
				$parentScope.entity.shopSearchSettings = {};
			}
			if (!$parentScope.entity.shopSearchSettings.title) {
				$scope.sourceSearch.push({ id: 1, value: 'title' });
			} else {
				$scope.modelSearch.push({ id: 1, value: 'title' });
			}
			if (!$parentScope.entity.shopSearchSettings.shortDescription) {
				$scope.sourceSearch.push({ id: 2, value: 'short description' });
			} else {
				$scope.modelSearch.push({ id: 2, value: 'short description' });
			}
			if (!$parentScope.entity.shopSearchSettings.longDescription) {
				$scope.sourceSearch.push({ id: 3, value: 'long description' });
			} else {
				$scope.modelSearch.push({ id: 3, value: 'long description' });
			}
			if (!$parentScope.entity.shopSearchSettings.metadatas) {
				$scope.sourceSearch.push({ id: 4, value: 'metadatas' });
			} else {
				$scope.modelSearch.push({ id: 4, value: 'metadatas' });
			}
		};

		$scope.setSelectedSearchSettings = function () {
			$scope.modelSearch.forEach(function (model) {
				if (model.value === 'title') {
					$parentScope.entity.shopSearchSettings.title = true;
				}
				if (model.value === 'short description') {
					$parentScope.entity.shopSearchSettings.shortDescription = true;
				}
				if (model.value === 'long description') {
					$parentScope.entity.shopSearchSettings.longDescription = true;
				}
				if (model.value === 'metadatas') {
					$parentScope.entity.shopSearchSettings.metadatas = true;
				}
			});
			$scope.sourceSearch.forEach(function (model) {
				if (model.value === 'title') {
					$parentScope.entity.shopSearchSettings.title = false;
				}
				if (model.value === 'short description') {
					$parentScope.entity.shopSearchSettings.shortDescription = false;
				}
				if (model.value === 'long description') {
					$parentScope.entity.shopSearchSettings.longDescription = false;
				}
				if (model.value === 'metadatas') {
					$parentScope.entity.shopSearchSettings.metadatas = false;
				}
			});
		};
	};
});

