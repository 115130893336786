/**
 * Created by nmayer on 13.06.16.
 */
define('modules/crm/customerGroup/CustomerGroupController',[
	'modules/module',
	'settings',
	'services/NotificationService',
	'modules/basemodule/controllers/EntityListController',
], function (module) {
	'use strict';
	module.controller('CustomerGroupController', [
		'$rootScope',
		'$scope',
		'$controller',
		'NotificationService',
		function ($rootScope, $scope, $controller, NotificationService) {
			angular.extend(
				this,
				$controller('EntityListController', {
					$scope: $scope,
				})
			);

			$scope.tableOptions.fields = [
				{
					id: 'NAME',
					heading: 'FIELDS.NAME',
					attribute: 'name',
				},
				{
					id: 'GROUPKEY',
					heading: 'FIELDS.ABBREVIATION',
					attribute: 'groupKey',
				},
			];
			$scope.formState = 'root.customergroup';

			$scope.init('CustomerGroup');

			$scope.$on('NotifyWaitingMessageCustomerGroup', function () {
				NotificationService.notifySuccess({
					statusText:
						'Das Löschen der Kundengruppe inkl. Preise wurde gestartet. Das Update kann mehrere Minuten in Anspruch nehmen.',
					msg: '',
				});
			});
		},
	]);
});

