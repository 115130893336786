define('modules/wawi/product/controllers/tabs/SellingProductsTabController',[], function () {
	'use strict';

	return ($parentScope, $scope, { BASE_TEMPLATES_PATH, ProductService }) => {
		$parentScope.formTabs.push(
			{
				label: 'FIELDS.UP_SELLING',
				template: BASE_TEMPLATES_PATH + '/wawi/product/form-up-selling.html',
			},
			{
				label: 'FIELDS.CROSS_SELLING',
				template: BASE_TEMPLATES_PATH + '/wawi/product/form-cross-selling.html',
			},
			{
				label: 'FIELDS.ADD_ON',
				template: BASE_TEMPLATES_PATH + '/wawi/product/form-add-on-selling.html',
			}
		);

		$scope.productService = ProductService;

		$scope.productService.setCurrentPage(0).then(function () {
			$scope.products = $scope.productService.getAll();
		});

		$scope.setSellingProducts = function () {
			$parentScope.entity.crossSellingProducts = [];
			$parentScope.entity.upSellingProducts = [];
			$parentScope.entity.addOnSellingProducts = [];
			if ($parentScope.entity.sellingProducts != undefined) {
				for (var i = 0; i < $parentScope.entity.sellingProducts.length; i++) {
					var sellingProduct = $parentScope.entity.sellingProducts[i];
					if (sellingProduct.sellingType == 'CROSS_SELLING') {
						$parentScope.entity.crossSellingProducts.push(sellingProduct);
					} else if (sellingProduct.sellingType == 'UP_SELLING') {
						$parentScope.entity.upSellingProducts.push(sellingProduct);
					} else if (sellingProduct.sellingType == 'ADD_ON_SELLING') {
						$parentScope.entity.addOnSellingProducts.push(sellingProduct);
					}
				}
			}
		};

		$scope.beforeBaseSave = () => {
			$parentScope.entity.addOnSellingProducts = $scope.addOnSellingProducts;

			$parentScope.entity.sellingProducts = [];
			$parentScope.entity.sellingProducts = $scope.upSellingProducts
				.concat($scope.crossSellingProducts)
				.concat($scope.addOnSellingProducts);
		};

		$scope.addOnSellingTableOptions = {};
		$scope.addOnSellingTableOptions.name = 'FIELDS.CROSS_SELLING_PRODUCT';
		$scope.addOnSellingTableOptions.fields = [
			{
				id: 'NAME',
				heading: 'FIELDS.NAME',
				attribute: 'name',
			},
			{
				id: 'SKU',
				heading: 'FIELDS.SKU',
				attribute: 'sku',
			},
		];

		$scope.crossSellingTableOptions = {};
		$scope.crossSellingTableOptions.name = 'FIELDS.CROSS_SELLING_PRODUCT';
		$scope.crossSellingTableOptions.fields = [
			{
				id: 'NAME',
				heading: 'FIELDS.NAME',
				attribute: 'name',
			},
			{
				id: 'SKU',
				heading: 'FIELDS.SKU',
				attribute: 'sku',
			},
		];

		$scope.upSellingTableOptions = {};
		$scope.upSellingTableOptions.name = 'FIELDS.UP_SELLING_PRODUCT';
		$scope.upSellingTableOptions.fields = [
			{
				id: 'NAME',
				heading: 'FIELDS.NAME',
				attribute: 'name',
			},
			{
				id: 'SKU',
				heading: 'FIELDS.SKU',
				attribute: 'sku',
			},
		];

		$parentScope.$on('afterInit', function () {
			$scope.crossSellingProducts = [];
			$scope.upSellingProducts = [];
			$scope.addOnSellingProducts = [];
			if ($parentScope.entity.sellingProducts == undefined) {
				$parentScope.entity.sellingProducts = [];
			}
			for (var i = 0; i < $parentScope.entity.sellingProducts.length; i++) {
				var product = $parentScope.entity.sellingProducts[i];
				if (product.sellingType == 'CROSS_SELLING') {
					$scope.crossSellingProducts.push(product);
				} else if (product.sellingType == 'UP_SELLING') {
					$scope.upSellingProducts.push(product);
				} else if (product.sellingType == 'ADD_ON_SELLING') {
					$scope.addOnSellingProducts.push(product);
				}
			}
		});
	};
});

