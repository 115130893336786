/**
 * Created by Nico on 26.07.2016.
 */
define('modules/wawi/seminar/SeminarFormController',[
	'modules/module',
	'modules/wawi/product/controllers/ProductBaseFormController',
	'services/CategoryService',
	'services/TagService',
	'services/NotificationService',
	'services/ValidationService',
	'services/ManufacturerService',
	'services/ImageService',
	'services/ParticipantTypesService',
	'services/CountryUnpagedService',
	'services/LocationUnpagedService',
	'services/SeminarService',
	'settings',
], function (module) {
	'use strict';
	module.controller('SeminarFormController', [
		'$scope',
		'$controller',
		'CategoryService',
		'TagService',
		'CountryUnpagedService',
		'NotificationService',
		'ValidationService',
		'ManufacturerService',
		'ImageService',
		'ParticipantTypesService',
		'LocationUnpagedService',
		'BASE_TEMPLATES_PATH',
		'SeminarService',
		'ProductService',
		function (
			$scope,
			$controller,
			CategoryService,
			TagService,
			CountryUnpagedService,
			NotificationService,
			ValidationService,
			ManufacturerService,
			ImageService,
			ParticipantTypesService,
			LocationUnpagedService,
			BASE_TEMPLATES_PATH,
			SeminarService,
			ProductService
		) {
			angular.extend(
				this,
				$controller('ProductBaseFormController', {
					$scope: $scope,
				})
			);

			$scope.formTabs = [
				{
					label: 'FIELDS.GENERAL',
					template: BASE_TEMPLATES_PATH + '/wawi/seminar/form-main.html',
					active: true,
				},
				{
					label: 'FIELDS.EVENTS',
					template: BASE_TEMPLATES_PATH + '/wawi/seminar/form-appointments.html',
				},
				{
					label: 'FIELDS.IMAGES',
					template: BASE_TEMPLATES_PATH + '/wawi/seminar/form-images.html',
				},
				{
					label: 'FIELDS.DESCRIPTION',
					template: BASE_TEMPLATES_PATH + '/wawi/seminar/form-description.html',
				},
				{
					label: 'FIELDS.PRICE',
					template: BASE_TEMPLATES_PATH + '/wawi/seminar/form-prices.html',
				},
				{
					label: 'FIELDS.SEATS',
					template: BASE_TEMPLATES_PATH + '/wawi/seminar/form-seats.html',
				},
				{
					label: 'FIELDS.VENUE',
					template: BASE_TEMPLATES_PATH + '/wawi/seminar/form-location.html',
				},
				{
					label: 'FIELDS.CATEGORIES',
					template: BASE_TEMPLATES_PATH + '/wawi/seminar/form-categories.html',
				},
				{
					label: 'FIELDS.TAGS',
					template: BASE_TEMPLATES_PATH + '/wawi/seminar/form-tags.html',
				},
				{
					label: 'FIELDS.SEO',
					template: BASE_TEMPLATES_PATH + '/wawi/seminar/form-seo.html',
				},
				{
					label: 'FIELDS.PRODUCT_FEATURES',
					template: BASE_TEMPLATES_PATH + '/wawi/product/form-metadata.html',
				},
				{
					label: 'FIELDS.ADDITIONAL_PRODUCTS',
					template: BASE_TEMPLATES_PATH + '/wawi/product/form-additional-products.html',
				},
			];

			$scope.productService = ProductService;
			$scope.productService.deactivateRequestId();

			$scope.productService.setCurrentPage(0).then(function () {
				$scope.availableData.customerProducts = $scope.productService.getAll();
				$scope.availableData.partnerProducts = angular.copy($scope.availableData.customerProducts);
				$scope.availableData.childProducts = angular.copy($scope.availableData.customerProducts);
			});

			$scope.intervalTypes = [
				{
					id: 'Week',
					name: 'Wochen',
				},
				{
					id: 'Month',
					name: 'Monate',
				},
			];

			$scope.periods = [
				{
					id: 1,
					name: '01',
				},
				{
					id: 2,
					name: '02',
				},
				{
					id: 3,
					name: '03',
				},
				{
					id: 4,
					name: '04',
				},
				{
					id: 5,
					name: '05',
				},
				{
					id: 6,
					name: '06',
				},
				{
					id: 7,
					name: '07',
				},
				{
					id: 8,
					name: '08',
				},
				{
					id: 9,
					name: '09',
				},
				{
					id: 10,
					name: '10',
				},
				{
					id: 11,
					name: '11',
				},
				{
					id: 12,
					name: '12',
				},
			];

			$scope.interval = 1;

			$scope.getEmptyAppointment = function () {
				return {
					subAppointments: [{}],
					active: true,
					availableStockLevel: $scope.entity.stock.availableStockLevel,
				};
			};

			$scope.location = {};

			$scope.addSingleAppointment = function () {
				if (!$scope.validateAppointment($scope.singleAppointmentCreation)) {
					return;
				}
				$scope.entity.appointments.push($scope.singleAppointmentCreation);
				$scope.singleAppointmentCreation = $scope.getEmptyAppointment();
			};

			$scope.validateAppointment = function (appointment) {
				for (var i = 0; i < appointment.subAppointments.length; i++) {
					var subAppointment = appointment.subAppointments[i];
					var validationObject = {
						startHour: {
							method: 'isset',
							fieldName: 'Start (Stunde)',
						},
						startMinutes: {
							method: 'isset',
							fieldName: 'Start (Minute)',
						},
						appointedDay: {
							method: 'isset',
							fieldName: 'Datum',
						},
						endHour: {
							method: 'isset',
							fieldName: 'Ende (Stunde)',
						},
						endMinutes: {
							method: 'isset',
							fieldName: 'Ende (Minute)',
						},
					};

					if (!ValidationService.validate(subAppointment, validationObject)) {
						NotificationService.notifyValidationErrors(ValidationService.getErrors());
						return false;
					}
				}
				return true;
			};

			$scope.addSingleAppointmentInList = function () {
				$scope.entity.appointments.push($scope.getEmptyAppointment());
			};

			$scope.removeAppointmentInList = function (index) {
				$scope.entity.appointments.splice(index, 1);
			};

			$scope.saveAndGenerate = function () {
				if (!$scope.validateAppointment($scope.entity.generationDefinitionAppointment)) {
					return;
				}

				$scope.entity.generateSeminars = true;
				if ($scope.newEntity) {
					$scope.create();
				} else {
					$scope.update();
				}
			};

			$scope.addSubAppointment = function (appointment, index) {
				appointment.subAppointments.splice(index + 1, 0, {});
			};

			$scope.removeSubAppointment = function (appointment, index) {
				appointment.subAppointments.splice(index, 1);
			};

			ParticipantTypesService.findAll().then(function () {
				$scope.participantTypes = ParticipantTypesService.getAll(true);
			});

			LocationUnpagedService.findAll().then(function () {
				$scope.locations = LocationUnpagedService.getAll(true).data;
				if ($scope.locations == undefined) {
					$scope.locations = [];
				}
			});

			CountryUnpagedService.findAll().then(function () {
				$scope.countries = CountryUnpagedService.getAll(true).data;
			});

			$scope.eventRadioValues = [
				{ value: true, display: 'Einzeltermin' },
				{ value: false, display: 'Serientermin' },
			];
			$scope.testDate = new Date();
			$scope.singleAppointment = false;

			$scope.location.selectedLocation = {};
			$scope.location.locationId;

			$scope.listState = 'root.seminars';

			$scope.addSeminarPriceDefinition = function () {
				$scope.entity.seminarParticipants.push({});
			};

			$scope.removeParticipantPrice = function (index) {
				$scope.entity.seminarParticipants.splice(index, 1);
			};

			$scope.init('Seminar').then(function () {
				if ($scope.newEntity) {
					$scope.entity.appointments = [];
				}

				$scope.singleAppointmentCreation = $scope.getEmptyAppointment();
				$scope.entity.generationDefinitionAppointment = $scope.getEmptyAppointment();

				if ($scope.entity.seminarParticipants == undefined || $scope.entity.seminarParticipants.length == 0) {
					$scope.entity.seminarParticipants = [
						{
							seminarParticipantType: 'CUSTOMER',
							seminarParticipantAmount: 0,
							price: 0,
						},
						{
							seminarParticipantType: 'CHILD',
							seminarParticipantAmount: 0,
							price: 0,
						},
						{
							seminarParticipantType: 'PARTNER',
							seminarParticipantAmount: 0,
							price: 0,
						},
					];
				}

				if ($scope.entity.appointments && $scope.entity.appointments.length > 0) {
					$scope.entity = SeminarService.convertDates($scope.entity);

					for (var i = 0; i < $scope.entity.appointments.length; i++) {
						if (
							$scope.entity.appointments[i].subAppointments === undefined ||
							$scope.entity.appointments[i].subAppointments.length === 0
						) {
							$scope.entity.appointments[i].subAppointments = [];
							$scope.entity.appointments[i].subAppointments.push({
								name: '',
								appointedDay: new Date($scope.entity.appointments[i].startDate),
								startHour: 8,
								startMinutes: 0,
								endHour: 16,
								endMinutes: 0,
								comment: '',
							});
						}
					}
				}

				if ($scope.entity.location == undefined) {
					$scope.entity.location = {};
				} else {
					$scope.location.selectedLocation = $scope.entity.location;
					$scope.location.locationId = $scope.entity.location.id;
				}

				$scope.customerSellingProducts = [];
				$scope.childSellingProducts = [];
				$scope.partnerSellingProducts = [];
				for (var i = 0; i < $scope.entity.sellingProducts.length; i++) {
					var sellingProduct = $scope.entity.sellingProducts[i];
					if (
						sellingProduct.sellingType == 'ADD_ON_SELLING' &&
						sellingProduct.seminarParticipantName == 'CUSTOMER'
					) {
						$scope.customerSellingProducts.push(sellingProduct);
					} else if (
						sellingProduct.sellingType == 'ADD_ON_SELLING' &&
						sellingProduct.seminarParticipantName == 'CHILD'
					) {
						$scope.childSellingProducts.push(sellingProduct);
					} else if (
						sellingProduct.sellingType == 'ADD_ON_SELLING' &&
						sellingProduct.seminarParticipantName == 'PARTNER'
					) {
						$scope.partnerSellingProducts.push(sellingProduct);
					}
				}
			});

			$scope.addOnSellingTableOptions = {};
			$scope.addOnSellingTableOptions.name = 'FIELDS.ADD_ON_PRODUCT';
			$scope.addOnSellingTableOptions.fields = [
				{
					id: 'NAME',
					heading: 'FIELDS.NAME',
					attribute: 'name',
				},
				{
					id: 'SKU',
					heading: 'Sku',
					attribute: 'sku',
				},
			];

			$scope.$on('beforeUpdate', function () {
				beforeSave();
			});

			$scope.$on('beforeCreate', function () {
				beforeSave();
			});

			$scope.onError = function () {
				$scope.entity.generateSeminars = false;
			};

			$scope.$on('createError', function (event) {
				$scope.onError();
			});

			$scope.$on('updateError', function (event) {
				$scope.onError();
			});

			$scope.mergeSellingProducts = function (productArray, participantName) {
				for (var i = 0; i < productArray.length; i++) {
					var sellingProduct = productArray[i];
					var sellingProductUpdate = {};
					sellingProductUpdate.productId = sellingProduct.id;
					sellingProductUpdate.sku = sellingProduct.sku;
					sellingProductUpdate.sellingType = 'ADD_ON_SELLING';
					sellingProductUpdate.seminarParticipantName = participantName;
					$scope.entity.sellingProducts.push(sellingProductUpdate);
				}
			};

			var beforeSave = function () {
				for (var i = 0; i < $scope.entity.appointments.length; i++) {
					var appointment = $scope.entity.appointments[i];
					if (!$scope.validateAppointment(appointment)) {
						return;
					}
				}

				for (var i = $scope.entity.sellingProducts.length - 1; i >= 0; i--) {
					var sellingProduct = $scope.entity.sellingProducts[i];
					if (sellingProduct.sellingType == 'ADD_ON_SELLING') {
						$scope.entity.sellingProducts.splice(i, 1);
					}
				}

				$scope.mergeSellingProducts($scope.customerSellingProducts, 'CUSTOMER');
				$scope.mergeSellingProducts($scope.partnerSellingProducts, 'PARTNER');
				$scope.mergeSellingProducts($scope.childSellingProducts, 'CHILD');

				$scope.entity.location = $scope.location.selectedLocation;

				$scope.setStartAndEndDate($scope.entity.generationDefinitionAppointment);
				if ($scope.entity.appointments !== undefined && $scope.entity.appointments.length > 0) {
					for (var i = 0; i < $scope.entity.appointments.length; i++) {
						$scope.setStartAndEndDate($scope.entity.appointments[i]);
					}
				}
			};

			$scope.setStartAndEndDate = function (appointment) {
				if (!appointment) {
					return;
				}

				if (!appointment.subAppointments) {
					return;
				}

				if (appointment.subAppointments <= 0) {
					return;
				}

				appointment.subAppointments.sort(function (a, b) {
					return a.appointedDay - b.appointedDay;
				});

				var startDate = angular.copy(appointment.subAppointments[0].appointedDay);
				if (startDate) {
					startDate.setHours(appointment.subAppointments[0].startHour);
					startDate.setMinutes(appointment.subAppointments[0].startMinutes);
					appointment.startDate = startDate;
				}

				var endDate = angular.copy(
					appointment.subAppointments[appointment.subAppointments.length - 1].appointedDay
				);
				if (endDate) {
					endDate.setHours(appointment.subAppointments[appointment.subAppointments.length - 1].endHour);
					endDate.setMinutes(appointment.subAppointments[appointment.subAppointments.length - 1].endMinutes);
					appointment.endDate = endDate;
				}
			};

			$scope.addAppointment = function (appointment) {
				appointment.subAppointments.push({
					appointedDay: new Date(),
					name: '',
					comment: '',
					startHour: 8,
					startMinutes: 0,
					endHour: 16,
					endMinutes: 0,
				});
			};

			$scope.removeAppointment = function (appointment, index) {
				if (appointment.subAppointments.length > 1) {
					appointment.subAppointments.splice(index, 1);
				} else {
					NotificationService.notifyGeneralError({
						statusText: 'Letzer Termin',
						msg: 'Ein Seminar muss mindestens einen Termin haben.',
					});
				}
			};

			$scope.afterSaveUpdate = function () {
				$scope.entity.generateSeminars = false;
				$scope.entity.generationDefinitionAppointment = $scope.getEmptyAppointment();
			};

			$scope.$on('afterUpdate', function () {
				$scope.afterSaveUpdate();
			});

			$scope.$on('afterCreate', function () {
				$scope.afterSaveUpdate();
			});

			$scope.setSelectedLocation = function (locationId) {
				for (var i = 0; i < $scope.locations.length; i++) {
					if (locationId == $scope.locations[i].id) {
						$scope.location.selectedLocation = $scope.locations[i];
						break;
					}
				}
				if (locationId == -1) {
					$scope.location.selectedLocation = {
						name: 'Neuer Eintrag',
						description: '',
						website: '',
						street: '',
						zipCode: '',
						city: '',
						countryId: null,
					};
				}
				if (locationId == undefined) {
					$scope.location.selectedLocation = $scope.locations[0];
				}
			};

			$scope.validate = function () {
				var validationObject = {
					name: {
						method: 'isset',
						fieldName: 'Name',
						errorMsg: 'Bitte geben Sie einen Namen in der Standard-Sprache an.',
						isLocaleValue: true,
						customValidate: function (entity) {
							//can be used for specific validation
							return true;
						},
					},
					taxId: {
						method: 'isset',
						fieldName: 'Steuersatz',
						errorMsg: 'Bitte geben Sie einen Steuersatz an.',
					},
					publishState: {
						method: 'isset',
						fieldName: 'Status',
						errorMsg: 'Bitte geben Sie einen Produkt-Status an.',
					},
					basePrice: {
						method: 'isset',
						fieldName: 'Preis',
						errorMsg: 'Bitte geben Sie einen Preis an.',
					},
					sellingPrice: {
						method: 'isset',
						fieldName: 'Verkaufspreis',
						errorMsg: 'Für den Verkaufspreis ist kein Wert hinterlegt.',
						customValidate: function (entity) {
							for (var i = 0; i < entity.prices.length; i++) {
								if (entity.prices[i].sellingPrice !== undefined) {
									return true;
								}
							}
							return false;
						},
					},
				};

				var locationValid = true;
				var validationObjectLocation = {
					name: {
						method: 'isset',
						fieldName: 'Name',
					},
					street: {
						method: 'isset',
						fieldName: 'Straße',
					},
					zipCode: {
						method: 'isset',
						fieldName: 'Postleitzahl',
					},
					city: {
						method: 'isset',
						fieldName: 'Stadt',
					},
					countryId: {
						method: 'isset',
						fieldName: 'Land',
					},
				};

				//Seminar specific validation
				if (!ValidationService.validate($scope.entity, validationObject)) {
					return false;
				}

				if (!ValidationService.validate($scope.location.selectedLocation, validationObjectLocation)) {
					return false;
				}

				return true;
			};

			$scope.minutes = [
				{
					name: '00',
					value: 0,
				},
				{
					name: '15',
					value: 15,
				},
				{
					name: '30',
					value: 30,
				},
				{
					name: '45',
					value: 45,
				},
			];

			$scope.hours = [
				{
					name: '00',
					value: 0,
				},
				{
					name: '01',
					value: 1,
				},
				{
					name: '02',
					value: 2,
				},
				{
					name: '03',
					value: 3,
				},
				{
					name: '04',
					value: 4,
				},
				{
					name: '05',
					value: 5,
				},
				{
					name: '06',
					value: 6,
				},
				{
					name: '07',
					value: 7,
				},
				{
					name: '08',
					value: 8,
				},
				{
					name: '09',
					value: 9,
				},
				{
					name: '10',
					value: 10,
				},
				{
					name: '11',
					value: 11,
				},
				{
					name: '12',
					value: 12,
				},
				{
					name: '13',
					value: 13,
				},
				{
					name: '14',
					value: 14,
				},
				{
					name: '15',
					value: 15,
				},
				{
					name: '16',
					value: 16,
				},
				{
					name: '17',
					value: 17,
				},
				{
					name: '18',
					value: 18,
				},
				{
					name: '19',
					value: 19,
				},
				{
					name: '20',
					value: 20,
				},
				{
					name: '21',
					value: 21,
				},
				{
					name: '22',
					value: 22,
				},
				{
					name: '23',
					value: 23,
				},
			];
		},
	]);
});

