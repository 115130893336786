define('modules/cms/controllers/FilterPageFormController',[
	'modules/module',
	'settings',
	'services/MetadataService',
	'services/FilterPageService',
	'services/NavigationService',
	'services/ValidationService',
	'services/NotificationService',
	'modules/cms/controllers/CmsFormBaseController',
	'directives/formatDate/formatDate',
	'directives/controls/customInputTime/CustomInputTime',
	'directives/tinyMce/tinyMce',
], function (module) {
	'use strict';
	module.controller('FilterPageFormController', [
		'$scope',
		'$controller',
		'FilterPageService',
		'PublishStateService',
		'MetadataService',
		'NavigationService',
		'BASE_TEMPLATES_PATH',
		'ValidationService',
		'NotificationService',
		FilterPageFormController,
	]);
});

function FilterPageFormController(
	$scope,
	$controller,
	FilterPageService,
	PublishStateService,
	MetadataService,
	NavigationService,
	BASE_TEMPLATES_PATH,
	ValidationService,
	NotificationService
) {
	angular.extend(
		this,
		$controller('CmsFormBaseController', {
			$scope: $scope,
		})
	);

	$scope.formTabs = [
		{
			label: 'Zuweisung',
			template: BASE_TEMPLATES_PATH + '/cms/partials/_form-filter-pages-assignment.html',
			active: true,
		},
		{
			label: 'Semantik',
			template: BASE_TEMPLATES_PATH + '/cms/partials/_form-filter-pages-semantics.html',
		},
		{
			label: 'FIELDS.CONTENT',
			template: BASE_TEMPLATES_PATH + '/cms/partials/_form-filter-pages-content.html',
		},
		{
			label: 'SEO',
			template: BASE_TEMPLATES_PATH + '/cms/partials/_form-filter-pages-seo.html',
		},
	];

	$scope.tinymceOptions = {
		theme: 'modern',
		height: '500',
		convert_urls: false,
		relative_urls: false,
		remove_script_host: false,
		plugins: [
			'link advlist lists image charmap print preview hr anchor pagebreak',
			'searchreplace wordcount visualblocks visualchars code fullscreen',
			'insertdatetime media nonbreaking save table contextmenu directionality',
			'emoticons template paste textcolor colorpicker textpattern imagetools',
			'fullpage moodiaimage',
		],
		menubar: 'insert | tools',
		toolbar1:
			'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link moodiaimage',
		toolbar2: 'print preview media | forecolor backcolor emoticons | fontsizeselect | fontselect | code',
		image_advtab: true,
	};

	$scope.publishStates = [];
	$scope.positions = [
		{ display: 'Oben', constant: 'TOP' },
		{ display: 'Unten', constant: 'BOTTOM' },
		{ display: 'Links', constant: 'LEFT' },
		{ display: 'Rechts', constant: 'RIGHT' },
	];
	$scope.metadata = [];
	$scope.metadataValues = [];
	$scope.selectedItems = {};
	$scope.listState = 'root.filterPages';
	$scope.entityType = 'FilterPage';

	$scope.init('FilterPage').then(function () {
		PublishStateService.findAll().then(function () {
			$scope.publishStates = PublishStateService.getAll();
		});
		MetadataService.findAll(true).then(function () {
			$scope.metadata = MetadataService.getAll();
			if ($scope.newEntity) {
			} else {
				$scope.entity.filterPagefilters[0] = $scope.entity.filterPagefilters[0] || {};

				$scope.selectedItems.metadataId = $scope.entity.filterPagefilters[0].metadataNameId;
				$scope.selectedItems.metadataValueId = $scope.entity.filterPagefilters[0].metadataValueId;
				$scope.metadataChanged();
			}
		});

		if ($scope.newEntity) {
		} else {
		}

		$scope.options = [];
		var countLocales = 0;
		if ($scope.entity.locales != undefined) {
			for (var i = 0; i < $scope.entity.locales.length; i++) {
				if (
					$scope.entity.locales[i].seoInformation != null &&
					$scope.entity.locales[i].seoInformation != undefined
				) {
					if ($scope.entity.locales[i].seoInformation.keywords != undefined) {
						if ($scope.entity.locales[i].seoInformation.keywords.length > countLocales) {
							countLocales = $scope.entity.locales[i].seoInformation.keywords.length;
						}
					}
				}
			}
			for (var i = 0; i < countLocales; i++) {
				$scope.options.push({});
			}
		}
		console.debug($scope.entity, 'entity');
	});

	$scope.prepareSave = function () {
		//at the moment it is only possible to save one metadataValueId

		//See coment below on the function definetion
		/*if(!$scope.saveEntity()) {
            return;
        }*/

		if ($scope.selectedItems.metadataId == undefined || $scope.selectedItems.metadataValueId == undefined) {
			NotificationService.notifyValidationErrors([
				{
					attributeName: 'Semantik',
					errorMsg: 'Geben sie bitte einen Merkmalswert bei Semantik ein.',
				},
			]);
			return;
		}

		$scope.entity.filterPagefilters = [];
		if ($scope.selectedItems.metadataValueId !== undefined) {
			$scope.entity.filterPagefilters.push({ metadataValueId: $scope.selectedItems.metadataValueId });
		}
	};

	//This function does not work properly - if validation fails, entity is nonetheless saved
	/*$scope.saveEntity = function () {

        var validateConfig = {
            publishStateId: {
                method: "isset",
                fieldName: "Satus"
            },
            name: {
                method: "isset",
                fieldName: "Name",
                isLocaleValue: true
            }
        };


        if(!ValidationService.validate($scope.entity, validateConfig)){
            NotificationService.notifyValidationErrors(ValidationService.getErrors());
            return false;
        }

        if ($scope.selectedItems.metadataId == undefined || $scope.selectedItems.metadataValueId == undefined) {
            NotificationService.notifyValidationErrors([{
                attributeName: "Semantik",
                errorMsg: "Geben sie bitte einen Merkmalswert bei Semantik ein."
            }]);
            return false;
        }

        return true;
    };*/

	$scope.validate = function () {
		return ValidationService.validate($scope.entity, {
			publishStateId: {
				method: 'isset',
				fieldName: 'Satus',
			},
			name: {
				method: 'isset',
				fieldName: 'Name',
				isLocaleValue: true,
			},
		});
	};

	$scope.metadataChanged = function () {
		$scope.metadata.forEach(function (item) {
			if (item.id == $scope.selectedItems.metadataId) {
				if (!item.areMetadataValuesLoaded && !item.metadataValues.length) {
					MetadataService.findValues(item.id).then((values) => {
						item.metadataValues = values;
						item.areMetadataValuesLoaded = true;

						$scope.metadataValues = item.metadataValues;
					});
				} else {
					$scope.metadataValues = item.metadataValues;
				}
			}
		});
	};

	$scope.addKeyword = function () {
		$scope.options.push({});
	};

	$scope.removeKeyword = function (index) {
		$scope.options.splice(index, 1);
	};
}
;
