define('services/ShippingRuleService',['app', 'modules/module'], function (app, module) {
	'use strict';
	return module.factory('ShippingRuleService', [
		'$q',
		'$rootScope',
		'$cookies',
		'$injector',
		'ModelFactory',
		function ($q, $rootScope, $cookies, $injector, ModelFactory) {
			var crudInstance = $injector.instantiate(CrudService);
			angular.extend(this, crudInstance);
			this.setType('ShippingRule');

			var _shippingRules = [],
				_shippingRule,
				_totalPages,
				_totalItems,
				_availableFieldTypes,
				_availableOperators,
				_availableActions;

			if ($cookies.get('_shippingRulePageSize') === undefined) {
				$cookies.put('_shippingRulePageSize', 10);
			}

			if ($cookies.get('_shippingRuleCurrentPage') === undefined) {
				$cookies.put('_shippingRuleCurrentPage', 0);
			}

			var _loadShippingRules = function () {
				var ref = $q.defer();
				var shippingRule = new $injector.get('ModelFactory')['ShippingRule']();
				shippingRule
					.findAll({
						page: $cookies.get('_shippingRuleCurrentPage'),
						size: $cookies.get('_shippingRulePageSize'),
					})
					.$promise.then(function (response) {
						_shippingRules = response.content;
						$cookies.put('_shippingRuleCurrentPage', response.actualPage);
						_totalPages = response.totalPages;
						_totalItems = response.totalElements;
						ref.resolve();
						$rootScope.$broadcast('shippingRulesChanges', _shippingRules);
					});

				return ref.promise;
			};

			this.loadAvailableActions = function () {
				var actions = $q.defer();

				var shippingRule = new $injector.get('ModelFactory')['ShippingActions']();

				shippingRule.findAll().$promise.then(function (response) {
					_availableActions = response;
					actions.resolve();
				});
				return actions.promise;
			};

			this.loadAvailableFieldTypes = function () {
				var fieldTypesModel = new $injector.get('ModelFactory')['ShippingFieldTypes']();

				var fieldTypes = $q.defer();
				fieldTypesModel.findAll().$promise.then(function (response) {
					_availableFieldTypes = response;
					fieldTypes.resolve();
				});
				return fieldTypes.promise;
			};

			this.loadShippingRules = _loadShippingRules;

			this.loadAvailableFieldTypes = function () {
				var fieldTypesModel = new $injector.get('ModelFactory')['ShippingFieldTypes']();
				var fieldTypes = $q.defer();
				fieldTypesModel.findAll().$promise.then(function (response) {
					_availableFieldTypes = response;
					fieldTypes.resolve();
				});
				return fieldTypes.promise;
			};

			this.loadAvailableOperators = function () {
				var shippingOperators = new $injector.get('ModelFactory')['ShippingOperators']();
				var operators = $q.defer();
				shippingOperators.findAll().$promise.then(function (response) {
					_availableOperators = response;
					operators.resolve();
				});
				return operators.promise;
			};

			this.loadAvailableActions = function () {
				var shippingActions = new $injector.get('ModelFactory')['ShippingActions']();
				var actions = $q.defer();
				shippingActions.findAll().$promise.then(function (response) {
					_availableActions = response;
					actions.resolve();
				});
				return actions.promise;
			};

			this.getAvailableFieldTypes = function () {
				return _availableFieldTypes;
			};

			this.getAvailableOperators = function () {
				return _availableOperators;
			};

			this.getAvailableActions = function () {
				return _availableActions;
			};

			return this;

			/*    return  {
                getCurrentPage: function () {
                    return (parseInt($cookies.get('_shippingRuleCurrentPage')) + 1);
                },
                getTotalPages: function () {
                    return _totalPages;
                },
                getTotalItems: function () {
                    return _totalItems;
                },
                getPageSize: function () {
                    return $cookies.get('_shippingRulePageSize');
                },
                setPageSize: function (size) {
                    $cookies.put('_shippingRulePageSize', size);
                },
                setCurrentPage: function (page) {
                    $cookies.put('_shippingRuleCurrentPage', page);
                },
                getShippingRules: function() {
                    return _shippingRules;
                },
                getShippingRule: function () {
                    return _shippingRule;
                },
                loadShippingRule: function(id) {
                    var ref = $q.defer();
                    ModelFactory.ShippingRule.findOne({id: id}).$promise.then(function (response) {
                        _shippingRule = response;
                        ref.resolve();
                    });
                    return ref.promise;
                },
                saveShippingRule: function (shippingRule) {
                    var ref = $q.defer();
                    ModelFactory.ShippingRule.save(shippingRule).$promise.then(function (response) {
                        ref.resolve(response);
                    });
                    return ref.promise;
                },
                deleteShippingRule: function (id) {
                    console.log('yes im here');
                    var ref = $q.defer();
                    ModelFactory.ShippingRule.delete({id: id}).$promise.then(function (response) {
                        ref.resolve(response);
                    });
                    return ref.promise;
                },
                loadShippingRules: _loadShippingRules,
                loadAvailableFieldTypes: function () {
                    var fieldTypes = $q.defer();
                    ModelFactory.ShippingFieldTypes.findAll().$promise.then(function (response) {
                        _availableFieldTypes = response;
                        fieldTypes.resolve();
                    });
                    return fieldTypes.promise;
                },
                loadAvailableOperators: function () {
                    var operators = $q.defer();
                    ModelFactory.ShippingOperators.findAll().$promise.then(function (response) {
                        _availableOperators = response;
                        operators.resolve();
                    });
                    return operators.promise;
                },
                loadAvailableActions: function () {
                    var actions = $q.defer();
                    ModelFactory.ShippingActions.findAll().$promise.then(function (response) {
                        _availableActions = response;
                        actions.resolve();
                    });
                    return actions.promise;
                },
                getAvailableFieldTypes: function () {
                    return _availableFieldTypes;
                },
                getAvailableOperators: function () {
                    return _availableOperators;
                },
                getAvailableActions: function () {
                    return _availableActions;
                }
            }*/
		},
	]);
});

