define('services/ShippingLabelService',['app', 'modules/module'], function (app, module) {
	'use strict';
	return module.factory('ShippingLabelService', [
		'$q',
		'$rootScope',
		'$cookies',
		'$injector',
		function ($q, $rootScope, $cookies, $injector) {
			this.createShippingLabel = function (orderIds) {
				var OrderManagement = new $injector.get('ModelFactory').OrderManagement();
				var ref = $q.defer();
				OrderManagement.createShippingLabel({ shopOrderIds: orderIds }).$promise.then(function (response) {
					if (response.ok != true) {
						ref.resolve(response);
					}
				});
				return ref.promise;
			};

			this.printShippingLabel = function (orderIds) {
				var OrderManagement = new $injector.get('ModelFactory').OrderManagement();
				var ref = $q.defer();
				OrderManagement.printShippingLabel({ shopOrderIds: orderIds }).$promise.then(function (response) {
					if (response.ok != true) {
						ref.resolve(response);
					}
				});
				return ref.promise;
			};

			var settings = null;
			this.loadSettings = function (shop, carrier) {
				var OrderManagement = new $injector.get('ModelFactory').ShippingLabel();
				var ref = $q.defer();
				var param = { carrierConstant: carrier, shop: shop };
				OrderManagement.loadSettings(param).$promise.then(function (response) {
					settings = response;
					ref.resolve(response);
				});
				return ref.promise;
			};

			this.getSettings = function () {
				return settings;
			};

			this.saveDHLSettings = function (shop, carrierData) {
				var OrderManagement = new $injector.get('ModelFactory').ShippingLabel();
				var ref = $q.defer();
				OrderManagement.saveDHLSettings({ shop: shop }, carrierData).$promise.then(function (response) {
					ref.resolve(response);
				});
				return ref.promise;
			};

			this.saveDPDSettings = function (shop, carrierData) {
				var OrderManagement = new $injector.get('ModelFactory').ShippingLabel();
				var ref = $q.defer();
				OrderManagement.saveDPDSettings({ shop: shop }, carrierData).$promise.then(function (response) {
					ref.resolve(response);
				});
				return ref.promise;
			};

			return this;
		},
	]);
});

