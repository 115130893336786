define('modules/models',[
	'angular',
	'app',
	'services/SessionValuesService',
	'services/LocalStorageService',
	'modules/module',
	'ngResource',
], function (angular, app, module) {
	module.factory('ModelFactory', [
		'$resource',
		'SessionValuesService',
		'localStorageService',
		function ($resource, SessionValuesService, localStorageService) {
			return {
				Installation: function () {
					return $resource('', null, {
						version: {
							method: 'GET',
							url: '/api/v1/core/version',
						},
						clientNumber: {
							method: 'GET',
							url: '/api/v1/core/client-number',
						},
						plugins: {
							method: 'GET',
							url: '/api/v1/core/plugins',
							isArray: true,
						},
					});
				},
				Backup: function () {
					return $resource(
						'/api/v1/core/database-backup',
						{ id: '@id' },
						{
							listBackup: {
								method: 'GET',
								url: 'api/v1/core/database-backup',
								isArray: true,
								headers: SessionValuesService.getFullSessionHeader(),
							},
							createBackup: {
								method: 'GET',
								url: 'api/v1/core/database-backup/create',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Cache: function () {
					return $resource(
						'/api/v1/core/cache',
						{},
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
								isArray: true,
							},
							clearAll: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							clear: {
								url: '/api/v1/core/cache/:name',
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Dashboard: function () {
					return $resource(
						'/api/v1/core/dashboard',
						{},
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Report: function () {
					return $resource(
						'/api/v1/core/report/top-products',
						{ id: '@id' },
						{
							findTopProducts: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							getProfitReport: {
								method: 'GET',
								url: '/api/v1/core/report/profit-revenue',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							getOrderStats: {
								method: 'GET',
								url: '/api/v1/core/report/order-stats',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							getSeminarReport: {
								method: 'GET',
								url: '/api/v1/core/report/seminar-report',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							getSeminarDetailReport: {
								method: 'GET',
								url: '/api/v1/core/report/seminar-detail-report/:id',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							getSeminarAppointments: {
								method: 'GET',
								url: '/api/v1/core/report/seminar-appointments/:id',
								headers: SessionValuesService.getFullSessionHeader(),
								isArray: true,
							},
						}
					);
				},
				/* WAWI*/
				PriceClass: function () {
					return $resource(
						'/api/v1/plugin/extended-prices/price-classes',
						{},
						{
							findAll: {
								url: '/api/v1/plugin/extended-prices/price-classes',
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
								params: {
									customerGroupId: '@customerGroupId',
								},
							},
						}
					);
				},
				Tag: function () {
					return $resource(
						'/api/v1/wawi/tags/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								url: '/api/v1/wawi/tags/:id/locales',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/wawi/tags/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Catalog: function () {
					return $resource(
						'/api/v1/shop-frontend/catalog/:slug',
						{ slug: '@slug' },
						{
							findAll: {
								method: 'GET',
								headers: {
									Authorization: 'Bearer ' + SessionValuesService.getToken(),
									'Current-Locale': SessionValuesService.getLocale(),
									'Current-Shop': SessionValuesService.getShop(),
									'Current-Currency': 'EUR',
								},
								cache: true,
							},
						}
					);
				},
				Category: function () {
					return $resource(
						'/api/v1/wawi/categories/:id',
						{ id: '@id', type: '@type' },
						{
							hierarchy: {
								method: 'GET',
								url: '/api/v1/wawi/categories/hierarchy/:type',
								headers: SessionValuesService.getFullSessionHeader(),
								isArray: true,
							},
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								url: '/api/v1/wawi/categories/:id/locales',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								url: '/api/v1/wawi/categories',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							updateTree: {
								method: 'PUT',
								url: '/api/v1/wawi/categories/hierarchy',
								headers: SessionValuesService.getFullSessionHeader(),
								isArray: true,
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							updateProductRelations: {
								method: 'PUI',
								url: '/api/v1/wawi/categories/products/:id',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Product: function () {
					return $resource(
						'/api/v1/wawi/products/:id',
						{ id: '@id', type: '@type', ids: '@ids', categoryId: '@categoryId' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								url: '/api/v1/wawi/products/:type/:id/locales',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findWithDefaultLocale: {
								method: 'GET',
								url: '/api/v1/wawi/products/:id',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								url: '/api/v1/wawi/products/:type/:id/',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								url: '/api/v1/wawi/products/:type',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/wawi/products/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							filterForBulkUpdate: {
								method: 'POST',
								url: '/api/v1/wawi/product-bulk-update/filter',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkUpdate: {
								method: 'POST',
								url: '/api/v1/wawi/product-bulk-update',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							updateSortOrder: {
								method: 'POST',
								url: '/api/v1/wawi/products/update-sort-order',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							updateCategorySortOrder: {
								method: 'POST',
								url: '/api/v1/wawi/products/update-sort-order/:categoryId',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findByIdsIn: {
								method: 'POST',
								url: '/api/v1/wawi/products/ids',
								headers: SessionValuesService.getFullSessionHeader(),
								isArray: true,
							},
							copyProducts: {
								method: 'POST',
								url: '/api/v1/wawi/products/copy-products',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							copyProductsCancelSync: {
								method: 'POST',
								url: '/api/v1/wawi/products/deactivate-synchronization',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				ProductMetadataValueSet: function () {
					return $resource(
						'/api/v1',
						{},
						{
							getVehicles: {
								method: 'GET',
								url: '/api/v1/vehicles',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				ImportExport: function () {
					return $resource(
						'/api/v1/core/import-export',
						{},
						{
							importCSV: {
								method: 'POST',
								url: '/api/v1/core/import-export/import-csv',
								headers: {
									Authorization: 'Bearer ' + SessionValuesService.getToken(),
									'Content-Type': undefined,
									'Current-Locale': SessionValuesService.getLocale(),
									'Current-Shop': SessionValuesService.getShop(),
								},
								transformRequest: function (data) {
									var formData = new FormData();
									angular.forEach(data, function (value, key) {
										if (value !== undefined) {
											formData.append(key, value);
										}
									});
									return formData;
								},
							},
							validateCSV: {
								method: 'POST',
								url: '/api/v1/core/import-export/validate-csv',
								headers: {
									Authorization: 'Bearer ' + SessionValuesService.getToken(),
									'Content-Type': undefined,
									'Current-Locale': SessionValuesService.getLocale(),
									'Current-Shop': SessionValuesService.getShop(),
								},
								transformRequest: function (data) {
									var formData = new FormData();
									angular.forEach(data, function (value, key) {
										if (value !== undefined) {
											formData.append(key, value);
										}
									});
									return formData;
								},
							},
							importRecipientsCSV: {
								method: 'POST',
								url: '/api/v1/core/import-export/import-recipients-csv',
								headers: {
									Authorization: 'Bearer ' + SessionValuesService.getToken(),
									'Content-Type': undefined,
									'Current-Locale': SessionValuesService.getLocale(),
									'Current-Shop': SessionValuesService.getShop(),
								},
								transformRequest: function (data) {
									var formData = new FormData();
									angular.forEach(data, function (value, key) {
										if (value !== undefined) {
											formData.append(key, value);
										}
									});
									return formData;
								},
							},
						}
					);
				},
				SellingProduct: function () {
					return $resource(
						'/api/v1/wawi/products/:id',
						{ id: '@id', type: '@type', ids: '@ids' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								url: '/api/v1/wawi/products/:type/:id/locales',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								url: '/api/v1/wawi/products/:type/:id/',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								url: '/api/v1/wawi/products/:type',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/wawi/products/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							filterForBulkUpdate: {
								method: 'POST',
								url: '/api/v1/wawi/product-bulk-update/filter',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkUpdate: {
								method: 'POST',
								url: '/api/v1/wawi/product-bulk-update',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findByIdsIn: {
								method: 'POST',
								url: '/api/v1/wawi/products/ids',
								headers: SessionValuesService.getFullSessionHeader(),
								isArray: true,
							},
						}
					);
				},
				VariantProduct: function () {
					return $resource(
						'/api/v1/wawi/products/:id',
						{ id: '@id', type: '@type', ids: '@ids' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								url: '/api/v1/wawi/products/:type/:id/locales',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								url: '/api/v1/wawi/products/:type/:id/',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								url: '/api/v1/wawi/products/:type',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/wawi/products/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							filterForBulkUpdate: {
								method: 'POST',
								url: '/api/v1/wawi/product-bulk-update/filter',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkUpdate: {
								method: 'POST',
								url: '/api/v1/wawi/product-bulk-update',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findByIdsIn: {
								method: 'POST',
								url: '/api/v1/wawi/products/ids',
								headers: SessionValuesService.getFullSessionHeader(),
								isArray: true,
							},
						}
					);
				},
				RefinementProduct: function () {
					return $resource(
						'/api/v1/wawi/refinement-products/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/wawi/refinement-products/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Seminar: function () {
					return $resource(
						'/api/v1/wawi/products/seminars/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								url: '/api/v1/wawi/products/seminars/:id/locales',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								url: '/api/v1/wawi/products/:id',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/wawi/products/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				ParticipantType: function () {
					return $resource(
						'/api/v1/wawi/seminarparticipanttypes',
						{},
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
								isArray: true,
							},
						}
					);
				},
				Location: function () {
					return $resource(
						'/api/v1/wawi/locations',
						{},
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				OrderState: function () {
					return $resource(
						'/api/v1/om/states/:stateType',
						{ stateType: '@stateType' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Cancellation: function () {
					return $resource(
						'/api/v1/om/cancellations/:id',
						{ id: '@id' },
						{
							findByShopOrderId: {
								method: 'GET',
								url: '/api/v1/om/cancellations/order/:id',
								headers: SessionValuesService.getFullSessionHeader('EUR'),
								isArray: true,
							},
							delete: {
								method: 'DELETE',
								url: '/api/v1/om/cancellations/:id',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Order: function () {
					return $resource(
						'/api/v1/om/orders/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/om/orders/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				PayPal: function () {
					return $resource(
						'/api/v1/om/checkout/paypal',
						{ id: '@id' },
						{
							fetchTransactionInfo: {
								method: 'GET',
								url: '/api/v1/om/checkout/paypal/info',
								headers: SessionValuesService.getFullSessionHeader(),
								isArray: true,
							},
							refund: {
								method: 'GET',
								url: '/api/v1/om/checkout/paypal/refund',
								headers: SessionValuesService.getFullSessionHeader(),
							}
						}
					);
				},
				ShopOrder: function () {
					return $resource(
						'/api/v1/om/orders/send-email',
						{},
						{
							sendEmail: {
								method: 'POST',
								url: '/api/v1/om/orders/send-email',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				DropShipping: function () {
					return $resource(
						'/api/v1/core/drop-shipping/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/core/drop-shipping/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				DropShippingOrder: function () {
					return $resource(
						'/api/v1/core/drop-shipping/order/:id',
						{ id: '@id' },
						{
							findByShopOrderId: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Attribute: function () {
					return $resource(
						'/api/v1/wawi/attributes/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								url: '/api/v1/wawi/attributes/:id/locales',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/wawi/attributes/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							getTypes: {
								method: 'GET',
								url: '/api/v1/wawi/attributes/types',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							deleteOption: {
								method: 'DELETE',
								url: '/api/v1/wawi/attributes/attribute-option/:id',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							isAttributeOptionAssigned: {
								method: 'GET',
								url: '/api/v1/wawi/attributes/is-attribute-option-assigned/:id',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				OrderManagement: function () {
					return $resource(
						'/api/v1/om/users/login',
						{},
						{
							login: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							register: {
								method: 'POST',
								url: '/api/v1/om/users/register',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							logOut: {
								method: 'GET',
								url: '/api/v1/om/users/log-out/:shopId',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							loadSettings: {
								method: 'GET',
								url: '/api/v1/om/users/load-settings/:shopId',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							saveSettings: {
								method: 'POST',
								url: '/api/v1/om/users/save-settings/:shopId',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							createShop: {
								method: 'POST',
								url: '/api/v1/om/users/create-shop/:shopId',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							getShopParameters: {
								method: 'GET',
								url: '/api/v1/om/users/get-shop-parameters/:shopId/:orderManagementShopId',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							setShopParameters: {
								method: 'POST',
								url: '/api/v1/om/users/set-shop-parameters/:shopId/:orderManagementShopId',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							createShippingLabel: {
								method: 'POST',
								url: '/api/v1/om/shipping-labels/create/:shopId',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							printShippingLabel: {
								method: 'POST',
								url: '/api/v1/om/shipping-labels/print/:shopId',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							cancelShippingLabel: {
								method: 'POST',
								url: '/api/v1/om/shipping-labels/cancel',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							completeShipping: {
								method: 'GET',
								url: '/api/v1/om/shipping-labels/complete/:shopId/:from/:to',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							setupTracking: {
								method: 'POST',
								url: '/api/v1/om/users/setup-tracking-bot',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				ShippingLabel: function () {
					return $resource(
						'/api/v1/om/shipping-labels/:id',
						{ id: '@id', carrierConstant: '@carrierConstant', shop: '@shop' },
						{
							createShippingLabel: {
								method: 'POST',
								isArray: true,
								headers: SessionValuesService.getFullSessionHeader(),
							},
							printShippingLabel: {
								method: 'POST',
								responseType: 'arraybuffer',
								transformResponse: function (data, headersGetter) {
									// Stores the ArrayBuffer object in a property called "data"
									return { data: data };
								},
								url: '/api/v1/om/shipping-labels/print',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							loadSettings: {
								url: '/api/v1/om/shipping-labels/settings/:shop/:carrierConstant',
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							saveDPDSettings: {
								url: '/api/v1/om/shipping-labels/settings/:shop/dpd',
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							saveDHLSettings: {
								url: '/api/v1/om/shipping-labels/settings/:shop/dhl',
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				/*ECONNECT*/
				Automation: function () {
					return $resource(
						'/api/v1/econnect/automations/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/econnect/automations/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				RecipientList: function () {
					return $resource(
						'/api/v1/econnect/recipientlists/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								url: '/api/v1/econnect/recipientlists',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							findOne: {
								method: 'GET',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							delete: {
								method: 'DELETE',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							update: {
								method: 'POST',
								url: '/api/v1/econnect/recipientlists',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							create: {
								method: 'POST',
								url: '/api/v1/econnect/recipientlists',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/econnect/recipientlists/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							removeRecipient: {
								method: 'POST',
								url: '/api/v1/econnect/recipientlists/remove-recipient',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Campaign: function () {
					return $resource(
						'/api/v1/econnect/campaigns/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								url: '/api/v1/econnect/campaigns',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							findOne: {
								method: 'GET',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							delete: {
								method: 'DELETE',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							create: {
								method: 'POST',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							update: {
								method: 'POST',
								url: '/api/v1/econnect/campaigns',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/econnect/campaigns/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Mailtemplate: function () {
					return $resource(
						'/api/v1/econnect/mailtemplates/:id',
						{ id: '@id', recipientId: '@recipientId' },
						{
							findAll: {
								method: 'GET',
								url: '/api/v1/econnect/mailtemplates',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							findOne: {
								method: 'GET',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							delete: {
								method: 'DELETE',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							create: {
								method: 'POST',
								url: '/api/v1/econnect/mailtemplates',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							update: {
								method: 'POST',
								url: '/api/v1/econnect/mailtemplates',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							duplicate: {
								method: 'POST',
								url: '/api/v1/econnect/mailtemplates/duplicate/:id',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							send: {
								method: 'GET',
								url: '/api/v1/econnect/mailtemplates/:id/send/recipient/:recipientId',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							sendPreview: {
								method: 'POST',
								params: { id: '@id' },
								url: '/api/v1/econnect/mailtemplates/preview/:id',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/econnect/mailtemplates/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Newsletter: function () {
					return $resource(
						'/api/v1/econnect/newsletters/:id',
						{ id: '@id', recipientId: '@recipientId' },
						{
							findAll: {
								method: 'GET',
								url: '/api/v1/econnect/newsletters',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							findOne: {
								method: 'GET',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							delete: {
								method: 'DELETE',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							duplicate: {
								method: 'POST',
								url: '/api/v1/econnect/newsletters/duplicate/:id',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							create: {
								method: 'POST',
								url: '/api/v1/econnect/newsletters',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							update: {
								method: 'POST',
								url: '/api/v1/econnect/newsletters',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/econnect/newsletters/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							send: {
								method: 'POST',
								url: '/api/v1/econnect/newsletters/send/:id',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Recipient: function () {
					return $resource(
						'/api/v1/econnect/recipients/:id',
						{ id: '@id', recipientListId: '@recipientListId' },
						{
							findAll: {
								method: 'GET',
								url: '/api/v1/econnect/recipients',
								params: {
									page: '@page',
									size: '@size',
									q: '@q',
								},
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							sendOptIns: {
								method: 'GET',
								url: '/api/v1/econnect/recipients/optin/request',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							sendBulkOptIns: {
								method: 'POST',
								url: '/api/v1/econnect/recipients/bulk-optin/request',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							findOne: {
								method: 'GET',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							delete: {
								method: 'DELETE',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							create: {
								method: 'POST',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							update: {
								method: 'POST',
								url: '/api/v1/econnect/recipients',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							findByRecipientListId: {
								method: 'GET',
								url: '/api/v1/econnect/recipients/recipientlist/:recipientListId',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/econnect/recipients/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				EconStatistics: function () {
					return $resource(
						'/api/v1/econnect/statistics',
						{},
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							sendDayliMail: {
								method: 'GET',
								url: '/api/v1/econnect/statistics/dayli-report/',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							getTrackedLinkTrackedClicks: {
								method: 'GET',
								url: '/api/v1/econnect/statistics/tracked-link/:trackedLinkId/tracked-clicks',
								headers: SessionValuesService.getFullSessionHeader(),
								isArray: true,
								params: {
									trackedLinkId: ':trackedLinkId',
								},
							},
						}
					);
				},
				EconSetting: function () {
					return $resource(
						'/api/v1/econnect/settings',
						{},
						{
							updateSettings: {
								method: 'PUT',
								isArray: true,
								headers: SessionValuesService.getFullSessionHeader(),
							},
							getSettings: {
								method: 'GET',
								isArray: true,
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				DataFeed: function () {
					return $resource(
						'/api/v1/media/data-feeds',
						{},
						{
							transferDataFeed: {
								params: { displayName: '@displayName' },
								method: 'POST',
								url: '/api/v1/media/data-feeds',
								headers: {
									Authorization: 'Bearer ' + SessionValuesService.getToken(),
									'Content-Type': undefined,
									'Current-Locale': SessionValuesService.getLocale(),
									'Current-Shop': SessionValuesService.getShop(),
								},
								transformRequest: function (data) {
									var fd = new FormData();
									angular.forEach(data, function (value, key) {
										if (key != 'id') {
											fd.append(key, value);
										}
									});
									return fd;
								},
							},
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				/*CRM*/
				Customer: function () {
					return $resource(
						'/api/v1/crm/customers/:id',
						{ id: '@id', imageId: '@imageId', documentId: '@documentId', query: '@query' },
						{
							findOrders: {
								url: '/api/v1/crm/customers/:id/orders',
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/crm/customers/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							transferImage: {
								method: 'POST',
								url: '/api/v1/crm/customer/:id/transfer-image',
								headers: {
									Authorization: 'Bearer ' + SessionValuesService.getToken(),
									'Content-Type': undefined,
									'Current-Locale': SessionValuesService.getLocale(),
									'Current-Shop': SessionValuesService.getShop(),
								},
								transformRequest: function (data) {
									var fd = new FormData();
									angular.forEach(data, function (value, key) {
										if (key != 'id') {
											fd.append(key, value);
										}
									});
									return fd;
								},
							},
							transferDocument: {
								method: 'POST',
								url: '/api/v1/crm/customer/:id/transfer-document',
								headers: {
									Authorization: 'Bearer ' + SessionValuesService.getToken(),
									'Content-Type': undefined,
									'Current-Locale': SessionValuesService.getLocale(),
									'Current-Shop': SessionValuesService.getShop(),
								},
								transformRequest: function (data) {
									var fd = new FormData();
									angular.forEach(data, function (value, key) {
										if (key != 'id') {
											fd.append(key, value);
										}
									});
									return fd;
								},
							},
							getDocuments: {
								method: 'GET',
								url: '/api/v1/crm/customer/:id/get-documents',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							updateDocuments: {
								method: 'PUT',
								url: '/api/v1/crm/customer/:id/update-documents',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							getImages: {
								method: 'GET',
								url: '/api/v1/crm/customer/:id/get-images',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							deleteImage: {
								method: 'DELETE',
								url: '/api/v1/crm/customer/:id/delete-image/:imageId',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							deleteDocument: {
								method: 'DELETE',
								url: '/api/v1/crm/customer/:id/delete-document/:documentId',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							deleteDataFeed: {
								method: 'DELETE',
								url: '/api/v1/crm/customer/:id/delete-data-feed/:dataFeedId',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							initPasswordReset: {
								method: 'GET',
								url: '/api/v1/crm/customers/auth/:email/password-reset',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							initEmailReset: {
								method: 'GET',
								url: '/api/v1/crm/customers/auth/:email/email-reset',
								headers: SessionValuesService.getFullSessionHeader(),
								transformResponse: function (email) {
									return { email: email };
								},
							},
							search: {
								method: 'GET',
								url: '/api/v1/crm/customers/search/:query',
								headers: SessionValuesService.getFullSessionHeader(),
								isArray: true,
							},
							sendOptIns: {
								method: 'GET',
								url: '/api/v1/crm/customers/optin/byadmin',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
						}
					);
				},
				Organisation: function () {
					return $resource(
						'/api/v1/crm/organisations/:id',
						{ id: '@id', imageId: '@imageId', documentId: '@documentId', email: '@email' },
						{
							findOrders: {
								url: '/api/v1/crm/organisations/:id/orders',
								method: 'GET',
								isArray: true,
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/crm/organisations/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							transferImage: {
								method: 'POST',
								url: '/api/v1/crm/customer/:id/transfer-image',
								headers: {
									Authorization: 'Bearer ' + SessionValuesService.getToken(),
									'Content-Type': undefined,
									'Current-Locale': SessionValuesService.getLocale(),
									'Current-Shop': SessionValuesService.getShop(),
								},
								transformRequest: function (data) {
									var fd = new FormData();
									angular.forEach(data, function (value, key) {
										if (key != 'id') {
											fd.append(key, value);
										}
									});
									return fd;
								},
							},
							transferDocument: {
								method: 'POST',
								url: '/api/v1/crm/customer/:id/transfer-document',
								headers: {
									Authorization: 'Bearer ' + SessionValuesService.getToken(),
									'Content-Type': undefined,
									'Current-Locale': SessionValuesService.getLocale(),
									'Current-Shop': SessionValuesService.getShop(),
								},
								transformRequest: function (data) {
									var fd = new FormData();
									angular.forEach(data, function (value, key) {
										if (key != 'id') {
											fd.append(key, value);
										}
									});
									return fd;
								},
							},
							getDocuments: {
								method: 'GET',
								url: '/api/v1/crm/customer/:id/get-documents',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							getImages: {
								method: 'GET',
								url: '/api/v1/crm/customer/:id/get-images',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							deleteImage: {
								method: 'DELETE',
								url: '/api/v1/crm/customer/:id/delete-image/:imageId',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							deleteDocument: {
								method: 'DELETE',
								url: '/api/v1/crm/customer/:id/delete-document/:documentId',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							initPasswordReset: {
								method: 'GET',
								url: '/api/v1/crm/customers/auth/:email/password-reset',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOrganisationCustomers: {
								method: 'GET',
								url: '/api/v1/crm/organisations/organisation-customer/:id',
								headers: SessionValuesService.getFullSessionHeader(),
								isArray: true,
							},
						}
					);
				},
				Prospect: function () {
					return $resource(
						'/api/v1/crm/prospective-customers/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/crm/prospective-customers/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							} /*,
                                 register: {
                                 method: 'GET',
                                 url: '/primarkets/api/v1/customer/register',
                                 headers: SessionValuesService.getFullSessionHeader()
                                 },
                                 initPasswordReset: {
                                 method: 'GET',
                                 url: '/primarkets/api/v1/customer/request-password-reset-via-email',
                                 headers: SessionValuesService.getFullSessionHeader()
                                 },
                                 info: {
                                 method: 'GET',
                                 url: '/primarkets/api/v1/customer/:id/customer-information',
                                 headers: SessionValuesService.getFullSessionHeader()
                                 }*/,
						}
					);
				},
				Manufacturer: function () {
					return $resource(
						'/api/v1/crm/organisations/manufacturer',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							getRadiusBasedManufacturerLocation: {
								method: 'POST',
								url: '/api/v1/crm/organisations/manufacturer-by-location',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Industry: function () {
					return $resource(
						'/api/v1/crm/industries/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/crm/industries/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				SystemInformation: function () {
					return $resource(
						'/api/v1/core/versions',
						{},
						{
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							getAdminVersion: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
								url: 'package.json',
							},
						}
					);
				},
				SystemMessage: function () {
					return $resource(
						'/api/v1/crm/systemMessages/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/crm/systemMessages/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Classification: function () {},
				SystemMessage: function () {
					return $resource(
						'/api/v1/crm/systemMessages/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/crm/systemMessages/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Classification: function () {
					return $resource(
						'/api/v1/crm/classifications/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/crm/classifications/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Reference: function () {
					return $resource(
						'/api/v1/crm/references',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Title: function () {
					return $resource(
						'/api/v1/crm/titles',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				SocialMediaProfilType: function () {
					return $resource(
						'/api/v1/crm/social-media-profile-types/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Task: function () {
					return $resource(
						'/api/v1/crm/customers/:customerId/tasks/:id',
						{ customerId: '@customerId', id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/crm/customers/:customerId/tasks/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Note: function () {
					return $resource(
						'/api/v1/crm/customers/:customerId/notes/:id',
						{ customerId: '@customerId', id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/crm/customers/:customerId/notes/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Ticket: function () {
					return $resource(
						'/api/v1/crm/tickets/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/crm/tickets/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				/*CMS*/
				FilterPage: function () {
					return $resource(
						'/api/v1/cms/filter-pages/:id',
						{ id: '@id' },
						{
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/cms/filter-pages/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				TemplatePage: function () {
					return $resource(
						'/api/v1/cms/template-pages/:id',
						{ id: '@id' },
						{
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/cms/template-pages/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				ContentPage: function () {
					return $resource(
						'/api/v1/cms/content-pages/:id',
						{ id: '@id' },
						{
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/cms/content-pages/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				GlossaryPage: function () {
					return $resource(
						'/api/v1/cms/glossary-pages/:id',
						{ id: '@id' },
						{
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/cms/glossary-pages/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							setShopGlossary: {
								method: 'POST',
								url: '/api/v1/cms/glossary-pages/set-shop-glossary',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				LandingPage: function () {
					return $resource(
						'/api/v1/cms/landing-pages/:id',
						{ id: '@id' },
						{
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/cms/landing-pages/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				CmsTag: function () {
					return $resource(
						'/api/v1/cms/tags/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								url: '/api/v1/cms/tags/:id/locales',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/cms/tags/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				ProductRating: function () {
					return $resource(
						'/api/v1/cms/product-rating/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								url: '/api/v1/cms/product-rating/:id/',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/cms/product-rating/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				ProductFaq: function () {
					return $resource(
						'/api/v1/admin/cms/product-faq/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								url: '/api/v1/admin/cms/product-faq/:id/',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/admin/cms/product-faq/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Node: function () {
					return $resource(
						'/api/v1/cms/nodes',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Image: function () {
					return $resource(
						'/api/v1/media/images/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								url: '/api/v1/media/browse-directories',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findDirectory: {
								method: 'GET',
								url: '/api/v1/media/browse-directories/:id',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								url: '/api/v1/media/images/:id',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							save: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Media: function () {
					return $resource(
						'/api/v1/media/files/:id',
						{ id: '@id' },
						{
							save: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							getDirectories: {
								method: 'GET',
								url: '/api/v1/media/browse-directories',
								headers: SessionValuesService.getFullSessionHeader(),
								params: {
									page: '@page',
									size: '@size',
									directoryId: '@directoryId',
									q: '@q',
									fileType: 'FILE',
								},
							},
						}
					);
				},
				/*SETTINGS*/
				CouponCode: function () {
					return $resource(
						'/api/v1/wawi/coupon-codes/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/wawi/coupon-codes/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				PriceRule: function () {
					return $resource(
						'/api/v1/wawi/shopping-cart-rules/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/wawi/shopping-cart-rules/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				MetaDataSet: function () {
					return $resource(
						'/api/v1/wawi/metadata-sets/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/wawi/metadata-sets/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				MediaMetadata: function () {
					return $resource(
						'/api/v1/media/files/:id/metadata',
						{ id: '@id' },
						{
							updateOne: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							getOne: {
								method: 'GEt',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				MetaData: function () {
					return $resource(
						'/api/v1/wawi/metadata/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								url: '/api/v1/wawi/metadata/:id/locales',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							updateAll: {
								method: 'PUT',
								url: '/api/v1/wawi/metadata/updateall',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							updatePosition: {
								method: 'PUT',
								url: '/api/v1/wawi/metadata/updateposition',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/wawi/metadata/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findValues: {
								method: 'GET',
								url: '/api/v1/wawi/metadata/:id',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Tax: function () {
					return $resource(
						'/api/v1/core/taxes/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/core/taxes/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				DataFeedInput: function () {
					return $resource(
						'/api/v1/core/data-feed-input/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/core/data-feed-input/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							generateDataFeed: {
								method: 'GET',
								url: '/api/v1/core/data-feed-input/:id/generate',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				ShopOrderProtocol: function () {
					return $resource(
						'/api/v1/om/shop-order-protocol',
						{},
						{
							get: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
								isArray: true,
							},
						}
					);
				},
				Unit: function () {
					return $resource(
						'/api/v1/core/units/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findAllVolume: {
								method: 'GET',
								url: '/api/v1/core/units/volume',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findAllWeight: {
								method: 'GET',
								url: '/api/v1/core/units/weight',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/core/units/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				CustomerGroup: function () {
					return $resource(
						'/api/v1/core/customer-groups/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(), // only findAll request needs custom shopId
								params: {
									shopId: '@shopId',
								},
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/core/customer-groups/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				BeePlugin: function () {
					return $resource(
						'/api/v1/core/bee/token',
						{},
						{
							getToken: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Currency: function () {
					return $resource(
						'/api/v1/core/currencies/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/core/currencies/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				BankAccount: function () {
					return $resource(
						'/api/v1/core/bank-accounts/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/core/bank-accounts/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Shop: function () {
					return $resource(
						'/api/v1/core/shops/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							shopLocales: {
								method: 'GET',
								url: '/api/v1/core/shops/:id/locales',
								headers: {
									'Current-Locale':
										localStorageService.get('_locale') != null
											? localStorageService.get('_locale')
											: '',
									'Current-Shop':
										localStorageService.get('_shop') != null
											? localStorageService.get('_shop')
											: '',
									Authorization: 'Bearer ' + localStorageService.get('_token'),
								},
								isArray: true,
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/core/shops/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				SeoSettings: function () {
					return $resource(
						'/api/v1/core/seo-settings/',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								url: '/api/v1/core/seo-settings/sitemap/:id',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							generateSitemap: {
								method: 'GET',
								url: '/api/v1/core/seo-settings/generate-sitemap/:id',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							downloadSitemap: {
								method: 'GET',
								url: '/api/v1/core/seo-settings/download-sitemap/:id',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				PluginSettingTemplates: function () {
					return $resource(
						'/api/v1/core/shops/plugin-settings-templates',
						{},
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
								isArray: true,
							},
						}
					);
				},
				PublishState: function () {
					return $resource(
						'/api/v1/core/publish-states/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				PaymentMethod: function () {
					return $resource(
						'/api/v1/core/payment-methods/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				PaymentPolicy: function () {
					return $resource(
						'/api/v1/core/payment-policy',
						{ id: '@id' },
						{
							getTemplates: {
								method: 'GET',
								url: '/api/v1/core/payment-policy/templates',
								headers: SessionValuesService.getFullSessionHeader(),
								isArray: true,
							},
						}
					);
				},
				Locale: function () {
					return $resource(
						'/api/v1/core/locales/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				ShippingMethod: function () {
					return $resource(
						'/api/v1/core/shipping-methods/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				DeliveryService: function () {
					return $resource(
						'/api/v1/core/shops/:shopId/delivery-services',
						{ shopId: '@shopId' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
								isArray: true,
							},
						}
					);
				},
				Country: function () {
					return $resource(
						'/api/v1/core/countries/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				Log: function () {
					return $resource(
						'/api/v1/core/logs',
						{},
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				User: function () {
					return $resource(
						'/api/v1/core/users/:id',
						{ id: '@id', emailOrUsername: '@emailOrUsername' },
						{
							login: {
								method: 'POST',
								url: '/api/v1/auth/token-auth',
							},
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/core/users/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							passwordReset: {
								method: 'GET',
								url: '/api/v1/crm/customers/auth/:emailOrUsername/password-reset/admin',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							passwordResetActivation: {
								method: 'POST',
								url: '/api/v1/crm/customers/auth/password-reset-activation',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							//						logout: {
							//							method: 'GET',
							//							url: '/api/v1/user/logout',
							//							headers: {'Authorization': 'Bearer ' + SessionValuesService.getToken()}
							//						},
							//                            status: {
							//                                method: 'GET',
							//                                url: '/primarkets/api/v1/user/status'
							//							headers: {'Authorization': 'Bearer ' + SessionValuesService.getToken()}
							//                            }
						}
					);
				},
				Role: function () {
					return $resource(
						'/api/v1/core/roles/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},

				SystemFile: function () {
					return $resource(
						'/api/v1/media/system-files',
						{},
						{
							transferSystemFile: {
								params: { displayName: '@displayName' },
								method: 'POST',
								url: '/api/v1/media/system-files',
								headers: {
									Authorization: 'Bearer ' + SessionValuesService.getToken(),
									'Content-Type': undefined,
									'Current-Locale': SessionValuesService.getLocale(),
									'Current-Shop': SessionValuesService.getShop(),
								},
								transformRequest: function (data) {
									var fd = new FormData();
									angular.forEach(data, function (value, key) {
										if (key != 'id') {
											fd.append(key, value);
										}
									});
									return fd;
								},
							},
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},

				/*
                         IMEX
                         */
				ImExProfile: function () {
					return $resource(
						'/api/v1/imex/imex-profiles/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/imex/imex-profiles/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				ImExType: function () {
					return $resource(
						'/api/v1/imex/imex-types/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				ImExEntityType: function () {
					return $resource(
						'/api/v1/imex/imex-entity-types/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				ImExMappingProperty: function () {
					return $resource(
						'/api/v1/imex/imex-mapping-properties/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},
				ServerProfile: function () {
					return $resource(
						'/api/v1/imex/server-profiles/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							findOne: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							create: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							update: {
								method: 'PUT',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(),
							},
							bulkDelete: {
								method: 'POST',
								url: '/api/v1/imex/server-profiles/bulk-delete',
								headers: SessionValuesService.getFullSessionHeader(),
							},
						}
					);
				},

				/*OLD*/
				Shipment: function () {
					return $resource(
						'/primarkets/api/v1/shipment',
						{},
						{
							findAll: {
								method: 'GET',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							saveAll: {
								method: 'POST',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
						}
					);
				},
				ShippingCountries: function () {
					return $resource(
						'/primarkets/api/v1/countries',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
						}
					);
				},
				ShippingFieldTypes: function () {
					return $resource(
						'/primarkets/api/v1/shipping/rules/available-field-types',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								isArray: true,
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
						}
					);
				},
				ShippingOperators: function () {
					return $resource(
						'/primarkets/api/v1/shipping/rules/available-operators',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								isArray: true,
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
						}
					);
				},
				ShippingActions: function () {
					return $resource(
						'/primarkets/api/v1/shipping/rules/available-actions',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								isArray: true,
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
						}
					);
				},
				Address: function () {
					return $resource(
						'/primarkets/api/v1/customer/:id/addresses',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							save: {
								method: 'POST',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							delete: {
								method: 'DELETE',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							setDefault: {
								method: 'POST',
								url: 'default-address',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
						}
					);
				},
				Content: function () {
					return $resource(
						'/primarkets/api/v1/admin/contents/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							findOne: {
								method: 'GET',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							create: {
								method: 'POST',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							update: {
								method: 'PUT',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							delete: {
								method: 'DELETE',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
						}
					);
				},
				ShippingGroup: function () {
					return $resource(
						'/primarkets/api/v1/shipping/groups/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							findOne: {
								method: 'GET',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							update: {
								method: 'PUT',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							create: {
								method: 'POST',
								url: '/primarkets/api/v1/shipping/groups',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							delete: {
								method: 'DELETE',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
						}
					);
				},
				ShippingRule: function () {
					return $resource(
						'/primarkets/api/v1/shipping/local-rules/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							findOne: {
								method: 'GET',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							create: {
								method: 'POST',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							update: {
								method: 'PUT',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							delete: {
								method: 'DELETE',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
						}
					);
				},
				ShippingGlobal: function () {
					return $resource(
						'/primarkets/api/v1/shipping/global-rules/:id',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							findOne: {
								method: 'GET',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							delete: {
								method: 'DELETE',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							update: {
								method: 'PUT',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							create: {
								method: 'POST',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
						}
					);
				},
				CustomerInfo: function () {
					return $resource(
						'/primarkets/api/v1/customer/:id/customer-information',
						{ id: '@id' },
						{
							findAll: {
								method: 'GET',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							findOne: {
								method: 'GET',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
						}
					);
				},
				OrderManagementSettings: function () {
					return $resource(
						'/primarkets/api/v1/ordermanagement',
						{},
						{
							findAll: {
								method: 'GET',
								url: '/primarkets/api/v1/ordermanagement/settings',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							save: {
								method: 'POST',
								url: '/primarkets/api/v1/ordermanagement/settings',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
							update: {
								method: 'POST',
								url: '/primarkets/api/v1/ordermanagement/settings',
								headers: { Authorization: 'Bearer ' + SessionValuesService.getToken() },
							},
						}
					);
				},
				ShoppingCart: function (currencyIso) {
					return $resource(
						'/api/v1/om/shopping-cart',
						{},
						{
							save: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(currencyIso),
							},
							load: {
								method: 'GET',
								headers: SessionValuesService.getFullSessionHeader(currencyIso),
							},
							delete: {
								method: 'DELETE',
								headers: SessionValuesService.getFullSessionHeader(currencyIso),
							},
							reorder: {
								method: 'POST',
								url: '/api/v1/om/shopping-cart/reorder',
								headers: SessionValuesService.getFullSessionHeader(currencyIso),
							},
						}
					);
				},
				Checkout: function (currencyIso) {
					return $resource(
						'/api/v1/om/checkout',
						{},
						{
							proceed: {
								method: 'POST',
								headers: SessionValuesService.getFullSessionHeader(currencyIso),
							},
							createPayment: {
								method: 'POST',
								url: '/api/v1/om/checkout/create-payment',
								headers: SessionValuesService.getFullSessionHeader(currencyIso),
							},
							updatePayment: {
								method: 'POST',
								url: '/api/v1/om/checkout/update-payment',
								headers: SessionValuesService.getFullSessionHeader(currencyIso),
							},
							createSofortPayment: {
								method: 'POST',
								url: '/api/v1/om/checkout/create-sofort-payment',
								headers: SessionValuesService.getFullSessionHeader(currencyIso),
							},
							executePayment: {
								method: 'POST',
								url: '/api/v1/om/checkout/execute-payment',
								headers: SessionValuesService.getFullSessionHeader(currencyIso),
							},
							guest: {
								method: 'POST',
								url: '/api/v1/om/checkout/guest',
								headers: SessionValuesService.getFullSessionHeader(currencyIso),
							},
						}
					);
				},
				Document: function () {
					return $resource(
						'/api/v1/media/document',
						{},
						{
							transferDocument: {
								method: 'POST',
								headers: {
									Authorization: 'Bearer ' + SessionValuesService.getToken(),
									'Content-Type': undefined,
									'Current-Locale': SessionValuesService.getLocale(),
									'Current-Shop': SessionValuesService.getShop(),
								},
								transformRequest: function (data) {
									var fd = new FormData();
									angular.forEach(data, function (value, key) {
										if (key !== 'id') {
											fd.append(key, value);
										}
									});
									return fd;
								},
							},
						}
					);
				},
			};
		},
	]);
});

