define('modules/crm/controllers/CustomerSelectProductController',[
	'modules/module',
	'modules/basemodule/controllers/EntityListController',
	'modules/wawi/product/controllers/ProductController',
], function (module) {
	'use strict';
	module.controller('CustomerSelectProductController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		CustomerSelectProductController,
	]);
});

function CustomerSelectProductController($scope, $controller, BASE_TEMPLATES_PATH) {
	angular.extend(
		this,
		$controller('ProductController', {
			$scope: $scope,
		})
	);

	$scope.tableOptions.fields = [
		{
			id: 'THUMB_IMG',
			heading: 'FIELDS.IMAGE',
			template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-thumb-col.html',
			searchDisabled: true,
			orderDisabled: true,
		},
		{
			id: 'STATUS',
			heading: 'FIELDS.STATUS',
			attribute: 'publishState',
			searchDisabled: true,
		},
		{
			id: 'PRODUCT_TYPE',
			heading: 'FIELDS.PRODUCT_TYPE',
			attribute: 'productType',
			searchDisabled: true,
			orderDisabled: true,
		},
		{
			id: 'NAME',
			heading: 'FIELDS.NAME',
			template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-name-col.html',
			attribute: 'name',
		},
		{
			id: 'SKU',
			heading: 'FIELDS.SKU',
			attribute: 'sku',
		},
		{
			id: 'SLUG',
			heading: 'FIELDS.SLUG',
			attribute: 'slug',
		},
		{
			id: 'CATEGORY',
			heading: 'FIELDS.CATEGORIES',
			template: BASE_TEMPLATES_PATH + '/wawi/product/col/product-category-col.html',
			attribute: 'category',
			searchDisabled: true,
			orderDisabled: true,
		},
	];

	$scope.tableOptions.optionalFields = [];

	$scope.formState = 'root.product';

	$scope.init('Product').then(function () {});
}
;
