define('modules/settings/shop/tabs/DropShipmentTabController',[], function () {
	'use strict';

	return function DropShipmentController(
		$parentScope,
		$scope,
		{
			BASE_TEMPLATES_PATH,
			DropShippingService,
			PrroductService,
			NotificationService,
			ValidationService,
			OrganisationService,
			OrderStateUnpagedService,
		}
	) {
		$parentScope.formTabs.push({
			label: 'FIELDS.DROP_SHIPMENT',
			template: BASE_TEMPLATES_PATH + '/settings/shop/form-drop-shipping.html',
			service: DropShippingService,
		});

		$scope.dropShippings = [];
		$scope.selectedDropShippings = [];
		$scope.dropShippingListView = true;
		$scope.dropShippingModes = [
			{
				id: 'JUST_IN_TIME',
				display: 'Bestellungen sofort versenden',
			},
			{ id: 'COLLECT_ORDER', display: 'Bestellungen sammeln' },
		];
		$scope.shippingAddressInfos = [
			{ value: 'ALL', display: 'Alle Lieferanten' },
			{
				value: 'LAST_SUPPLIER',
				display: 'Letzter Lieferant',
			},
		];
		$scope.surchargeUnits = [
			{ id: 'PERCENT', display: 'Prozentual' },
			{
				id: 'AMOUNT_OF_MONEY',
				display: 'Betrag',
			},
		];
		$scope.dropShippingProductsQuery = {};

		$scope.dropShippingEntity = null;

		$scope.dropShippingProductsTableOptions = {};
		$scope.dropShippingProductsTableOptions.name = 'Produkte';
		$scope.dropShippingProductsTableOptions.fields = [
			{
				//id: 'NAME',
				heading: 'FIELDS.NAME',
				attribute: 'name',
			},
			{
				//id: 'SKU',
				heading: 'Sku',
				attribute: 'sku',
			},
		];

		$scope.addDropShipping = function () {
			$scope.dropShippingListView = false;
			$scope.dropShippingEntity = {};
			$scope.dropShippingEntity.selectedProducts = [];
			$scope.dropShippingEntity.organisationSequences = [{}];
			$scope.dropShippingEntity.productIds = [];
		};

		$scope.deleteDropShippings = function () {
			NotificationService.confirm({
				// TODO: translate
				statusText: 'Wollen sie dieses Streckengeschäft inklusive der Streckengeschäftsaufträge löschen?',
				msg: '',
			}).then(
				function () {
					var dropShippingIds = [];
					for (var i = 0; i < $scope.selectedDropShippings.length; i++) {
						dropShippingIds.push($scope.selectedDropShippings[i].id);
					}
					DropShippingService.bulkDelete(dropShippingIds).then(function () {
						DropShippingService.findAll().then(function () {
							$scope.dropShippings = DropShippingService.getAll();
						});
					});
				},
				function () {}
			);
		};

		$scope.init = () => {
			DropShippingService.findAll().then(function () {
				$scope.dropShippings = DropShippingService.getAll();
			});

			OrderStateUnpagedService.findAllOrderStates('ORDER').then(function () {
				$scope.orderStates = OrderStateUnpagedService.getAllOrderStates().data;
			});
		};

		$scope.dropShippingsTableOptions = {};
		$scope.dropShippingsTableOptions.fields = [
			{
				heading: 'Id',
				attribute: 'id',
			},
			{
				heading: 'FIELDS.NAME',
				attribute: 'name',
			},
			{
				heading: 'FIELDS.ACTIVE',
				attribute: 'active',
			},
		];

		$scope.dropShippingOnItemClick = function (item, field) {
			PrroductService.findByIdsIn(item.productIds).then(function (products) {
				$scope.dropShippingListView = false;
				item.selectedProducts = products;
				$scope.dropShippingEntity = item;
			});
		};

		$scope.addOrganisationSequence = function () {
			$scope.dropShippingEntity.organisationSequences.push({});
		};

		$scope.removeOrganisationSequence = function (index) {
			$scope.dropShippingEntity.organisationSequences.splice(index, 1);
		};

		$scope.saveDropShipping = function () {
			if ($scope.dropShippingEntity == null) {
				return true;
			}

			var validateConfig = {
				name: {
					method: 'isset',
					fieldName: 'Name',
				},
				organisationSequences: {
					method: 'isset',
					fieldName: 'Lieferantenabfolge',
				},
				//                                dropShippingMode: {
				//                                    method: "isset",
				//                                    fieldName: "Verfahrensart"
				//                                },
				processStartOrderStateId: {
					method: 'isset',
					fieldName: 'Prozessstart bei',
				},
				shippingSurcharge: {
					method: 'isset',
					fieldName: 'Versandkostenaufschlag',
				},
				surchargeUnit: {
					method: 'isset',
					fieldName: 'Versandkostenaufschlagseinheit',
				},
				//                                passTrackingNumber: {
				//                                    method: "isset",
				//                                    fieldName: "Weitergabe Trackingnummer"
				//                                },
				commissioning: {
					method: 'isset',
					fieldName: 'Kommisionierung',
				},
				shippingType: {
					method: 'isset',
					fieldName: 'Versandart',
				},
			};

			if (!ValidationService.validate($scope.dropShippingEntity, validateConfig)) {
				NotificationService.notifyValidationErrors(ValidationService.getErrors());
				return false;
			}

			$scope.dropShippingListView = true;
			if ($scope.dropShippingEntity.id) {
				DropShippingService.update($scope.dropShippingEntity).then(function () {
					DropShippingService.findAll().then(function () {
						$scope.dropShippings = DropShippingService.getAll();
					});
				});
			} else {
				DropShippingService.create($scope.dropShippingEntity).then(function () {
					DropShippingService.findAll().then(function () {
						$scope.dropShippings = DropShippingService.getAll();
					});
				});
			}
			return true;
		};

		$scope.dropShippingsSetPage = function () {
			DropShippingService.setCurrentPage(
				$scope.getRealCurrentPage($parentScope.selected.dropShippingsCurrentPage)
			);
		};

		$scope.createDropShipping = function () {
			DropShippingService.create({
				processStartOrderStateId: 8,
				commissioning: true,
				passTrackingNumber: true,
				dropShippingMode: 'JUST_IN_TIME',
				shippingSurcharge: 10.0,
				productIds: [136],
				organisationSequences: [{ organisationId: 5173, position: 1, localeId: 1 }],
			});
		};
	};
});

