define('modules/settings/tracking/TrackingController',['modules/module', 'services/TrackingService', 'filters/DateFilterTranslatable'], function (module) {
	'use strict';
	module.controller('TrackingController', [
		'$scope',
		'TrackingService',
		function ($scope, TrackingService) {
			$scope.setupTracking = function () {
				TrackingService.setupTracking();
			};
		},
	]);
});

