/**
 * Created by Nico on 26.07.2016.
 */
define('modules/wawi/refinementProduct/RefinementProductListController',['modules/module', 'modules/basemodule/controllers/EntityListController', 'settings'], function (module) {
	'use strict';
	module.controller('RefinementProductListController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		function ($scope, $controller, BASE_TEMPLATES_PATH) {
			angular.extend(
				this,
				$controller('EntityListController', {
					$scope: $scope,
				})
			);

			$scope.tableOptions.fields = [
				{
					id: 'NAME',
					heading: 'FIELDS.NAME',
					attribute: 'name',
				},
				{
					id: 'DESCRIPTION',
					heading: 'FIELDS.DESCRIPTION',
					attribute: 'description',
				},
				{
					id: 'COMPANY',
					heading: 'FIELDS.MANUFACTURER',
					attribute: 'companyName',
				},
				{
					id: 'MAXCOLORS',
					heading: 'FIELDS.COLORS',
					attribute: 'maxColors',
					searchDisabled: true,
					orderDisabled: true,
				},
				{
					id: 'MAXSIZE',
					heading: 'FIELDS.MAX_SIZE',
					attribute: 'maxSize',
					searchDisabled: true,
					orderDisabled: true,
				} /*,
                    {
                        id: 'DATE',
                        heading: 'Datum',
                        template: BASE_TEMPLATES_PATH + '/wawi/seminar/column/date-col.html',
                        attribute: 'startDate'
                    }*/,
			];

			$scope.formState = 'root.refinementProduct';

			$scope.init('RefinementProduct');
		},
	]);
});

