define('services/EconNewsletterService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('EconNewsletterService', [
		'$injector',
		'NotificationService',
		'$q',
		'ModelFactory',
		NewsletterService,
	]);
});

function NewsletterService($injector, NotificationService, $q, ModelFactory) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('Newsletter');

	angular.extend(this, crudInstance);

	this.send = function (id) {
		var ref = $q.defer();
		this.getModel()
			.send({ id: id })
			.$promise.then(function (response) {
				if (!response) {
					NotificationService.notifyGeneralError({
						statusText: 'Es ist ein Fehler beim Versand der Newsletter aufgetreten.',
					});
				}
				ref.resolve(response);
			});
		return ref.promise;
	};

	this.duplicate = function (id) {
		return this.getModel().duplicate({ id: id }).$promise;
	};

	return this;
}
;
