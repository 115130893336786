define('directives/contentConfigurator/_outline/Outline',['app', 'angular', 'services/ContentBuilderHelper'], function (app) {
	'use strict';
	app.directive('outline', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				restrict: 'EA',
				scope: {
					layoutObj: '<',
				},
				templateUrl: BASE_DIRECTIVES_PATH + '/contentConfigurator/_outline/views/outline.html',
				controller: [
					'$scope',
					'$rootScope',
					'$timeout',
					'ContentBuilderHelper',
					function ($scope, $rootScope, $timeout, ContentBuilderHelper) {
						$scope.showOutline = false;
						$scope.overrideShow = false;
						$scope.tree = [];
						$scope.pageStyle = {};

						var _init = function () {
							prepareData();
							// if ($scope.handle$ShowConfigOutline !== undefined) {
							//     $scope.handle$ShowConfigOutline();
							// }
						};

						var prepareData = function () {
							if (typeof $scope.layoutObj.json === 'string') {
								var json = angular.fromJson($scope.layoutObj.json);
								$scope.tree = json.root;
								$scope.pageStyle = json.pageStyle;
							} else {
								$scope.tree = $scope.layoutObj.json.root;
								$scope.pageStyle = $scope.layoutObj.json.pageStyle;
							}
						};
						$scope.nodeName = function (node) {
							var nodeName = node.type;
							if (node.type === 'stack') {
								nodeName = 'cell';
							} else if (node.type === 'layout') {
								nodeName = 'row';
							} else if (node.type === 'text') {
								if (typeof node.text !== 'undefined' && node.text.length > 0) {
									nodeName = node.text;
								}
							} else if (node.type === 'section') {
								if (typeof node.config !== 'undefined' && typeof node.config.type !== 'undefined') {
									nodeName = node.config.type;
								}
							}

							return nodeName[0].toUpperCase() + nodeName.slice(1).split(' ')[0];
						};
						$scope.select = function (node) {
							var metaIdentifier = ContentBuilderHelper.getCurrentInstanceIdentifier();
							if (typeof node === 'undefined') {
								ContentBuilderHelper.callOutSelected(metaIdentifier, 0, 'page', undefined, {
									style: $scope.pageStyle,
								});
								return;
							}
							var elementType = 'snippet';
							var snippetType = node.type;
							if (node.type === 'stack') {
								elementType = 'cell';
								snippetType = undefined;
							} else if (node.type === 'layout') {
								elementType = 'row';
								snippetType = undefined;
							}
							ContentBuilderHelper.callOutSelected(
								metaIdentifier,
								node.identifier,
								elementType,
								snippetType,
								node
							);
						};
						$scope.handle$ShowConfigOutline = $rootScope.$on('ToggleConfigOutline', function (event, args) {
							if (args.override) {
								$scope.overrideShow = args.override;
							} else {
								$scope.overrideShow = false;
							}
							if (args.state === undefined) {
								$scope.showOutline = !$scope.showOutline;
							} else {
								$scope.showOutline = args.state;
							}
						});

						// contentBuilderInstanceIdChange
						$scope.$on('$destroy', $scope.handle$ShowConfigOutline);
						$scope.$watchCollection('layoutObj', function (newValue, oldValue) {
							if (newValue !== undefined) {
								prepareData();
							}
						});

						_init();
					},
				],
			};
		},
	]);
});

