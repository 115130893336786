define('services/ShippingCarrierService',['app', 'modules/module', 'services/SessionValuesService'], function (app, module) {
	'use strict';
	return module.factory('ShippingCarrierService', [ShippingCarrierService]);
});

function ShippingCarrierService() {
	// TODO This service should use a plugin mechanism to rename generic shipping states to carrier specific ones

	// TODO Also, probably make them translatable

	const knCarrier = function (shippingStates) {
		// Kühne + Nagel

		const result = [];
		for (state of shippingStates) {
			switch (state.name) {
				case 'SHIPPING_STATE_ONE': {
					result.push(Object.assign({}, state, { displayName: 'Transportauftrag eingegangen' }));
					break;
				}
				case 'SHIPPING_STATE_TWO': {
					result.push(Object.assign({}, state, { displayName: 'Ware bei Versender abgeholt' }));
					break;
				}
				case 'SHIPPING_STATE_THREE': {
					result.push(Object.assign({}, state, { displayName: 'Ware ist unterwegs' }));
					break;
				}
				case 'SHIPPING_STATE_FOUR': {
					result.push(Object.assign({}, state, { displayName: 'Ware erfolgreich abgeliefert' }));
					break;
				}
				case 'SHIPPING_STATE_FIVE': {
					result.push(Object.assign({}, state, { displayName: 'Adresse unvollständig' }));
					break;
				}
				case 'SHIPPING_STATE_TEN': {
					result.push(Object.assign({}, state, { displayName: 'Unbekannter Auftragsstatus' }));
					break;
				}
			}
		}

		return result;
	};

	this.renameShippingStates = function (carrierId, shippingStates) {
		if (carrierId === 'Kühne + Nagel') {
			return knCarrier(shippingStates);
		}
	};

	return this;
}
;
