define('modules/orderManagement/controllers/OrderManagementSettingsFormController',['app', 'angular', 'settings', 'services/ShippingLabelService'], function (module) {
	'use strict';
	module.controller('OrderManagementSettingsFormController', [
		'$scope',
		'ShippingLabelService',
		'$controller',
		'BASE_TEMPLATES_PATH',
		function ($scope, ShippingLabelService, $controller, BASE_TEMPLATES_PATH) {
			$scope.formTabs = [
				{
					label: 'Einstellungen',
					template: BASE_TEMPLATES_PATH + '/orderManagement/partials/settings-modal.html',
					active: true,
				},
			];

			$scope.listState = 'root.ordermanagements';

			angular.extend(
				this,
				$controller('EntityFormController', {
					$scope: $scope,
				})
			);

			$scope.init('OrderManagementSettings').then(function () {
				$scope.newEntity = false;

				$scope.disabled = {
					invalidCredentials: true,
					noShopSelected: true,
				};

				ShippingLabelService.loadSettings().then(function () {
					$scope.entity = ShippingLabelService.getSettings();

					if ($scope.entity.shops !== undefined && $scope.entity.shops.length !== 0) {
						$scope.initSelected();
						$scope.disabled.invalidCredentials = false;
						$scope.disabled.noShopSelected = false;
						$scope.flags.sandbox = $scope.entity.sandbox;
					}
				});

				$scope.flags = {
					sandbox: false,
				};

				$scope.viewCheckbox = function () {};
			});

			$scope.initSelected = function () {
				$scope.entity.shops.forEach(function (shop) {
					if (shop.selected === true) {
						$scope.selected.shop = shop;
					}
				});
				$scope.selected.shop.carriers.forEach(function (carrier) {
					if (carrier.selected === true) {
						$scope.selected.carrier = carrier;
					}
				});
			};

			$scope.validateCredentials = function () {
				if ($scope.entity.user.id > 0 && $scope.entity.user.token.length === 26) {
					ShippingLabelService.validateUser($scope.entity.user).then(function () {
						$scope.entity.shops = ShippingLabelService.getShops();
						$scope.disabled.invalidCredentials = false;
					});
				} else {
					$scope.disabled.invalidCredentials = true;
					//						$scope.selected = {
					//							shop: {},
					//							carrier: {}
					//						};
				}
			};

			$scope.setSelectedShop = function () {
				$scope.disabled.noShopSelected = false;
			};

			$scope.saveSettings = function () {
				$scope.setSelectedShop();
				ShippingLabelService.saveSettings({
					shop: $scope.selected.shop.id,
					carrier: $scope.selected.carrier.id,
					sandbox: $scope.flags.sandbox,
				}).then(function () {});
			};
		},
	]);
});

