define('directives/contentConfigurator/contentSnippets/sectionSnippet/directives/popoverPinpoint/popoverPinpoint',['app', 'settings'], function (app) {
	'use strict';
	app.directive('ngPopoverPinpoint', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					inputData: '<data',
					stateEditing: '<',
				},
				templateUrl:
					BASE_DIRECTIVES_PATH +
					'/contentConfigurator/contentSnippets/sectionSnippet/directives/popoverPinpoint/views/index.html',
				controller: [
					'$scope',
					'$timeout',
					'$element',
					function ($scope, $timeout, $element) {
						var path =
							BASE_DIRECTIVES_PATH +
							'/contentConfigurator/contentSnippets/sectionSnippet/directives/popoverPinpoint/views/';

						$scope.customStyle = path + 'style.css';
						$scope.customStyleFake = path + 'styleFake.css';
						$scope.customJs = path + 'js.html';
						$scope.includeVisibility = true;

						function _init() {
							$scope.data = angular.merge({}, $scope.inputData);
						}

						_init();
						$scope.$watchCollection('inputData', function (newValue, oldValue) {
							if (newValue !== oldValue) {
								_init();
								$($element).find('[data-content-block="popover-pinpoint"]').popoverPinpoint('destroy');
								$scope.includeVisibility = false;
								$timeout(function () {
									$scope.includeVisibility = true;
								}, 100);
							}
						});
					},
				],
			};
		},
	]);
});

/* sample data
{
  "image": "/asset/images/content/map_pinpoint/map.png",
  "imageAlt": "Interactive Map with pins",
  "pins": [
    {
      "top": "37.8%",
      "left": "56%",
      "html": "<h3>Deutschland</h3><p><span>KRUPS Fördersysteme GmbH</span><br/><span>Industriegebiet Urbacher Wald Ringstr. 13</span><br/><span>56307 Dernbach</span><br/><span>D - Germany</span><br/><br/><span>Telefon: +49(2689)9435 - 0</span><br/><span>Fax: +49(2689)9435 - 35</span><br/><br/><span>info@krups-online.de</span><br/><span>www.krups-online.de</span><br/></p>"
    },
    {
        "top": "49.8%",
        "left": "25.3%",
        "html": "<h3>Deutschland</h3><p><span>KRUPS Fördersysteme GmbH</span><br/><span>Industriegebiet Urbacher Wald Ringstr. 13</span><br/><span>56307 Dernbach</span><br/><span>D - Germany</span><br/><br/><span>Telefon: +49(2689)9435 - 0</span><br/><span>Fax: +49(2689)9435 - 35</span><br/><br/><span>info@krups-online.de</span><br/><span>www.krups-online.de</span><br/></p>"
    }
  ]
}
*/
;
