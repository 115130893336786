define('services/SystemFileService',['app', 'modules/module', 'services/EntityCrudUnpagedService'], function (app, module) {
	'use strict';
	return module.factory('SystemFileService', ['$injector', SystemFileService]);
});

function SystemFileService($injector) {
	crudInstance = $injector.instantiate(EntityCrudUnpagedService);
	crudInstance.setType('SystemFile');

	angular.extend(this, crudInstance);

	this.transferSystemFile = function (id, file, displayName) {
		return this.getModel().transferSystemFile({ id: id, file: file, displayName: displayName }).$promise;
	};

	return this;
}
;
