/**
 * Created by Nico on 07.07.2016.
 */
define('services/DeliveryServiceService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('DeliveryServiceService', ['$injector', DeliveryServiceService]);
});

function DeliveryServiceService($injector) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('DeliveryService');

	angular.extend(this, crudInstance);

	return this;
}
;
