define('modules/wawi/product/controllers/tabs/CategoriesTabController',[], function () {
	'use strict';

	return ($parentScope, $scope, { BASE_TEMPLATES_PATH }) => {
		$parentScope.formTabs.push({
			label: 'FIELDS.CATEGORIES',
			template: BASE_TEMPLATES_PATH + '/wawi/product/form-categories.html',
		});

		$scope.afterInit = () => {
			if ($parentScope.entity.categoryIds == undefined) {
				$parentScope.entity.categoryIds = [];
			}
		};
	};
});

