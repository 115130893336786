/**
 * Created by Nico on 18.07.2016.
 */
define('services/CustomerUnpagedService',['app', 'modules/module', 'services/EntityCrudUnpagedService'], function (app, module) {
	'use strict';
	return module.factory('CustomerUnpagedService', ['$injector', CustomerUnpagedService]);
});

function CustomerUnpagedService($injector) {
	crudInstance = $injector.instantiate(EntityCrudUnpagedService);
	crudInstance.setType('Customer');

	angular.extend(this, crudInstance);

	return this;
}
;
