define('directives/contentConfigurator/contentSnippets/sectionSnippet/directives/depiction/depiction',['app', 'settings'], function (app) {
	'use strict';
	app.directive('ngDepiction', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					inputData: '<data',
					stateEditing: '<',
					tab: '&tab',
				},
				templateUrl:
					BASE_DIRECTIVES_PATH +
					'/contentConfigurator/contentSnippets/sectionSnippet/directives/depiction/views/index.html',
				controller: [
					'$scope',
					'$timeout',
					'$element',
					function ($scope, $timeout, $element) {
						var path =
							BASE_DIRECTIVES_PATH +
							'/contentConfigurator/contentSnippets/sectionSnippet/directives/depiction/views/';

						$scope.customStyle = path + 'style.css';
						$scope.customStyleFake = path + 'styleFake.css';
						$scope.customJs = path + 'js.html';
						$scope.customJs2 = path + 'js2.html';
						$scope.includeVisibility = true;

						$scope.tab = 1;

						function _init() {
							$scope.data = angular.merge({}, $scope.inputData);

							$scope.setTab = function (tabId) {
								//$scope.tab = tabId;
							};
							$scope.isSet = function (tabId) {
								//return $scope.tab === tabId;
							};
						}
						_init();

						$scope.$watchCollection('inputData', function (newValue, oldValue) {
							if (newValue !== oldValue) {
								_init();
							}
						});
						$scope.$watch('stateEditing', function (newValue, oldValue) {
							if (newValue !== oldValue) {
								$scope.data = angular.merge({}, $scope.inputData);
								_init();
							}
						});
					},
				],
			};
		},
	]);
});

/* sample data
{
    "image": "/asset/images/content/pinpoint/big_placeholder.jpg",
    "imageAlt": "Interactive Product Map",
    "pins": [
        {
            "title": "Lorem Ipsum ",
            "top": "27.8%",
            "left": "46.8%",
            "text": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel nesciunt praesentium quas! Error, repellat. Autem cum reprehenderit vel non rerum, magni nesciunt natus cumque quae quia ratione assumenda aliquam distinctio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel nesciunt praesentium quas! Error, repellat. Autem cum reprehenderit vel non rerum, magni nesciunt natus cumque quae quia ratione assumenda aliquam distinctio.",
            "url": "test1.com",
            "image": "/asset/images/content/product/e-cart/thumbnail.png",
            "imageAlt": "E-Cart"
        },
        {
            "title": "Dolor Sit Amet",
            "top": "87%",
            "left": "64.7%",
            "text": "2 Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel nesciunt praesentium quas! Error, repellat. Autem cum reprehenderit vel non rerum, magni nesciunt natus cumque quae quia ratione assumenda aliquam distinctio.",
            "url": "test2.com",
            "image": "/asset/images/content/product/e-cart/thumbnail.png",
            "imageAlt": "E-Cart"
        }
    ]
}
 */
;
