define('modules/wawi/product/controllers/tabs/MediaTabController',[], function () {
	'use strict';

	return ($parentScope, $scope, { BASE_TEMPLATES_PATH, MediaService, SessionValuesService }) => {
		$parentScope.formTabs.push({
			label: 'FIELDS.MEDIA_TAB',
			template: BASE_TEMPLATES_PATH + '/wawi/product/form-media.html',
		});

		$parentScope.$on('afterInit', function () {
			if ($parentScope.entity.productImages == undefined) {
				$parentScope.entity.productImages = [];
			}
		});

		$scope.getObjectKeys = (obj) => {
			if (obj) {
				return Object.keys(obj);
			}
		};

		$parentScope.$on('afterInit', function () {
			if ($parentScope.entity.catalogMedia == undefined) {
				// $parentScope.entity.catalogMedia = [];
				$parentScope.entity.catalogMedia = [];
			} else {
				for (let i in $parentScope.entity.catalogMedia) {
					if (!$parentScope.entity.catalogMedia[i].position) {
						$parentScope.entity.catalogMedia[i].position = +i + 1;
					}
				}
				$parentScope.entity.catalogMedia.sort((a, b) => (a.position > b.position ? 1 : -1));
			}

			for (let i of $parentScope.entity.catalogMedia) {
				i['expanded'] = false;
				i['inEditMode'] = false;
				if (!i.metadata) {
					i.metadata = {};
				}
			}
		});

		$scope.expandMedia = (id) => {
			$parentScope.entity.catalogMedia.find(
				(x) => x.fileId == id
			).expanded = !$parentScope.entity.catalogMedia.find((x) => x.fileId == id).expanded;
		};

		$scope.setEditMode = (id) => {
			$parentScope.entity.catalogMedia.find(
				(x) => x.fileId == id
			).inEditMode = !$parentScope.entity.catalogMedia.find((x) => x.fileId == id).inEditMode;

			let media = $parentScope.entity.catalogMedia.find((x) => x.fileId == id);
			media.mediaMetadata = media.mediaMetadata || { mediaMetadataValues: {} };

			if (media.inEditMode && Object.keys(media.mediaMetadata.mediaMetadataValues).length == 0) {
				MediaService.getMetadata(id);
			}

			$parentScope.entity.catalogMedia.find((x) => x.fileId == id).expanded = true;
		};

		$scope.removeProperty = (prop, id) => {
			let media = $parentScope.entity.catalogMedia.find((x) => x.fileId == id);
			delete $parentScope.entity.catalogMedia.find((x) => x.fileId == id).mediaMetadata.mediaMetadataValues[prop];

			MediaService.updateMetadata(
				media.fileId,
				media.shopId,
				media.mimeType,
				media.mediaMetadata.mediaMetadataValues
			);
		};

		$scope.addProperty = (id) => {
			let media = $parentScope.entity.catalogMedia.find((x) => x.fileId == id);
			$parentScope.entity.catalogMedia.find((x) => x.fileId == id).metadata[jQuery('#key-' + id).val()] = jQuery(
				'#value-' + id
			).val();
			jQuery('#key-' + id).val('');
			jQuery('#value-' + id).val('');

			MediaService.updateMetadata(
				media.fileId,
				media.shopId,
				media.mimeType,
				media.mediaMetadata.mediaMetadataValues
			);
		};

		$parentScope.$on('metadataUpdated', ({ id, response }) => {
			$parentScope.entity.catalogMedia.find((x) => x.fileId == id).mediaMetadata = response.metadata;
		});

		$scope.removeMedia = function (catalogMedia) {
			var index = $parentScope.entity.catalogMedia.indexOf(catalogMedia);
			$parentScope.entity.catalogMedia.splice(index, 1);
		};

		$scope.addMedia = (media) => {
			media.mediaMetadata = media.mediaMetadata || { mediaMetadataValues: {} };

			if (!$parentScope.entity.catalogMedia.find((x) => x.fileId === media.id)) {
				$parentScope.entity.catalogMedia.push({
					name: media.fileName || media.name,
					mimeType: media.mimeType,
					active: true,
					productId: $parentScope.entity.id,
					originalFileId: media.id,
					shopId: SessionValuesService.getShop(),
					mediaMetadata: media.metadata,
				});
			} else {
				alert('such file already exists');
			}
		};

		$scope.onCatalogMediaDrop = function ($event, $data, array) {
			$scope.addMedia($data);
		};

		$parentScope.$on('CustomerMediaUploaded', ($event, media, contextLabel) => {
			if (contextLabel === 'catalogMedia') {
				$scope.addMedia(media);
			}
		});

		$scope.treeOptions = {
			accept: function (sourceNodeScope, destNodesScope, destIndex) {
				return true;
			},
			dropped: function () {
				for (let i in $parentScope.entity.catalogMedia) {
					$parentScope.entity.catalogMedia[i].position = +i + 1;
				}
			},
		};
	};
});

