define('services/CheckoutService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('CheckoutService', ['$injector', '$q', 'ModelFactory', CheckoutService]);
});

function CheckoutService($injector, $q, ModelFactory) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('Checkout');

	angular.extend(this, crudInstance);
	var _currencyIso;

	this.proceed = function (shoppingCart) {
		return ModelFactory.Checkout(_currencyIso).proceed(shoppingCart).$promise;
	};

	this.createOrder = function (shoppingCart) {
		var ref = $q.defer();
		if (!shoppingCart.paymentMethodId) {
			shoppingCart.paymentMethodId = shoppingCart.paymentMethod.id;
		}
		ModelFactory.Checkout(_currencyIso)
			.proceed(shoppingCart)
			.$promise.then(
				function (response) {
					//success
					response.tradeTermsConfirmed = true;
					ModelFactory.Checkout(_currencyIso)
						.executePayment(response)
						.$promise.then(
							function (response) {
								ref.resolve(response);
							},
							function (response) {
								//error
								console.error(response);
								ref.reject(response);
							}
						);
				},
				function (response) {
					//error
					console.error(response);
					ref.reject(response);
				}
			);

		return ref.promise;
	};

	this.setCurrencyIso = function (currencyIso) {
		_currencyIso = currencyIso;
	};

	return this;
}
;
