define('services/CopyProductsService',['app', 'modules/module'], function (app, module) {
	'use strict';
	return module.factory('CopyProductsService', ['$injector', CopyProductsService]);
});

function CopyProductsService($injector) {
	this.copyProducts = function (copyProductsDTO) {
		var model = $injector.get('ModelFactory').Product();
		return model.copyProducts(copyProductsDTO).$promise;
	};

	this.copyProductsCancelSync = function (copyProductsDTO) {
		var model = $injector.get('ModelFactory').Product();
		return model.copyProductsCancelSync(copyProductsDTO).$promise;
	};

	return this;
}
;
