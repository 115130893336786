define('modules/navigation/controllers/NavigationController',[
	'modules/module',
	'services/NavigationService',
	'services/UserService',
	'services/NotificationService',
	'bootstrap',
	'directives/LanguageSwitcher/LanguageSwitcher',
], function (module) {
	'use strict';
	module.controller('NavigationController', [
		'$rootScope',
		'$q',
		'$scope',
		'$state',
		'NavigationService',
		'ShopService',
		'UserService',
		'ModelFactory',
		'NotificationService',
		NavigationController,
	]);
});
function NavigationController(
	$rootScope,
	$q,
	$scope,
	$state,
	NavigationService,
	ShopService,
	UserService,
	ModelFactory,
	NotificationService
) {
	$scope.isCollapsed = false;
	$scope.showNavigation = true;
	$scope.navbar = NavigationService;
	$scope.stateName = $state.current.name;
	$scope.navbar.setActiveItemByStateName($scope.stateName);

	$scope.state = {
		shops: [],
	};

	$scope.selectMainMenuItem = function (item) {
		if (item.subMenu) {
			if (item.subMenu[0].states) {
				$scope.navbar.changeState(item.subMenu[0].states[0]);
			}
		} else if (item.states != undefined) {
			$scope.navbar.changeState(item.states[0]);
		}
	};

	$scope.getShops = function () {
		var deferred = $q.defer();
		ModelFactory.Shop()
			.findAll({ page: 0, size: 10000000 })
			.$promise.then(function (response) {
				// Allowed shops are filtered in backend
				$scope.state.shops = response.data;
				deferred.resolve($scope.state.shops);
			}, NotificationService.notifyRequestError);
		return deferred.promise;
	};

	$scope.unregisterHide = $rootScope.$on('hideNavigation', function (event, data) {
		$scope.showNavigation = false;
	});

	$scope.unregisterShow = $rootScope.$on('showNavigation', function (event, data) {
		$scope.showNavigation = true;
		$scope.getShops();
	});

	$scope.unregisterShopsChanged = $rootScope.$on('shopsChanged', function (event, data) {
		$scope.getShops();
	});

	$scope.unregisterUserStatusChanged = $rootScope.$on('userStatusChanged', () => {
		loadActiveUser();
	});

	if (UserService.isLoggedIn()) {
		//Getting the shops on a refresh
		$scope.getShops();
	}

	$scope.$on('$destroy', function () {
		//will be called on controller destroy
		$scope.unregisterHide();
		$scope.unregisterShow();
		$scope.unregisterShopsChanged();
		$scope.unregisterUserStatusChanged();
	});

	$scope.setShop = function (shop) {
		UserService.setShop(shop.id);
		UserService.setLocale(shop.defaultLocaleId);
		$rootScope.$emit('ShopChanged');
		location.reload();
		// window.location.href= "/"; //This hard reload is needed. Otherwise the shop id in the header will not change.
	};

	$scope.getShopName = function () {
		var shopId = UserService.getShop();
		for (var key in $scope.state.shops) {
			if ($scope.state.shops[key].id == shopId) {
				return $scope.state.shops[key].name;
			}
		}
	};

	const loadActiveUser = () => {
		$scope.activeUser = UserService.getUser();
	};

	loadActiveUser();
}
;
