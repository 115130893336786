define('services/DropShippingOrderService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('DropShippingOrderService', ['$injector', 'ModelFactory', '$q', DropShippingOrderService]);
});

function DropShippingOrderService($injector, ModelFactory, $q) {
	var _dropShippingOrders = [];

	this.findByOrderId = function (orderId) {
		var ref = $q.defer();
		ModelFactory.DropShippingOrder()
			.findByShopOrderId({ id: orderId })
			.$promise.then(function (response) {
				ref.resolve(response.data);
			});
		return ref.promise;
	};

	//    crudInstance = $injector.instantiate(CrudService);
	//    crudInstance.setType('DropShipping');
	//
	//    angular.extend(this, crudInstance);

	return this;
}
;
