define('modules/settings/shop/tabs/MediaTabController',[], function () {
	'use strict';

	return function MediaTabController($parentScope, $scope, { BASE_TEMPLATES_PATH }) {
		$parentScope.adminFormTabs.push({
			label: 'FIELDS.MEDIA_TAB',
			template: BASE_TEMPLATES_PATH + '/settings/shop/form-media.html',
		});

		// $scope.onMediaDrop = function ($event, $data, media) {
		// 	$parentScope.media = $data;
		// 	$parentScope.entity.shopConfiguration.mediaId = $scope.media.id;
		// };

		// $scope.removeMedia = function () {
		// 	$parentScope.media = undefined;
		// 	$parentScope.entity.shopConfiguration.mediaId = null;
		// };
	};
});

