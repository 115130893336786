define('modules/wawi/category/controllers/CategoryController',['modules/module', 'modules/basemodule/controllers/EntityListController', 'services/CategoryService'], function (
	module
) {
	'use strict';
	module.controller('CategoryController', [
		'$scope',
		'$rootScope',
		'CategoryService',
		'NavigationService',
		'NotificationService',
		CategoryController,
	]);
});

function CategoryController($scope, $rootScope, CategoryService, NavigationService, NotificationService) {
	$scope.formState = 'root.category';

	$rootScope.$on('ShopChanged', function () {
		$scope.loadHierarchy();
	});

	$scope.removeCategory = function (scope, category) {
		NotificationService.confirm({
			statusText: 'Wollen Sie wirklich diese Kategorie löschen?',
			msg: '', //msgs.join('; ')
		}).then(
			function () {
				CategoryService.delete(category.id).then(function () {
					$scope.loadHierarchy();
				});
			},
			function () {}
		);
		//scope.remove();
	};

	$scope.loadHierarchy = function () {
		CategoryService.getHierarchy().then(function () {
			$scope.categories = CategoryService.getAllHierarchy();
		});
	};

	$scope.toggle = function (scope) {
		scope.toggle();
	};

	$scope.moveLastToTheBeginning = function () {
		var a = $scope.categories.pop();
		$scope.categories.splice(0, 0, a);
	};

	$scope.newSubItem = function (scope) {
		var category = scope.$modelValue;
		category.children.push({
			id: 'Neu',
			name: 'Neue Kategorie',
			children: [],
		});
	};

	$scope.collapseAll = function () {
		$scope.$broadcast('angular-ui-tree:collapse-all');
	};

	$scope.expandAll = function () {
		$scope.$broadcast('angular-ui-tree:expand-all');
	};

	$scope.edit = function (category) {
		NavigationService.changeState('root.category', category.id);
	};

	$scope.saveStructure = function () {};

	$scope.addRootCategory = function () {
		$scope.categories.push({
			id: 'Neu',
			name: 'Neue Kategorie',
			children: [],
		});
	};

	$scope.create = function () {
		CategoryService.clear();
		NavigationService.changeState('root.category');
	};

	$scope.updateCategory = function (category) {};

	$scope.treeOptions = {
		accept: function (sourceNodeScope, destNodesScope, destIndex) {
			return true;
		},
		dropped: function () {
			CategoryService.updateTree($scope.categories).then(function (response) {
				$scope.categories = response;
			});
		},
	};

	$scope.$on('saveCategories', function (evt, data) {
		CategoryService.updateTree($scope.categories).then(function (response) {
			$scope.categories = response;
		});
	});

	$scope.loadHierarchy();
}
;
