define('services/DataFeedService',['app', 'modules/module', 'services/EntityCrudUnpagedService'], function (app, module) {
	'use strict';
	return module.factory('DataFeedService', ['$injector', DataFeedService]);
});

function DataFeedService($injector) {
	crudInstance = $injector.instantiate(EntityCrudUnpagedService);
	crudInstance.setType('DataFeed');

	angular.extend(this, crudInstance);

	this.transferDataFeed = function (customerId, file, displayName) {
		return this.getModel().transferDataFeed({ id: customerId, file: file, displayName: displayName }).$promise;
	};

	return this;
}
;
