define('directives/contentConfigurator/toolbar/Toolbar',['app', 'angular', 'services/ContentBuilderHelper'], function (app) {
	'use strict';
	app.directive('toolbar', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				restrict: 'EA',
				scope: {
					elementIdentifier: '<',
					elementType: '<',
					snippetType: '<',
					element: '=',
					parent: '=',
					metaIdentifier: '=',
					elementPosition: '=',
					selected: '<',
					editElement: '&?',
					copy: '&?',
					options: '=?',
					can: '<',
				},
				templateUrl: BASE_DIRECTIVES_PATH + '/contentConfigurator/toolbar/views/toolbar.html',
				controller: [
					'$scope',
					'$timeout',
					'ContentBuilderHelper',
					function ($scope, $timeout, ContentBuilderHelper) {
						var ELEMENT_TYPE = ContentBuilderHelper.ELEMENT_TYPE;
						$scope.edit = true;
						$scope.final = false;

						$scope.can = angular.extend(
							{},
							{
								delete: true,
								paste_style: true,
								copy_style: true,
								pick_style: true,
								save_style: true,
								duplicate: true,
								edit: true,
								drag: true,
							},
							$scope.can
						);
						var _init = function () {
							$scope.dropStyles = false;
							ContentBuilderHelper.subscribeEditModeToggled($scope, function (e, args) {
								if (args.identifier === $scope.metaIdentifier || args.identifier === 'GLOBAL') {
									$scope.edit = args.edit;
									$scope.final = args.preSave || false;
								}
							});
						};

						$scope.$watch('selected', _init);

						$scope.isDuplicable = function () {
							return true;
						};

						$scope.isEditable = function () {
							if (typeof $scope.editElement === 'function') {
								return true;
							} else return false;
						};

						$scope.onEditElement = function ($event) {
							$event.stopPropagation();
							$scope.editElement()($event);
						};

						$scope.onClickDrag = function ($event) {
							$event.stopPropagation();
						};

						$scope.onDuplicateElement = function ($event) {
							$event.stopPropagation();
							ContentBuilderHelper.duplicateElement(
								$scope.metaIdentifier,
								typeof $scope.elementPosition === 'object'
									? $scope.elementPosition.col
									: $scope.elementPosition,
								$scope.elementType,
								$scope.parent,
								$scope.element
							);
						};

						$scope.onDeleteElement = function ($event) {
							$event.stopPropagation();

							switch ($scope.elementType) {
								case ELEMENT_TYPE.SNIPPET: {
									ContentBuilderHelper.deleteElement(
										$scope.metaIdentifier,
										$scope.parent,
										$scope.elementPosition
									);
									break;
								}
								case ELEMENT_TYPE.ROW: {
									ContentBuilderHelper.deleteElement(
										$scope.metaIdentifier,
										$scope.parent,
										$scope.elementPosition.row
									);
									break;
								}
								case ELEMENT_TYPE.CELL: {
									ContentBuilderHelper.deleteElement(
										$scope.metaIdentifier,
										$scope.parent,
										$scope.elementPosition.col
									);
									break;
								}
							}
						};

						$scope.onDragStart = function (event, data, type) {
							ContentBuilderHelper.callOutDragStartPart(type, data);
							if ($scope.elementType === ContentBuilderHelper.ELEMENT_TYPE.CELL) {
								$scope.onDragStartCell(event, data);
							} else if ($scope.elementType === ContentBuilderHelper.ELEMENT_TYPE.SNIPPET) {
								$scope.onDragStartSnippet(event, data);
							}
						};
						$scope.onDragEnd = function (event, data, type) {
							ContentBuilderHelper.callOutDragEndPart(type, data);
						};

						$scope.onDragStartCell = function (event, data) {
							ContentBuilderHelper.callOutDragStart(
								$scope.metaIdentifier,
								$scope.elementPosition.col,
								$scope.parent.children[$scope.elementPosition.row],
								$scope.elementIdentifier,
								$scope.elementType,
								$scope.snippetType,
								$scope.element
							);
						};

						$scope.onDragStartSnippet = function (event, data) {
							ContentBuilderHelper.callOutDragStart(
								$scope.metaIdentifier,
								$scope.elementPosition,
								$scope.parent,
								$scope.elementIdentifier,
								$scope.elementType,
								$scope.snippetType,
								$scope.element
							);
						};

						$scope.onSaveStyle = function () {
							ContentBuilderHelper.saveStyle($scope.element.style);
						};

						$scope.onPickStyle = function () {
							$scope.dropStyles = !$scope.dropStyles;
							if ($scope.dropStyles) {
								$scope.styles = ContentBuilderHelper.getStyles();
							}
						};

						$scope.pickStyle = function (style) {
							ContentBuilderHelper.applyStyle(style, $scope.element);
						};

						$scope.onCopyStyle = function () {
							ContentBuilderHelper.copyStyle($scope.element.style);
						};

						$scope.onPasteStyle = function () {
							ContentBuilderHelper.pasteStyle($scope.element);
						};
					},
				],
			};
		},
	]);
});

