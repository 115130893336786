define('modules/econ/controllers/EconAutomationFormController',[
	'modules/module',
	'settings',
	'services/EconAutomationService',
	'services/EconMailtemplateService',
	'services/EconRecipientListService',
	'services/NavigationService',
	'directives/formatDate/formatDate',
	'directives/controls/customInputTime/CustomInputTime',
], function (module) {
	'use strict';
	module.controller('EconAutomationFormController', [
		'$scope',
		'$controller',
		'EconAutomationService',
		'EconMailtemplateService',
		'EconRecipientListService',
		'NavigationService',
		'BASE_TEMPLATES_PATH',
		'ProductService',
		EconAutomationFormController,
	]);
});

function EconAutomationFormController(
	$scope,
	$controller,
	EconAutomationService,
	EconMailtemplateService,
	EconRecipientListService,
	NavigationService,
	BASE_TEMPLATES_PATH,
	ProductService
) {
	$scope.formTabs = [
		{
			label: 'FIELDS.GENERAL',
			template: BASE_TEMPLATES_PATH + '/econ/partials/_form-automation.html',
			active: true,
		},
	];

	$scope.availableAutomationTypes = [
		{
			display: 'Nach Benutzerregistrierung',
			value: 'SUBSCRIPTION',
		},
		{
			display: 'Nach Kauf eines bestimmten Artikels',
			value: 'SPECIFIC_PRODUCT',
		},
		{
			display: 'Nach einem Kauf',
			value: 'PRODUCT_BOUGHT',
		},
		{
			display: 'Kein Kauf seit',
			value: 'NO_ACTION_SINCE',
		},
	];

	$scope.recipientListHeadlineText =
		'Bitte wählen Sie eine Empfängerliste aus. Nachdem sich ein Benutzer bei einer dieser Empfängerlisten registriert, wird die Automatisierung gestartet.';

	$scope.recipientLists = [];
	$scope.mailTemplates = [];
	$scope.yesNoRadioValues = [
		{ value: true, display: 'FIELDS.YES' },
		{ value: false, display: 'FIELDS.NO' },
	];
	$scope.automationStates = [
		{ display: 'ACTIONS.ACTIVATE', value: 'ENABLED' },
		{ display: 'ACTIONS.DEACTIVATE', value: 'DISABLED' },
		{ display: 'FIELDS.INCORRECT', value: 'ERRONEOUS' },
	];
	$scope.lastPosition = 0;
	$scope.data = {};

	$scope.validationConfig = {
		name: {
			method: 'isset',
			fieldName: 'Name',
		},
		state: {
			method: 'isset',
			fieldName: 'Status',
		},
		mailTemplateId: {
			method: 'isset',
			fieldName: 'Vorlage',
			customValidate: function (entity) {
				for (const automationStep of entity.automationSteps) {
					if (!automationStep.mailTemplate || !automationStep.mailTemplate.id) {
						return false;
					}
				}
				return true;
			},
		},
	};

	$scope.productTableOptions = {};
	$scope.productTableOptions.name = 'Produkte';
	$scope.productTableOptions.fields = [
		{
			id: 'NAME',
			heading: 'FIELDS.NAME',
			attribute: 'name',
		},
		{
			id: 'SKU',
			heading: 'Sku',
			attribute: 'sku',
		},
	];

	$scope.recipientListTableOptions = {};
	$scope.recipientListTableOptions.name = 'Empfängerlisten';
	$scope.recipientListTableOptions.fields = [
		{
			id: 'NAME',
			heading: 'FIELDS.NAME',
			attribute: 'name',
		},
		{
			id: 'SKU',
			heading: 'Sku',
			attribute: 'sku',
		},
	];

	$scope.listState = 'root.econAutomations';
	$scope.productService = ProductService;
	$scope.recipientListService = EconRecipientListService;

	angular.extend(
		this,
		$controller('EntityFormController', {
			$scope: $scope,
		})
	);

	$scope.init('Automation', EconAutomationService).then(function () {
		if ($scope.newEntity) {
			$scope.entity.automationType = 'SUBSCRIPTION';
			$scope.entity.automationSteps = [];
			$scope.addStep();

			$scope.entity.parameters = {};
			$scope.entity.products = [];
		} else {
			$scope.entity.parameters = JSON.parse($scope.entity.parameters);
		}

		ProductService.setCurrentPage(0).then(function () {
			$scope.data.products = ProductService.getAll();
		});

		EconRecipientListService.findAll(true).then(function () {
			$scope.data.recipientLists = EconRecipientListService.getAll();
			$scope.processRecipientLists();
		});

		$scope.entity.parameters.recipientListIDs = [];
		$scope.entity.parameters.productIDs = [];

		$scope.processAutomationSteps();
		$scope.processNoActionSince();
		$scope.processRecipientLists();
		$scope.processProducts();
	});

	$scope.processRecipientLists = function () {
		if ($scope.newEntity) {
			$scope.entity.recipientLists = [];
		}

		if ($scope.entity.automationType == 'SUBSCRIPTION') {
			$scope.entity.parameters.recipientListIDs = [];
			for (var i = 0; $scope.entity.recipientLists && i < $scope.entity.recipientLists.length; i++) {
				$scope.entity.parameters.recipientListIDs.push($scope.entity.recipientLists[i].id);
			}
		}
	};

	$scope.processProducts = function () {
		if ($scope.newEntity) {
			$scope.entity.products = [];
		}
		if ($scope.entity.automationType == 'SPECIFIC_PRODUCT') {
			for (var i = 0; $scope.entity.products && i < $scope.entity.products.length; i++) {
				$scope.entity.parameters.productIDs.push($scope.entity.products[i].id);
			}
		}
	};

	$scope.processNoActionSince = function () {
		if ($scope.newEntity || $scope.entity.automationType != 'NO_ACTION_SINCE') {
			return;
		}
		$scope.entity.tmpDaysSince = parseInt($scope.entity.parameters.minutes / (24 * 60));
		$scope.entity.parameters.minutes -= $scope.entity.tmpDaysSince * 60 * 24;
		$scope.entity.tmpHoursSince = parseInt($scope.entity.parameters.minutes / 60);
		$scope.entity.parameters.minutes -= $scope.entity.tmpHoursSince * 60;
		$scope.entity.tmpMinutesSince = $scope.entity.parameters.minutes;
	};

	$scope.processAutomationSteps = function () {
		EconMailtemplateService.findAll().then(function () {
			$scope.mailTemplates = EconMailtemplateService.getAll();
			if (!$scope.newEntity) {
				$scope.entity.automationSteps.forEach(function (automationStep) {
					if (automationStep.position > $scope.lastPosition) {
						$scope.lastPosition = automationStep.position;
					}
				});

				$scope.entity.automationSteps.forEach(function (automationStep) {
					var delay = automationStep.delay;
					automationStep.tmpDays = parseInt(delay / (24 * 60));
					delay -= automationStep.tmpDays * 60 * 24;
					automationStep.tmpHours = parseInt(delay / 60);
					delay -= automationStep.tmpHours * 60;
					automationStep.tmpMinutes = delay;

					$scope.mailTemplates.forEach(function (mailTemplate) {
						if (
							automationStep.mailTemplate !== undefined &&
							automationStep.mailTemplate.id === mailTemplate.id
						) {
							automationStep.mailTemplate = mailTemplate;
							automationStep.mailTemplate.content = null;
						}
					});
				});
			}
		});
	};

	$scope.$on('beforeUpdate', function () {
		$scope.prepareSave();
	});

	$scope.$on('beforeCreate', function () {
		$scope.prepareSave();
	});

	$scope.$on('afterCreate', function () {
		$scope.afterSave();
	});

	$scope.$on('afterUpdate', function () {
		$scope.afterSave();
	});

	$scope.addStep = function () {
		$scope.lastPosition = $scope.lastPosition + 10;
		$scope.entity.automationSteps.push({ position: $scope.lastPosition });
	};

	$scope.removeStep = function (index) {
		$scope.entity.automationSteps.splice(index, 1);
	};

	$scope.afterSave = function () {
		$scope.entity.parameters = JSON.parse($scope.entity.parameters);
		$scope.entity.parameters.recipientListIDs = [];
		$scope.entity.parameters.productIDs = [];

		$scope.processAutomationSteps();
		$scope.processNoActionSince();
		$scope.processRecipientLists();
		$scope.processProducts();
	};

	$scope.prepareSave = function () {
		switch ($scope.entity.automationType) {
			case 'SUBSCRIPTION':
				for (var i = 0; i < $scope.entity.parameters.recipientListIDs.length; i++) {
					$scope.entity.recipientLists.push({ id: $scope.entity.parameters.recipientListIDs[i] });
				}
				$scope.entity.parameters = {};
				break;
			case 'SPECIFIC_PRODUCT':
				$scope.entity.recipientLists = [];
				delete $scope.entity.parameters.minutes;
				delete $scope.entity.parameters.recipientListIDs;
				break;
			case 'PRODUCT_BOUGHT':
				$scope.entity.recipientLists = [];
				$scope.entity.parameters = {};
				break;
			case 'NO_ACTION_SINCE':
				$scope.entity.recipientLists = [];
				$scope.entity.parameters = {
					minutes: $scope.convertToMinutes(
						$scope.entity.tmpMinutesSince,
						$scope.entity.tmpHoursSince,
						$scope.entity.tmpDaysSince
					),
				};
				break;
		}
		$scope.entity.parameters = angular.toJson($scope.entity.parameters);

		$scope.entity.automationSteps.forEach(function (automationStep) {
			automationStep.delay = $scope.convertToMinutes(
				automationStep.tmpMinutes,
				automationStep.tmpHours,
				automationStep.tmpDays
			);
		});
	};

	$scope.convertToMinutes = function (minutes, hours, days) {
		if (!minutes) {
			minutes = 0;
		}
		if (!hours) {
			hours = 0;
		}
		if (!days) {
			days = 0;
		}

		var minutes = parseInt(minutes);
		minutes += parseInt(hours * 60);
		minutes += parseInt(days * 24 * 60);
		return minutes;
	};
}
;
