define('modules/wawi/product/controllers/tabs/ShippingMediaTabController',[], function () {
	'use strict';

	return function ($parentScope, $scope, { SessionValuesService, BASE_TEMPLATES_PATH }) {
		$parentScope.formTabs.push({
			label: 'PLUGINS.DIGITAL_PRODUCTS.PRODUCT_MEDIA',
			template: BASE_TEMPLATES_PATH + '/wawi/product/form-shipping-media.html',
		});

		$parentScope.$on('afterInit', function () {
			if ($parentScope.entity.shippingMedia == undefined) {
				$parentScope.entity.shippingMedia = [];
			} else {
				$parentScope.entity.shippingMedia.sort((a, b) => (a.position > b.position ? 1 : -1));
			}
		});

		$parentScope.$on('CustomerMediaUploaded', ($event, media, contextLabel) => {
			if (contextLabel === 'shippingMedia') {
				$scope.addShippingMedia(media);
			}
		});

		$scope.removeShippingMedia = function (catalogMedia) {
			var index = $parentScope.entity.shippingMedia.indexOf(catalogMedia);
			$parentScope.entity.shippingMedia.splice(index, 1);
		};

		$scope.addShippingMedia = (media) => {
			if (!$parentScope.entity.shippingMedia) {
				$parentScope.entity.shippingMedia = [];
			}

			$parentScope.entity.shippingMedia.push({
				fileName: media.fileName || media.name,
				mimeType: media.mimeType,
				active: true,
				productId: $parentScope.entity.id,
				fileId: media.id,
				shopId: SessionValuesService.getShop(),
			});
		};

		$scope.onShippingMediaDrop = function ($event, $data, array) {
			$scope.addShippingMedia($data);
		};

		$scope.treeOptions = {
			accept: function (sourceNodeScope, destNodesScope, destIndex) {
				return true;
			},
			dropped: function () {
				for (let i in $parentScope.entity.shippingMedia) {
					$parentScope.entity.shippingMedia[i].position = +i + 1;
				}
			},
		};
	};
});

