define('services/TrackingService',['app', 'modules/module', 'services/SessionValuesService'], function (app, module) {
	'use strict';
	return module.factory('TrackingService', [
		'$injector',
		'$window',
		'ModelFactory',
		'SessionValuesService',
		TrackingService,
	]);
});

function TrackingService($injector, $window, ModelFactory) {
	this.setupTracking = () => {
		return ModelFactory.OrderManagement()
			.setupTracking()
			.$promise.then(function (response) {
				return response;
			});
	};

	return this;
}
;
