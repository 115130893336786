define('services/SellingProductService',[
	'app',
	'modules/module',
	'services/EntityCrudService',
	'services/LocalStorageService',
	'services/NotificationService',
], function (app, module) {
	'use strict';
	return module.factory('SellingProductService', [
		'$injector',
		'LocalStorageService',
		'NotificationService',
		'$q',
		SellingProductService,
	]);
});

function SellingProductService($injector, LocalStorageService, NotificationService, $q) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('SellingProduct');

	angular.extend(this, crudInstance);

	this.findByIdsIn = function (ids) {
		var ref = $q.defer();
		$injector
			.get('ModelFactory')
			.SellingProduct()
			.findByIdsIn({ ids: ids })
			.$promise.then(function (response) {
				ref.resolve(response);
			}, NotificationService.notifyRequestError);
		return ref.promise;
	};

	return this;
}
;
