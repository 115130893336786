define('modules/settings/systemMessage/SystemMessageFormController',[
	'modules/module',
	'modules/basemodule/controllers/EntityFormController',
	'services/ValidationService',
	'services/LocaleService',
	'services/OrderStateUnpagedService',
	'services/ProductService',
	'services/MetadataService',
	'services/ProductUnpagedService',
	'services/SystemFileService',
	'services/LocaleService',
	'settings',
], function (module) {
	'use strict';
	module.controller('SystemMessageFormController', [
		'$scope',
		'$rootScope',
		'$controller',
		'$sce',
		'BASE_TEMPLATES_PATH',
		'ValidationService',
		'LocaleService',
		'OrderStateUnpagedService',
		'ProductService',
		'MetadataService',
		'ProductUnpagedService',
		'SystemFileService',
		'PRIMARKETS_EVENTS',
		'LocaleService',
		function (
			$scope,
			$rootScope,
			$controller,
			$sce,
			BASE_TEMPLATES_PATH,
			ValidationService,
			LocaleService,
			OrderStateUnpagedService,
			ProductService,
			MetadataService,
			ProductUnpagedService,
			SystemFileService,
			PRIMARKETS_EVENTS
		) {
			angular.extend(
				this,
				$controller('EntityFormController', {
					$scope: $scope,
				})
			);

			$scope.state.requireSuperuserForUpdate = true;

			$scope.selectedProducts = [];
			$scope.formTabs = [
				{
					label: 'FIELDS.GENERAL',
					template: BASE_TEMPLATES_PATH + '/settings/systemMessage/form-main.html',
					active: true,
				},
			];
			$scope.msgSystemFiles = [];
			$scope.data = {};
			$scope.init('SystemMessage').then(function () {
				if ($scope.entity.productIds === undefined) {
					$scope.entity.productIds = [];
				}
				if ($scope.entity.attachments === undefined) {
					$scope.entity.attachments = [{ templateCode: {} }];
				} else {
					for (var i = 0; i < $scope.entity.attachments.length; i++) {
						if ($scope.entity.attachments[i].systemFile != undefined) {
							$scope.msgSystemFiles.push($scope.entity.attachments[i].systemFile);
						} else if (!$scope.entity.attachments[i].templateCode) {
							$scope.entity.attachments[i].templateCode = {};
						}
					}
				}
				$scope.removeSystemFilesFromAttachements();
				$scope.attachments = $scope.entity.attachments;

				$scope.data.selectedEventType = PRIMARKETS_EVENTS.filter(function (eventType) {
					return eventType.key == $scope.entity.primarketsEvent;
				});
				if ($scope.data.selectedEventType.length > 0)
					$scope.data.eventType = $scope.data.selectedEventType[0].type;
				else $scope.data.eventType = 'ORDER_EVENT';
			});

			$scope.getEntityObject = function () {
				return $scope.entity;
			};

			$scope.productService = ProductService;
			$scope.productService.setAdditionalParams([{ name: 'allow_seminar', value: 'true' }]);
			$scope.productService.setCurrentPage(0).then(function () {
				$scope.products = ProductService.getAll();
			});

			$scope.$on('afterInit', function () {
				$scope.productService.findByIdsIn($scope.entity.productIds).then(function (products) {
					for (var i = 0; i < products.length; i++) {
						$scope.selectedProducts.push(products[i]);
					}
				});
			});

			$scope.listState = 'root.systemMessages';

			$scope.validate = function () {
				return ValidationService.validate($scope.entity, {
					name: {
						method: 'isset',
						fieldName: 'Name',
					},
					localeId: {
						method: 'isset',
						fieldName: 'Locale',
					},
					subject: {
						method: 'isset',
						fieldName: 'Betreff',
					},
					emailTemplate: {
						method: 'isset',
						fieldName: 'Email Inhalt',
						errorMsg: 'Das feld kann nicht leer sein',
						customValidate: function () {
							return $scope.entity.mailBody;
						},
					},
					attachement: {
						method: 'isset',
						fieldName: 'PDF Anhang',
						errorMsg: 'Das feld kann nicht leer sein',
						customValidate: function () {
							if (!$scope.entity.attachments || $scope.entity.attachments.length === 0) {
								return true;
							}

							return $scope.entity.attachments[0].templateCode.code;
						},
					},
					localeIds: {
						method: 'isset',
						fieldName: 'Anhangsname',
						errorMsg: 'Bitte bestimmen Sie für jeden Anhang einen Name.',
						customValidate: function () {
							for (var i = 0; i < $scope.entity.attachments.length; i++) {
								var attachement = $scope.entity.attachments[i];
								if (!attachement.name) {
									return false;
								}
							}
							return true;
						},
					},
				});
			};

			$scope.tinymceOptions = {
				onChange: function (e) {},
				inline: false,
				plugins: 'advlist autolink link image lists charmap print preview code',
				skin: 'lightgray',
				theme: 'modern',
				language: LocaleService.getTinyLang(),
			};

			$scope.locales = LocaleService.getAll(true);

			$scope.systemEvents = PRIMARKETS_EVENTS;

			$scope.changeEventType = function () {
				$scope.systemEvents = PRIMARKETS_EVENTS.filter(function (eventType) {
					return eventType.type == $scope.data.eventType;
				});
			};

			$scope.$on('beforeUpdate', function () {
				$scope.save();
			});

			$scope.$on('beforeCreate', function () {
				$scope.save();
			});

			$scope.extendForSelectValues = function (systemFiles) {
				for (var i = 0; i < systemFiles.length; i++) {
					var dataFeed = systemFiles[i];
					dataFeed.display = dataFeed.displayName + ' (' + dataFeed.name + ')';
					dataFeed.value = dataFeed.id;
				}
				return systemFiles;
			};

			$scope.removeSystemFilesFromAttachements = function () {
				var i = $scope.entity.attachments.length;
				while (i--) {
					if ($scope.entity.attachments[i].systemFile) {
						$scope.entity.attachments.splice(i, 1);
					}
				}
			};

			$scope.save = function () {
				if ($scope.data.eventType == 'ORDER_EVENT') {
					$scope.entity.primarketsEvent = null;
				} else {
					$scope.entity.productIds = [];
					$scope.entity.stateId = '';
				}

				$scope.removeSystemFilesFromAttachements();

				for (var i = 0; i < $scope.msgSystemFiles.length; i++) {
					var attachment = {};
					attachment.name = $scope.msgSystemFiles[i].displayName;
					attachment.systemFile = $scope.msgSystemFiles[i];
					attachment.system_message_id = $scope.entity.id;
					$scope.entity.attachments.push(attachment);
				}
			};

			$scope.addSystemFile = function () {
				for (var i = 0; $scope.systemFiles && i < $scope.systemFiles.length; i++) {
					var systemFile = $scope.systemFiles[i];
					if (systemFile.value == $scope.selected.choosedSystemFileId) {
						$scope.msgSystemFiles.push(systemFile);
						break;
					}
				}
			};

			$scope.deleteSystemFile = function (systemFileId) {
				for (var i = 0; i < $scope.msgSystemFiles.length; i++) {
					var systemFile = $scope.msgSystemFiles[i];
					if (systemFile.id == systemFileId) {
						$scope.msgSystemFiles.splice(i, 1);
					}
				}
			};

			SystemFileService.findAll().then(function () {
				$scope.systemFiles = $scope.extendForSelectValues(SystemFileService.getAll().data);
			});

			OrderStateUnpagedService.findAllOrderStates('ORDER').then(function () {
				$scope.states = OrderStateUnpagedService.getAllOrderStates().data;
			});

			MetadataService.findAll(true).then(function () {
				$scope.metadatas = MetadataService.getAll();
			});

			ProductUnpagedService.findAll().then(function () {
				$scope.products = ProductUnpagedService.getAll(true).data;
			});

			$scope.productsTableOptions = {};
			$scope.productsTableOptions.name = 'Produkten';
			$scope.productsTableOptions.field = [
				{
					id: 'NAME',
					heading: 'Name',
					attribute: 'name',
				},
				{
					id: 'SKU',
					heading: 'Sku',
					attribute: 'sku',
				},
			];

			$scope.eventTypes = [
				{
					key: 'ORDER_EVENT',
					value: 'Order Event',
				},
				{
					key: 'SYSTEM_EVENT',
					value: 'System Event',
				},
			];

			$scope.addAttachment = function () {
				$scope.attachments.push({ templateCode: {} });
			};

			$scope.removeAttachment = function (index) {
				$scope.attachments.splice(index, 1);
			};

			$rootScope.$on('refreshSystemFiles', function (event, data) {
				SystemFileService.findAll().then(function () {
					$scope.systemFiles = $scope.extendForSelectValues(SystemFileService.getAll().data);
				});
			});

			$scope.transferSystemFileController = function ($rootScope, $scope, dataToPass, $mdDialog) {
				$scope.outerScope = dataToPass;

				$scope.transferSystemFile = function () {
					SystemFileService.transferSystemFile(
						$scope.outerScope.entityId,
						$scope.customerFile,
						$scope.systemFileDisplayName
					).then(function () {
						$rootScope.$emit('refreshSystemFiles', '');
					});
					$mdDialog.hide();
				};

				$scope.cancel = function () {
					$mdDialog.cancel();
				};
			};

			$scope.trustAsHTML = function (str) {
				return $sce.trustAsHtml(str);
			};
		},
	]);
});

