define('services/BackupService',['app', 'modules/module', 'services/SessionValuesService'], function (app, module) {
	'use strict';
	return module.factory('BackupService', [
		'$injector',
		'$window',
		'ModelFactory',
		'SessionValuesService',
		BackupService,
	]);
});

function BackupService($injector, $window, ModelFactory, SessionValuesService) {
	this.listBackup = () => {
		return ModelFactory.Backup()
			.listBackup()
			.$promise.then(function (response) {
				return response;
			});
	};

	this.createBackup = () => {
		return ModelFactory.Backup()
			.createBackup()
			.$promise.then(function (response) {
				return response;
			});
	};

	this.downloadBackup = (timestamp) => {
		$window.open(
			'/api/v1/core/database-backup/download/' +
				timestamp +
				'?' +
				SessionValuesService.getAuthorizationParamKeyAndValue(),
			'_blank'
		);
	};

	return this;
}
;
