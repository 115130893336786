define('directives/notification/ngNotification',['app', 'settings'], function (app) {
	'use strict';
	app.directive('ngNotification', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {},
				templateUrl: BASE_DIRECTIVES_PATH + '/notification/views/notification.html',
				controller: [
					'$scope',
					'$rootScope',
					'$timeout',
					function ($scope, $rootScope, $timeout) {
						$scope.notifications = [];
						$scope.timeout = 4000;
						$scope.scrollToTopTimer;

						$rootScope.$on('notification', function (event, alert) {
							clearTimeout($scope.scrollToTopTimer);
							$scope.scrollToTopTimer = setTimeout(function () {
								$('html, body').animate({ scrollTop: 0 }, 'slow');
							}, 100);

							$scope.notifications.push(alert);
							if (alert.type != 'validation' && alert.type != 'confirm') {
								setTimeout(function () {
									var index = $scope.notifications.indexOf(alert);
									$scope.notifications.splice(index, 1);
									$scope.$apply();
								}, $scope.timeout);
							}
						});

						$scope.closeNotification = function (index) {
							$scope.notifications.splice(index, 1);
						};
					},
				],
			};
		},
	]);
});

