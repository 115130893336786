define('config',['app', 'routes', 'interceptors', 'angular-translate'], function (app, routes, interceptors) {
	'use strict';
	return app.config([
		'$qProvider',
		'$stateProvider',
		'$urlRouterProvider',
		'$httpProvider',
		'localStorageServiceProvider',
		'cfpLoadingBarProvider',
		'$mdDateLocaleProvider',
		'$translateProvider',
		function (
			$qProvider,
			$stateProvider,
			$urlRouterProvider,
			$httpProvider,
			localStorageServiceProvider,
			cfpLoadingBarProvider,
			$mdDateLocaleProvider,
			$translateProvider
		) {
			//$qProvider.errorOnUnhandledRejections(false);

			interceptors.initInterceptors($httpProvider);
			routes.initRoutes($urlRouterProvider, $stateProvider);

			localStorageServiceProvider.setPrefix('prim');
			localStorageServiceProvider.setStorageType('sessionStorage');

			$mdDateLocaleProvider.formatDate = function (date) {
				if (!date) {
					return '';
				}
				return date.toLocaleDateString('de-DE');
			};

			cfpLoadingBarProvider.includeBar = true;
			//cfpLoadingBarProvider.parentSelector = '#loading-bar-container';
			//cfpLoadingBarProvider.spinnerTemplate = '<div><span class="custom-spinner" id="mySpinner" us-spinner="{lines: 17, radius:35, width:12, length: 0}"></span></div>'
			//cfpLoadingBarProvider.spinnerTemplate = '<div id="floatingCirclesG"><div class="f_circleG" id="frotateG_01"></div><div class="f_circleG" id="frotateG_02"></div><div class="f_circleG" id="frotateG_03"></div><div class="f_circleG" id="frotateG_04"></div><div class="f_circleG" id="frotateG_05"></div><div class="f_circleG" id="frotateG_06"></div><div class="f_circleG" id="frotateG_07"></div><div class="f_circleG" id="frotateG_08"></div></div>'
			cfpLoadingBarProvider.spinnerTemplate = '';

			$translateProvider
				.useStaticFilesLoader({
					prefix: '../../template/language/',
					suffix: '.json',
				})
				.preferredLanguage('de')
				.fallbackLanguage('de')
				.useSanitizeValueStrategy('sanitizeParameters');
		},
	]);
});

