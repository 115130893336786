define('modules/wawi/tag/controllers/TagListController',['modules/module', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('TagListController', ['$scope', '$controller', TagListController]);
});

function TagListController($scope, $controller) {
	angular.extend(
		this,
		$controller('EntityListController', {
			$scope: $scope,
		})
	);

	$scope.tableOptions.fields = [
		{
			id: 'NAME',
			heading: 'FIELDS.NAME',
			attribute: 'name',
		},
	];

	$scope.formState = 'root.tag';

	$scope.init('Tag');
}
;
