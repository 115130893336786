/**
 * Created by nmayer on 09.06.16.
 */
define('modules/settings/priceRule/PriceRuleFormController',[
	'modules/module',
	'modules/basemodule/controllers/EntityFormController',
	'services/ValidationService',
	'services/CustomerGroupService',
	'services/ShopService',
	'services/CouponCodeUnpagedService',
	'services/ProductUnpagedService',
	'services/TagUnpagedService',
	'services/MetadataService',
	'services/MetadataUnpagedService',
	'services/CountryUnpagedService',
	'services/PriceRuleUnpagedService',
	'services/CategoryService',
	'directives/categorySelector/CategorySelector',
	'settings',
], function (module) {
	'use strict';
	module.controller('PriceRuleFormController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		'ValidationService',
		'CustomerGroupService',
		'ShopService',
		'CouponCodeUnpagedService',
		'ProductUnpagedService',
		'TagUnpagedService',
		'MetadataService',
		'MetadataUnpagedService',
		'CountryUnpagedService',
		'PriceRuleUnpagedService',
		'CategoryService',
		function (
			$scope,
			$controller,
			BASE_TEMPLATES_PATH,
			ValidationService,
			CustomerGroupService,
			ShopService,
			CouponCodeUnpagedService,
			ProductUnpagedService,
			TagUnpagedService,
			MetadataService,
			MetadataUnpagedService,
			CountryUnpagedService,
			PriceRuleUnpagedService,
			CategoryService
		) {
			angular.extend(
				this,
				$controller('EntityFormController', {
					$scope: $scope,
				})
			);

			$scope.formTabs = [
				{
					label: 'FIELDS.GENERAL',
					template: BASE_TEMPLATES_PATH + '/settings/priceRule/form-main.html',
					active: true,
				},
				{
					label: 'FIELDS.CONDITIONS',
					template: BASE_TEMPLATES_PATH + '/settings/priceRule/form-condition.html',
				},
				{
					label: 'FIELDS.ACTION',
					template: BASE_TEMPLATES_PATH + '/settings/priceRule/form-action.html',
				},
			];

			$scope.tagsTableOptions = {};
			$scope.tagsTableOptions.name = 'NAVIGATION.CUSTOMER_GROUPS';
			$scope.tagsTableOptions.field = {
				id: 'NAME',
				heading: 'FIELDS.NAME',
				attribute: 'name',
			};

			ShopService.findAll(true).then(function () {
				$scope.shops = ShopService.getAll(true);
			});

			CouponCodeUnpagedService.findAll().then(function () {
				$scope.couponCodes = CouponCodeUnpagedService.getAll(true).data;
			});

			ProductUnpagedService.findAll().then(function () {
				$scope.products = ProductUnpagedService.getAll(true).data;
			});

			TagUnpagedService.findAll().then(function () {
				$scope.tags = TagUnpagedService.getAll(true).data;
			});

			MetadataUnpagedService.findAll().then(function () {
				$scope.metadatas = MetadataUnpagedService.getAll(true).data;
			});

			CountryUnpagedService.findAll().then(function () {
				$scope.countries = CountryUnpagedService.getAll(true).data;
			});

			PriceRuleUnpagedService.findAll().then(function () {
				$scope.priceRules = PriceRuleUnpagedService.getAll(true).data;
			});

			const loadAndSetMetadataValues = (metadata) => {
				if (
					!metadata ||
					metadata.metadataValues.length > 0 ||
					metadata.metadataValuesLoadingStatus === 'LOADING' ||
					metadata.metadataValuesLoadingStatus === 'LOADED'
				) {
					return;
				}

				metadata.metadataValuesLoadingStatus = 'LOADING';

				MetadataService.findValues(metadata.id).then((values) => {
					metadata.metadataValues = values;
					metadata.metadataValuesLoadingStatus = 'LOADED';
				});
			};

			// Init customer groups based on shop id value
			const deregisterWatchShopId = $scope.$watch('entity.shopId', (newValue, oldValue) => {
				if (!$scope.entity) {
					return;
				}

				if (!$scope.entity.shopId) {
					// if no shop was selected
					$scope.customerGroups = [];
					$scope.customerGroupIds = [];
					return;
				}

				CustomerGroupService.findByShopId($scope.entity.shopId).then((response) => {
					$scope.customerGroups = response.data;

					if (newValue && oldValue) {
						// if non-empty value was changed to non-empty value
						$scope.entity.customerGroupIds = [];
					} else {
						// Unselect customer groups which don't belong to selected shop
						$scope.entity.customerGroupIds = $scope.entity.customerGroupIds.filter((customerGroupId) => {
							return $scope.customerGroups.find((cg) => cg.id === customerGroupId);
						});
					}
				});
			});

			$scope.init('PriceRule').then(function () {
				// Apparently not called?

				if ($scope.entity.validFrom == undefined) {
					$scope.entity.validFrom = new Date();
				} else {
					$scope.entity.validFrom = new Date($scope.entity.validFrom);
				}

				if (!$scope.entity.customerGroupIds) {
					$scope.entity.customerGroupIds = [];
				}

				if ($scope.entity.validTo == undefined) {
					$scope.entity.validTo = new Date();
				} else {
					$scope.entity.validTo = new Date($scope.entity.validTo);
				}

				MetadataUnpagedService.findAll().then(() => {
					$scope.metadatas = MetadataUnpagedService.getAll(true).data;

					if (!$scope.entity.ruleConditions) {
						$scope.entity.ruleConditions = [];
					} else {
						for (var i = 0; i < $scope.entity.ruleConditions.length; i++) {
							if ($scope.entity.ruleConditions[i].metadataNameId) {
								$scope.conditionMetadataSelect($scope.entity.ruleConditions[i].metadataNameId);
							}
						}
					}

					$scope.entity.ruleActions = $scope.entity.ruleActions || [];
					//TODO: https://moodia.atlassian.net/browse/PMA-15
					for (const ruleAction of $scope.entity.ruleActions) {
						if (ruleAction.metadataNameId) {
							$scope.actionMetadataSelect(ruleAction.metadataNameId);
							break;
						}
					}
				});

				$scope.transformRuleActionForDisplay();
			});

			$scope.$on('duplicateSaved', function () {
				$scope.transformRuleActionForDisplay();
				if ($scope.entity.validFrom == undefined) {
					$scope.entity.validFrom = new Date();
				} else {
					$scope.entity.validFrom = new Date($scope.entity.validFrom);
				}

				if ($scope.entity.validTo == undefined) {
					$scope.entity.validTo = new Date();
				} else {
					$scope.entity.validTo = new Date($scope.entity.validTo);
				}
			});

			$scope.transformRuleActionForDisplay = function () {
				if ($scope.entity.ruleActions == undefined) {
					$scope.entity.ruleActions = [];
				} else {
					for (var i = 0; i < $scope.entity.ruleActions.length; i++) {
						if ($scope.entity.ruleActions[i].className == 'ShoppingCartAbsoluteShippingDiscountAction') {
							$scope.entity.ruleActions[i].discountType = 'absoluteShippingDiscount';
							delete $scope.entity.ruleActions[i].className;
						} else if (
							$scope.entity.ruleActions[i].className == 'ShoppingCartAbsoluteTotalDiscountAction'
						) {
							$scope.entity.ruleActions[i].discountType = 'absoluteDiscount';
							$scope.entity.ruleActions[i].actionTarget = 'totalShoppingCartTarget';
							delete $scope.entity.ruleActions[i].className;
						} else if (
							$scope.entity.ruleActions[i].className == 'ShoppingCartPercentageShippingDiscountAction'
						) {
							$scope.entity.ruleActions[i].discountType = 'percentageShippingDiscount';
							delete $scope.entity.ruleActions[i].className;
						} else if (
							$scope.entity.ruleActions[i].className == 'ShoppingCartPercentageTotalDiscountAction'
						) {
							$scope.entity.ruleActions[i].discountType = 'percentageDiscount';
							$scope.entity.ruleActions[i].actionTarget = 'totalShoppingCartTarget';
							delete $scope.entity.ruleActions[i].className;
						} else if (
							$scope.entity.ruleActions[i].className == 'ShoppingCartSpecificItemsAbsoluteDiscountAction'
						) {
							$scope.entity.ruleActions[i].discountType = 'absoluteDiscount';
							$scope.entity.ruleActions[i].actionTarget = 'specificItemsTarget';
							delete $scope.entity.ruleActions[i].className;
						} else if (
							$scope.entity.ruleActions[i].className ==
							'ShoppingCartSpecificItemsPercentageDiscountAction'
						) {
							$scope.entity.ruleActions[i].discountType = 'percentageDiscount';
							$scope.entity.ruleActions[i].actionTarget = 'specificItemsTarget';
							delete $scope.entity.ruleActions[i].className;
						} else if (
							$scope.entity.ruleActions[i].className == 'ShoppingCartGetForFreeSpecificItemsAction'
						) {
							$scope.entity.ruleActions[i].discountType = 'shoppingCartGetForFreeSpecificItemsAction';
							delete $scope.entity.ruleActions[i].className;
						} else if (
							$scope.entity.ruleActions[i].className ==
							'ShoppingCartGetForFreeSpecificItemsWithMetadataAction'
						) {
							$scope.entity.ruleActions[i].discountType =
								'shoppingCartGetForFreeSpecificItemsWithMetadataAction';
							delete $scope.entity.ruleActions[i].className;
						} else if ($scope.entity.ruleActions[i].className == 'ShoppingCartAddSpecificItemsAction') {
							$scope.entity.ruleActions[i].discountType = 'addItems';
							$scope.entity.ruleActions[i].actionTarget = 'specificItemsTarget';
							delete $scope.entity.ruleActions[i].className;
						} else if (
							$scope.entity.ruleActions[i].className ==
							'ShoppingCartGetForFreeSpecificItemsWithMetadataActionInterval'
						) {
							$scope.entity.ruleActions[i].discountType =
								'shoppingCartGetForFreeSpecificItemsWithMetadataActionInterval';
							delete $scope.entity.ruleActions[i].className;
						} else if (
							$scope.entity.ruleActions[i].className ==
							'ShoppingCartItemInCategoryPercentageDiscountAction'
						) {
							$scope.entity.ruleActions[i].discountType =
								'shoppingCartItemInCategoryPercentageDiscountAction';
							delete $scope.entity.ruleActions[i].className;
						} else {
							$scope.entity.ruleActions[i].discountType = $scope.entity.ruleActions[i].className;
							delete $scope.entity.ruleActions[i].className;
						}
					}
				}
			};

			$scope.listState = 'root.pricerules';

			$scope.validate = function () {
				return ValidationService.validate($scope.entity, {
					name: {
						method: 'isset',
						fieldName: 'Regelname',
					},
					shopId: {
						method: 'isset',
						fieldName: 'Shop',
					},
					customerGroupIds: {
						method: 'isset',
						fieldName: 'Kundengruppe',
					},
					conditionEvaluationType: {
						method: 'isset',
						fieldName: 'SETTINGS.PRICE_RULE.CONDITION',
					},
					action: {
						fieldName: 'SETTINGS.PRICE_RULE.ACTION',
						customValidate: (entity) => {
							return entity.ruleActions.length > 0;
						},
					},
				});
			};

			$scope.$on('beforeUpdate', function () {
				$scope.beforeSave();
			});

			$scope.$on('beforeCreate', function () {
				$scope.beforeSave();
			});

			$scope.beforeSave = function () {
				var from = $scope.entity.validFrom;
				var to = $scope.entity.validTo;
				var monthFrom = from.getMonth() + 1;
				var monthTo = to.getMonth() + 1;

				$scope.entity.validFrom =
					from.getFullYear() +
					'-' +
					(monthFrom < 10 ? '0' : '') +
					monthFrom +
					'-' +
					(from.getDate() < 10 ? '0' : '') +
					from.getDate();
				$scope.entity.validTo =
					to.getFullYear() +
					'-' +
					(monthTo < 10 ? '0' : '') +
					monthTo +
					'-' +
					(to.getDate() < 10 ? '0' : '') +
					to.getDate();

				if ($scope.entity.isActive == undefined) {
					$scope.entity.isActive = false;
				}

				for (var i = 0; i < $scope.entity.ruleActions.length; i++) {
					if ($scope.entity.ruleActions[i].discountType == 'absoluteShippingDiscount') {
						$scope.entity.ruleActions[i].className = 'ShoppingCartAbsoluteShippingDiscountAction';
						delete $scope.entity.ruleActions[i].discountType;
						delete $scope.entity.ruleActions[i].actionTarget;
					} else if (
						$scope.entity.ruleActions[i].discountType == 'absoluteDiscount' &&
						$scope.entity.ruleActions[i].actionTarget == 'totalShoppingCartTarget'
					) {
						$scope.entity.ruleActions[i].className = 'ShoppingCartAbsoluteTotalDiscountAction';
						delete $scope.entity.ruleActions[i].discountType;
						delete $scope.entity.ruleActions[i].actionTarget;
					} else if ($scope.entity.ruleActions[i].discountType == 'percentageShippingDiscount') {
						$scope.entity.ruleActions[i].className = 'ShoppingCartPercentageShippingDiscountAction';
						delete $scope.entity.ruleActions[i].discountType;
						delete $scope.entity.ruleActions[i].actionTarget;
					} else if (
						$scope.entity.ruleActions[i].discountType == 'percentageDiscount' &&
						$scope.entity.ruleActions[i].actionTarget == 'totalShoppingCartTarget'
					) {
						$scope.entity.ruleActions[i].className = 'ShoppingCartPercentageTotalDiscountAction';
						delete $scope.entity.ruleActions[i].discountType;
						delete $scope.entity.ruleActions[i].actionTarget;
					} else if (
						$scope.entity.ruleActions[i].discountType == 'absoluteDiscount' &&
						$scope.entity.ruleActions[i].actionTarget == 'specificItemsTarget'
					) {
						$scope.entity.ruleActions[i].className = 'ShoppingCartSpecificItemsAbsoluteDiscountAction';
						delete $scope.entity.ruleActions[i].discountType;
						delete $scope.entity.ruleActions[i].actionTarget;
					} else if (
						$scope.entity.ruleActions[i].discountType == 'percentageDiscount' &&
						$scope.entity.ruleActions[i].actionTarget == 'specificItemsTarget'
					) {
						$scope.entity.ruleActions[i].className = 'ShoppingCartSpecificItemsPercentageDiscountAction';
						delete $scope.entity.ruleActions[i].discountType;
						delete $scope.entity.ruleActions[i].actionTarget;
					} else if (
						$scope.entity.ruleActions[i].discountType == 'shoppingCartGetForFreeSpecificItemsAction'
					) {
						$scope.entity.ruleActions[i].className = 'ShoppingCartGetForFreeSpecificItemsAction';
						delete $scope.entity.ruleActions[i].discountType;
					} else if (
						$scope.entity.ruleActions[i].discountType ==
						'shoppingCartGetForFreeSpecificItemsWithMetadataAction'
					) {
						$scope.entity.ruleActions[i].className =
							'ShoppingCartGetForFreeSpecificItemsWithMetadataAction';
						delete $scope.entity.ruleActions[i].discountType;
						delete $scope.entity.ruleActions[i].actionTarget;
					} else if (
						$scope.entity.ruleActions[i].discountType == 'addItems' &&
						$scope.entity.ruleActions[i].actionTarget == 'specificItemsTarget'
					) {
						$scope.entity.ruleActions[i].className = 'ShoppingCartAddSpecificItemsAction';
						delete $scope.entity.ruleActions[i].discountType;
						delete $scope.entity.ruleActions[i].actionTarget;
					} else if (
						$scope.entity.ruleActions[i].discountType ==
						'shoppingCartGetForFreeSpecificItemsWithMetadataActionInterval'
					) {
						$scope.entity.ruleActions[i].className =
							'ShoppingCartGetForFreeSpecificItemsWithMetadataActionInterval';
						delete $scope.entity.ruleActions[i].discountType;
						delete $scope.entity.ruleActions[i].actionTarget;
					} else if (
						$scope.entity.ruleActions[i].discountType ==
						'shoppingCartItemInCategoryPercentageDiscountAction'
					) {
						$scope.entity.ruleActions[i].className = 'ShoppingCartItemInCategoryPercentageDiscountAction';
						delete $scope.entity.ruleActions[i].discountType;
						delete $scope.entity.ruleActions[i].actionTarget;
					} else {
						$scope.entity.ruleActions[i].className = $scope.entity.ruleActions[i].discountType;
						delete $scope.entity.ruleActions[i].discountType;
						delete $scope.entity.ruleActions[i].actionTarget;
					}
				}
			};

			$scope.addCondition = function () {
				if ($scope.entity.ruleConditions == undefined) {
					$scope.entity.ruleConditions = [];
				}
				$scope.entity.ruleConditions.push({});
			};

			$scope.removeCondition = function (index) {
				$scope.entity.ruleConditions.splice(index, 1);
			};

			$scope.addAction = function () {
				if ($scope.entity.ruleActions == undefined) {
					$scope.entity.ruleActions = [];
				}
				$scope.entity.ruleActions.push({});
			};

			$scope.removeAction = function (index) {
				$scope.entity.ruleActions.splice(index, 1);
			};

			$scope.grossNet = [
				{
					name: 'brutto',
					value: true,
				},
				{
					name: 'netto',
					value: false,
				},
			];

			$scope.conditionTypes = [
				{
					name: 'Warenkorbsumme',
					value: 'ShoppingCartTotalCondition',
				},
				{
					name: 'Rabattcode',
					value: 'ShoppingCartCouponCodeIsUsedCondition',
				},
				{
					name: 'Menge eines Artikels im Warenkorb',
					value: 'ShoppingCartSingleItemAmountCondition',
				},
				{
					name: 'Menge bestimmter Artikel im Warenkorb',
					value: 'ShoppingCartSpecificItemsAmountCondition',
				},
				{
					name: 'Anzahl der Artikel im Warenkorb',
					value: 'ShoppingCartTotalItemAmountCondition',
				},
				{
					name: 'Artikel hat den Tag',
					value: 'OrderProductHasTagCondition',
				},
				{
					name: 'Artikel hat das Merkmal',
					value: 'OrderProductHasMetadataNameValueCondition',
				},
				{
					name: 'Anzahl Artikel mit Merkmal',
					value: 'ShoppingCartSpecificItemsWithMetadataAmountCondition',
				},
				{
					name: 'Land der Versandadresse',
					value: 'ShoppingCartShippingCountryCondition',
				},
				{
					name: 'Preisregel bereits angewendet',
					value: 'ShoppingCartRuleAppliedCondition',
				},
			];

			$scope.conditionOperators = [
				{
					name: '=',
					value: 'EQUAL',
				},
				{
					name: '!=',
					value: 'NOT_EQUAL',
				},
				{
					name: '<',
					value: 'LESS_THAN',
				},
				{
					name: '>',
					value: 'GREATER_THAN',
				},
				{
					name: '<=',
					value: 'LESS_THAN_OR_EQUAL',
				},
				{
					name: '>=',
					value: 'GREATER_THAN_OR_EQUAL',
				},
			];

			$scope.values = [
				{
					name: 'WAHR',
					value: 'ALL_TRUE',
				},
				{
					name: 'FALSCH',
					value: 'ALL_FALSE',
				},
			];

			$scope.actionTypes = [
				{
					name: 'Absoluter Rabatt',
					value: 'absoluteDiscount',
				},
				{
					name: '%tualer Rabatt',
					value: 'percentageDiscount',
				},
				{
					name: 'Bestandserhöhung',
					value: 'addItems',
				},
				{
					name: '%tualer Versandrabatt',
					value: 'percentageShippingDiscount',
				},
				{
					name: 'Absoluter Versandrabatt',
					value: 'absoluteShippingDiscount',
				},
				{
					name: 'Anzahl Gratisprodukte',
					value: 'shoppingCartGetForFreeSpecificItemsAction',
				},
				{
					name: 'Anzahl Gratisprodukte mit Produktmerkmal',
					value: 'shoppingCartGetForFreeSpecificItemsWithMetadataAction',
				},
				{
					name: 'Anzahl Gratisprodukte mit Produktmerkmal im Intervall',
					value: 'shoppingCartGetForFreeSpecificItemsWithMetadataActionInterval',
				},
				{
					name: 'Prozentualer Rabatt auf Produkte einer bestimmten Kategorie',
					value: 'shoppingCartItemInCategoryPercentageDiscountAction',
				},
				{
					name: 'SETTINGS.PRICE_RULE.ABSOLUTE_SURCHARGE_FOR_METADATA_AMOUNT',
					value: 'OrderProductMetadataAndAmountAbsoluteSurchargeAction',
				},
				{
					name: 'SETTINGS.PRICE_RULE.PERCENTAGE_SURCHARGE_FOR_METADATA_AMOUNT',
					value: 'OrderProductMetadataAndAmountPercentageSurchargeAction',
				},
			];

			$scope.actionTargets = [
				{
					name: 'auf gesamten Warenkorb',
					value: 'totalShoppingCartTarget',
				},
				{
					name: 'auf folgende(n) Artikel',
					value: 'specificItemsTarget',
				},
			];

			$scope.actionTargetAddItems = [
				{
					name: 'auf folgende(n) Artikel',
					value: 'specificItemsTarget',
				},
			];

			// Deprecated
			$scope.actionMetadataSelect = function (metadataNameId) {
				if (metadataNameId != undefined) {
					for (var i = 0; i < $scope.metadatas.length; i++) {
						if (metadataNameId == $scope.metadatas[i].id) {
							$scope.actionMetadata = $scope.metadatas[i];
							loadAndSetMetadataValues($scope.actionMetadata);
							break;
						}
					}
				}
			};

			$scope.conditionMetadataSelect = function (metadataNameId) {
				if (metadataNameId != undefined) {
					if ($scope.metadatas === undefined) {
						MetadataUnpagedService.findAll().then(function () {
							$scope.metadatas = MetadataUnpagedService.getAll(true).data;
							for (var i = 0; i < $scope.metadatas.length; i++) {
								if (metadataNameId == $scope.metadatas[i].id) {
									$scope.conditionMetadata = $scope.metadatas[i];
									loadAndSetMetadataValues($scope.conditionMetadata);
									break;
								}
							}
						});
					} else {
						for (var i = 0; i < $scope.metadatas.length; i++) {
							if (metadataNameId == $scope.metadatas[i].id) {
								$scope.conditionMetadata = $scope.metadatas[i];
								loadAndSetMetadataValues($scope.conditionMetadata);
								break;
							}
						}
					}
				}
			};

			$scope.searchTerm;
			$scope.clearSearchTerm = function () {
				$scope.searchTerm = '';
			};

			/**
			 * @param id {number|string}
			 * @returns {null|*}
			 */
			$scope.getMetadataByMetadataNameId = (id) => {
				if (!$scope.metadatas) {
					return null;
				}

				// noinspection EqualityComparisonWithCoercionJS
				return $scope.metadatas.find((m) => m.id == id) || null;
			};

			$scope.initActionMetadataList = (action) => {
				if (!action.metadataList || !action.metadataList.length) {
					action.metadataList = [{}];
				}
			};

			$scope.addAmountRangeToAction = (action) => {
				if (!action.amountRanges || !action.amountRanges.length) {
					$scope.initActionAmountRanges(action);
				} else {
					const lastAmountRange = action.amountRanges[action.amountRanges.length - 1];
					lastAmountRange.to = lastAmountRange.from + 1;
					action.amountRanges.push({
						from: lastAmountRange.to + 1,
						to: -1,
						price: 0,
					});
				}
			};

			$scope.deleteAmountRangeFromAction = (action, amountRangeIndex) => {
				if (!action.amountRanges || action.amountRanges.length < 2) {
					return;
				}

				action.amountRanges.splice(amountRangeIndex, 1);
				action.amountRanges[action.amountRanges.length - 1].to = -1;
			};

			$scope.initActionAmountRanges = (action) => {
				if (!action.amountRanges || !action.amountRanges.length) {
					action.amountRanges = [
						{
							from: 1,
							to: -1,
							price: 0,
						},
					];
				}
			};

			$scope.loadAndSetMetadataValuesByMetadataNameId = (id) => {
				const metadata = $scope.getMetadataByMetadataNameId(id);
				loadAndSetMetadataValues(metadata);
			};

			$scope.onSearchChange = function ($event) {
				$event.stopPropagation();
			};

			$scope.$on('$destroy', () => {
				deregisterWatchShopId();
			});
		},
	]);
});

