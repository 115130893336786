define('directives/bufferedScroll/bufferedScroll',['app'], function (app) {
	'use strict';
	app.directive('bufferedScroll', function ($parse) {
		return function ($scope, element, attrs) {
			console.log('bufferedScrollDirective');
			console.log($scope);
			console.log(element);
			console.log(attrs);
			var handler = $parse(attrs.bufferedScroll);
			element.scroll(function (evt) {
				var scrollTop = element[0].scrollTop,
					scrollHeight = element[0].scrollHeight,
					offsetHeight = element[0].offsetHeight;
				if (scrollTop === scrollHeight - offsetHeight) {
					$scope.$apply(function () {
						handler($scope);
					});
				}
			});
		};
	});
});

