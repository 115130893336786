define('modules/settings/shop/tabs/PaymentPolicyTabController',[], function () {
	'use strict';

	return function PaymentPolicyController(
		$parentScope,
		$scope,
		{ BASE_TEMPLATES_PATH, PaymentPolicyService, RuleService }
	) {
		$parentScope.adminFormTabs.push({
			label: 'FIELDS.PAYMENT_POLICY',
			template: BASE_TEMPLATES_PATH + '/settings/shop/form-payment-policy.html',
		});

		$scope.conditionPath = BASE_TEMPLATES_PATH + '/settings/shop/paymentPolicyConditions/';
		$scope.groupConjunctions = RuleService.groupConjunctions();
		$scope.conditionConjunctions = RuleService.conditionConjunctions();
		$scope.paymentConfigToAdd = {};
		RuleService.conditionTypes().then((conditions) => {
			$scope.conditionTypes = conditions;
		});

		$scope.init = function () {
			PaymentPolicyService.fetchPaymentPolicyTemplates().then(() => {
				$scope.paymentPolicyTemplates = PaymentPolicyService.getTemplates();
			});
		};

		$scope.onSetConditionType = function (condition) {
			for (let key in condition.conditionDescriptor) {
				if (key !== 'className') {
					delete condition.conditionDescriptor[key];
				}
			}
		};

		$scope.sortableOptions = {
			'ui-floating': true,
			axis: 'y',
			stop: function (e, ui) {
				for (let i = 0; i < $parentScope.entity.shopPaymentPolicies.length; i++) {
					$parentScope.entity.shopPaymentPolicies[i].position = i + 1;
				}
			},
		};

		$scope.addPaymentConfig = function (policy) {
			if ($scope.paymentConfigToAdd.id === undefined) {
				return;
			}
			const paymentMethod = $parentScope.entity.shopPaymentMethods.find(
				(payment) => payment.id === $scope.paymentConfigToAdd.id && !!payment.id
			);
			$scope.paymentConfigToAdd.id = undefined;

			if (policy.shopPaymentConfigs === undefined) {
				policy.shopPaymentConfigs = [];
			}
			policy.shopPaymentConfigs.push(paymentMethod);
		};

		$scope.addCondition = function (conditionGroup) {
			if (conditionGroup.conditions === undefined) {
				conditionGroup.conditions = [];
			}
			conditionGroup.conditions.push({});
		};

		$scope.addConditionGroup = function (policy) {
			if (policy.conditionGroups === undefined) {
				policy.conditionGroups = [];
			}
			policy.conditionGroups.push({ conditionEvaluationType: 'ALL_TRUE' });
		};

		$scope.addPolicy = function () {
			if ($parentScope.entity.shopPaymentPolicies === undefined) {
				$parentScope.entity.shopPaymentPolicies = [];
			}
			let highestPosition =
				$parentScope.entity.shopPaymentPolicies.length > 0
					? $parentScope.entity.shopPaymentPolicies.sort((lh, rh) => lh.position - rh.position).position + 1
					: 0;

			$parentScope.entity.shopPaymentPolicies.push({
				name: '---',
				conjunction: 'AND',
				active: true,
				position: highestPosition,
			});
		};

		$scope.deletePolicyPaymentMethod = function (policy, index) {
			policy.shopPaymentConfigs.splice(index, 1);
		};

		$scope.deleteCondition = function (conditionGroup, index) {
			conditionGroup.conditions.splice(index, 1);
		};

		$scope.deleteConditionGroup = function (policy, index) {
			policy.conditionGroups.splice(index, 1);
		};

		$scope.deleteShopPaymentPolicy = function (index) {
			$parentScope.entity.shopPaymentPolicies.splice(index, 1);
		};
	};
});

