define('directives/contentConfigurator/contentSnippets/sectionSnippet/directives/jobs/jobs',['app', 'settings', 'services/ContentBuilderHelper', 'filters/TrustedHtml'], function (app) {
	'use strict';
	app.directive('ngJobs', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					inputData: '<data',
					stateEditing: '<',
				},
				templateUrl:
					BASE_DIRECTIVES_PATH +
					'/contentConfigurator/contentSnippets/sectionSnippet/directives/jobs/views/index.html',
				controller: [
					'$scope',
					'$timeout',
					'$element',
					'$compile',
					'ContentBuilderHelper',
					function ($scope, $timeout, $element, $compile, ContentBuilderHelper) {
						var path =
							BASE_DIRECTIVES_PATH +
							'/contentConfigurator/contentSnippets/sectionSnippet/directives/jobs/views/';

						$scope.customStyle = path + 'style.css';
						$scope.customStyleFake = path + 'styleFake.css';
						$scope.customJs = path + 'js.html';
						$scope.customJs2 = path + 'js2.html';
						$scope.includeVisibility = true;

						var wrapper = $($element).find('[data-content-block="jobs"]');

						var template =
							'<div class="jobs-container"><table class="" style="width:100%" data-schema="{{data|json}}"></table></div>';

						function _init() {
							$scope.data = angular.merge({}, $scope.inputData);
							ContentBuilderHelper.subscribeSizeChanged($scope, function (e, args) {
								_refresh(); // refresh flexslider on fake viewport change
							});
							_refresh();
						}

						_init();

						function _refresh() {
							try {
								wrapper.jobs('destroy');
							} catch (e) {}
							wrapper.find('.jobs-container').remove();
							wrapper.append($compile(template)($scope));
							$scope.includeVisibility = false;
							$timeout(function () {
								$scope.$apply();
								$scope.includeVisibility = true;
							}, 100);
						}

						$scope.$watchCollection('inputData', function (newValue, oldValue) {
							if (newValue !== oldValue) {
								$scope.data = angular.merge({}, $scope.inputData);
								_refresh();
							}
						});
						$scope.$watch('stateEditing', function (newValue, oldValue) {
							if (newValue !== oldValue) {
								$scope.data = angular.merge({}, $scope.inputData);
								_refresh();
							}
						});
					},
				],
			};
		},
	]);
});

/* sample data
{
    "slides": [
        {
            "url": "https://avago.de",
            "default": "/asset/images/content/partners/avago.png",
            "caption": "Avago",
            "subcaption": "Avago"
        },
        {
            "url": "https://digilent.de",
            "default": "/asset/images/content/partners/digilent.png",
            "caption": "DIGILENT",
            "subcaption": "DIGILENT"
        },
        {
            "url": "https://texas-instruments.de",
            "default": "/asset/images/content/partners/texas_instruments.png",
            "caption": "Texas Instruments",
            "subcaption": "Texas Instruments"
        },
        {
            "url": "https://tyco.de",
            "default": "/asset/images/content/partners/tyco.png",
            "caption": "TYCO",
            "subcaption": "TYCO"
        },
        {
            "url": "https://pseg.de",
            "default": "/asset/images/content/partners/pseg.png",
            "caption": "PSEG",
            "subcaption": "PSEG"
        }
    ]
}
*/
;
