define('services/EconSettingService',['app', 'modules/module'], function (app, module) {
	'use strict';
	return module.factory('EconSettingService', [
		'$q',
		'$injector',
		function ($q, $injector) {
			var settings = null;
			this.loadSettings = function (shop) {
				var settingModel = new $injector.get('ModelFactory').EconSetting();
				var ref = $q.defer();
				var param = { shop: shop };
				settingModel.getSettings(param).$promise.then(function (response) {
					settings = response;
					ref.resolve(response);
				});
				return ref.promise;
			};

			this.getSettings = function () {
				return settings;
			};

			this.updateAll = function (settings) {
				var settingModel = new $injector.get('ModelFactory').EconSetting();
				var ref = $q.defer();
				settingModel.updateSettings(settings).$promise.then(function (response) {
					ref.resolve(response);
				});
				return ref.promise;
			};

			return this;
		},
	]);
});

