define('modules/wawi/product/controllers/ProductBaseFormController',[
	'modules/module',
	'modules/wawi/product/controllers/tabs/GeneralTabController',
	'modules/wawi/product/controllers/tabs/ShippingMediaTabController',
	'modules/wawi/product/controllers/tabs/DescriptionTabController',
	'modules/wawi/product/controllers/tabs/PriceTabController',
	'modules/wawi/product/controllers/tabs/ProductDimensionsTabController',
	'modules/wawi/product/controllers/tabs/TagsTabController',
	'modules/wawi/product/controllers/tabs/CategoriesTabController',
	'modules/wawi/product/controllers/tabs/SeoTabController',
	'modules/wawi/product/controllers/tabs/MediaTabController',
	'modules/wawi/product/controllers/tabs/PrintAreasTabController',
	'modules/wawi/product/controllers/tabs/MetadataTabController',
	'modules/wawi/product/controllers/tabs/StockManagementTabController',
	'modules/wawi/product/controllers/tabs/ImagesTabController',
	'modules/wawi/product/controllers/tabs/SellingProductsTabController',
	'modules/basemodule/controllers/EntityFormController',
	'directives/controls/customInputTime/CustomInputTime',
	'directives/dateRangePicker/DateRangePicker',
	'directives/listSelect/ListSelect',
	'directives/listSelectSku/ListSelectSku',
	'services/CategoryService',
	'services/TagUnpagedService',
	'services/UserService',
	'services/ProductUnpagedService',
	'services/MetadataSetUnpagedService',
	'services/MetadataService',
	'services/MetadataUnpagedService',
	'services/UnitUnpagedService',
	'services/NotificationService',
	'services/ShopService',
	'services/SessionValuesService',
	'services/SellingProductService',
	'services/CustomerGroupService',
	'services/AttributeService',
	'filters/RemoveSpacesAndSpecialCharacters',
	'settings',
], function (
	module,
	generalTab,
	shippingMediaTab,
	descriptionTab,
	priceTab,
	productDimensionsTab,
	tagsTab,
	categoriesTab,
	seoTab,
	mediaTab,
	printAreasTab,
	metadataTab,
	stockManagementTab,
	imagesTab,
	sellingProductsTab
) {
	'use strict';
	module.controller('ProductBaseFormController', [
		'$rootScope',
		'$scope',
		'$controller',
		'$state',
		'UserService',
		'PublishStateService',
		'ManufacturerService',
		'UnitUnpagedService',
		'TaxService',
		'TagUnpagedService',
		'ProductUnpagedService',
		'CategoryService',
		'RefinementProductService',
		'MetadataSetUnpagedService',
		'MetadataService',
		'MetadataUnpagedService',
		'NotificationService',
		'BASE_TEMPLATES_PATH',
		'ImageService',
		'MediaService',
		'FileUploader',
		'ProductService',
		'ShopService',
		'SessionValuesService',
		'SellingProductService',
		'CustomerGroupService',
		'AttributeService',
		ProductBaseFormController(
			generalTab,
			shippingMediaTab,
			descriptionTab,
			priceTab,
			productDimensionsTab,
			tagsTab,
			categoriesTab,
			seoTab,
			mediaTab,
			printAreasTab,
			metadataTab,
			stockManagementTab,
			imagesTab,
			sellingProductsTab
		),
	]);
});

function ProductBaseFormController(
	generalTab,
	shippingMediaTab,
	descriptionTab,
	priceTab,
	productDimensionsTab,
	tagsTab,
	categoriesTab,
	seoTab,
	mediaTab,
	printAreasTab,
	metadataTab,
	stockManagementTab,
	imagesTab,
	sellingProductsTab
) {
	return function (
		$rootScope,
		$scope,
		$controller,
		$state,
		UserService,
		PublishStateService,
		ManufacturerService,
		UnitUnpagedService,
		TaxService,
		TagUnpagedService,
		ProductUnpagedService,
		CategoryService,
		RefinementProductService,
		MetadataSetUnpagedService,
		MetadataService,
		MetadataUnpagedService,
		NotificationService,
		BASE_TEMPLATES_PATH,
		ImageService,
		MediaService,
		FileUploader,
		ProductService,
		ShopService,
		SessionValuesService,
		SellingProductService,
		CustomerGroupService,
		AttributeService
	) {
		angular.extend(
			this,
			$controller('EntityFormController', {
				$scope: $scope,
			})
		);

		$scope.hasProductWizard = true;

		const injectionContext = {
			UserService,
			PublishStateService,
			ManufacturerService,
			UnitUnpagedService,
			TaxService,
			TagUnpagedService,
			ProductUnpagedService,
			CategoryService,
			RefinementProductService,
			MetadataSetUnpagedService,
			MetadataService,
			MetadataUnpagedService,
			NotificationService,
			BASE_TEMPLATES_PATH,
			ImageService,
			MediaService,
			FileUploader,
			ProductService,
			ShopService,
			SessionValuesService,
			SellingProductService,
			CustomerGroupService,
			AttributeService,
		};

		$scope.formTabs = [];

		generalTab($scope, ($scope.generalScope = {}), injectionContext);
		descriptionTab($scope, ($scope.descriptionScope = {}), injectionContext);
		priceTab($scope, ($scope.priceScope = {}), injectionContext);
		productDimensionsTab($scope, ($scope.productDimensionsScope = {}), injectionContext);
		tagsTab($scope, ($scope.tagsScope = {}), injectionContext);
		categoriesTab($scope, ($scope.categoriesScope = {}), injectionContext);
		seoTab($scope, ($scope.seoScope = {}), injectionContext);
		mediaTab($scope, ($scope.mediaScope = {}), injectionContext);
		printAreasTab($scope, ($scope.printAreasScope = {}), injectionContext);
		metadataTab($scope, ($scope.metadataScope = {}), injectionContext);
		stockManagementTab($scope, ($scope.stockManagementScope = {}), injectionContext);
		imagesTab($scope, ($scope.imagesScope = {}), injectionContext);
		sellingProductsTab($scope, ($scope.sellingProductsScope = {}), injectionContext);

		// Plugins
		$scope.requestPlugins(() => {
			$scope.werePluginsRegistered = true;

			if ($scope.isPluginInstalled('7710854808841553559')) {
				shippingMediaTab($scope, ($scope.shippingMediaScope = {}), injectionContext);
			}

			$scope.sortProductPrices = !$scope.isPluginInstalled('8657470497111706292');

			$scope.priceScope.initProductPricesArray($scope.werePluginsRegistered, $scope.sortProductPrices);
		});

		$scope.availableData = {};
		$scope.selected = {};
		$scope.listState = 'root.products';

		$scope.type = $state.current.url.split('/')[2];

		$scope.selectedCrossSellingProducts = [];
		$scope.selected.productsCurrentPage = 0;
		$scope.selected.productsPageSize = 10;
		$scope.selected.productsTotalItems = 0;

		$scope.werePluginsRegistered = false;
		$scope.sortProductPrices = null; // null means haven't decided yet

		$scope.$on('afterInit', function () {
			$scope.topBarTemplate = BASE_TEMPLATES_PATH + '/wawi/product/partials/duplicate-button.html';

			if (!$scope.entity.tax || !$scope.entity.taxId) {
				ShopService.findOne(UserService.getShop()).then(() => {
					const shop = ShopService.getOne();
					$scope.entity.tax = shop.defaultTax || null;
					$scope.entity.taxId = shop.defaultTax ? shop.defaultTax.id : null;
				});
			}

			var countLocales = 0;
			if ($scope.entity.locales != undefined) {
				$scope.entity.locales.sort(function (a, b) {
					if (a.localeId < b.localeId) return -1;
					if (a.localeId > b.localeId) return 1;
					return 0;
				});

				$scope.metadataScope.afterInit();
				$scope.seoScope.afterInit();
			}

			if ($scope.entity.productAssistant == undefined) {
				$scope.entity.productAssistant = false;
			}

			if ($scope.entity.useGlobalSettings == undefined) {
				$scope.entity.useGlobalSettings = false;
			}

			if ($scope.entity.sortOrder == undefined) {
				$scope.entity.sortOrder = 9999;
			}

			$scope.categoriesScope.afterInit();
			$scope.productDimensionsScope.init();
			$scope.tagsScope.afterInit();

			if ($scope.entity.sellingProducts == undefined) {
				$scope.entity.sellingProducts = [];
			}

			$scope.priceScope.initPrices();

			switch ($scope.type) {
				case 'simple':
					$scope.entity.type = 'simples';
					break;
				case 'config':
					$scope.entity.type = 'configurables';
					break;
				case 'bundle':
					$scope.entity.type = 'bundles';
					break;
				default:
					break;
			}

			if ($scope.entity.stock == undefined) {
				$scope.entity.stock = {};
			}

			$scope.availableMetadatas = [];
			$scope.params = [{ name: 'filter[order]', value: 'position ASC' }];

			$scope.metadataScope.postAfterInit();

			$scope.sellingProductsScope.setSellingProducts();
			$scope.printAreasScope.initSelectedRefinementProducts();
		});

		$scope.mergeSellingProductsWithType = function (entity, productArray, sellingType) {
			for (var i = 0; i < productArray.length; i++) {
				var sellingProduct = productArray[i];
				var sellingProductUpdate = {};
				sellingProductUpdate.id = sellingProductUpdate.productId = sellingProduct.id;
				sellingProductUpdate.sku = sellingProduct.sku;
				sellingProductUpdate.name = sellingProduct.name;
				sellingProductUpdate.publishState = sellingProduct.publishState;
				sellingProductUpdate.sellingType = sellingType;
				if (!entity.sellingProducts) {
					entity.sellingProducts = [];
				}
				entity.sellingProducts.push(sellingProductUpdate);
			}
		};

		$scope.$on('duplicateSaved', function () {
			var countLocales = 0;
			if ($scope.entity.locales !== undefined) {
				$scope.entity.locales.sort(function (a, b) {
					if (a.localeId < b.localeId) return -1;
					if (a.localeId > b.localeId) return 1;
					return 0;
				});
				for (var i = 0; i < $scope.entity.locales.length; i++) {
					if ($scope.entity.locales[i].metadata !== undefined) {
						if ($scope.entity.locales[i].metadata.length > countLocales) {
							countLocales = $scope.entity.locales[i].metadata.length;
						}
					}
					$scope.entity.locales[i].metadata.sort(function (a, b) {
						if (a.position === b.position) {
							var x = a.identifier.toLowerCase(),
								y = b.identifier.toLowerCase();
							return x < y ? -1 : x > y ? 1 : 0;
						}
						if (a.position < b.position) return -1;
						if (a.position > b.position) return 1;
						return a.position - b.position;
					});
				}
			}
			$scope.metadataScope.metadataObjects = [];
			$scope.metadataScope.getMetadataFromServer();
		});

		$scope.$on('beforeUpdate', function () {
			$scope.beforeBaseSave();
		});

		$scope.$on('beforeCreate', function () {
			$scope.beforeBaseSave();
		});

		$scope.beforeBaseSave = function () {
			for (var i = 0; i < $scope.entity.locales.length; i++) {
				var productLocale = $scope.entity.locales[i];
				if (Object.keys(productLocale).length <= 1 && !productLocale.name) {
					//if the name is not filled out remove this locale
					$scope.entity.locales.splice(i, 1);
				}
			}

			$scope.metadataScope.beforeBaseSave();
			$scope.sellingProductsScope.beforeBaseSave();
			if ($scope.entity.variants != undefined && $scope.entity.variants.length > 0) {
				//Variants have to be save seperately in the row
				$scope.entity.variants = undefined;
			}
			$scope.printAreasScope.beforeSave();
			$scope.entityService.setAdditionalParams([
				{
					name: 'type',
					value: $scope.entity.type,
				},
			]);
			$scope.priceScope.removePricesWithNoSellingPrice();
			$scope.imagesScope.beforeBaseSave();
		};

		$scope.$on('afterUpdate', function () {
			$scope.printAreasScope.initSelectedRefinementProducts();
			$scope.sellingProductsScope.setSellingProducts();
		});

		$scope.$on('afterCreate', function () {
			$scope.printAreasScope.initSelectedRefinementProducts();
			$scope.sellingProductsScope.setSellingProducts();
		});

		/*/-----DUPLICATE PRODUCT-----/*/
		$scope.duplicate = function () {
			NotificationService.confirm({
				statusText: 'Haben sie das aktuelle Produkt gespeichert?',
			}).then(
				function () {
					NotificationService.notifyWarning({
						statusText: 'Bitte passen Sie im Duplikat den Namen, den Slug und die Sku an.',
					});
					$scope.entityId = '';
					$scope.newEntity = true;

					delete $scope.entity.id;
					delete $scope.entity.stock.id;

					$scope.entity.sku = $scope.entity.sku + '-duplicate';
					$scope.entity.slug = $scope.entity.slug + '-duplicate';
					$scope.entity.publishState = 'MAINTENANCE';

					for (var i = 0; i < $scope.entity.locales.length; i++) {
						$scope.entity.locales[i].name = $scope.entity.locales[i].name;
						for (var j = 0; j < $scope.entity.locales[i].metadata.length; j++) {
							delete $scope.entity.locales[i].metadata[j].id;
							for (
								var k = 0;
								k < $scope.entity.locales[i].metadata[j].productMetadataValues.length;
								k++
							) {
								delete $scope.entity.locales[i].metadata[j].productMetadataValues[k].id;
							}
						}
					}

					for (var i = 0; i < $scope.entity.prices.length; i++) {
						delete $scope.entity.prices[i].id;
					}
				},
				function () {}
			);
		};
		/*-----DUPLICATE PRODUCT-----*/

		$scope.getEntityObject = function () {
			return $scope.entity;
		};

		$scope.getEntityObjectString = function () {
			return 'entity';
		};

		$scope.validationConfig = {
			name: {
				method: 'isset',
				fieldName: 'Name',
				errorMsg: 'Bitte geben Sie einen Namen in der Standard-Sprache an.',
				isLocaleValue: true,
				customValidate: function (entity) {
					//can be used for specific validation
					return true;
				},
			},
			taxId: {
				method: 'isset',
				fieldName: 'Steuersatz',
				errorMsg: 'Bitte geben Sie einen Steuersatz an.',
			},
			publishState: {
				method: 'isset',
				fieldName: 'Status',
				errorMsg: 'Bitte geben Sie einen Produkt-Status an.',
			},
			//TODO: validate weight as number only if it's not empty
			/*weight: {
				method: "isNumeric",
				fieldName: "Gewicht",
				errorMsg: "Bitte geben Sie ein Gewicht an. Es muss ein numerischer Wert sein."
			},*/
			sellingPrice: {
				method: 'isset',
				fieldName: 'Verkaufspreis',
				errorMsg: 'Für den Verkaufspreis ist kein Wert hinterlegt.',
				customValidate: $scope.priceScope.isAtLeastOneSellingPricePresent,
			},
			metadata: {
				method: 'isset',
				fieldName: 'Produktmerkmale',
				errorMsg: 'Nicht alle Pflichtmerkmale sind vorhanden.',
				customValidate: $scope.metadataScope.metadataValidate,
			},
			attributes: {
				method: 'isset',
				fieldName: 'Attribute',
				errorMsg: 'Es sind keine Attribute vorhanden',
				customValidate: function (entity) {
					$scope.customValidation = true;

					if ($scope.entity.type === 'configurables' && $scope.entityId === '') {
						if ($scope.entity.selectedAttributes === undefined) {
							$scope.customValidation = false;
						} else {
							for (var i = 0; i < $scope.entity.selectedAttributes.length; i++) {
								if (
									$scope.entity.selectedAttributes[i].optionIds != undefined &&
									$scope.entity.selectedAttributes[i].optionIds.length > 0
								) {
									$scope.customValidation = true;
									break;
								} else {
									$scope.customValidation = false;
								}
							}
						}
					}

					return $scope.customValidation;
				},
			},
		};
	};
}
;
