define('services/MediaService',[
	'app',
	'modules/module',
	'services/NotificationService',
	'modules/models',
	'services/EntityCrudService',
	'services/SessionValuesService',
], function (app, module) {
	'use strict';
	return module.factory('MediaService', [
		'$q',
		'$rootScope',
		'$cookies',
		'$injector',
		'$timeout',
		'FileUploader',
		'NotificationService',
		'SessionValuesService',
		function (
			$q,
			$rootScope,
			$cookies,
			$injector,
			$timeout,
			FileUploader,
			NotificationService,
			SessionValuesService
		) {
			var crudInstance = $injector.instantiate(CrudService);
			angular.extend(this, crudInstance);

			let _files, _fileUploader;
			let _directories;
			let _pageSize = 5;
			let _currentPage = 1;

			let _loadDirectories = function (page, size, directoryId, query) {
				var ref = $q.defer();
				$injector
					.get('ModelFactory')
					.Media()
					.getDirectories({ page, size, directoryId, query })
					.$promise.then(function (response) {
						_directories = response.children;
						_files = response.files;
						ref.resolve(response);
					}, NotificationService.notifyRequestError);
				return ref.promise;
			};

			if ($cookies.get('_pageSize') === undefined) {
				$cookies.put('_pageSize', 10);
			}

			if ($cookies.get('_currentPage') === undefined) {
				$cookies.put('_currentPage', 0);
			}

			let updateMetadata = (id, shopid, mimetype, mediaMetadataValues) => {
				var ref = $q.defer();
				$injector
					.get('ModelFactory')
					.MediaMetadata()
					.updateOne({ id, shopid, mimetype, mediaMetadataValues, isDefault: false })
					.$promise.then(function (response) {
						ref.resolve();
						$rootScope.$broadcast('metadataUpdated', { id, response });
					}, NotificationService.notifyRequestError);
				return ref.promise;
			};

			let getMetadata = (id) => {
				var ref = $q.defer();
				$injector
					.get('ModelFactory')
					.MediaMetadata()
					.getOne({ id })
					.$promise.then(function (response) {
						ref.resolve();
						$rootScope.$broadcast('metadataUpdated', { id, response });
					}, NotificationService.notifyRequestError);
				return ref.promise;
			};

			var initFileUploader = function (uploader) {
				_fileUploader = uploader;
				/*_fileUploader.filters.push({
					name: 'mediaFilter',
					fn: function (item, options) {
						var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
						return '|wav|pdf|mp3|txt|mpeg|plain|'.indexOf(type) !== -1;
					},
				});*/
				_fileUploader.onBeforeUploadItem = function (item) {
					item.formData.push({ description: item.description });
				};
			};

			let uploadItem = function (item, contextLabel) {
				item.onSuccess = function (response) {
					$rootScope.$broadcast('mediaUploaded', response);
					$rootScope.$broadcast('CustomerMediaUploaded', response, contextLabel);
				};
				item.headers = SessionValuesService.getFullSessionHeader();
				$timeout(item.upload(), 1);
			};

			let deleteItem = function (id) {
				var ref = $q.defer();
				$injector
					.get('ModelFactory')
					.Media()
					.delete({ id })
					.$promise.then(function (response) {
						_files = response.files;
						ref.resolve();
						$rootScope.$broadcast('MediaDeleted', _files);
					}, NotificationService.notifyRequestError);
				return ref.promise;
			};

			this.getDirectories = function () {
				return _directories;
			};

			this.initFileUpload = function (uploader) {
				initFileUploader(uploader);
				return _fileUploader;
			};

			this.updateMetadata = (id, shopid, mimetype, metadata) => {
				updateMetadata(id, shopid, mimetype, metadata);
			};

			this.getMetadata = (id) => {
				getMetadata(id);
			};

			this.loadDirectories = (page, pageSize, directoryId, query) => {
				return _loadDirectories(page, pageSize, directoryId, query);
			};

			this.uploadItems = function (items, contextLabel) {
				items.forEach(function (item) {
					uploadItem(item, contextLabel);
				});
			};

			this.getFiles = () => {
				return _files;
			};

			this.getPageSize = function () {
				return $cookies.get('_pageSize');
			};

			this.deleteItem = function (item) {
				return deleteItem(item);
			};

			return this;
		},
	]);
});

