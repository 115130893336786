define('services/DropShippingService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('DropShippingService', ['$injector', DropShippingService]);
});

function DropShippingService($injector) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('DropShipping');

	angular.extend(this, crudInstance);

	return this;
}
;
