define('modules/settings/shop/tabs/GeneralTabController',[], function () {
	'use strict';

	return function GeneralTabController(
		$parentScope,
		$scope,
		{
			BASE_TEMPLATES_PATH,
			PublishStateService,
			OrganisationService,
			CustomerGroupService,
			TaxService,
			RecipientListService,
		}
	) {
		$parentScope.formTabs.push({
			label: 'FIELDS.GENERAL',
			template: BASE_TEMPLATES_PATH + '/settings/shop/form-main.html',
			active: true,
		});

		$scope.selectedPhones = [];

		OrganisationService.findAll().then(function () {
			$scope.organisations = OrganisationService.getAll();
		});

		TaxService.findAll().then(function () {
			$scope.taxes = TaxService.getAll(true);
		});

		$scope.customerGroupPriceHandlingOptions = CustomerGroupService.getPriceHandlingOptions();

		$scope.addPhone = function () {
			$scope.selectedPhones.push({});
		};

		$scope.removePhone = function (index) {
			$scope.selectedPhones.splice(index, 1);
		};

		$scope.setSelectedPhones = function () {
			$parentScope.entity.shopConfiguration.phones = $scope.selectedPhones;
		};

		$scope.init = () => {
			if ($parentScope.entity.shopConfiguration.phones != undefined) {
				for (var i = 0; i < $parentScope.entity.shopConfiguration.phones.length; i++) {
					$scope.selectedPhones.push($parentScope.entity.shopConfiguration.phones[i]);
				}
			}

			PublishStateService.findAll().then(function () {
				$scope.publishStates = PublishStateService.getAll(true);
			});

			RecipientListService.findAll().then(function () {
				$scope.recipientListIds = RecipientListService.getAll(true);
			});
		};
	};
});

