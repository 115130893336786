/**
 * Created by Nico on 05.07.2016.
 */
define('modules/settings/unit/UnitFormController',[
	'modules/module',
	'modules/basemodule/controllers/EntityFormController',
	'services/ValidationService',
	'settings',
], function (module) {
	'use strict';
	module.controller('UnitFormController', [
		'$scope',
		'$controller',
		'ValidationService',
		'BASE_TEMPLATES_PATH',
		function ($scope, $controller, ValidationService, BASE_TEMPLATES_PATH) {
			angular.extend(
				this,
				$controller('EntityFormController', {
					$scope: $scope,
				})
			);

			$scope.state.requireSuperuserForUpdate = true;

			$scope.formTabs = [
				{
					label: 'FIELDS.GENERAL',
					template: BASE_TEMPLATES_PATH + '/settings/unit/form-main.html',
					active: true,
				},
			];
			$scope.init('Unit').then(function () {
				if (!$scope.entity.factor) {
					$scope.entity.factor = 0.0;
				}
			});

			$scope.listState = 'root.units';

			$scope.validate = function () {
				return ValidationService.validate($scope.entity, {
					name: {
						method: 'isset',
						fieldName: 'Name',
					},
					unit: {
						method: 'isset',
						fieldName: 'Einheit',
					},
					type: {
						method: 'isset',
						fieldName: 'Typ',
					},
					factor: {
						method: 'isset',
						fieldName: 'Faktor',
					},
				});
			};

			$scope.dropDown = {
				unitTypes: [
					{
						display: 'AMOUNT',
						value: 'AMOUNT',
					},
					{
						display: 'VOLUME',
						value: 'VOLUME',
					},
					{
						display: 'WEIGHT',
						value: 'WEIGHT',
					},
					{
						display: 'HEIGHT',
						value: 'HEIGHT',
					},
					{
						display: 'WIDTH',
						value: 'WIDTH',
					},
					{
						display: 'DEPTH',
						value: 'DEPTH',
					},
					{
						display: 'WEIGHT_CALCULATED',
						value: 'WEIGHT_CALCULATED',
					},
				],
			};
		},
	]);
});

