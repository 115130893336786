define('directives/contentConfigurator/contentSnippets/listSnippet/ListSnippet',[
	'app',
	'angular',
	'services/ContentBuilderHelper',
	'directives/contentConfigurator/contentSnippets/_snippet',
], function (app) {
	'use strict';
	app.directive('listSnippet', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				restrict: 'E',
				scope: {
					metaIdentifier: '<',
					snippet: '=',
					position: '=',
					parent: '<',
				},
				templateUrl:
					BASE_DIRECTIVES_PATH + '/contentConfigurator/contentSnippets/listSnippet/views/listSnippet.html',
				controller: [
					'$scope',
					'$controller',
					'$element',
					'$compile',
					'$timeout',
					'ContentBuilderHelper',
					function ($scope, $controller, $element, $compile, $timeout, ContentBuilderHelper) {
						var path = BASE_DIRECTIVES_PATH + '/contentConfigurator/contentSnippets/listSnippet/views/';

						$scope.customStyle = path + 'style.css';
						$scope.includeVisibility = true;

						var wrapper = $($element).find('[data-content-snippet="list"]');
						var template =
							'<ul class="list-container"><li ng-repeat="item in snippet.config.items"><i class="fa" ng-class="[snippet.config.icon]"></i><strong>{{item.title}}</strong><p>{{item.text}}</p></li></ul>';

						angular.extend(
							this,
							$controller('snippetController', {
								$scope: $scope,
							})
						);

						var _init = function () {
							$scope.snippetType = 'list';
							$scope.elementType = $scope.ELEMENT_TYPE.SNIPPET;

							if ($scope.snippet.config === undefined) {
								$scope.snippet.config = {
									classes: '',
									icon: undefined,
									items: [],
								};
							}

							_refresh();
						};
						_init();
						function _refresh() {
							try {
								wrapper.list('destroy');
							} catch (e) {}
							wrapper.find('.list-container').remove();
							wrapper.append($compile(template)($scope));
							$scope.includeVisibility = false;
							$timeout(function () {
								$scope.$apply();
								$scope.includeVisibility = true;
							}, 100);
						}

						function _watcher(newValue, oldValue) {
							if (newValue !== oldValue) {
								_refresh();
							}
						}
						$scope.$watch('snippet.config', _watcher, true);
						$scope.$watch('edit', _watcher, true);
					},
				],
			};
		},
	]);
});

/*

 */
;
