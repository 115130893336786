define('services/TagVariantService',['app', 'modules/module', 'services/EntityCrudService'], function (app, module) {
	'use strict';
	return module.factory('TagVariantService', ['$injector', TagVariantService]);
});

function TagVariantService($injector) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('Tag');

	angular.extend(this, crudInstance);

	return this;
}
;
