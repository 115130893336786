define('modules/settings/shop/tabs/LocaleTabController',[], function () {
	'use strict';

	return function LocaleController($parentScope, $scope, { BASE_TEMPLATES_PATH, LocaleService }) {
		$parentScope.adminFormTabs.push({
			label: 'FIELDS.LOCALE',
			template: BASE_TEMPLATES_PATH + '/settings/shop/form-locales.html',
			service: LocaleService,
		});

		$scope.selectedLocales = [];
		$scope.localesTableOptions = {};
		$scope.localesTableOptions.fields = [
			{
				heading: 'FIELDS.LANGUAGE',
				attribute: 'language',
			},
			{
				heading: 'FIELDS.ABBREVIATION',
				attribute: 'locale',
			},
		];

		$scope.init = () => {
			$scope.locales = LocaleService.getAll(true);
			$parentScope.selected.localesTotalItems = LocaleService.getTotalItems();
			$parentScope.selected.localesPageSize = LocaleService.getPageSize();
			$scope.markSelectedLocales();
		};

		let initializedFromEntity = $scope.newEntity;
		$scope.markSelectedLocales = function () {
			for (var key in $scope.locales) {
				if (!initializedFromEntity) {
					if ($parentScope.entity.localeIds !== undefined) {
						for (var i = 0; i < $parentScope.entity.localeIds.length; i++) {
							if ($parentScope.entity.localeIds[i] == $scope.locales[key].id) {
								$scope.selectedLocales.push($scope.locales[key]);
							}
						}
					}
				}
				if (!!$scope.selectedLocales.find((sl) => sl.id === $scope.locales[key].id)) {
					$scope.locales[key].selected = true;
				}
			}
			if (!!$scope.locales.length && !initializedFromEntity) {
				initializedFromEntity = true;
			}
		};

		$scope.setSelectedLocales = function () {
			$parentScope.entity.localeIds = [];
			$scope.selectedLocales.forEach(function (locale) {
				$parentScope.entity.localeIds.push(locale.id);
			});
		};

		$scope.localesSetPage = function () {
			LocaleService.setCurrentPage($parentScope.getRealCurrentPage($parentScope.selected.localesCurrentPage));
		};

		$scope.setTableDataLocales = function () {
			$parentScope.selected.localesPageSize = LocaleService.getPageSize();
			$parentScope.selected.localesTotalItems = LocaleService.getTotalItems();

			$scope.localesTotalPages = LocaleService.getTotalPages();

			if ($scope.localesTotalPages === 0) {
				//If there are no items the total pages size should be displayed as 1
				$scope.localesTotalPages = 1;
			}

			$parentScope.selected.localesCurrentPage = LocaleService.getCurrentPage();
			$scope.locales = LocaleService.getAll();
			$parentScope.$broadcast('tableDataRefreshed');
		};

		$scope.defaultLocaleChanged = function () {
			$parentScope.defaultValueChanged(
				$scope.locales,
				$scope.selectedLocales,
				$parentScope.entity.defaultLocaleId
			);
		};
	};
});

