define('directives/listSelect/ListSelect',['app', 'settings'], function (app) {
	'use strict';
	app.directive('ngListSelect', ['BASE_DIRECTIVES_PATH', NgListSelect]);
});
function NgListSelect(BASE_DIRECTIVES_PATH) {
	return {
		scope: {
			items: '=',
			itemIds: '=',
			field: '=',
			name: '=',
			isCrossSelling: '=',
			rightSideItemClick: '=?',
		},
		templateUrl: BASE_DIRECTIVES_PATH + '/listSelect/views/view.html',
		controller: ['$scope', NgListSelectController],
	};
}
function NgListSelectController($scope) {
	const deregisterWatchItemIds = $scope.$watch('itemIds', function (newValue, oldValue) {
		if (!newValue) {
			return;
		}
		$scope.selectItems();
	});

	const deregisterWatchItems = $scope.$watch('items', function (newValue, oldValue) {
		if (newValue === undefined || newValue == null) {
			return;
		}
		$scope.items = newValue;
		$scope.selectItems();
	});

	$scope.selectedItems = [];

	$scope.localRightSideItemClick = function (item) {
		if ($scope.rightSideItemClick) {
			$scope.rightSideItemClick(item);
		}
		$scope.selectedRowItem = item;
	};

	$scope.selectItems = function () {
		$scope.selectedItems = [];
		if ($scope.isCrossSelling) {
			for (var i = 0; i < $scope.itemIds.length; i++) {
				if ($scope.items != undefined) {
					for (var j = 0; j < $scope.items.length; j++) {
						if ($scope.itemIds[i].productId == $scope.items[j].id) {
							$scope.selectedItems.push($scope.items[j]);
							$scope.items[j].added = true;
							$scope.items[j].selected = false;
						}
					}
				}
			}
		} else {
			for (var i = 0; $scope.itemIds && i < $scope.itemIds.length; i++) {
				if ($scope.items != undefined) {
					for (var j = 0; j < $scope.items.length; j++) {
						if ($scope.itemIds[i] == $scope.items[j].id) {
							$scope.selectedItems.push($scope.items[j]);
							$scope.items[j].added = true;
							$scope.items[j].selected = false;
						}
					}
				}
			}
		}
	};

	$scope.addItems = function () {
		for (var i = 0; i < $scope.items.length; i++) {
			if ($scope.items[i].selected) {
				$scope.selectedItems.push($scope.items[i]);
				$scope.items[i].added = true;
				$scope.items[i].selected = false;
				if ($scope.isCrossSelling) {
					$scope.itemIds.push({ productId: $scope.items[i].id, sellingType: 'CROSS_SELLING' });
				} else {
					$scope.itemIds.push($scope.items[i].id);
				}
			}
		}
	};

	$scope.removeItems = function () {
		for (var i = 0; i < $scope.selectedItems.length; i++) {
			if ($scope.selectedItems[i].selected) {
				for (var j = 0; j < $scope.items.length; j++) {
					if ($scope.items[j].id == $scope.selectedItems[i].id) {
						$scope.items[j].added = false;
						$scope.items[j].selected = false;
					}
				}
				if ($scope.isCrossSelling) {
					for (var k = 0; k < $scope.itemIds.length; k++) {
						if ($scope.itemIds[k].productId == $scope.selectedItems[i].id) {
							$scope.itemIds.splice(k, 1);
						}
					}
				} else {
					for (var k = 0; k < $scope.itemIds.length; k++) {
						if ($scope.itemIds[k] == $scope.selectedItems[i].id) {
							$scope.itemIds.splice(k, 1);
						}
					}
				}
				$scope.selectedItems.splice(i, 1);
				i--;
			}
		}
	};

	$scope.itemsLeftSelected = function () {
		if ($scope.items != undefined) {
			for (var i = 0; i < $scope.items.length; i++) {
				if ($scope.items[i].selected && !$scope.items[i].added) {
					return true;
				}
			}
			return false;
		}
	};

	$scope.itemsRightSelected = function () {
		for (var i = 0; i < $scope.selectedItems.length; i++) {
			if ($scope.selectedItems[i].selected) {
				return true;
			}
		}
		return false;
	};

	$scope.$on('destroy', () => {
		deregisterWatchItemIds();
		deregisterWatchItems();
	});
}
;
