define('directives/customImage/CustomImage',['app'], function (app) {
	'use strict';
	app.directive('ngCustomImage', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				replace: true,
				scope: {
					imageId: '=',
					maxWidth: '=?',
					maxHeight: '=?',
					imageElementId: '@?',
					imageAlt: '=?',
				},
				templateUrl: BASE_DIRECTIVES_PATH + '/customImage/views/customImage.html',
				controller: [
					'$scope',
					function ($scope) {
						if (!$scope.maxWidth) {
							$scope.maxWidth = 1000;
						}

						if (!$scope.maxHeight) {
							$scope.maxHeight = 1000;
						}

						if (!$scope.imageElementId) {
							$scope.imageElementId = '';
						}

						if (!$scope.imageAlt) {
							$scope.imageAlt = '';
						}
					},
				],
			};
		},
	]);
});

