/**
 * Created by Nico on 07.07.2016.
 */
define('services/PriceRuleUnpagedService',['app', 'modules/module', 'services/EntityCrudUnpagedService'], function (app, module) {
	'use strict';
	return module.factory('PriceRuleUnpagedService', ['$injector', PriceRuleUnpagedService]);
});

function PriceRuleUnpagedService($injector) {
	crudInstance = $injector.instantiate(EntityCrudUnpagedService);
	crudInstance.setType('PriceRule');

	angular.extend(this, crudInstance);

	return this;
}
;
