define('modules/settings/shop/tabs/GlobalSettingsTabController',[], function () {
	'use strict';

	return function GlobalSettingsController($parentScope, $scope, { BASE_TEMPLATES_PATH }) {
		$parentScope.adminFormTabs.push({
			label: 'FIELDS.GLOBAL_SETTINGS',
			template: BASE_TEMPLATES_PATH + '/settings/shop/form-global-settings.html',
		});

		$scope.conditionOperators = [
			{
				name: '=',
				value: 'EQUAL',
			},
			{
				name: '!=',
				value: 'NOT_EQUAL',
			},
			{
				name: '<',
				value: 'LESS_THAN',
			},
			{
				name: '>',
				value: 'GREATER_THAN',
			},
			{
				name: '<=',
				value: 'LESS_THAN_OR_EQUAL',
			},
			{
				name: '>=',
				value: 'GREATER_THAN_OR_EQUAL',
			},
		];

		$scope.actionTypes = [
			{
				name: 'FIELDS.RANDOM_VARIANT',
				value: 'GlobalSettingActionRandomVariant',
			},
			{
				name: 'FIELDS.CHEAPEST_VARIANT',
				value: 'GlobalSettingActionCheapestVariant',
			},
		];

		$scope.conditionTypes = [
			{
				name: 'FIELDS.ALWAYS',
				value: 'GlobalSettingConditionAlways',
			},
			{
				name: 'FIELDS.PRODUCT_HAS_NO_IMAGE',
				value: 'GlobalSettingConditionParentHasNoImage',
			},
			{
				name: 'FIELDS.PRODUCT_HAS_NO_PRICE',
				value: 'GlobalSettingConditionParentHasNoPrice',
			},
		];
	};
});

