/**
 * Created by Nico on 18.07.2016.
 */
define('services/SystemMessageService',['app', 'modules/module', 'services/EntityCrudService', 'services/NotificationService'], function (app, module) {
	'use strict';
	return module.factory('SystemMessageService', ['$injector', 'NotificationService', SystemMessageService]);
});

function SystemMessageService($injector, NotificationService) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('SystemMessage');

	angular.extend(this, crudInstance);

	return this;
}
;
