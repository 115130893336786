define('modules/cms/controllers/LandingPageFormController',[
	'modules/module',
	'settings',
	'services/NavigationService',
	'services/ProductService',
	'modules/cms/controllers/CmsFormBaseController',
	'directives/formatDate/formatDate',
	'directives/controls/customInputTime/CustomInputTime',
	'directives/tinyMce/tinyMce',
], function (module) {
	'use strict';
	module.controller('LandingPageFormController', [
		'$scope',
		'$controller',
		'PublishStateService',
		'ProductService',
		'BASE_TEMPLATES_PATH',
		LandingPageFormController,
	]);
});

function LandingPageFormController($scope, $controller, PublishStateService, ProductService, BASE_TEMPLATES_PATH) {
	angular.extend(
		this,
		$controller('CmsFormBaseController', {
			$scope: $scope,
		})
	);

	$scope.productService = ProductService;
	$scope.productService.setCurrentPage(0).then(function () {
		$scope.data.products = $scope.productService.getAll();
	});

	$scope.formTabs = [
		{
			label: 'FIELDS.GENERAL',
			template: BASE_TEMPLATES_PATH + '/cms/partials/_form-landing-pages.html',
			active: true,
		},
		{
			label: 'FIELDS.CONTENT',
			template: BASE_TEMPLATES_PATH + '/cms/partials/_form-filter-pages-content.html',
		},
		{
			label: 'FIELDS.PRODUCTS',
			template: BASE_TEMPLATES_PATH + '/cms/partials/_form-landing-pages-product.html',
		},
		{
			label: 'FIELDS.SEO',
			template: BASE_TEMPLATES_PATH + '/cms/partials/_form-filter-pages-seo.html',
		},
	];

	$scope.validationConfig = {
		publishStateId: {
			method: 'isset',
			fieldName: 'Satus',
		},
		name: {
			method: 'isset',
			fieldName: 'Name',
			isLocaleValue: true,
		},
	};

	$scope.tinymceOptions = {
		theme: 'modern',
		height: '500',
		convert_urls: false,
		relative_urls: false,
		remove_script_host: false,
		plugins: [
			'link advlist lists image charmap print preview hr anchor pagebreak',
			'searchreplace wordcount visualblocks visualchars code fullscreen',
			'insertdatetime media nonbreaking save table contextmenu directionality',
			'emoticons template paste textcolor colorpicker textpattern imagetools',
			'fullpage moodiaimage',
		],
		menubar: 'insert | tools',
		toolbar1:
			'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link moodiaimage',
		toolbar2: 'print preview media | forecolor backcolor emoticons | fontsizeselect | fontselect | code',
		image_advtab: true,
	};

	$scope.landingPagesTableOptions = {};
	$scope.landingPagesTableOptions.name = 'Produkte';
	$scope.landingPagesTableOptions.fields = [
		{
			id: 'NAME',
			heading: 'FIELDS.NAME',
			attribute: 'name',
		},
		{
			id: 'SKU',
			heading: 'Sku',
			attribute: 'sku',
		},
	];

	$scope.products = [];
	$scope.publishStates = [];
	$scope.selectedItems = {};
	$scope.listState = 'root.landingPages';

	$scope.data = {};
	$scope.data.dateFrom = null;
	$scope.data.dateTo = null;
	$scope.entityType = 'LandingPage';

	$scope.init('LandingPage').then(function () {
		PublishStateService.findAll().then(function () {
			$scope.publishStates = PublishStateService.getAll();
		});

		if ($scope.entity.activeFrom != null || $scope.entity.activeFrom != undefined) {
			$scope.data.dateFrom = new Date($scope.entity.activeFrom);
		}

		if ($scope.entity.activeTo != null || $scope.entity.activeTo != undefined) {
			$scope.data.dateTo = new Date($scope.entity.activeTo);
		}

		if ($scope.newEntity) {
			$scope.entity.products = [];
		}

		$scope.options = [];
		var countLocales = 0;
		if ($scope.entity.locales != undefined) {
			for (var i = 0; i < $scope.entity.locales.length; i++) {
				if (
					$scope.entity.locales[i].seoInformation != null &&
					$scope.entity.locales[i].seoInformation != undefined
				) {
					if ($scope.entity.locales[i].seoInformation.keywords != undefined) {
						if ($scope.entity.locales[i].seoInformation.keywords.length > countLocales) {
							countLocales = $scope.entity.locales[i].seoInformation.keywords.length;
						}
					}
				}
			}
			for (var i = 0; i < countLocales; i++) {
				$scope.options.push({});
			}
		}
	});

	$scope.prepareSave = function () {
		if ($scope.data.dateFrom != null || $scope.data.dateFrom != undefined) {
			$scope.entity.activeFrom = $scope.data.dateFrom.toISOString();
		}
		if ($scope.data.dateTo != null || $scope.data.dateTo != undefined) {
			$scope.entity.activeTo = $scope.data.dateTo.toISOString();
		}
	};

	$scope.addKeyword = function () {
		$scope.options.push({});
	};

	$scope.removeKeyword = function (index) {
		$scope.options.splice(index, 1);
	};
}
;
