define('modules/login/controllers/LoginController',[
	'app',
	'modules/module',
	'modules/models',
	'services/UserService',
	'services/StateFaderService',
	'services/NavigationService',
], function (module) {
	'use strict';
	module.controller('LoginController', [
		'$rootScope',
		'$scope',
		'$state',
		'UserService',
		'NavigationService',
		function ($rootScope, $scope, $state, UserService, NavigationService) {
			$rootScope.$emit('hideNavigation');

			var state = 1;

			$scope.isLogin = true;

			$scope.reset = { emailOrUsername: '' };
			$scope.showBadUsername = false;
			$scope.showBadCredentials = false;

			$scope.login = function () {
				const loginRequestBody = {
					username: $scope.user.username,
					password: $scope.user.password,
				};

				UserService.login(loginRequestBody).then(
					() => {
						var token = UserService.getToken();
						if (token) {
							// Init menu items after login
							NavigationService.setFilteredMenuItems();

							let navigationItem;

							if (UserService.isEconnectOnlyUser()) {
								navigationItem = NavigationService.getItemByID('ECON');
							} else {
								navigationItem = NavigationService.getItems()[0];
							}

							if (!!navigationItem) {
								NavigationService.setActiveItem(
									navigationItem,
									navigationItem.subMenu ? navigationItem.subMenu[0] : undefined
								);
							}

							$rootScope.$emit('showNavigation');
							$scope.showBadCredentials = false;
						} else {
							$scope.showBadCredentials = true;
						}
					},
					() => {
						$scope.showBadCredentials = true;
					}
				);
			};

			$scope.logout = function () {
				UserService.logout();
				$state.go('root.login');
			};

			$scope.passwordReset = function () {
				UserService.sendPasswordReset($scope.reset.emailOrUsername).then(function () {
					if (UserService.isBadUsername()) {
						$scope.showBadUsername = UserService.isBadUsername();
					}
				});
			};

			$scope.getCurrentUser = function (args) {
				$scope.user = UserService.getUser();
				if (!$scope.user) {
					$scope.user = {};
				}
			};

			$scope.showLoginForm = function () {
				return state === 1;
			};

			$scope.showPassResetForm = function () {
				return state === 2;
			};

			$scope.setState = function (val) {
				$scope.showBadUsername = false;
				$scope.showBadCredentials = false;
				state = val;
			};

			$scope.logout();

			$rootScope.$on('userStatusChanged', $scope.getCurrentUser);
			$scope.getCurrentUser();
		},
	]);
});

