define('services/FileUploadService',['app', 'modules/module'], function (app, module) {
	'use strict';
	return module.factory('FileUploadService', [
		'LocalStorageService',
		'$rootScope',
		function (LocalStorageService, $rootScope) {
			var identifierCount = 0;

			return {
				save: function (key, file) {
					var uploadedFiles = LocalStorageService.read('uploadedFiles');
					if (!uploadedFiles) {
						uploadedFiles = {};
					}
					if (!uploadedFiles[key]) {
						uploadedFiles[key] = [];
					}
					uploadedFiles[key].push(file);
					LocalStorageService.write('uploadedFiles', uploadedFiles);
					$rootScope.$broadcast('uploadedFilesChanged');
				},
				remove: function (key, file) {
					var uploadedFiles = LocalStorageService.read('uploadedFiles');
					if (!uploadedFiles || !uploadedFiles[key]) {
						return;
					}
					for (var i = 0; i < uploadedFiles[key].length; i++) {
						var fileTmp = uploadedFiles[key][i];
						if (fileTmp.name == file.name) {
							uploadedFiles[key].splice(i, 1);
							break;
						}
					}
					LocalStorageService.write('uploadedFiles', uploadedFiles);
					$rootScope.$broadcast('uploadedFilesChanged');
				},
				removeAll: function () {
					LocalStorageService.remove('uploadedFiles');
					$rootScope.$broadcast('uploadedFilesChanged');
				},
				getFiles: function (key) {
					var uploadedFiles = LocalStorageService.read('uploadedFiles');
					if (!uploadedFiles || !uploadedFiles[key]) {
						return [];
					}
					return uploadedFiles[key];
				},
				getImageUrl: function (imageId) {
					return '/api/v1/media/images/' + imageId + '/default-characteristic';
				},
				getUploaderId: function () {
					var identifier = identifierCount++;
					return identifier.toString();
				},
			};
		},
	]);
});

