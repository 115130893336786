define('directives/controls/customInputTime/CustomInputTime',['app', 'settings'], function (app) {
	'use strict';
	app.directive('ngCustomInputTime', [
		'BASE_DIRECTIVES_PATH',
		function (BASE_DIRECTIVES_PATH) {
			return {
				scope: {
					/*hours: "=",
                	minutes: "=",*/
					label: '@',
					options: '=',
					result: '=',
					checkbox: '@',
					disable: '=',
					outerLabel: '=',
				},
				templateUrl: BASE_DIRECTIVES_PATH + '/controls/customInputTime/views/CustomInputTime.html',
				controller: [
					'$scope',
					function ($scope) {
						$scope.changedManually = false;

						$scope.calculateResult = function () {
							var hours = $scope.hours;
							var minutes = $scope.minutes;

							if (hours === undefined) {
								hours = 0;
							}

							if (minutes === undefined) {
								minutes = 0;
							}

							$scope.result = parseInt(hours) * 60 + parseInt(minutes);
						};

						$scope.preventRecalc = function () {
							$scope.changedManually = true;
						};

						$scope.$watch('result', function (newValue, oldValue) {
							if (newValue === undefined || newValue == null) {
								delete $scope.hours;
								delete $scope.minutes;
								return;
							}

							if ($scope.changedManually) {
								$scope.changedManually = false;
								return;
							}

							$scope.hours = Math.floor($scope.result / 60);
							$scope.minutes = $scope.result % 60;
						});

						$scope.$watch('hours', function (newValue, oldValue) {
							if (newValue === undefined || newValue == null) {
								return;
							}
							$scope.calculateResult();
						});

						$scope.$watch('minutes', function (newValue, oldValue) {
							if (newValue === undefined || newValue == null) {
								return;
							}
							$scope.calculateResult();
						});
					},
				],
			};
		},
	]);
});

