define('modules/econ/controllers/EconAutomationController',['modules/module', 'settings', 'modules/basemodule/controllers/EntityListController'], function (module) {
	'use strict';
	module.controller('EconAutomationController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		automationController,
	]);
});

function automationController($scope, $controller, BASE_TEMPLATES_PATH) {
	angular.extend(
		this,
		$controller('EntityListController', {
			$scope: $scope,
		})
	);

	$scope.tableOptions.fields = [
		{
			heading: 'FIELDS.DESIGNATION',
			attribute: 'name',
		},
		{
			heading: 'FIELDS.STATUS',
			template: BASE_TEMPLATES_PATH + '/econ/partials/_automations-enabled-col-template.html',
		},
		{
			heading: 'FIELDS.RECIPIENT_LIST',
			template: BASE_TEMPLATES_PATH + '/econ/partials/_recipient-recipient-list.html',
			searchDisabled: true,
		},
		{
			heading: 'FIELDS.AUTOMATION.TYPE',
			template: BASE_TEMPLATES_PATH + '/econ/partials/_automations-type-col-template.html',
		},
	];

	$scope.formState = 'root.econAutomation';

	$scope.init('Automation');
}
;
