define('modules/notification/controllers/NotificationController',[
	'app',
	'modules/module',
	'modules/models',
	'services/NotificationService',
	'directives/notification/ngNotification',
], function (module) {
	'use strict';
	module.controller('NotificationController', ['$scope', function ($scope) {}]);
});

