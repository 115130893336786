define('modules/wawi/category/controllers/tabs/CategoryProductsTab',['modules/wawi/category/controllers/tabs/CategoryProductsTabController'], function () {
	'use strict';

	return function (
		$parentScope,
		$scope,
		_this,
		{ $controller, BASE_TEMPLATES_PATH, CategoryService, ProductService }
	) {
		function init() {}

		$parentScope.formTabs.push({
			label: 'FIELDS.PRODUCTS',
			template: BASE_TEMPLATES_PATH + '/wawi/category/form-product-list.html',
			init,
		});
	};
});

