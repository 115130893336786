/**
 * Created by nmayer on 09.06.16.
 */
define('modules/settings/branch/BranchFormController',[
	'modules/module',
	'modules/basemodule/controllers/EntityFormController',
	'services/ValidationService',
	'settings',
], function (module) {
	'use strict';
	module.controller('BranchFormController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		'ValidationService',
		function ($scope, $controller, BASE_TEMPLATES_PATH, ValidationService) {
			angular.extend(
				this,
				$controller('EntityFormController', {
					$scope: $scope,
				})
			);

			$scope.formTabs = [
				{
					label: 'FIELDS.GENERAL',
					template: BASE_TEMPLATES_PATH + '/settings/branch/form-main.html',
					active: true,
				},
			];
			$scope.init('Industry').then(function () {});

			$scope.listState = 'root.branches';

			$scope.validate = function () {
				return ValidationService.validate($scope.entity, {
					name: {
						method: 'isset',
						fieldName: 'Name',
					},
				});
			};
		},
	]);
});

