/**
 * Created by mrafiq on 03.07.18.
 */
define('modules/cms/controllers/ProductFaqFormController',[
	'modules/module',
	'modules/basemodule/controllers/EntityFormController',
	'services/ValidationService',
	'directives/languageForm/languageForm',
	'services/CustomerService',
	'services/ProductService',
	'modules/cms/controllers/RatingSelectProductController',
	'modules/cms/controllers/RatingSelectCustomerController',

	'settings',
], function (module) {
	'use strict';
	module.controller('ProductFaqFormController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		'CustomerService',
		'ProductService',
		'PublishStateService',
		'ValidationService',
		ProductFaqFormController,
	]);
});

function ProductFaqFormController(
	$scope,
	$controller,
	BASE_TEMPLATES_PATH,
	CustomerService,
	ProductService,
	PublishStateService,
	ValidationService
) {
	$scope.formTabs = [
		{
			label: 'FIELDS.GENERAL',
			template: BASE_TEMPLATES_PATH + '/cms/productFaq/form-main.html',
			active: true,
		},
	];

	$scope.listState = 'root.productFaqs';
	$scope.statusSwitch;

	angular.extend(
		this,
		$controller('EntityFormController', {
			$scope: $scope,
		})
	);

	PublishStateService.findAll().then(function () {
		$scope.publishStates = PublishStateService.getAll(true);
	});

	$scope.init('ProductFaq').then(function () {
		if ($scope.newEntity) {
			$scope.entity.publishState = 'MAINTENANCE';
		}

		if ($scope.entity.publishState == 'ACTIVE') $scope.statusSwitch = true;

		if ($scope.entity.publishState == 'MAINTENANCE') $scope.statusSwitch = false;

		if ($scope.entity.customerId != undefined) {
			CustomerService.findOne($scope.entity.customerId).then(function () {
				var customer = CustomerService.getOne();
				if (customer.error) {
					$scope.customer = 'Gastkauf';
				} else {
					$scope.customer = customer.firstName + ' ' + customer.lastName;
					$scope.entity.customerId = customer.id;
				}
			});

			ProductService.findByDefaultLocale($scope.entity.productId).then(function (response) {
				$scope.product = response;
			});
		}
	});

	$scope.onChange = function (cbState) {
		if (cbState) $scope.entity.publishState = 'ACTIVE';
		if (!cbState) $scope.entity.publishState = 'MAINTENANCE';

		//$scope.message = cbState;
	};

	$scope.onSuccessProduct = function (result) {
		$scope.product = result;
		$scope.entity.productId = result.id;
		$scope.entityService.setType('ProductFaq');
	};

	$scope.onSuccessCustomer = function (result) {
		$scope.customer = result.firstName + ' ' + result.lastName;
		$scope.entity.customerId = result.id;
		$scope.entityService.setType('ProductFaq');
	};

	$scope.onCancel = function () {
		$scope.entityService.setType('ProductFaq');
	};

	$scope.dialogCustomerSelectController = function ($scope, dataToPass, $mdDialog) {
		angular.extend(
			this,
			$controller('RatingSelectCustomerController', {
				$scope: $scope,
			})
		);

		$scope.setCustomer = function (customer) {
			$scope.customer = customer;
			$mdDialog.hide($scope.customer);
		};

		// $scope.data = dataToPass;
		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		$scope.addSelectedCustomerAndBack = function (inv, ship) {
			if ($scope.selected.items.length == 1) {
				$scope.addSelectedCustomer();
				$mdDialog.hide($scope.customer);
			}
		};

		/*      $scope.addSelectedCustomer = function () {
         $scope.selectedCustomer = $scope.selected.items[0];
         $scope.customer = $scope.selectedCustomer;
         console.log($scope.customer.name);
         /!*for (var p = 0; p < $scope.selected.items.length; p++) {
         addList.push($scope.wrapIntoRating($scope.selected.items[p]));
         }*!/
         //OrderService.pushProductsInOrderForm(addList);
         /!*  ShoppingCartService.addProducts(addList, true).then(
         function () {
         OrderService.refreshOrderCart();
         }
         );*!/
         };*/
	};

	$scope.dialogProductSelectController = function ($scope, dataToPass, $mdDialog) {
		angular.extend(
			this,
			$controller('RatingSelectProductController', {
				$scope: $scope,
			})
		);

		$scope.cancel = function () {
			$mdDialog.cancel();
		};

		$scope.addSelectedProductAndBack = function () {
			if ($scope.selected.items.length == 0) alert('Please select a product');
			if ($scope.selected.items.length > 1) alert('Please select only one product');
			if ($scope.selected.items.length == 1) {
				$scope.addSelectedProduct();
				$mdDialog.hide($scope.product);
			}
		};
		/*
         $scope.addProduct = function(product) {
         //OrderService.pushProductsInOrderForm([$scope.wrapIntoOrder(product)]);
         ShoppingCartService.addProducts([$scope.wrapIntoOrder(product)], true).then(
         function () {
         OrderService.refreshOrderCart();
         }
         );
         };*/

		$scope.addSelectedProduct = function () {
			$scope.selectedProduct = $scope.selected.items[0];
			$scope.product = $scope.selectedProduct;
			/*for (var p = 0; p < $scope.selected.items.length; p++) {
             addList.push($scope.wrapIntoRating($scope.selected.items[p]));
             }*/
			//OrderService.pushProductsInOrderForm(addList);
			/*  ShoppingCartService.addProducts(addList, true).then(
             function () {
             OrderService.refreshOrderCart();
             }
             );*/
		};
	};

	$scope.validate = function () {
		return ValidationService.validate($scope.entity, {
			productId: {
				method: 'isset',
				fieldName: 'Product',
			},
			comment: {
				method: 'isset',
				fieldName: 'Kommentar',
			},
		});
	};

	/*   $scope.$on("beforeUpdate", function () {
     $scope.beforeSave();
     });

     $scope.$on("beforeCreate", function () {
     $scope.beforeSave();
     });


     $scope.beforeSave = function () {
     if($scope.statusSwitch)
     $scope.entity.publishState == "ACTIVE"

     if(!$scope.statusSwitch)
     $scope.entity.publishState == "MAINTENANCE"


     };*/

	/* $scope.validate = function () {
     return ValidationService.validate($scope.entity, {
     rating: {
     method: "isset",
     fieldName: "Name",
     errorMsg: "Das Feld Name darf in der Standardsprache des Shops nicht leer sein",
     isLocaleValue: true
     }
     });
     };*/
}
;
