/**
 * Created by nmayer on 09.06.16.
 */
define('modules/settings/couponCode/CouponCodeFormController',[
	'modules/module',
	'modules/basemodule/controllers/EntityFormController',
	'services/ValidationService',
	'settings',
], function (module) {
	'use strict';
	module.controller('CouponCodeFormController', [
		'$scope',
		'$controller',
		'BASE_TEMPLATES_PATH',
		'ValidationService',
		function ($scope, $controller, BASE_TEMPLATES_PATH, ValidationService) {
			angular.extend(
				this,
				$controller('EntityFormController', {
					$scope: $scope,
				})
			);

			$scope.state.requireSuperuserForUpdate = true;

			$scope.formTabs = [
				{
					label: 'Rabattcodegenerator',
					template: BASE_TEMPLATES_PATH + '/settings/couponCode/form-main.html',
					active: true,
				},
			];
			$scope.init('CouponCode').then(function () {
				if ($scope.entity.values == undefined) {
					$scope.entity.values = [{}];
				}
				if ($scope.entity.couponCodeType == 'COMPLEX' && $scope.entityId != '') {
					$scope.formTabs.push({
						label: 'Rabattcodes',
						template: BASE_TEMPLATES_PATH + '/settings/couponCode/form-values.html',
					});
				}
			});

			$scope.listState = 'root.couponcodes';

			$scope.inputTypes = [
				{
					value: 'SIMPLE',
					display: 'Einfach',
				},
				{
					value: 'COMPLEX',
					display: 'Komplex',
				},
			];

			$scope.validate = function () {
				if ($scope.entity.couponCodeType == 'SIMPLE') {
					return ValidationService.validate($scope.entity, {
						name: {
							method: 'isset',
							fieldName: 'Name',
						},
						couponCodeType: {
							method: 'isset',
							fieldName: 'Rabattart',
						},
						value: {
							method: 'isset',
							fieldName: 'Rabattcode',
						},
					});
				} else if ($scope.entity.couponCodeType == 'COMPLEX') {
					return ValidationService.validate($scope.entity, {
						name: {
							method: 'isset',
							fieldName: 'Name',
						},
						couponCodeType: {
							method: 'isset',
							fieldName: 'Rabattart',
						},
						amount: {
							method: 'isset',
							fieldName: 'Stückzahl',
						},
						codeLength: {
							method: 'isset',
							fieldName: 'Codelänge',
						},
						startNumber: {
							method: 'isset',
							fieldName: 'Erste Zahl',
						},
					});
				} else {
					return ValidationService.validate($scope.entity, {
						name: {
							method: 'isset',
							fieldName: 'Name',
						},
						couponCodeType: {
							method: 'isset',
							fieldName: 'Rabattart',
						},
					});
				}
			};

			$scope.$on('beforeUpdate', function () {
				$scope.beforeSave();
			});

			$scope.$on('beforeCreate', function () {
				$scope.beforeSave();
			});

			$scope.beforeSave = function () {
				if ($scope.entity.couponCodeType === 'SIMPLE') {
					if ($scope.entity.values != undefined) {
						delete $scope.entity.values;
					}
				}
			};

			$scope.addValue = function () {
				if ($scope.entity.values == undefined) {
					$scope.entity.values = [];
				}
				$scope.entity.values.push({});
			};

			$scope.removeValue = function (index) {
				$scope.entity.values.splice(index, 1);
			};
		},
	]);
});

