define('filters/RemoveSpacesAndSpecialCharacters',['app'], function (app) {
	'use strict';
	app.filter('removeSpacesAndSpecialCharacters', function () {
		return function (string) {
			if (!angular.isString(string)) {
				return string;
			}
			var tmpString = string.replace(/[\s]/g, '');
			return tmpString.replace(/[^A-Za-z0-9]/g, '');
		};
	});
});

