/**
 * Created by nmayer on 20.05.16.
 */
define('modules/imEx/controllers/ServerProfilesFormController',[
	'modules/module',
	'modules/basemodule/controllers/EntityFormController',
	'services/ValidationService',
	'settings',
], function (module) {
	'use strict';
	module.controller('ServerProfilesFormController', [
		'$scope',
		'$controller',
		'ValidationService',
		'BASE_TEMPLATES_PATH',
		function ($scope, $controller, ValidationService, BASE_TEMPLATES_PATH) {
			angular.extend(
				this,
				$controller('EntityFormController', {
					$scope: $scope,
				})
			);

			$scope.formTabs = [
				{
					label: 'FIELDS.GENERAL',
					template: BASE_TEMPLATES_PATH + '/imEx/partials/form-serverprofiles.html',
					active: true,
				},
			];

			$scope.init('ServerProfile').then(function () {
				if ($scope.entity.port == undefined) {
					$scope.entity.port = 21;
				}
			});

			$scope.listState = 'root.serverprofiles';

			$scope.validate = function () {
				return ValidationService.validate($scope.entity, {
					name: {
						method: 'isset',
						fieldName: 'Name',
					},
					host: {
						method: 'isset',
						fieldName: 'Host',
					},
					port: {
						method: 'isset',
						fieldName: 'Port',
					},
					username: {
						method: 'isset',
						fieldName: 'Benutzername',
					},
				});
			};
		},
	]);
});

